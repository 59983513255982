import { forwardRef, useEffect } from 'react';

type Props = {};

const MatchScoreInfoView = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const {} = props;

  return (
    <div className="flex flex-row justify-start mt-2">
      <div
        className="inline-flex flex-col py-4 pl-5 pr-7 rounded text-slate-950 text-base"
        style={{ backgroundColor: '#9ae8dbbb' }}
      >
        <div className="text-start">
          <span className="font-bold">Match scores </span>
          <span>are based on </span>
        </div>

        <div className="flex items-start border-l-2 border-l-black mt-2">
          <div className="ml-2.5 gap-y-2 text-start">
            <div>Competition in Sites and nearby</div>
            <div>Current Capacity at Site</div>
            <div>Access to Patient Population</div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default MatchScoreInfoView;
