import { ScenarioColors } from './colors_data';
import { Scenario, ScenarioData } from './models/scenario';
import { Site } from './models/site';

export const fakeEnrollmentScenarioData = [
  {
    name: 'Q4 2021',
    // date: '1',
    a: {
      date: new Date('2021-10-01').toISOString(),
      quarter: 'Q4 2021',
      mostLikely: 1,
      bestCase: 1,
      worstCase: 1,
    },
    b: {
      date: new Date('2021-10-01').toISOString(),
      quarter: 'Q4 2021',
      mostLikely: 1,
      bestCase: 1,
      worstCase: 1,
    },
    c: {
      date: new Date('2021-10-01').toISOString(),
      quarter: 'Q4 2021',
      mostLikely: 1,
      bestCase: 1,
      worstCase: 1,
    },
  },
  {
    name: 'Q1 2022',
    // date: 2,
    a: {
      date: new Date('2022-01-01').toISOString(),
      quarter: 'Q1 2022',
      mostLikely: 5,
      bestCase: 6,
      worstCase: 4,
    },
    b: {
      date: new Date('2022-01-01').toISOString(),
      quarter: 'Q1 2022',
      mostLikely: 8,
      bestCase: 9,
      worstCase: 5,
    },
    c: {
      date: new Date('2022-01-01').toISOString(),
      quarter: 'Q1 2022',
      mostLikely: 6,
      bestCase: 7,
      worstCase: 5,
    },
  },
  {
    name: 'Q2 2022',
    // date: 3,
    a: {
      date: new Date('2022-04-01').toISOString(),
      quarter: 'Q2 2022',
      mostLikely: 15,
      bestCase: 16,
      worstCase: 12,
    },
    b: {
      date: new Date('2022-04-01').toISOString(),
      quarter: 'Q2 2022',
      mostLikely: 18,
      bestCase: 21,
      worstCase: 16,
    },
    c: {
      date: new Date('2022-04-01').toISOString(),
      quarter: 'Q2 2022',
      mostLikely: 16,
      bestCase: 18,
      worstCase: 14,
    },
  },
  {
    name: 'Q3 2022',
    // date: 4,
    a: {
      date: new Date('2022-07-01').toISOString(),
      quarter: 'Q3 2022',
      mostLikely: 18,
      bestCase: 20,
      worstCase: 17,
    },
    b: {
      date: new Date('2022-07-01').toISOString(),
      quarter: 'Q3 2022',
      mostLikely: 25,
      bestCase: 28,
      worstCase: 22,
    },
    c: {
      date: new Date('2022-07-01').toISOString(),
      quarter: 'Q3 2022',
      mostLikely: 23,
      bestCase: 27,
      worstCase: 20,
    },
  },
  {
    name: 'Q4 2022',
    // date: 5,
    a: {
      date: new Date('2022-10-01').toISOString(),
      quarter: 'Q4 2022',
      mostLikely: 22,
      bestCase: 23,
      worstCase: 20,
    },
    b: {
      date: new Date('2022-10-01').toISOString(),
      quarter: 'Q4 2022',
      mostLikely: 30,
      bestCase: 33,
      worstCase: 26,
    },
    c: {
      date: new Date('2022-10-01').toISOString(),
      quarter: 'Q4 2022',
      mostLikely: 27,
      bestCase: 30,
      worstCase: 24,
    },
  },
  {
    name: 'Q1 2023',
    // date: 6,
    a: {
      date: new Date('2023-01-01').toISOString(),
      quarter: 'Q1 2023',
      mostLikely: 32,
      bestCase: 33,
      worstCase: 28,
    },
    b: {
      date: new Date('2023-01-01').toISOString(),
      quarter: 'Q1 2023',
      mostLikely: 39,
      bestCase: 41,
      worstCase: 36,
    },
    c: {
      date: new Date('2023-01-01').toISOString(),
      quarter: 'Q1 2023',
      mostLikely: 36,
      bestCase: 38,
      worstCase: 30,
    },
  },
  {
    name: 'Q2 2023',
    // date: 7,
    a: {
      date: new Date('2023-04-01').toISOString(),
      quarter: 'Q2 2023',
      mostLikely: 40,
      bestCase: 44,
      worstCase: 36,
    },
    b: {
      date: new Date('2023-04-01').toISOString(),
      quarter: 'Q2 2023',
      mostLikely: 56,
      bestCase: 60,
      worstCase: 53,
    },
    c: {
      date: new Date('2023-04-01').toISOString(),
      quarter: 'Q2 2023',
      mostLikely: 55,
      bestCase: 60,
      worstCase: 50,
    },
  },
  {
    name: 'Q3 2023',
    // date: 8,
    a: {
      date: new Date('2023-07-01').toISOString(),
      quarter: 'Q3 2023',
      mostLikely: 55,
      bestCase: 60,
      worstCase: 48,
    },
    b: {
      date: new Date('2023-07-01').toISOString(),
      quarter: 'Q3 2023',
      mostLikely: 75,
      bestCase: 78,
      worstCase: 72,
    },
    c: {
      date: new Date('2023-07-01').toISOString(),
      quarter: 'Q3 2023',
      mostLikely: 70,
      bestCase: 75,
      worstCase: 67,
    },
  },
];

const getScenarioData = (key: string): ScenarioData[] => {
  return fakeEnrollmentScenarioData.map((d: any) => {
    const convertQuarterToDate = (quarter: string) => {
      const [q, year] = quarter.split(' ');
      const month = parseInt(q.slice(1)) * 3 - 2;
      return `${month < 10 ? '0' : ''}${month}/${year.slice(2)}`;
    };

    return {
      date: d[key].date,
      quarter: convertQuarterToDate(d[key].quarter),
      mostLikely: d[key].mostLikely,
      bestCase: d[key].bestCase,
      worstCase: d[key].worstCase,
    };
  });
};

export const ScenariosFakeData: Scenario[] = [
  {
    id: 'a',
    name: 'UK Only',
    color: ScenarioColors[0],
    data: getScenarioData('a'),
  },
  {
    id: 'b',
    name: 'UK and EU',
    color: ScenarioColors[1],
    data: getScenarioData('b'),
  },
  {
    id: 'c',
    name: 'UK, EU, and APAC',
    color: ScenarioColors[2],
    data: getScenarioData('c'),
  },
];

export const FakeSites: Site[] = [
  {
    id: '1',
    name: 'Mayo Clinic',
    rand: 0.3,
    historicalStudies: {
      current: 6,
      max: 125,
    },
    patients: {
      current: 11,
      max: 49,
    },
    scenarioId: 'a',
    position: { lat: 53.54992, lng: 10.0178 },
  },
  {
    id: '2',
    name: 'Guys Hospital',
    rand: 0.1,
    historicalStudies: {
      current: 5,
      max: 50,
    },
    patients: {
      current: 9,
      max: 30,
    },
    scenarioId: 'a',
    position: { lat: 53.552, lng: 10.0069 },
  },
  {
    id: '3',
    name: 'Royal Marsden Hospital',
    rand: 0.1,
    historicalStudies: {
      current: 2,
      max: 20,
    },
    patients: {
      current: 4,
      max: 15,
    },
    scenarioId: 'b',
    position: { lat: 53.547, lng: 10.0065 },
  },
  {
    id: '4',
    name: 'St. Thomas Hospital',
    rand: 0.1,
    historicalStudies: {
      current: 1,
      max: 10,
    },
    patients: {
      current: 3,
      max: 8,
    },
    scenarioId: 'b',
    position: { lat: 53.545, lng: 10.0 },
  },
];

export const fakeEnrollmentScenarioData2 = [
  {
    name: '10/21',
    // date: '1',
    a: {
      date: new Date('2021-10-01').toISOString(),
      quarter: 'Q4 2021',
      mostLikely: 1,
      bestCase: 1,
      worstCase: 1,
    },
    b: {
      date: new Date('2021-10-01').toISOString(),
      quarter: 'Q4 2021',
      mostLikely: 1,
      bestCase: 1,
      worstCase: 1,
    },
    c: {
      date: new Date('2021-10-01').toISOString(),
      quarter: 'Q4 2021',
      mostLikely: 1,
      bestCase: 1,
      worstCase: 1,
    },
  },
  {
    name: '01/22',
    // date: 2,
    a: {
      date: new Date('2022-01-01').toISOString(),
      quarter: 'Q1 2022',
      mostLikely: 5,
      bestCase: 6,
      worstCase: 4,
    },
    b: {
      date: new Date('2022-01-01').toISOString(),
      quarter: 'Q1 2022',
      mostLikely: 8,
      bestCase: 9,
      worstCase: 5,
    },
    c: {
      date: new Date('2022-01-01').toISOString(),
      quarter: 'Q1 2022',
      mostLikely: 6,
      bestCase: 7,
      worstCase: 5,
    },
  },
  {
    name: '04/22',
    // date: 3,
    a: {
      date: new Date('2022-04-01').toISOString(),
      quarter: 'Q2 2022',
      mostLikely: 15,
      bestCase: 16,
      worstCase: 12,
    },
    b: {
      date: new Date('2022-04-01').toISOString(),
      quarter: 'Q2 2022',
      mostLikely: 18,
      bestCase: 21,
      worstCase: 16,
    },
    c: {
      date: new Date('2022-04-01').toISOString(),
      quarter: 'Q2 2022',
      mostLikely: 16,
      bestCase: 18,
      worstCase: 14,
    },
  },
  {
    name: '07/22',
    // date: 4,
    a: {
      date: new Date('2022-07-01').toISOString(),
      quarter: 'Q3 2022',
      mostLikely: 18,
      bestCase: 20,
      worstCase: 17,
    },
    b: {
      date: new Date('2022-07-01').toISOString(),
      quarter: 'Q3 2022',
      mostLikely: 25,
      bestCase: 28,
      worstCase: 22,
    },
    c: {
      date: new Date('2022-07-01').toISOString(),
      quarter: 'Q3 2022',
      mostLikely: 23,
      bestCase: 27,
      worstCase: 20,
    },
  },

  {
    name: '10/22',
    // date: 5,
    a: {
      date: new Date('2022-10-01').toISOString(),
      quarter: 'Q4 2022',
      mostLikely: 22,
      bestCase: 23,
      worstCase: 20,
    },
    b: {
      date: new Date('2022-10-01').toISOString(),
      quarter: 'Q4 2022',
      mostLikely: 30,
      bestCase: 33,
      worstCase: 26,
    },
    c: {
      date: new Date('2022-10-01').toISOString(),
      quarter: 'Q4 2022',
      mostLikely: 27,
      bestCase: 30,
      worstCase: 24,
    },
  },
  {
    name: '01/23',
    // date: 6,
    a: {
      date: new Date('2023-01-01').toISOString(),
      quarter: 'Q1 2023',
      mostLikely: 32,
      bestCase: 33,
      worstCase: 28,
    },
    b: {
      date: new Date('2023-01-01').toISOString(),
      quarter: 'Q1 2023',
      mostLikely: 39,
      bestCase: 41,
      worstCase: 36,
    },
    c: {
      date: new Date('2023-01-01').toISOString(),
      quarter: 'Q1 2023',
      mostLikely: 36,
      bestCase: 38,
      worstCase: 30,
    },
  },
  {
    name: '04/23',
    // date: 7,
    a: {
      date: new Date('2023-04-01').toISOString(),
      quarter: 'Q2 2023',
      mostLikely: 40,
      bestCase: 44,
      worstCase: 36,
    },
    b: {
      date: new Date('2023-04-01').toISOString(),
      quarter: 'Q2 2023',
      mostLikely: 56,
      bestCase: 60,
      worstCase: 53,
    },
    c: {
      date: new Date('2023-04-01').toISOString(),
      quarter: 'Q2 2023',
      mostLikely: 55,
      bestCase: 60,
      worstCase: 50,
    },
  },
];
