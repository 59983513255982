import React from 'react';
import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InvisibleInput,
} from '../components/StyledElements';

import { Brainstorm } from '@/types/Brainstorm';

const Step2InterventionType: React.FC<{
  brainstorm: Brainstorm;
  setBrainstorm: (brainstorm: Brainstorm) => void;
  onNextStep: () => void;
  field: keyof Brainstorm;
  placeholder: string;
}> = ({
  brainstorm,
  setBrainstorm,
  onNextStep,
  field,
  placeholder = 'Tell me your intervention type here',
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBrainstorm({ ...brainstorm, [field]: e.target.value });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      onNextStep();
    }
  };

  return (
    <>
      <InputLikeElement>
        <InvisibleInput
          placeholder={placeholder}
          value={brainstorm[field] as string}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <CircleButton onClick={onNextStep}>
          <ArrowRightIcon />
        </CircleButton>
      </InputLikeElement>
    </>
  );
};

export default Step2InterventionType;
