import { useState } from 'react';

interface Site {
  id: string;
  name: string;
  num_studies: number;
  num_patients: number;
  active: boolean;
  nearby_active: boolean;
  speciality: number;
  coordinates: [number, number];
  rand: number;
  studies: string[];
}

interface SitesResponse {
  avg_rand: number;
  max_rand: number;
  avg_num_studies: number;
  max_num_studies: number;
  sites: Site[];
  siteSearchId: string; // Add siteSearchId to the response interface
}

interface RerankingResponse {
  ranked_results: string[];
}

export default function MockMLPage() {
  const [data, setData] = useState<SitesResponse | null>(null);
  const [rerankingData, setRerankingData] = useState<RerankingResponse | null>(
    null,
  );
  const [loading, setLoading] = useState(false);
  const [reranking, setReranking] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    try {
      const response = await fetch('/cloudFunc/siteSearch', {
        headers: {
          accept: 'application/json',
        },
      });

      const data = await response.json();
      setData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const rerank = async () => {
    setReranking(true);
    try {
      console.log(data?.siteSearchId);
      const response = await fetch('/api/criteria_to_sites', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          research_id: data?.siteSearchId,
          inclusion_list: ['Erm, what the sigma?'],
          exclusion_list: ['We can go - for -'],
          isProd: true,
        }),
      });
      const responseData = await response.json();
      setRerankingData(responseData);
      // Reorder the sites array based on ranked_results
      const rankedSites = responseData.ranked_results
        .map((id: string) => data?.sites.find((site: any) => site.id === id))
        .filter((site: Site | undefined) => site !== undefined) as Site[];

      setData((prevData) =>
        prevData ? { ...prevData, sites: rankedSites } : prevData,
      );
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setReranking(false);
    }
  };

  return (
    <div className="h-full w-full relative p-4">
      <div className="flex flex-row">
        <button
          className="btn btn-primary mb-4 mr-4"
          onClick={fetchData}
          disabled={loading}
        >
          {loading ? 'Loading...' : 'Fetch Site Searches'}
        </button>
        <button
          className="btn btn-primary mb-4"
          onClick={rerank}
          disabled={data == null}
        >
          {loading ? 'Loading...' : 'Rerank sites'}
        </button>
      </div>
      {data ? (
        <div>
          <div className="flex-row flex flex-auto">
            <div className="mb-4">
              <h1 className="text-2xl font-bold text-white">
                Site Search Results
              </h1>
              <p className="text-white">
                <strong>Firestore Document ID:</strong> {data.siteSearchId}
              </p>
              <p className="text-white">
                <strong>Average Randomization:</strong> {data.avg_rand}
              </p>
              <p className="text-white">
                <strong>Maximum Randomization:</strong> {data.max_rand}
              </p>
              <p className="text-white">
                <strong>Average Number of Studies:</strong>{' '}
                {data.avg_num_studies}
              </p>
              <p className="text-white">
                <strong>Maximum Number of Studies:</strong>{' '}
                {data.max_num_studies}
              </p>
            </div>
            <div className="text-white">blah</div>
          </div>
          <div className="grid grid-cols-1 gap-4">
            {data.sites.map((site, index) => (
              <div
                key={`${site.id}-${index}`}
                className="card w-full bg-base-100 shadow-xl"
              >
                <div className="card-body">
                  <h2 className="card-title">{site.name}</h2>
                  <p>
                    <strong>Number of Studies:</strong> {site.num_studies}
                  </p>
                  <p>
                    <strong>Number of Patients:</strong> {site.num_patients}
                  </p>
                  <p>
                    <strong>Active:</strong> {site.active ? 'Yes' : 'No'}
                  </p>
                  <p>
                    <strong>Nearby Active:</strong>{' '}
                    {site.nearby_active ? 'Yes' : 'No'}
                  </p>
                  <p>
                    <strong>Specialty:</strong> {site.speciality}
                  </p>
                  <p>
                    <strong>Coordinates:</strong> [{site.coordinates[0]},{' '}
                    {site.coordinates[1]}]
                  </p>
                  <p>
                    <strong>Randomization:</strong> {site.rand}
                  </p>
                  <p>
                    <strong>Studies:</strong> {site.studies.join(', ')}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : loading ? (
        'Loading...'
      ) : (
        'Click the button to fetch data'
      )}
    </div>
  );
}
