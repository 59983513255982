import React from 'react';

interface NavItemProps {
  iconSrc: string;
  label: string;
  isActive?: boolean;
  onClick?: () => void;
  isExpanded: boolean;
  link?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  iconSrc,
  label,
  isActive,
  onClick,
  isExpanded,
  link,
}) => {
  const handleClick = (e: React.MouseEvent) => {
    if (!link && onClick) {
      e.preventDefault();
      onClick();
    }
  };

  const content = (
    <>
      <img src={iconSrc} alt={label} className="w-6 h-6 flex-shrink-0" />
      <div
        className={`
          ml-4 overflow-hidden transition-all duration-300 ease-in-out
          ${isExpanded ? 'w-40 opacity-100' : 'w-0 opacity-0'}
        `}
      >
        <span
          className="whitespace-nowrap text-left text-lg font-normal leading-[25.2px]"
          style={{
            letterSpacing: '-0.015em',
          }}
        >
          {label}
        </span>
      </div>
    </>
  );

  const className = `
    flex items-center cursor-pointer h-12
    transition-all duration-300 ease-in-out
    ${isActive ? 'text-white' : 'text-gray-400 hover:text-gray-200'}
  `;

  return (
    <a href={link || '#'} className={className} onClick={handleClick}>
      {content}
    </a>
  );
};

export default NavItem;
