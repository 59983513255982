import Gantt from 'frappe-gantt';
import { useCallback, useEffect, useRef, useState } from 'react';
import randImage from '../../../images/rand.svg';
import './gantt.css';
import RandView from './RandView';
import clsx from 'clsx';

type Site = {
  name: string;
  rand: number;
  firstPatientStartDate: Date;
  firstPatientEndDate: Date;
};

const SiteHeader = () => {
  return (
    <div className="flex flex-row text-white text-left pl-4">
      <div className="flex flex-col mr-10">
        <div className="text-[0.43rem] font-semibold">estimated</div>
        <div className="font-medium leading-4">Time to 1 Patient</div>
      </div>

      <div className="flex flex-col">
        <div
          className="text-[0.43rem] font-semibold"
          style={{ color: '#BFBDC9' }}
        >
          avg. randomization{' '}
        </div>
        <RandView value={0.75} medium />
      </div>
    </div>
  );
};

const SiteItem = ({ site, leadSite }: { site: Site; leadSite: boolean }) => {
  const rand = site.rand.toFixed(2).replace(/\.?0+$/, '');

  return (
    <div className="flex flex-row pl-4 pr-2 py-1.5 text-left h-[50px] items-center">
      <div
        className="flex flex-row px-1.5 py-0.5 mr-4 h-min items-center rounded"
        style={{ minWidth: 70, backgroundColor: '#2C2B32' }}
      >
        <img src={randImage} alt="rand icon" className="h-3 mr-1.5" />
        <span className="text-white text-sm">{rand}</span>
      </div>
      <div
        className={clsx('text-white text-sm', leadSite && 'font-medium')}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'block',
          textOverflow: 'ellipsis',
        }}
      >
        {site.name}
      </div>
    </div>
  );
};

type GanttViewModeOption = 'Day' | 'Week' | 'Month' | 'Year';

const GanttViewMode = (props: {
  isSelected: boolean;
  changeViewMode: (viewMode: GanttViewModeOption) => void;
  viewMode: GanttViewModeOption;
}) => {
  const { changeViewMode, viewMode, isSelected } = props;

  return (
    <div
      className={clsx(
        'px-4 pt-[5px] pb-1 cursor-pointer rounded-3xl mr-2 text-gray-100 text-sm',
        isSelected
          ? 'bg-primary border-primary'
          : 'bg-gray-800 border border-gray-700',
      )}
      onClick={() => changeViewMode(viewMode)}
    >
      {viewMode}
    </div>
  );
};

const sites: Site[] = [
  {
    name: 'Mayo Clinic',
    rand: 0.3,
    firstPatientStartDate: new Date('2021-01-03'),
    firstPatientEndDate: new Date('2021-03-31'),
  },
  {
    name: 'Guy`s Hospital',
    rand: 0.1,
    firstPatientStartDate: new Date('2021-01-10'),
    firstPatientEndDate: new Date('2021-05-28'),
  },
  {
    name: 'Royal Marsden Hospital',
    rand: 0.1,
    firstPatientStartDate: new Date('2021-02-10'),
    firstPatientEndDate: new Date('2021-06-28'),
  },
];

export default function TimelineChart() {
  const ganttRef = useRef<Gantt | null>(null);
  const currentObservers = useRef<MutationObserver[]>([]);
  const [gantt, setGantt] = useState<Gantt | null>(null);
  const [selectedGanttViewMode, setSelectedGanttViewMode] =
    useState<GanttViewModeOption>('Month');

  const headerHeight = 60;
  const siteItemHeight = 50;
  const scrollbarHeight = 24;

  const drawDashedLines = useCallback(() => {
    const lines = document.querySelectorAll('.grid g line.row-line');
    lines.forEach((line) => {
      const y1 = line.getAttribute('y1');
      if (!y1) {
        return;
      }

      const yOffSet = 25;
      const newY1 = (parseFloat(y1) - yOffSet).toString();
      line.setAttribute('y1', newY1);
      line.setAttribute('y2', newY1);
    });
  }, []);

  const updateGanttHeight = useCallback(() => {
    const ganttElement = document.querySelector('svg.gantt');
    const totalHeight =
      headerHeight + siteItemHeight * sites.length + scrollbarHeight;
    ganttElement?.setAttribute('height', totalHeight.toString());
  }, []);

  const addFirstPatientIcons = useCallback(() => {
    const bars = document.querySelectorAll('.bar-wrapper .bar');
    bars.forEach((bar) => {
      const squareClass = 'first-p-icon-bg';
      const text1Class = 'first-p-icon-text-1';
      const text2Class = 'first-p-icon-text-2';

      const createFirstPIconIfNeeded = () => {
        const minBarWidthForIcon = 200;
        const onlyIconBarWidth = 142;

        const firstPIconSize = 24;
        const width = parseFloat(bar.getAttribute('width')!);

        const shouldDisplayOnlyIcon =
          width > firstPIconSize * 1.5 && width <= onlyIconBarWidth;

        if (width < minBarWidthForIcon && !shouldDisplayOnlyIcon) {
          const squareElement = bar.parentElement?.querySelector(
            `.${squareClass}`,
          );
          const textElement1 = bar.parentElement?.querySelector(
            `.${text1Class}`,
          );
          const textElement2 = bar.parentElement?.querySelector(
            `.${text2Class}`,
          );

          squareElement?.remove();
          textElement1?.remove();
          textElement2?.remove();
          return;
        }

        if (bar.parentElement?.querySelector(`.${squareClass}`)) {
          return;
        }

        const svgNS = 'http://www.w3.org/2000/svg';
        const squareElement = document.createElementNS(svgNS, 'rect');
        squareElement.classList.add(squareClass);
        squareElement.setAttribute('width', firstPIconSize.toString());
        squareElement.setAttribute('height', firstPIconSize.toString());
        squareElement.setAttribute('fill', '#00C7A8');
        squareElement.setAttribute('rx', '4');
        squareElement.setAttribute('ry', '4');
        bar.parentElement?.appendChild(squareElement);

        const textElement1 = document.createElementNS(svgNS, 'text');
        textElement1.classList.add(text1Class);
        textElement1.setAttribute('fill', 'white');
        textElement1.setAttribute('font-size', '12');
        textElement1.textContent = '1';

        const textElement2 = document.createElementNS(svgNS, 'text');
        textElement2.classList.add(text2Class);
        textElement2.setAttribute('fill', 'white');
        textElement2.setAttribute('font-size', '12');
        textElement2.textContent = 'p';

        bar.parentElement?.appendChild(textElement1);
        bar.parentElement?.appendChild(textElement2);
      };

      const updateCoordinates = () => {
        const squareElement = bar.parentElement?.querySelector(
          `.${squareClass}`,
        );
        const textElement1 = bar.parentElement?.querySelector(`.${text1Class}`);
        const textElement2 = bar.parentElement?.querySelector(`.${text2Class}`);

        if (!squareElement || !textElement1 || !textElement2) {
          return;
        }

        const newX = parseFloat(bar.getAttribute('x')!);
        squareElement.setAttribute('x', (newX + 4).toString());
        textElement1.setAttribute('x', (newX + 8).toString());
        textElement2.setAttribute('x', (newX + 16).toString());

        const newY = parseFloat(bar.getAttribute('y')!);
        squareElement.setAttribute('y', (newY + 4).toString());
        textElement1.setAttribute('y', (newY + 18).toString());
        textElement2.setAttribute('y', (newY + 22).toString());
      };

      createFirstPIconIfNeeded();
      updateCoordinates();

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const attribute = mutation.attributeName;
          if (
            mutation.type === 'attributes' &&
            (attribute === 'x' || attribute === 'width')
          ) {
            createFirstPIconIfNeeded();
            updateCoordinates();
          }
        });
      });

      const config = { attributes: true, childList: false, subtree: false };
      observer.observe(bar, config);
      currentObservers.current.push(observer);
    });
  }, []);

  const setupGantt = useCallback((viewMode: GanttViewModeOption = 'Month') => {
    if (!gantt) {
      const monthsBetween = (date1: Date, date2: Date) => {
        const diff = date2.getTime() - date1.getTime();
        const months = diff / (1000 * 60 * 60 * 24 * 30);
        return Math.ceil(months);
      };

      const tasks: Gantt.Task[] = sites.map((site) => {
        const months = monthsBetween(
          site.firstPatientStartDate,
          site.firstPatientEndDate,
        );

        return {
          id: site.name,
          name: `First patient in ${months} months`,
          start: site.firstPatientStartDate.toISOString().split('T')[0],
          end: site.firstPatientEndDate.toISOString().split('T')[0],
          progress: 0,
          custom_class: 'bar-milestone',
          dependencies: '',
        };
      });

      const gantt = new Gantt('#gantt', tasks, {
        bar_height: 32,
        view_mode: viewMode,
      });
      ganttRef.current = gantt;
      setGantt(gantt);
    }

    updateGanttHeight();
    drawDashedLines();
    addFirstPatientIcons();
  }, []);

  useEffect(() => {
    setupGantt();
  }, []);

  const changeViewMode = useCallback(
    (viewMode: GanttViewModeOption) => {
      currentObservers.current.forEach((observer) => observer.disconnect());
      currentObservers.current = [];

      ganttRef.current?.change_view_mode(viewMode);
      setSelectedGanttViewMode(viewMode);

      drawDashedLines();
      updateGanttHeight();
      addFirstPatientIcons();
    },
    [drawDashedLines, updateGanttHeight, addFirstPatientIcons],
  );

  return (
    <div className="w-full">
      <div className="timeline m-4 border border-gray-700 flex flex-col items-start rounded-xl shadow overflow-hidden">
        <div className="flex flex-row w-full">
          <div className="flex flex-col" style={{ minWidth: 300, width: 300 }}>
            <div className="flex flex-col justify-center h-[60px] ">
              <SiteHeader />
            </div>
            <div className="w-full flex flex-col flex-shrink-0">
              {sites.map((site, index) => (
                <SiteItem key={site.name} site={site} leadSite={index === 0} />
              ))}
            </div>
          </div>

          <div className="flex flex-grow overflow-hidden">
            <div
              id="gantt"
              className="gantt gantt-target dark flex flex-grow overflow-auto"
            ></div>
          </div>
        </div>
      </div>

      <div className="flex flex-row justify-end pr-4">
        <GanttViewMode
          isSelected={selectedGanttViewMode === 'Day'}
          changeViewMode={changeViewMode}
          viewMode="Day"
        />
        <GanttViewMode
          isSelected={selectedGanttViewMode === 'Week'}
          changeViewMode={changeViewMode}
          viewMode="Week"
        />
        <GanttViewMode
          isSelected={selectedGanttViewMode === 'Month'}
          changeViewMode={changeViewMode}
          viewMode="Month"
        />
        {/* <GanttViewMode gantt={gantt} viewMode="Year" /> */}
      </div>
    </div>
  );
}
