import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimatedSmall from './components/NeurouteAnimatedSmall';
import CarouselSelector from './components/CarouselSelector';
import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InvisibleInput,
  CornerPositioned,
} from './components/StyledElements';
import AdvancedOptionsDialog from './components/AdvancedOptionsDialog';
import { RootState } from '../../app/store';
import {
  setYears,
  selectStudyMethodText,
  selectYearsText,
} from '../../features/flow_3/flow_3_slice';
import { useAuth } from '../auth/AuthContext';
import HamburgerMenu from '@/components/HamburgerMenu';

const listItems: string[] = [
  'All Time',
  '10 Years',
  '5 Years',
  '3 Years',
  'Last Year',
];

const escapeSpecialCharacters = (str: string): string => {
  return str.replace(/\\/g, '\\\\').replace(/`/g, '\\`').replace(/\//g, '\\/');
};

const Step5Page: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow3);
  const { currentUser } = useAuth();
  const studyMethodText = useSelector((state: RootState) =>
    selectStudyMethodText(state),
  );
  const yearsText = useSelector((state: RootState) => selectYearsText(state));

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleChange = (value: string) => {
    dispatch(setYears(value));
  };

  const handleNextStep = () => {
    navigate('/flow-3-wireframes/step-6-7');
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        event.stopPropagation();
        handleNextStep();
      }
    };

    window.removeEventListener('keydown', handleKeyDown);
    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  const escapedDisease = escapeSpecialCharacters(data.disease);

  const helpHowTextTrimmed = data.helpHow.replace(/by /g, '');

  return (
    <ProjectLayout title="Projects">
      <div className="flex flex-row">
        <CornerPositioned>
          <NeurouteAnimatedSmall />
          <div className="flex flex-row content-between items-center gap-4">
            <HamburgerMenu />
          </div>
        </CornerPositioned>

        {/* Left Side Panel */}
        <div className="w-60 max-w-60 p-4 block position-absolute top-20 left-0 overflow-hidden">
          <div className="mt-24">
            <CarouselSelector
              items={listItems}
              value={data.years}
              onChange={handleChange}
            />
          </div>
        </div>

        {/* Main Content */}
        <main className="w-3/4 flex-grow flex flex-col items-left p-4 pt-10 pl-36">
          <div className="max-w-lg w-full text-left">
            <div className="text-gray-500 text-4xl mb-2 pl-6">
              I want to help patients with
            </div>
            <div className="text-white text-4xl mb-2 pl-6">{data.disease}</div>
            <div className="text-gray-500 text-4xl mb-2 pl-6">
              by <span className="text-white ">{helpHowTextTrimmed}</span>
            </div>
            <div className="text-gray-500 text-4xl mb-2 pl-6"> </div>
            <div className="text-gray-500 text-4xl mb-8 mt-16 pl-6">
              It’s sort of like...
            </div>
            <InputLikeElement className="mt-4">
              <InvisibleInput
                readOnly
                placeholder={`Search for ${escapedDisease} and ${studyMethodText} ${yearsText}`}
              />
              <CircleButton onClick={handleNextStep}>
                <ArrowRightIcon />
              </CircleButton>
            </InputLikeElement>
            <div
              className="text-right mt-4 text-grey-500 cursor-pointer"
              onClick={handleOpenModal}
              style={{ width: '720px' }}
            >
              advanced
            </div>
          </div>
        </main>
      </div>

      {isModalOpen && <AdvancedOptionsDialog onClose={handleCloseModal} />}
    </ProjectLayout>
  );
};

export default Step5Page;
