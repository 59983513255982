import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyBqXgqQ8RqWYFEFrh4iYyJKecBms9kmBRA',
  authDomain: 'neuroute-react-dev.firebaseapp.com',
  projectId: 'neuroute-react-dev',
  storageBucket: 'neuroute-react-dev.appspot.com',
  messagingSenderId: '1077398301020',
  appId: '1:1077398301020:web:751539e30f33761d647999',
  measurementId: 'G-T069MM38GP',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const auth = getAuth(app);

export { app, db, analytics, auth };
