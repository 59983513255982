import React from 'react';
import NeurouteAnimatedSearching from '../components/NeurouteAnimatedSearching';
import { Brainstorm } from '@/types/Brainstorm';
import {
  PhaseEnum,
  RelevanceEnum,
  InterventionTypeEnum,
} from '@/types/BrainstormOptions';
import { convertDateToMMYY } from '@/utils/date';

interface StepSectionProps {
  brainstorm: Brainstorm;
  setBrainstorm: React.Dispatch<React.SetStateAction<Brainstorm>>;
  isSection: (field: string) => boolean;
  isCurrentStep: (field: string | string[]) => boolean;
  hasStep: (field: string) => boolean;
  notCompleted: (field: string) => boolean;
  nextStep: (nextStep: string, alsoComplete?: string) => void;
  completeStep: (field: string | string[]) => void;
  removeStep: (field: string | string[]) => void;
  setCurrentStep: (field: string) => void;
}

interface SideLabelProps extends StepSectionProps {
  field: keyof Brainstorm;
  label: string;
  onClick?: () => void;
}

const SideEditIntervention: React.FC<{
  value: string;
  setBrainstorm: React.Dispatch<React.SetStateAction<Brainstorm>>;
}> = ({ value, setBrainstorm }) => {
  const interventions = Object.values(InterventionTypeEnum);

  const handleInterventionClick = (intervention: string) => {
    setBrainstorm((prev) => ({
      ...prev,
      interventionType: intervention,
      currentStep: 'interventionType.correct',
    }));
  };

  return (
    <div className="flex flex-col gap-2 pl-2">
      {interventions.map((intervention) => (
        <div
          key={intervention}
          onClick={() => handleInterventionClick(intervention)}
          className={`cursor-pointer ${
            intervention === value ? 'text-white pl-2' : 'text-[#9491a7]'
          } text-lg font-normal`}
        >
          {intervention}
        </div>
      ))}
    </div>
  );
};

export const mappedYearsToText: { [key: string]: string } = {
  [RelevanceEnum.all_time]: 'in all time',
  [RelevanceEnum.ten_years]: 'in the last 10 years',
  [RelevanceEnum.five_years]: 'in the last 5 years',
  [RelevanceEnum.three_years]: 'in the last 3 years',
  [RelevanceEnum.last_year]: 'in the last year',
};

const SideEditRelevance: React.FC<{
  value: string;
  setBrainstorm: React.Dispatch<React.SetStateAction<Brainstorm>>;
}> = ({ value, setBrainstorm }) => {
  const years = Object.values(RelevanceEnum);

  const handleYearClick = (year: string) => {
    setBrainstorm((prev) => ({
      ...prev,
      relevance: year,
      currentStep: 'relevance.search',
    }));
  };

  return (
    <div className="flex flex-col gap-2 pl-2">
      {years.map((year) => (
        <div
          key={year}
          onClick={() => handleYearClick(year)}
          className={`cursor-pointer ${
            year === value ? 'text-white pl-2' : 'text-[#9491a7]'
          } text-lg font-normal`}
        >
          {year}
        </div>
      ))}
    </div>
  );
};

const SideEditPhase: React.FC<{
  value: string | Date;
  setBrainstorm: React.Dispatch<React.SetStateAction<Brainstorm>>;
}> = ({ value, setBrainstorm }) => {
  const phases = Object.values(PhaseEnum);

  const handlePhaseClick = (phase: string) => {
    setBrainstorm((prev) => ({
      ...prev,
      phase,
      currentStep: 'phase.search',
    }));
  };

  return (
    <div className="flex flex-col gap-2 pl-2">
      {phases.map((phase) => (
        <div
          key={phase}
          onClick={() => handlePhaseClick(phase)}
          className={`cursor-pointer ${
            phase === value ? 'text-white pl-2' : 'text-[#9491a7]'
          } text-lg font-normal`}
        >
          {phase}
        </div>
      ))}
    </div>
  );
};

const SideLabel: React.FC<SideLabelProps> = ({
  field,
  label,
  onClick,
  //
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const value = brainstorm[field];

  let displayValue = '';
  if (Array.isArray(value)) {
    displayValue = `${value.length}`;
  } else if (typeof value === 'number') {
    displayValue = value.toString();
  } else if (field === 'timing') {
    displayValue = convertDateToMMYY(value as string) as string;
  } else {
    displayValue = value as string;
  }

  const handleClick = () => {
    if (onClick) {
      onClick();
    }
    removeStep(`${field}*`);
    // set current step
    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev, currentStep: `${field}.edit` };
      return updatedBrainstorm;
    });
  };

  if (isCurrentStep(`${field}.edit`) || isCurrentStep(`${field}.search`)) {
    if (isCurrentStep('interventionType.edit')) {
      return (
        <div className="mt-6">
          <div className="text-[#9491a7] text-lg font-normal uppercase">
            {label}
          </div>
          <SideEditIntervention
            value={value as string}
            setBrainstorm={setBrainstorm}
          />
        </div>
      );
    } else if (field === 'phase' && isCurrentStep('phase.edit')) {
      return (
        <div className="mt-6">
          <div className="text-[#9491a7] text-lg font-normal uppercase">
            {label}
          </div>
          <SideEditPhase
            value={value as string}
            setBrainstorm={setBrainstorm}
          />
        </div>
      );
    } else if (field === 'relevance' && isCurrentStep('relevance.edit')) {
      return (
        <div className="mt-6">
          <div className="text-[#9491a7] text-lg font-normal uppercase">
            {label}
          </div>
          <SideEditRelevance
            value={value as string}
            setBrainstorm={setBrainstorm}
          />
        </div>
      );
    }

    return (
      <div className="mt-6 cursor-pointer" onClick={handleClick}>
        <div className="text-[#9491a7] text-lg font-normal uppercase">
          {label}
        </div>
        <div className="text-white text-lg font-normal capitalize">
          <NeurouteAnimatedSearching />
        </div>
      </div>
    );
  } else if (
    brainstorm.completedSteps.includes(`${field}`) ||
    brainstorm.completedSteps.includes(`${field}.edit`) ||
    brainstorm.completedSteps.includes(`${field}.correct`) ||
    isCurrentStep(`${field}.correct`)
  ) {
    return (
      <div className="mt-6 cursor-pointer" onClick={handleClick}>
        <div className="text-[#9491a7] text-lg font-normal uppercase">
          {label}
        </div>
        <div className="text-white text-lg font-normal capitalize">
          {displayValue}
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default SideLabel;
