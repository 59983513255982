import React, { useEffect, useRef } from 'react';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';

import { BackTrack, Scene } from '@/types/index';
import AudioTrackCard from './AudioTrackCard';

// Component to track drag state
const DragLayer: React.FC<{
  onDragEnd: (isDropped: boolean, itemId: string) => void;
}> = ({ onDragEnd }) => {
  const { isDragging, item } = useDragLayer((monitor) => ({
    isDragging: monitor.isDragging(),
    item: monitor.getItem() as { id: string; isDropped: boolean } | null,
  }));

  useEffect(() => {
    if (!isDragging && item) {
      onDragEnd(item.isDropped, item.id);
    }
  }, [isDragging, item, onDragEnd]);

  return null;
};

// DraggableScene component
const DraggableScene: React.FC<{
  scene: Scene;
  index: number;
  type: 'available' | 'main';
  moveScene?: (dragIndex: number, hoverIndex: number) => void;
  onDragStart: (id: string) => void;
}> = ({ scene, index, type, moveScene, onDragStart }) => {
  const ref = useRef<HTMLLIElement>(null);

  const [{ isDragging }, drag] = useDrag({
    type: 'SCENE',
    item: () => {
      onDragStart(scene.id);
      return { id: scene.id, index, type, isDropped: false };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item, monitor) => {
      item.isDropped = monitor.didDrop();
    },
  });

  const [, drop] = useDrop({
    accept: 'SCENE',
    hover: (item: { id: string; index: number; type: string }, monitor) => {
      if (!ref.current || type === 'available' || item.type === 'available') {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      if (dragIndex === hoverIndex) {
        return;
      }

      moveScene && moveScene(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  drag(drop(ref));

  return (
    <li
      ref={ref}
      className={`bg-[#2C2B32] w-full cursor-grab ${type === 'available' ? 'h-12' : 'h-[47px]'} rounded-[6px] ${
        isDragging ? 'opacity-50' : ''
      }`}
      style={{
        backgroundImage: scene.imageUrl ? `url(${scene.imageUrl})` : 'none',
        backgroundSize: 'cover',
      }}
    />
  );
};

// AvailableScenes component
const AvailableScenes: React.FC<{
  scenes: Scene[];
  onDragStart: (id: string) => void;
}> = ({ scenes, onDragStart }) => (
  <ul className="grid grid-cols-4 gap-2 mb-8 list-none p-0 cursor-grab">
    {scenes.map((scene, index) => (
      <DraggableScene
        key={scene.id}
        scene={scene}
        index={index}
        type="available"
        onDragStart={onDragStart}
      />
    ))}
  </ul>
);

// SceneSlot component
const SceneSlot: React.FC<{
  index: number;
  scene?: Scene;
  moveScene: (dragIndex: number, hoverIndex: number) => void;
  dropScene: (
    item: { id: string; index: number; type: string },
    hoverIndex: number,
  ) => void;
  onDragStart: (id: string) => void;
}> = ({ index, scene, moveScene, dropScene, onDragStart }) => {
  const [{ isOver }, drop] = useDrop({
    accept: 'SCENE',
    drop: (item: { id: string; index: number; type: string }) => {
      dropScene(item, index);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <li
      ref={drop}
      className={`w-full h-[47px] rounded-[6px] ${
        isOver ? 'ring-2 ring-[#00C7A8]' : 'bg-[#2C2B32]'
      }`}
    >
      {scene && (
        <DraggableScene
          scene={scene}
          index={index}
          type="main"
          moveScene={moveScene}
          onDragStart={onDragStart}
        />
      )}
    </li>
  );
};

// ScenesRow component
const ScenesRow: React.FC<{
  scenes: (Scene | null)[];
  moveScene: (dragIndex: number, hoverIndex: number) => void;
  dropScene: (
    item: { id: string; index: number; type: string },
    hoverIndex: number | null,
  ) => void;
  onDragStart: (id: string) => void;
}> = ({ scenes, moveScene, dropScene, onDragStart }) => {
  const [, drop] = useDrop({
    accept: 'SCENE',
    drop: (item: { id: string; index: number; type: string }, monitor) => {
      const didDrop = monitor.didDrop();
      if (!didDrop) {
        dropScene(item, null);
      }
    },
  });

  return (
    <div ref={drop} className="flex items-center mb-4">
      <p className="text-gray-400 text-lg font-normal leading-6 tracking-[-1px] w-[90px] min-w-[90px]">
        Scenes
      </p>
      <ul className="flex-grow grid grid-cols-5 gap-2 list-none p-0">
        {scenes.map((scene, index) => (
          <SceneSlot
            key={index}
            index={index}
            scene={scene || undefined}
            moveScene={moveScene}
            dropScene={dropScene}
            onDragStart={onDragStart}
          />
        ))}
      </ul>
    </div>
  );
};

const RowWithBoxes: React.FC<{ label: string; numberOfColumns?: number }> = ({
  label,
  numberOfColumns = 0,
}) => (
  <div className="flex items-center mb-4">
    <p className="text-gray-400 text-lg font-normal leading-6 tracking-[-1px] w-[90px] min-w-[90px]">
      {label}
    </p>
    <div
      className="flex-grow"
      style={{
        display: 'grid',
        gridTemplateColumns: `repeat(${numberOfColumns}, minmax(0, 1fr))`,
        gap: '0.5rem',
      }}
    >
      {[...Array(numberOfColumns)].map((_, index) => (
        <div
          key={index}
          style={{
            backgroundColor: '#2C2B32',
            width: '100%',
            height: '47px',
            borderRadius: '6px',
          }}
        ></div>
      ))}
    </div>
  </div>
);

const BaseRow: React.FC = () => (
  <RowWithBoxes label="Base" numberOfColumns={1} />
);

const VoiceRow: React.FC = () => {
  return (
    <div className="flex items-center mb-4">
      <p className="text-gray-400 text-lg font-normal leading-6 tracking-[-1px] w-[90px] min-w-[90px]">
        Voice
      </p>
      <ul className="flex-grow grid grid-cols-5 gap-2 list-none p-0">
        {[...Array(5)].map((_, index) => (
          <li key={index} className="w-full h-[47px]">
            <AudioTrackCard title={`Voice ${index + 1}`} tag={`Transcript`} />
          </li>
        ))}
      </ul>
    </div>
  );
};

interface TuneRowProps {
  selectedBackTrack: BackTrack;
}

const TuneRow: React.FC<TuneRowProps> = ({ selectedBackTrack }) => (
  <div className="flex items-center mb-4">
    <p className="text-gray-400 text-lg font-normal leading-6 tracking-[-1px] w-[90px] min-w-[90px]">
      Tune
    </p>
    <div className="flex-grow">
      <AudioTrackCard
        title={selectedBackTrack.artist}
        tag={'artist'}
        song={`(${selectedBackTrack.title})`}
      />
    </div>
  </div>
);

export {
  DragLayer,
  DraggableScene,
  AvailableScenes,
  SceneSlot,
  ScenesRow,
  RowWithBoxes,
  BaseRow,
  VoiceRow,
  TuneRow,
};
