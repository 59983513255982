import React from 'react';
import { useAppSelector } from '../../../../app/hooks';
import { useGetSiteByIdQuery } from '@/api/slices/dashboard/siteApiSlice';
import { useGetStudyByIdQuery } from '@/api/slices/dashboard/studiesApiSlice';
import TimelineChart from '../TimelineChart';
import EnrollmentScenarioGraph from '../graph/EnrollmentScenarioGraph';
import FullscreenGraph from '../graph/FullsceeenGraph';
import SiteEnrollmentScenarioGraph from '../graph/SiteEnrollmentScenarioGraph';
import MapView from '../map_view/MapView';
import TopBar from '../top_bar/TopBar';
import { SELECTED_STUDY_ID } from '../../../../app/lib/constants';
import ActiveEnrollmentScenarioGraph from '../graph/ActiveEnrollmentScenarioGraph';

export default function ModelView() {
  const activeScenario = useAppSelector(
    (s) => s.enrollmentScenario.activeScenario,
  );
  const selectedSiteId = useAppSelector(
    (s) => s.enrollmentScenario.selectedSiteId,
  );
  const selectedStudyId = SELECTED_STUDY_ID;

  const viewMode = useAppSelector((s) => s.enrollmentScenario.viewMode);

  const {
    data: siteData,
    error,
    isLoading,
  } = useGetSiteByIdQuery(selectedSiteId || '');

  const { data: studyData } = useGetStudyByIdQuery(selectedStudyId || '');

  const renderContent = () => {
    if (viewMode === 'map') {
      return <MapView />;
    }

    if (selectedSiteId) {
      if (isLoading) return <p>Loading site data...</p>;
      if (error) return <p>Error loading site data...</p>;
      return <SiteEnrollmentScenarioGraph siteData={siteData} />;
    }

    if (activeScenario) {
      return <ActiveEnrollmentScenarioGraph />;
    }

    return <EnrollmentScenarioGraph />;
  };

  return (
    <>
      <TopBar />

      <div className="overflow-y-auto pb-6 pl-4">
        <FullscreenGraph>{renderContent()}</FullscreenGraph>
        <TimelineChart />
      </div>
    </>
  );
}
