import { SiteMapSite } from '@/types/SiteMap';
import { MapContainer, TileLayer, Marker } from 'react-leaflet';

import CompletedStudyCard from './CompletedStudyCard';

import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import { Sponsor, StudyDetails } from '@/types/Library';

// Fix for default marker icon in react-leaflet
const defaultIcon = L.icon({
  iconUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png',
  shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
});

const SiteCard: React.FC<{
  studies_in_condition?: Sponsor[];
  site: SiteMapSite;
  onToggleSelect: (site: SiteMapSite) => void;
}> = ({ site, onToggleSelect }) => {
  // search sponsors to find this site with related studies
  const studies: StudyDetails[] = [];
  return (
    <div
      className="bg-[#2A2A2E] rounded-lg cursor-pointer hover:bg-[#353539] transition-colors"
      onClick={() => onToggleSelect(site)}
    >
      <div className="flex justify-between items-start">
        <div className="w-2/3 p-6 border-r border-[#4B4A55]">
          <h3 className="text-white text-4xl mb-4 break-words">
            {site.site_name}
          </h3>
          <p className="text-[#9491a7] text-sm">
            <span className="text-white">
              {site.num_completed_studies} Completed Studies.{' '}
              {site.num_active_studies} Active Studies.
            </span>
          </p>
          {site.studies_in_condition.map((study, index) => (
            <CompletedStudyCard study={study} key={index} />
          ))}
        </div>

        <div className="w-1/3">
          <div className="h-[220px] rounded-lg overflow-hidden relative">
            <div className="absolute inset-0 z-10" />
            <MapContainer
              center={[site.coordinates[0], site.coordinates[1]]}
              zoom={13}
              style={{ height: '100%', width: '100%' }}
              zoomControl={false}
              attributionControl={false}
              dragging={false}
              scrollWheelZoom={false}
              doubleClickZoom={false}
              touchZoom={false}
            >
              <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
              <Marker
                position={[site.coordinates[0], site.coordinates[1]]}
                icon={defaultIcon}
              />
            </MapContainer>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SiteCard;
