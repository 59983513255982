import React from 'react';
import ArrowRightSvg from '@/images/arrow_right.svg';

const ThatLooksRightToMe: React.FC<{ onNextStep: () => void }> = ({
  onNextStep,
}) => (
  <div className="flex justify-end items-center mt-4 mb-8">
    <p className="text-[#bab9c4] text-lg font-light font-['Satoshi Variable'] mr-2">
      That looks right to me.
    </p>
    <div
      onClick={onNextStep}
      className="w-8 h-8 flex items-center justify-center rounded-full bg-[#00c7a81a] cursor-pointer"
    >
      <img className="w-4 h-4" src={ArrowRightSvg} alt="Next" />
    </div>
  </div>
);

export default ThatLooksRightToMe;
