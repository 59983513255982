import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import Header from './components/Header';

const BuildProfileGraphPageStep1: React.FC = () => {
  const navigate = useNavigate();
  const svgRef = useRef<SVGSVGElement>(null);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > window.innerHeight,
  );
  const [animationProgress, setAnimationProgress] = useState(0);
  const [textSlideY, setTextSlideY] = useState(0);
  const [showHumanLifeExtension, setShowHumanLifeExtension] = useState(0);
  const [isMobileAnimating, setIsMobileAnimating] = useState(false);
  const [showBigBangText, setShowBigBangText] = useState(true);
  const [showInteractiveText, setShowInteractiveText] = useState(false);
  const [interactiveTextPosition, setInteractiveTextPosition] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setWindowSize({ width, height });
      setIsDesktop(width > height);
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);
    const { width, height } = windowSize;

    svg.attr('viewBox', `0 0 ${width} ${height}`);
    svg.selectAll('*').remove();

    const bigCircleRadius = isDesktop
      ? Math.min(width, height) * 0.38
      : Math.min(width, height) * 0.75;
    const bigCircleCenterX = isDesktop ? width * 0.7 : width * 1.2;
    const bigCircleCenterY = isDesktop ? height * 0.5 : height * 0.8;
    const smallCircleRadius = isDesktop ? 45 : 25;
    const smallCircleAngle = Math.PI;
    const smallCircleCenterX = isDesktop
      ? bigCircleCenterX + bigCircleRadius * Math.cos(smallCircleAngle)
      : bigCircleCenterX + bigCircleRadius * Math.cos(smallCircleAngle);
    const smallCircleCenterY = isDesktop
      ? bigCircleCenterY + bigCircleRadius * Math.sin(smallCircleAngle)
      : bigCircleCenterY + (bigCircleRadius * Math.sin(smallCircleAngle)) / 2;
    const lineEndX = isDesktop
      ? bigCircleCenterX - bigCircleRadius / 1.8
      : bigCircleCenterX - bigCircleRadius / 1.8;

    const arcGenerator = d3.arc<d3.DefaultArcObject>();
    const createArcObject = (endAngle: number): d3.DefaultArcObject => ({
      innerRadius: bigCircleRadius,
      outerRadius: bigCircleRadius,
      startAngle: -Math.PI / 2,
      endAngle: endAngle,
    });

    const bigCircle = svg
      .append('path')
      .attr('fill', 'none')
      .attr('stroke', '#38DEC4')
      .attr('stroke-width', 2)
      .attr('opacity', 0.8)
      .attr('transform', `translate(${bigCircleCenterX},${bigCircleCenterY})`)
      .attr('d', arcGenerator(createArcObject(-Math.PI / 2)) || '');

    const smallCircle = svg
      .append('circle')
      .attr('cx', smallCircleCenterX)
      .attr('cy', smallCircleCenterY)
      .attr('r', 0)
      .attr('fill', '#38DEC4')
      .style('cursor', 'default');

    const line = svg
      .append('line')
      .attr('x1', lineEndX)
      .attr('y1', bigCircleCenterY)
      .attr('x2', lineEndX)
      .attr('y2', bigCircleCenterY)
      .attr('stroke', '#38DEC4')
      .attr('stroke-width', 1);

    const smallDot = svg
      .append('circle')
      .attr('cx', lineEndX)
      .attr('cy', bigCircleCenterY)
      .attr('r', 5)
      .attr('fill', '#38DEC4')
      .attr('opacity', 0);

    const updateAnimation = (progress: number) => {
      // Fade in Human Life text (0-1000ms)
      if (progress <= 1000) {
        setShowHumanLifeExtension((progress - 0) / 1000);
        setShowBigBangText(false);
        setShowInteractiveText(false);
      } else if (progress > 1000 && progress < 5900 + 100) {
        setShowBigBangText(true);
      } else {
        setShowBigBangText(false);
      }

      // Small dot fade in (0-2000ms)
      smallDot.attr(
        'opacity',
        d3.easeCubicInOut(Math.min(1, Math.max(0, (progress - 0) / 2000))),
      );

      // Draw line (2000-3000ms)
      const lineProgress = Math.min(1, Math.max(0, (progress - 2000) / 1000));
      line.attr(
        'x2',
        d3.easeCubicIn(lineProgress) * (smallCircleCenterX - lineEndX) +
          lineEndX,
      );

      // Grow circles (3000-3800ms)
      const circleProgress = Math.min(1, Math.max(0, (progress - 3000) / 800));
      smallCircle.attr(
        'r',
        d3.easeCubicInOut(circleProgress) * smallCircleRadius,
      );
      bigCircle.attr(
        'd',
        arcGenerator(
          createArcObject(
            -Math.PI / 2 + d3.easeCubicInOut(circleProgress) * 2 * Math.PI,
          ),
        ) || '',
      );

      // Move small circle horizontally and fade out texts (5000-5900ms)
      if (progress >= 4500) {
        const moveProgress = Math.min(1, (progress - 4500) / 900);
        const newX = isDesktop
          ? d3.easeCubicInOut(moveProgress) * (90 - smallCircleCenterX) +
            smallCircleCenterX
          : d3.easeCubicInOut(moveProgress) * (90 - smallCircleCenterX) +
            smallCircleCenterX;
        const newY = bigCircleCenterY;

        // Slide animation for mobile
        const slideDistance = isDesktop ? 0 : 200;
        const slideY = d3.easeCubicInOut(moveProgress) * slideDistance;
        setTextSlideY(slideY); // Update the text slide position

        smallCircle.attr('cx', newX).attr('cy', newY - slideY);
        line
          .attr('x1', lineEndX)
          .attr('y1', bigCircleCenterY - slideY)
          .attr('x2', newX + smallCircleRadius)
          .attr('y2', newY - slideY);
        smallDot.attr('cx', lineEndX).attr('cy', bigCircleCenterY - slideY);
        bigCircle.attr(
          'transform',
          `translate(${bigCircleCenterX},${bigCircleCenterY - slideY})`,
        );

        // Fade out texts and big circle
        const fadeOutElements = svg.selectAll('.fade-out-text, #bigCircle');
        fadeOutElements.style('opacity', 1 - d3.easeCubicInOut(moveProgress));

        // Gradually fade out the "Human life" text
        setShowHumanLifeExtension(1 - moveProgress);

        // Trigger mobile text animation
        if (!isDesktop && moveProgress > 0 && !isMobileAnimating) {
          setIsMobileAnimating(true);
        }

        if (moveProgress === 1) {
          setInteractiveTextPosition({
            x: newX,
            y: isDesktop
              ? newY - smallCircleRadius - 20
              : newY - smallCircleRadius - 20 - slideDistance,
          });
          setShowInteractiveText(true);
        }
      }
    };

    updateAnimation(animationProgress);
  }, [windowSize, animationProgress]);

  useEffect(() => {
    const animationDuration = 5900;
    const startTime = Date.now();

    const animate = () => {
      const currentTime = Date.now();
      const elapsed = currentTime - startTime;
      if (elapsed < animationDuration) {
        setAnimationProgress(elapsed);
        requestAnimationFrame(animate);
      } else {
        setAnimationProgress(animationDuration);
      }
    };

    requestAnimationFrame(animate);
  }, []);

  const handleInteraction = () => {
    if (animationProgress >= 5400) {
      const svg = d3.select(svgRef.current);
      const smallCircle = svg.select('circle');
      const line = svg.select('line');
      const smallDot = svg.select('circle:last-of-type');
      const bigCircle = svg.select('path');

      const newX = isDesktop ? 90 : 45;
      const newY = isDesktop ? 160 : 120;

      setShowInteractiveText(false);
      setShowBigBangText(false);

      smallCircle
        .transition()
        .duration(900)
        .ease(d3.easeCubicInOut)
        .attr('cx', newX)
        .attr('cy', newY);

      line
        .transition()
        .duration(900)
        .ease(d3.easeCubicInOut)
        .attr('x1', newX + 100 + 45)
        .attr('y1', newY)
        .attr('x2', newX)
        .attr('y2', newY);

      smallDot
        .transition()
        .duration(900)
        .ease(d3.easeCubicInOut)
        .attr('cx', newX + 100 + 45)
        .attr('cy', newY);

      // Fade out big circle
      bigCircle
        .transition()
        .duration(900)
        .ease(d3.easeCubicInOut)
        .style('opacity', 0);

      setTimeout(() => {
        navigate('/waitlist');
      }, 1000);
    }
  };

  return (
    <div className="relative w-full h-screen bg-[#1F1F23] overflow-hidden">
      <style>{`
        @keyframes slideUpFade {
          0% {
            transform: translateY(0);
            opacity: 1;
          }
          100% {
            transform: translateY(-200px);
            opacity: 0;
          }
        }
        .mobile-animate-slide-up {
          animation: slideUpFade 900ms ease-in-out forwards;
        }
      `}</style>
      <svg
        ref={svgRef}
        className="absolute inset-0 w-full h-full"
        style={{ zIndex: 1 }}
      ></svg>
      <div className="relative z-10 w-full h-full">
        <Header includeAuthButtons={false} />

        {showHumanLifeExtension > 0 && (
          <div
            className={`absolute ${
              isDesktop ? 'left-[10%] top-[20%]' : 'left-[5%] top-[15%]'
            } text-white fade-out-text ${!isDesktop && isMobileAnimating ? 'mobile-animate-slide-up' : ''}`}
            style={{
              opacity: isDesktop ? showHumanLifeExtension : 1, // We'll handle mobile opacity with CSS animation
            }}
          >
            <h1
              className={`${isDesktop ? 'mt-16 text-6xl font-medium ' : 'mt-0 px-8 text-5xl font-medium'} mb-16 text-white leading-none tracking-tighter`}
            >
              {[
                'Human life',
                'extension is',
                'only possible',
                'if we all work',
                'together.',
              ].map((line, index) => (
                <span
                  key={index}
                  className="block opacity-0 blur-sm animate-fadeIn"
                  style={{
                    animationDelay: `${index * 250}ms`,
                    animationFillMode: 'forwards',
                  }}
                >
                  {line}
                </span>
              ))}
            </h1>
          </div>
        )}

        {showBigBangText && (
          <div
            className={`absolute ${
              isDesktop
                ? 'left-[70%] top-1/2 transform -translate-x-1/2 -translate-y-1/2'
                : 'left-0 right-0 bottom-1 px-8'
            } text-left fade-out-text`}
          >
            <p
              className={`mt-16 mb-16 text-left text-white/60 ${
                isDesktop ? 'text-4xl font-medium' : 'px-8 text-3xl font-normal'
              } tracking-tighter opacity-0 animate-fadeIn `}
              style={{ animationDelay: '1.5s', animationFillMode: 'forwards' }}
            >
              <span className="block">Big bangs start with</span>
              <span className="block text-white">one collision</span>
            </p>
          </div>
        )}

        {showInteractiveText && (
          <div
            className={`absolute text-white ${
              isDesktop ? 'text-6xl' : 'text-4xl'
            } leading-none font-medium tracking-tighter animate-fadeIn cursor-pointer`}
            style={{
              left: `${interactiveTextPosition.x - 10}px`,
              top: `${interactiveTextPosition.y}px`,
              transform: isDesktop
                ? 'translate(-40px, -130px)'
                : 'translate(-40px, 100px)',
              pointerEvents: 'auto',
              zIndex: 2,
              height: isDesktop ? '300px' : '200px',
              padding: '20px',
            }}
            onClick={handleInteraction}
          >
            <span
              className="block opacity-0 blur-sm animate-fadeIn"
              style={{
                animationDelay: `${0}ms`,
                animationFillMode: 'forwards',
              }}
            >
              Press me if you
            </span>
            <span
              className="block opacity-0 blur-sm animate-fadeIn"
              style={{
                animationDelay: `${250}ms`,
                animationFillMode: 'forwards',
              }}
            >
              want to collide?
            </span>
          </div>
        )}
      </div>
    </div>
  );
};

export default BuildProfileGraphPageStep1;
