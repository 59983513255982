import React from 'react';

interface InvisibleInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InvisibleInput: React.FC<InvisibleInputProps> = (props) => {
  return (
    <input
      {...props}
      className="text-md font-normal leading-[24.3px] text-left text-white bg-transparent border-none outline-none shadow-none flex-grow pl-0 focus:text-white focus:border-none focus:outline-none focus:shadow-none active:border-none active:outline-none active:shadow-none hover:border-none hover:outline-none hover:shadow-none"
      style={{ boxShadow: 'none' }}
    />
  );
};

export default InvisibleInput;
