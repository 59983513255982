import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '@/core/setup_firebase';
import { signOut } from 'firebase/auth';

const SignOutPage: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const performSignOut = async () => {
      try {
        await signOut(auth);
        console.log('User signed out successfully');
      } catch (error) {
        console.error('Error signing out:', error);
      } finally {
        // Redirect to home page regardless of sign-out success or failure
        navigate('/');
      }
    };

    performSignOut();
  }, [navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-[#1F1F23]">
      <p className="text-white text-xl">Signing out...</p>
    </div>
  );
};

export default SignOutPage;
