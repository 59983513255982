import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import scenarioImg from '../../images/scenario.svg';
import crossIcon from '../../images/cross_icon.svg';
import { ProjectLayoutCard } from './components/ProjectLayoutCard';
import { FormField } from './components/FormField';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectCountries,
  selectAgeMin,
  selectAgeMax,
  selectScenarioName,
  setCountries,
  setAgeMin,
  setAgeMax,
  setScenarioName,
} from '../../features/create_study/create_study_slice';

const CreateScenarioFormPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const countries = useSelector(selectCountries);
  const ageMin = useSelector(selectAgeMin);
  const ageMax = useSelector(selectAgeMax);
  const scenarioName = useSelector(selectScenarioName);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({ countries, ageMin, ageMax, scenarioName });
    navigate('/wireframes/add-inclusion-criteria-page');
  };

  return (
    <ProjectLayout title="Projects">
      <div className="min-h-screen flex flex-col">
        <ProjectLayoutCard width={600}>
          <div className="flex flex-row w-full justify-end mb-4">
            <div
              className="w-8 h-8 p-2 bg-zinc-700 rounded-md flex items-center justify-center hover:bg-zinc-900 cursor-pointer"
              onClick={() => navigate('/wireframes/create-project')}
            >
              <img className="w-4 h-4" src={crossIcon} alt="Close" />
            </div>
          </div>

          <div className="flex flex-row w-full justify-center">
            <img src={scenarioImg} alt="Scenario" className="w-40 h-40 mb-2" />
          </div>
          <h1 className="w-full text-center text-2xl font-medium mb-8 text-white ">
            Great! let's create your first scenario.
          </h1>
          <div className="w-full text-center text-zinc-400 text-sm font-medium leading-tight mb-6 ">
            Create your own scenario by adding countries and age range for your
            study
          </div>

          <form
            onSubmit={handleSubmit}
            className="w-full flex flex-col gap-y-4"
          >
            <FormField
              label="What countries do you want to consider?"
              id="countries"
              value={countries}
              onChange={(e) => dispatch(setCountries(e.target.value))}
              placeholder="e.g. UK, EU countries"
              required
            />

            <div className="flex flex-col w-full gap-y-2">
              <div className="blocktext-[#9391A6] text-[13px] font-normal">
                What is the age range for your study?
              </div>

              <div className="flex flex-row w-full gap-x-4 items-end">
                <FormField
                  id="ageMin"
                  value={ageMin}
                  onChange={(e) =>
                    dispatch(setAgeMin(parseInt(e.target.value)))
                  }
                  placeholder="Min"
                  type="number"
                  className="w-[42%]"
                  min={0}
                  max={120}
                  required
                />

                <span className="text-white mb-2">To</span>
                <div className="flex flex-row flex-grow">
                  <FormField
                    id="ageMax"
                    value={ageMax}
                    type="number"
                    className="w-full"
                    onChange={(e) =>
                      dispatch(setAgeMax(parseInt(e.target.value)))
                    }
                    placeholder="Max"
                    min={0}
                    max={120}
                    required
                  />
                </div>
              </div>
            </div>

            <FormField
              label="Scenario name"
              id="scenarioName"
              value={scenarioName}
              onChange={(e) => dispatch(setScenarioName(e.target.value))}
              placeholder="e.g. Sepsis 1"
              required
            />

            <button type="submit" className="btn btn-primary w-full mt-2">
              Next
            </button>
          </form>
          <p className="mt-4 text-center text-sm text-white">
            You can add more inclusion and exclusion criteria next.
          </p>
        </ProjectLayoutCard>
      </div>
    </ProjectLayout>
  );
};

export default CreateScenarioFormPage;
