import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseApi } from '../../api';
import { ClickHouseQueryParams, Site, Sponsor, Study } from '../types';

/**
 * Interface representing the response for site data.
 */
interface SiteResponse {
  avg_rand: number;
  max_rand: number;
  avg_num_studies: number;
  max_num_studies: number;
  sites: Site[];
}

/**
 * Interface representing the response for site modem data.
 */
interface SiteModemResponse {
  experience: number;
  speciality: number;
  performance: number;
  match_percentage: number;
  rand: number;
  num_all_studies: number;
  num_indication_studies: number;
  num_commercial_studies: number;
  num_non_commercial_studies: number;
  commercial_percentage: number;
  active_studies_in_indication: Study[];
  top_two_sponsors: Sponsor[];
  avg_num_studies_per_sponsor: number;
  directly_competing_studies: Study[];
  nearby_competing_studies: Study[];
  studies: Study[];
}

/**
 * Type for the query parameters used in getting sites.
 * Inherits properties from ClickHouseQueryParams.
 */
type GetSitesQueryParams = ClickHouseQueryParams;

/**
 * Type for the query parameters used in getting site by id.
 */
type GetSiteByIdQueryParams = {
  id: string;
};

/**
 * Type for the query parameters used in getting site RAND.
 */
type GetSiteRandQueryParams = {
  condition: string[];
  relevance: string;
  phase: string;
  intervention_type: string;
  site_name: string;
};

/**
 * Type for the query parameters used in getting site by modem.
 * Inherits properties from ClickHouseQueryParams and includes site_name.
 */
type GetSiteByModemQueryParams = ClickHouseQueryParams & {
  site_name: string;
};

/**
 * Defines the API endpoints for site data using baseApi.
 */
export const sitesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Endpoint to get a list of sites based on query parameters.
     * @param {GetSitesQueryParams} params - The query parameters.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getSites: builder.query<SiteResponse, GetSitesQueryParams>({
      query: (params: GetSitesQueryParams): string | FetchArgs => {
        const { country, phase, intervention_type, condition } = params;

        const queryParams: URLSearchParams = new URLSearchParams();

        if (country) {
          queryParams.append('country', country);
        }

        // Append multiple intervention_type parameters if they exist
        if (intervention_type) {
          intervention_type.forEach((type: string): void => {
            queryParams.append('intervention_type', type);
          });
        }

        // Append multiple condition parameters if they exist
        if (condition) {
          condition.forEach((condition: string): void => {
            queryParams.append('condition', condition);
          });
        }

        // Optionally append phase if it's provided
        if (phase) {
          queryParams.append('phase', phase);
        }

        // Construct the full URL with query parameters
        return `sites?${queryParams}`;
      },
    }),
    /**
     * Endpoint to get site modem data based on query parameters.
     * @param {GetSiteByModemQueryParams} params - The query parameters.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getSiteByName: builder.query<SiteModemResponse, GetSiteByModemQueryParams>({
      query: (params: GetSiteByModemQueryParams): string | FetchArgs => {
        const { country, phase, intervention_type, condition, site_name } =
          params;

        const queryParams: URLSearchParams = new URLSearchParams();

        if (country) {
          queryParams.append('country', country);
        }

        if (site_name) {
          queryParams.append('site_name', site_name);
        }

        // Optionally append phase if it's provided
        if (phase) {
          queryParams.append('phase', phase);
        }

        // Append multiple intervention_type parameters if they exist
        if (intervention_type) {
          intervention_type.forEach((type: string): void => {
            queryParams.append('intervention_type', type);
          });
        }

        // Append multiple condition parameters if they exist
        if (condition) {
          condition.forEach((cond: string): void => {
            queryParams.append('condition', cond);
          });
        }

        // Construct the full URL with query parameters
        return `site-modem?${queryParams}`;
      },
    }),
    /**
     * Endpoint to get site modem data based on query parameters.
     * @param {GetSiteByModemQueryParams} params - The query parameters.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getSiteById: builder.query<Site, GetSiteByIdQueryParams>({
      query: (params: GetSiteByIdQueryParams): string | FetchArgs => {
        const { id } = params;

        // Initialize URLSearchParams with the non-array parameters
        const queryParams: URLSearchParams = new URLSearchParams();

        if (id) {
          queryParams.append('id', id);
        }

        // Construct the full URL with query parameters
        return `site-by-id?${queryParams}`;
      },
    }),
    getSiteRand: builder.query<Site, GetSiteRandQueryParams>({
      query: (params: GetSiteRandQueryParams): string | FetchArgs => {
        const { condition, relevance, phase, intervention_type, site_name } =
          params;

        // Initialize URLSearchParams with the non-array parameters
        const queryParams: URLSearchParams = new URLSearchParams();

        if (site_name) {
          queryParams.append('site_name', site_name);
        }

        // Construct the full URL with query parameters
        return `routes/get-site-rand?${queryParams}`;
      },
    }),
  }),
});

// Export the hook for querying sites
export const {
  useGetSitesQuery,
  useGetSiteByNameQuery,
  useGetSiteRandQuery,
  useGetSiteByIdQuery,
} = sitesApi;
