import { MutableRefObject, useEffect } from 'react';

export function useOutsideTouchAlerter(
  ref: MutableRefObject<any>,
  callback: () => void,
) {
  useEffect(() => {
    if (!ref.current) {
      return;
    }

    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, [ref.current, callback]);
}
