import clsx from 'clsx';

type Props = {
  width?: number;
  children: React.ReactNode;
  className?: string;
};

export function ProjectLayoutCard(props: Props) {
  const { width, children, className } = props;
  return (
    <main
      className={clsx(
        'flex flex-col items-start justify-start text-start p-6 rounded-xl mx-auto my-auto',
        className,
      )}
      style={{
        width: width || 400,
      }}
    >
      {children}
    </main>
  );
}
