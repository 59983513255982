import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { StudyResponse } from '@/api/slices/study/StudySlice';

export const mappedStudyMethodsToText: { [key: string]: string } = {
  Surgical: 'surgical',
  Device: 'device',
  Diagnostic: 'diagnostic',
  Radiation: 'radiation',
  Drug: 'drug',
  Procedure: 'procedure',
  Genetic: 'genetic',
  Biologics: 'biologics',
};

export const mappedYearsToText: { [key: string]: string } = {
  'All Time': 'in all time',
  '10 Years': 'in the last 10 years',
  '5 Years': 'in the last 5 years',
  '3 Years': 'in the last 3 years',
  'Last Year': 'in the last year',
};

interface Flow1State {
  disease: string;
  helpHow: string;
  studyMethod: string;
  years: string;
  studyData: StudyResponse | null;
  inclCriteria: string[];
  exclCriteria: string[];
  selectedPhases: string[];
  patientNumber: number | null;
  countries: string[];
  initiativeId: string | null;
}

const initialState: Flow1State = {
  disease: '', // 'Parkinson’s Disease',
  helpHow: '', // 'giving them Ozempic',
  studyMethod: 'Sleep',
  years: '5 Years',
  studyData: null,
  inclCriteria: [],
  exclCriteria: [],
  selectedPhases: [],
  patientNumber: null,
  countries: [],
  initiativeId: null,
};

export const flow1Slice = createSlice({
  name: 'flow1',
  initialState,
  reducers: {
    clearFlow1: (state) => {
      return initialState;
    },
    setDisease: (state, action: PayloadAction<string>) => {
      state.disease = action.payload;
    },
    setHelpHow: (state, action: PayloadAction<string>) => {
      state.helpHow = action.payload;
    },
    setStudyMethod: (state, action: PayloadAction<string>) => {
      state.studyMethod = action.payload;
    },
    setYears: (state, action: PayloadAction<string>) => {
      state.years = action.payload;
    },
    setStudyData: (state, action: PayloadAction<StudyResponse>) => {
      state.studyData = action.payload;
    },
    setInclCriteria: (state, action: PayloadAction<string[]>) => {
      state.inclCriteria = action.payload;
    },
    setExclCriteria: (state, action: PayloadAction<string[]>) => {
      state.exclCriteria = action.payload;
    },
    setPhases: (state, action: PayloadAction<string[]>) => {
      state.selectedPhases = action.payload;
    },
    setPatientNumber: (state, action: PayloadAction<number | null>) => {
      state.patientNumber = action.payload;
    },
    setCountries: (state, action: PayloadAction<string[]>) => {
      state.countries = action.payload;
    },
    setInitiativeId: (state, action: PayloadAction<string | null>) => {
      state.initiativeId = action.payload;
    },
  },
});

export const {
  clearFlow1,
  setDisease,
  setHelpHow,
  setStudyMethod,
  setYears,
  setStudyData,
  setInclCriteria,
  setExclCriteria,
  setPhases,
  setPatientNumber,
  setCountries,
  setInitiativeId,
} = flow1Slice.actions;

export const selectStudyMethodText = (state: RootState) => {
  return mappedStudyMethodsToText[state.flow1.studyMethod];
};

export const selectYearsText = (state: RootState) => {
  return mappedYearsToText[state.flow1.years];
};

export default flow1Slice.reducer;
