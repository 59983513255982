import React, { forwardRef } from 'react';

interface InvisibleInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InvisibleInput = forwardRef<HTMLInputElement, InvisibleInputProps>(
  (props, ref) => {
    return (
      <input
        {...props}
        ref={ref}
        className="w-full text-[36px] font-normal leading-[60.75px] text-left text-white bg-transparent border-none outline-none shadow-none flex-grow pl-0 focus:text-white focus:border-none focus:outline-none focus:shadow-none active:border-none active:outline-none active:shadow-none hover:border-none hover:outline-none hover:shadow-none"
        style={{ boxShadow: 'none', ...props?.style }}
      />
    );
  },
);

export default InvisibleInput;
