export const PhaseEnum = {
  EARLY_PHASE1: 'Early Phase 1',
  PHASE1: 'Phase 1',
  PHASE1_PHASE2: 'Phase 1/Phase 2',
  PHASE2: 'Phase 2',
  PHASE2_PHASE3: 'Phase 2/Phase 3',
  PHASE3: 'Phase 3',
  PHASE3_PHASE4: 'Phase 3/Phase 4',
  PHASE4: 'Phase 4',
  NA: 'N/A',
};

export const RelevanceEnum = {
  all_time: 'All Time',
  ten_years: '10 Years',
  five_years: '5 Years',
  three_years: '3 Years',
  last_year: 'Last Year',
};

export const InterventionTypeEnum = {
  behavioral: 'Behavioral',
  biological: 'Biological',
  combination_product: 'Combination Product',
  device: 'Device',
  diagnostic_test: 'Diagnostic Test',
  dietary_supplement: 'Dietary Supplement',
  drug: 'Drug',
  genetic: 'Genetic',
  other: 'Other',
  procedure: 'Procedure',
  radiation: 'Radiation',
};
