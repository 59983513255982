import React, { useState, useEffect } from 'react';
import { getBrainstorms, getDisplayName } from '@/utils/brainstormUtils';
import type { Brainstorm } from '@/types/Brainstorm';
import { useNavigate, Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import KadenceLogoSwitcher from './KadenceLogoSwitcher';
import NavItem from './NavItem';
import NavItemDivider from './NavItemDivider';
import { useAuth } from '@/pages/auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';

import brainstormIcon from '@/images/storm_icon.svg';
import libraryIcon from '@/images/library_icon.svg';
import mapIcon from '@/images/map_icon.svg';
import routeIcon from '@/images/route_icon.svg';
import iconSrc from '@/images/kadence_icon.svg';

import { Search } from 'lucide-react';
import { isThisWeek, isToday, isYesterday, isThisMonth } from 'date-fns';

interface SidebarProps {
  activeItem?: string;
  onNavItemClick?: (item: string) => void;
}

interface BrainstormItem {
  title: string;
  link: string;
}

interface OrganizedBrainstorms {
  today: BrainstormItem[];
  yesterday: BrainstormItem[];
  thisWeek: BrainstormItem[];
  thisMonth: BrainstormItem[];
  older: BrainstormItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ activeItem, onNavItemClick }) => {
  const [brainstorms, setBrainstorms] = useState<Brainstorm[]>([]);
  const [organizedBrainstorms, setOrganizedBrainstorms] =
    useState<OrganizedBrainstorms>({
      today: [],
      yesterday: [],
      thisWeek: [],
      thisMonth: [],
      older: [],
    });
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isDropupOpen, setIsDropupOpen] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [showBrainstormLinks, setShowBrainstormLinks] = useState(false);

  const location = useLocation();

  // Check URL parameter on mount and when location changes
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const testParam = searchParams.get('test');
    setShowBrainstormLinks(testParam === 'kadencebio');
  }, [location.search]);

  useEffect(() => {
    const fetchBrainstorms = async () => {
      if (currentUser?.id) {
        const brainstormList = await getBrainstorms(currentUser.id);
        setBrainstorms(brainstormList);

        // Organize brainstorms by time periods
        const organized = brainstormList.reduce<OrganizedBrainstorms>(
          (acc, brainstorm) => {
            const item: BrainstormItem = {
              title: getDisplayName(brainstorm),
              link: `/kadencebio/brainstorm/edit/${brainstorm.id}`,
            };

            if (!brainstorm?.createdAt) {
              acc.older.push(item);
              return acc;
            }

            try {
              // Convert Firestore timestamp to Date
              const createdDate = brainstorm.createdAt.toDate();

              console.log('Processing brainstorm:', {
                id: brainstorm.id,
                createdAt: createdDate,
                title: getDisplayName(brainstorm),
              });

              if (isToday(createdDate)) {
                acc.today.push(item);
              } else if (isYesterday(createdDate)) {
                acc.yesterday.push(item);
              } else if (
                isThisWeek(createdDate) &&
                !isToday(createdDate) &&
                !isYesterday(createdDate)
              ) {
                acc.thisWeek.push(item);
              } else if (isThisMonth(createdDate) && !isThisWeek(createdDate)) {
                acc.thisMonth.push(item);
              } else {
                acc.older.push(item);
              }
            } catch (error) {
              console.error(
                'Error processing date for brainstorm:',
                brainstorm.id,
                error,
              );
              acc.older.push(item);
            }

            return acc;
          },
          {
            today: [],
            yesterday: [],
            thisWeek: [],
            thisMonth: [],
            older: [],
          },
        );

        console.log('Organized brainstorms:', organized);
        setOrganizedBrainstorms(organized);
      }
    };

    fetchBrainstorms();
  }, [currentUser?.id]);

  const filterBrainstorms = (
    items: BrainstormItem[],
    query: string,
  ): BrainstormItem[] => {
    if (!query) return items;
    if (!Array.isArray(items)) return [];

    return items.filter((item) =>
      item.title.toLowerCase().includes(query.toLowerCase()),
    );
  };

  const handleNavItemClick = (item: string) => () => {
    if (onNavItemClick) {
      onNavItemClick(item);
    }
  };

  const toggleDropup = () => {
    setIsDropupOpen(!isDropupOpen);
  };

  const closeMenu = () => setIsDropupOpen(false);
  const handleNavigation = (path: string) => {
    setIsDropupOpen(!isDropupOpen);
    navigate(path);
    closeMenu();
  };

  if (!currentUser) return null;

  const renderSection = (title: string, items: BrainstormItem[]) => {
    if (!Array.isArray(items) || items.length === 0) return null;

    return (
      <div className="space-y-2">
        <h3 className="text-gray-400 text-sm font-medium">{title}</h3>
        <div className="space-y-2 pl-2">
          {items.map((item, index) => (
            <Link
              key={`${item.link}-${index}`}
              to={item.link}
              className="block text-gray-200 text-sm hover:text-white transition-colors"
            >
              {item.title}
            </Link>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      className={`fixed z-50 top-0 left-0 h-full bg-[#2c2b32] transition-all duration-300 ease-in-out ${
        isExpanded ? 'w-64' : 'w-16'
      }`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => {
        setIsExpanded(false);
        setIsSearchActive(false);
      }}
    >
      <div className="flex flex-col h-full py-4 p-4">
        <nav className="flex-grow space-y-4 pt-8">
          <KadenceLogoSwitcher isExpanded={isExpanded} iconSrc={iconSrc} />
          <NavItemDivider />

          <div className="space-y-2">
            <div className="flex items-center justify-between">
              <NavItem
                iconSrc={brainstormIcon}
                label="Brainstorms"
                isActive={activeItem === 'brainstorms'}
                isExpanded={isExpanded}
                link="/kadencebio/brainstorms"
              />
              {isExpanded && showBrainstormLinks && (
                <button
                  onClick={() => setIsSearchActive(!isSearchActive)}
                  className="mr-2 p-1 hover:bg-gray-700 rounded-full transition-colors"
                >
                  <Search className="w-5 h-5 text-gray-400" />
                </button>
              )}
            </div>

            {/* Only show nested items if URL parameter is present */}
            {isExpanded && showBrainstormLinks && (
              <div className="ml-8 space-y-4 font-satoshi">
                {/* Search Input */}
                {isSearchActive && (
                  <div className="px-2">
                    <input
                      type="text"
                      placeholder="Search brainstorms..."
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                      className="w-full bg-gray-700 text-white text-sm rounded-md px-3 py-2 focus:outline-none focus:ring-1 focus:ring-gray-500"
                    />
                  </div>
                )}

                {/* Categories */}
                {(!isSearchActive || organizedBrainstorms.today.length > 0) &&
                  renderSection(
                    'Today',
                    filterBrainstorms(organizedBrainstorms.today, searchQuery),
                  )}

                {(!isSearchActive ||
                  organizedBrainstorms.yesterday.length > 0) &&
                  renderSection(
                    'Yesterday',
                    filterBrainstorms(
                      organizedBrainstorms.yesterday,
                      searchQuery,
                    ),
                  )}

                {(!isSearchActive ||
                  organizedBrainstorms.thisWeek.length > 0) &&
                  renderSection(
                    'This Week',
                    filterBrainstorms(
                      organizedBrainstorms.thisWeek,
                      searchQuery,
                    ),
                  )}

                {(!isSearchActive ||
                  organizedBrainstorms.thisMonth.length > 0) &&
                  renderSection(
                    'This Month',
                    filterBrainstorms(
                      organizedBrainstorms.thisMonth,
                      searchQuery,
                    ),
                  )}

                {(!isSearchActive || organizedBrainstorms.older.length > 0) &&
                  renderSection(
                    'Older',
                    filterBrainstorms(organizedBrainstorms.older, searchQuery),
                  )}
              </div>
            )}
          </div>

          <NavItem
            iconSrc={libraryIcon}
            label="Libraries"
            isActive={activeItem === 'libraries'}
            onClick={handleNavItemClick('libraries')}
            isExpanded={isExpanded}
            link="/kadencebio/libraries"
          />
          <NavItem
            iconSrc={mapIcon}
            label="Site Maps"
            isActive={activeItem === 'siteMaps'}
            onClick={handleNavItemClick('siteMaps')}
            isExpanded={isExpanded}
            link="/kadencebio/sitemaps"
          />
          <NavItemDivider />
          <NavItem
            iconSrc={routeIcon}
            label="Routes"
            isActive={activeItem === 'routes'}
            onClick={handleNavItemClick('routes')}
            isExpanded={isExpanded}
            link="/kadencebio/routes"
          />
          {currentUser.role == 'super-admin' && (
            <>
              <NavItemDivider />
            </>
          )}
        </nav>

        {currentUser && (
          <div className="mt-auto px-0 ml-[-5px] rounded-full border border-gray-600 relative">
            <div className={`relative ${isExpanded ? 'w-full' : ''}`}>
              <div
                onClick={toggleDropup}
                className={`${isExpanded ? 'inline-flex items-center w-full' : 'inline-flex'} cursor-pointer`}
              >
                {isExpanded ? (
                  <div className="flex items-center px-1 py-1 w-full">
                    <div className="w-8 h-8 flex-shrink-0">
                      <AuthorAvatar
                        author={currentUser}
                        styleVariant="simple"
                      />
                    </div>
                    <span className="text-white text-sm truncate pl-2 flex-grow">
                      {currentUser.email || 'User'}
                    </span>
                  </div>
                ) : (
                  <div className="flex items-center px-1 py-1">
                    <div className="w-8 h-8 flex-shrink-0">
                      <AuthorAvatar
                        author={currentUser}
                        styleVariant="simple"
                      />
                    </div>
                  </div>
                )}
              </div>
              {isExpanded && isDropupOpen && (
                <ul className="menu p-2 shadow bg-[#2c2b32] rounded-box w-52 absolute bottom-0 left-0 z-50 font-satoshi">
                  <li>
                    <a
                      onClick={() => navigate('/profile')}
                      className="text-white hover:bg-gray-700"
                    >
                      My Profile
                    </a>
                  </li>
                  {currentUser.role === 'super-admin' && (
                    <li>
                      <button
                        onClick={() => handleNavigation('/user-management')}
                        className="text-white hover:bg-gray-700"
                      >
                        User Management
                      </button>
                    </li>
                  )}
                  <li>
                    <button
                      onClick={() => handleNavigation('/sign-out')}
                      className="text-white hover:bg-gray-700"
                    >
                      Logout
                    </button>
                  </li>
                </ul>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Sidebar;
