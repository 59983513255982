import { useMemo, useState } from 'react';
import historyIcon from '../../../../images/history.svg';
import calendarIcon from '../../../../images/calendar.svg';
import timerIcon from '../../../../images/timer.svg';
import emojiSmileIcon from '../../../../images/emotion-smile.svg';
import emojiHappyIcon from '../../../../images/emotion-happy.svg';
import memoryCardIcon from '../../../../images/memory-card.svg';
import clsx from 'clsx';
import GlobeIcon, { ChartIcon } from './Icons';
import Divider from '../Divider';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  ViewMode,
  setCohortMinSize,
  setCohortTargetSize,
  setEndDate,
  setSelectedScenarioId,
  setSelectedSiteId,
  setStartDate,
  setViewMode,
  useSelectedScenario,
} from '@/features/enrollment_scenario/enrollment_scenario_slice';
import ScenarioItem from './ScenarioItem';
import {
  directCompetingSitesFixture,
  indirectCompetingSitesFixture,
  leadSiteFixture,
  selectedSitesFixture,
} from './sidebar_data_fixtures';
import SidebarInput from './SidebarInput';
import { ScenariosFakeData } from '@/features/enrollment_scenario/fake_data';
import { Scenario } from '@/features/enrollment_scenario/models/scenario';
import { useLogout } from '@/pages/auth/AuthContext';
import { PaymentManager } from '@/features/payments/payment_manager';
import { setSubscriptionDialogOpen } from '@/features/profile/profile_slice';
import { StudyNameOrIdSelector } from '../filterable/StudyNameOrIdSelector';

type Mode = 'Design' | 'Model';
type DataMode = 'chart' | 'globe';

function ManageSubscriptions() {
  const subscriptions = useAppSelector((s) => s.payments.subscriptions);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const manageSubscriptions = async () => {
    setIsLoading(true);

    try {
      await PaymentManager.getInstance().goToCustomerPortal();
    } catch (error) {
      console.error('Error cancelling subscription', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col items-start border-t border-t-gray-600 px-8">
      <div className="font-medium text-white my-2 text-sm">Subscriptions</div>

      <button
        className="btn btn-primary btn-sm my-2"
        onClick={() => dispatch(setSubscriptionDialogOpen(true))}
      >
        Subscribe
      </button>

      {(!subscriptions || subscriptions.length === 0) && (
        <div className="text-sm text-gray-300 mt-2 mb-4">
          No active subscriptions
        </div>
      )}
      {isLoading && (
        <div className="flex flex-row items-center h-10">Loading...</div>
      )}
      {!isLoading &&
        subscriptions.map((subscription) => (
          <div
            key={subscription.id}
            className="flex flex-row h-10 items-center font-medium text-primary text-sm cursor-pointer"
            onClick={() => manageSubscriptions()}
          >
            {subscription['items'][0].price.product.name} - Manage Subscription
          </div>
        ))}
    </div>
  );
}

const ModeItem = (props: {
  mode: Mode;
  currentMode: Mode;
  setMode: (mode: Mode) => void;
}) => {
  const { mode, currentMode, setMode } = props;

  return (
    <div
      className={clsx('text-sm font-semibold cursor-pointer', {
        'text-white': currentMode === mode,
      })}
      onClick={() => setMode(mode)}
    >
      {mode}
    </div>
  );
};

const ViewModeItem = (props: { viewMode: ViewMode }) => {
  const { viewMode } = props;
  const currentViewMode = useAppSelector((s) => s.enrollmentScenario.viewMode);
  const dispatch = useAppDispatch();

  const iconProps = {
    color: currentViewMode === viewMode ? 'white' : '#9491A7',
    className: 'h-5 w-5 fill-current cursor-pointer',
    onClick: () => dispatch(setViewMode(viewMode)),
  };

  return (
    {
      model: <ChartIcon {...iconProps} />,
      map: <GlobeIcon {...iconProps} />,
      table: <div />,
      timeline: <div />,
    }[viewMode] || null
  );
};

const parseMMYYDate = (value: string | null) => {
  try {
    return value ? new Date(value).toISOString() : null;
  } catch (e) {
    return null;
  }
};

const convertDateToMMYY = (dateValue: string | null) => {
  const date = dateValue ? new Date(dateValue) : null;
  const string = date
    ? `${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)}`
    : undefined;
  return string;
};

export default function Sidebar() {
  const [mode, setMode] = useState<Mode>('Model');
  const selectedScenario = useSelectedScenario();
  const selectedSiteId = useAppSelector(
    (s) => s.enrollmentScenario.selectedSiteId,
  );
  const cohortSize = useAppSelector((s) => s.enrollmentScenario.cohortSize);
  const dates = useAppSelector((s) => s.enrollmentScenario.dates);

  const dispatch = useAppDispatch();

  const selectSite = (siteId: string) => {
    dispatch(setSelectedSiteId(siteId));
  };

  const selectScenario = (scenario: Scenario) => {
    dispatch(setSelectedScenarioId(scenario.id));
  };

  const intervalString = useMemo(() => {
    if (!dates.start || !dates.end) {
      return '';
    }

    // calc diff in months
    const startDate = new Date(dates.start);
    const endDate = new Date(dates.end);

    const diff = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    const diffMonths = diff + endDate.getMonth() - startDate.getMonth();
    return `${diffMonths} mo`;
  }, [dates.start, dates.end]);

  const logout = useLogout();

  return (
    <div className="min-w-[336px] h-full flex flex-col bg-base-300">
      <div className="flex flex-col flex-grow overflow-y-auto">
        <div className="h-16 min-h-16 flex items-center px-6">
          <div className="flex flex-row gap-x-4">
            <ModeItem mode="Design" currentMode={mode} setMode={setMode} />
            <ModeItem mode="Model" currentMode={mode} setMode={setMode} />
          </div>

          <div className="ml-auto text-white">
            <img
              src={historyIcon}
              alt="History"
              className="w-6 h-6 hover:opacity-80"
            />
          </div>
        </div>
        <Divider />

        <div className="h-16 min-h-16 flex flex-row items-center gap-x-4 px-6">
          <ViewModeItem viewMode="model" />
          <ViewModeItem viewMode="map" />
        </div>
        <Divider />

        <div className="py-4 px-4 items-start">
          <div className="text-white text-sm font-semibold mb-2 text-start px-3">
            Timing
          </div>
          <div className="flex flex-row gap-x-2">
            <SidebarInput
              id="start-date"
              icon={calendarIcon}
              name="start"
              placeholder="S"
              type="date"
              value={convertDateToMMYY(dates.start)}
              minDate={new Date('2020-01-01')}
              maxDate={new Date()}
              onChange={(e) => {
                const date = parseMMYYDate(e.target.value || null);
                dispatch(setStartDate(date));
              }}
            />
            <SidebarInput
              id="end-date"
              icon={calendarIcon}
              name="end"
              placeholder="E"
              type="date"
              value={convertDateToMMYY(dates.end)}
              minDate={new Date('2020-01-01')}
              maxDate={new Date()}
              onChange={(e) => {
                const date = parseMMYYDate(e.target.value || null);
                dispatch(setEndDate(date));
              }}
            />
            <SidebarInput
              icon={timerIcon}
              type="text"
              name="timer"
              placeholder="T"
              value={intervalString}
              disabled
              className="w-24"
            />
          </div>
        </div>
        <Divider />

        <div className="py-4 px-4 items-start">
          <div className="text-white text-sm font-semibold mb-2 text-start px-3">
            Cohort Size
          </div>
          <div className="flex flex-row gap-x-4">
            <SidebarInput
              icon={emojiSmileIcon}
              name="min"
              placeholder="Min"
              value={cohortSize.min}
              onChange={(e) => {
                dispatch(setCohortMinSize(parseInt(e.target.value, 10) || 0));
              }}
            />
            <SidebarInput
              icon={emojiHappyIcon}
              name="target"
              placeholder="Target"
              className="w-[96px]"
              value={cohortSize.target}
              onChange={(e) => {
                dispatch(
                  setCohortTargetSize(parseInt(e.target.value, 10) || 0),
                );
              }}
            />
            <SidebarInput icon={memoryCardIcon} name="var" placeholder="Var" />
          </div>
        </div>
        <Divider />

        <div className="pb-4">
          <div className="text-white text-sm font-semibold mb-2 mt-4 text-start px-7">
            Scenarios
          </div>
          {ScenariosFakeData.map((scenario) => (
            <ScenarioItem
              key={scenario.name}
              name={scenario.name}
              isSelected={selectedScenario?.id === scenario.id}
              color={scenario.color.mostLikely}
              onClick={() => selectScenario(scenario)}
            />
          ))}
        </div>
        <Divider />

        <StudyNameOrIdSelector />

        <div className="pb-4">
          <div className="text-white text-sm font-semibold mb-2 mt-4 text-start px-7">
            Lead Site
          </div>
          {leadSiteFixture.map((scenario) => (
            <ScenarioItem
              key={scenario.name}
              name={scenario.name}
              isSelected={false}
              color={scenario.color}
              hideIcon={scenario.hideIcon}
            />
          ))}

          <div className="text-white text-sm font-semibold mb-2 mt-4 text-start px-7">
            Selected Sites
          </div>

          {selectedSitesFixture.map((site) => (
            <ScenarioItem
              key={site.name}
              name={site.name}
              isSelected={site.id === selectedSiteId}
              color={site.color}
              hideIcon={site.hideIcon}
              onClick={() => selectSite(site.id)}
            />
          ))}
        </div>
        <Divider />

        <div className="pb-6">
          <div className="text-white text-sm font-semibold mb-2 mt-4 text-start px-7">
            Direct Competing Sites
          </div>
          {directCompetingSitesFixture.map((site) => (
            <ScenarioItem
              key={site.name}
              name={site.name}
              isSelected={site.id === selectedSiteId}
              color={site.color}
              hideIcon={site.hideIcon}
              // onClick={() => selectSite(site.id)}
            />
          ))}

          <div className="text-white text-sm font-semibold mb-2 mt-4 text-start px-7">
            Indirect Competing Sites
          </div>
          {indirectCompetingSitesFixture.map((site) => (
            <ScenarioItem
              key={site.name}
              name={site.name}
              isSelected={site.id === selectedSiteId}
              color={site.color}
              hideIcon={site.hideIcon}
              // onClick={() => selectSite(site.id)}
            />
          ))}
        </div>
      </div>

      <ManageSubscriptions />

      <div className="flex flex-row h-16 border-t border-t-gray-600 px-8 items-center">
        <div
          className="text-error text-sm font-medium cursor-pointer"
          onClick={logout}
        >
          Log Out
        </div>
      </div>
    </div>
  );
}
