import { Scene } from '@/types/index';

const mockedAvailableScenes: Scene[] = [
  {
    id: 'as1',
    imageUrl:
      'https://images.unsplash.com/photo-1499568509606-4f9b771232ed?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    position: null,
  },
  {
    id: 'as2',
    imageUrl:
      'https://images.unsplash.com/photo-1438761681033-6461ffad8d80?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    position: null,
  },
  {
    id: 'as3',
    imageUrl:
      'https://images.unsplash.com/photo-1478061653917-455ba7f4a541?q=80&w=2950&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    position: null,
  },
  {
    id: 'as4',
    imageUrl:
      'https://images.unsplash.com/photo-1471864167314-e5f7e37e404c?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    position: null,
  },
  {
    id: 'as5',
    imageUrl:
      'https://images.unsplash.com/photo-1576669801945-7a346954da5a?q=80&w=2970&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D',
    position: null,
  },
  { id: 'as6', imageUrl: null, position: null },
  { id: 'as7', imageUrl: null, position: null },
  { id: 'as8', imageUrl: null, position: null },
];

export { mockedAvailableScenes };
