import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as d3 from 'd3';
import Header from './components/Header';
import NodeInfoPanel from './components/NodeInfoPanel';
import { auth, db } from '@/core/setup_firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Initiative } from '@/types/Initiative';
import { InterventionType } from '@/types/InterventionType';
import MakeNewScienceButton from '@/components/buttons/MakeNewScienceButton';

// Define the GraphNode type
export type GraphNode = {
  id: string;
  name: string;
  description: string;
  tags: string[];
  size?: number;
  color: string;
  isOwnNode: boolean;
  intervention_type: InterventionType; // Added intervention_type
};

// Define the Link type
export type Link = {
  source: string;
  target: string;
  sharedTags: string[];
};

const degreesToRadians = (degrees: number): number => degrees * (Math.PI / 180);
const radiansToDegrees = (radians: number): number => radians * (180 / Math.PI);

// List of InterventionTypes
const interventionTypes: InterventionType[] = [
  'Behavioral',
  'Biological',
  'Combination Product',
  'Device',
  'Diagnostic Test',
  'Dietary Supplement',
  'Drug',
  'Genetic',
  'Other',
  'Procedure',
  'Radiation',
];

// Function to generate links based on shared tags and calculate node sizes
const generateLinksAndSizes = (
  nodes: GraphNode[],
): { links: Link[]; nodes: GraphNode[] } => {
  const links: Link[] = [];
  const tagCounts = new Map<string, number>();

  for (let i = 0; i < nodes.length; i++) {
    for (let j = i + 1; j < nodes.length; j++) {
      const sharedTags = nodes[i].tags.filter((tag) =>
        nodes[j].tags.includes(tag),
      );
      if (sharedTags.length > 0) {
        links.push({ source: nodes[i].id, target: nodes[j].id, sharedTags });
        sharedTags.forEach((tag) => {
          tagCounts.set(tag, (tagCounts.get(tag) || 0) + 1);
        });
      }
    }
  }

  // Calculate size based on the number of shared tags
  nodes.forEach((node) => {
    const size = node.tags.reduce(
      (sum, tag) => sum + (tagCounts.get(tag) || 0),
      0,
    );
    node.size = Math.max(10, Math.min(30, 10 + size * 2)); // Adjust size range as needed
  });

  return { links, nodes };
};

const InitiativeGraphHomePage: React.FC = () => {
  const navigate = useNavigate();
  const [initiatives, setInitiatives] = useState<Initiative[]>([]);
  const [nodes, setNodes] = useState<GraphNode[]>([]);
  const [links, setLinks] = useState<Link[]>([]);
  const [selectedNode, setSelectedNode] = useState<GraphNode | null>(null);
  const [offsetAngle, setOffsetAngle] = useState(0);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [showInfoPanel, setShowInfoPanel] = useState(false);
  const [currentUser, setCurrentUser] = useState<string | null>(null);
  const [isDataReady, setIsDataReady] = useState(false);

  const svgRef = useRef<SVGSVGElement>(null);
  const graphRef = useRef<d3.Selection<
    SVGGElement,
    unknown,
    null,
    undefined
  > | null>(null);

  // Function to apply transitions with custom easing
  const applyTransition = (
    selection: d3.Selection<any, any, any, any>,
    duration: number = 800,
    ease: (t: number) => number = d3.easeLinear,
  ) => {
    return selection.transition().duration(duration).ease(ease);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user ? user.uid : null);
      getInitiatives(user ? user.uid : null);
    });

    return () => unsubscribe();
  }, []);

  useEffect(() => {
    if (!isDataReady || !svgRef.current || nodes.length === 0) return;
    console.log('Rendering graph with nodes:', nodes);

    const width = window.innerWidth;
    const height = window.innerHeight;
    const radius = Math.min(width, height) / 3;

    const svg = d3
      .select(svgRef.current)
      .attr('width', width)
      .attr('height', height);

    if (!graphRef.current) {
      const g = svg
        .append('g')
        .attr('transform', `translate(${width / 2},${height / 2})`);
      graphRef.current = g;
    }

    const g = graphRef.current;

    const nodePositions = calculateNodePositions(radius);

    // Clear existing elements
    g.selectAll('*').remove();

    // Add background circle
    g.selectAll('circle.background')
      .data([null])
      .join('circle')
      .attr('class', 'background')
      .attr('r', radius)
      .attr('fill', 'none')
      .attr('stroke', '#38DEC4')
      .attr('stroke-width', 0.5)
      .attr('opacity', 0.8);

    // Create a function to generate curve paths
    const linkArc = (d: Link) => {
      const sourceNode = nodes.find((n) => n.id === d.source);
      const targetNode = nodes.find((n) => n.id === d.target);
      const isOwnLink = sourceNode?.isOwnNode || targetNode?.isOwnNode;
      const sourceAngle = degreesToRadians(nodePositions.get(d.source) || 0);
      const targetAngle = degreesToRadians(nodePositions.get(d.target) || 0);
      const sourceRadius = radius + (sourceNode?.size || 0) / 2;
      const targetRadius = radius + (targetNode?.size || 0) / 2;

      // Calculate positions of source and target nodes
      const x1 = sourceRadius * Math.cos(sourceAngle);
      const y1 = sourceRadius * Math.sin(sourceAngle);
      const x2 = targetRadius * Math.cos(targetAngle);
      const y2 = targetRadius * Math.sin(targetAngle);

      // Calculate Euclidean distance between source and target
      const distance = Math.hypot(x2 - x1, y2 - y1);

      // Calculate angular difference and normalize it
      let angleDifference = Math.abs(targetAngle - sourceAngle);
      if (angleDifference > Math.PI) {
        angleDifference = 2 * Math.PI - angleDifference;
      }
      const normalizedAngleDifference = angleDifference / Math.PI; // Range: 0 to 1

      // Calculate midpoint between source and target
      const midX = (x1 + x2) / 2;
      const midY = (y1 + y2) / 2;

      // Direction vector from midpoint towards the center
      const dx = -midX;
      const dy = -midY;
      const length = Math.hypot(dx, dy);
      const nx = dx / length;
      const ny = dy / length;

      // Adjust control point offset based on normalized angular difference
      const maxOffset = radius * 0.5; // Maximum offset for control point
      const controlPointOffset = maxOffset * normalizedAngleDifference;

      // Calculate control point coordinates
      const controlX = midX + nx * controlPointOffset;
      const controlY = midY + ny * controlPointOffset;

      return {
        path: `M${x1},${y1} Q${controlX},${controlY} ${x2},${y2}`,
        color: isOwnLink ? '#FC78F3' : '#38DEC4',
        data: {
          source: d.source,
          target: d.target,
          sharedTags: d.sharedTags,
          sourceAngle,
          targetAngle,
          distance,
          angleDifference,
          normalizedAngleDifference,
          midX,
          midY,
          nx,
          ny,
          controlPointOffset,
          controlX,
          controlY,
          sourceNode,
          targetNode,
        },
      };
    };

    // Add or update links
    const linkElements = g
      .selectAll<SVGPathElement, Link>('path.link')
      .data(links)
      .join('path')
      .attr('class', 'link')
      .attr('d', (d) => linkArc(d).path)
      .attr('fill', 'none')
      .attr('stroke', (d) => linkArc(d).color)
      .attr('stroke-width', 0.5)
      .attr('opacity', 0.8)
      .on('click', (event: MouseEvent, d: Link) => {
        const linkData = linkArc(d).data;
        console.log('Link Data:', linkData);
        event.stopPropagation(); // Prevent the click from propagating to elements beneath
      });

    // Add or update nodes
    const nodeElements = g
      .selectAll<SVGCircleElement, GraphNode>('circle.node')
      .data(nodes, (d: GraphNode) => d.id)
      .join('circle')
      .attr('class', 'node')
      .attr(
        'cx',
        (d) =>
          radius * Math.cos(degreesToRadians(nodePositions.get(d.id) || 0)),
      )
      .attr(
        'cy',
        (d) =>
          radius * Math.sin(degreesToRadians(nodePositions.get(d.id) || 0)),
      )
      .attr('r', (d) => d.size || 0)
      .attr('fill', (d) => d?.color || '#6FE9D6')
      .attr('opacity', 1)
      .style('cursor', 'pointer');

    // Add hover and click effects for nodes
    nodeElements
      .on('mouseover', (event: MouseEvent, d: GraphNode) => {
        const fontSize = 12; // Adjust this value to change the font size

        applyTransition(
          d3.select(event.currentTarget as SVGPathElement),
          200,
          d3.easeCubicOut,
        ).attr('r', (d.size || 0) * 1.75);

        const nodeAngle = nodePositions.get(d.id) || 0;

        // Calculate label position (starting from node center)
        const labelX = radius * Math.cos(degreesToRadians(nodeAngle));
        const labelY = radius * Math.sin(degreesToRadians(nodeAngle));

        // Calculate rotation to keep label horizontal
        const rotation = -offsetAngle; // -offsetAngle - nodeAngle;

        // Create a group for the label
        const labelGroup = g
          .append('g')
          .attr('class', 'node-info')
          .attr(
            'transform',
            `translate(${labelX},${labelY}) rotate(${rotation})`,
          );

        // Add invisible rect for padding (adjust width as needed)
        const padding = (d.size || 0) + 16; // Node radius + extra space
        labelGroup
          .append('rect')
          .attr('x', 0)
          .attr('y', -fontSize / 2) // Adjust based on font size
          .attr('width', padding)
          .attr('height', fontSize) // Adjust based on font size
          .attr('fill', 'none')
          .attr('pointer-events', 'none');

        // Add text after padding
        labelGroup
          .append('text')
          .attr('x', padding)
          .attr('y', fontSize / 4) // Adjust to vertically center the text
          .attr('text-anchor', 'start')
          .attr('fill', '#fff')
          .attr('font-size', `${fontSize}px`) // Set font size here
          .text(d.name);

        // Highlight connected edges
        linkElements
          .filter((link) => link.source === d.id || link.target === d.id)
          .attr('stroke', (link) => linkArc(link).color)
          .attr('stroke-width', 1);
      })
      .on('mouseout', (event: MouseEvent, d: GraphNode) => {
        if (d.id !== selectedNode?.id) {
          applyTransition(
            d3.select(event.currentTarget as SVGPathElement),
            200,
            d3.easeCubicIn,
          ).attr('r', d.size || 0);
        }
        g.selectAll('.node-info').remove();
        g.selectAll('.node-tags').remove();
        // Deselect all edges
        linkElements
          .attr('stroke', (link) => linkArc(link).color)
          .attr('stroke-width', 0.5);
      })
      .on('click', (event: MouseEvent, d: GraphNode) => {
        event.stopPropagation();
        handleNodeSelected(d.id);
      });

    // Apply rotation with custom easing
    applyTransition(g, 800, d3.easeCubicInOut).attr(
      'transform',
      `translate(${width / 2},${height / 2}) rotate(${offsetAngle})`,
    );

    if (selectedNode) {
      const nodeAngle = nodePositions.get(selectedNode.id) || 0;
      const newOffsetAngle = 180 - nodeAngle;

      applyTransition(g, 800, d3.easeCubicInOut).attr(
        'transform',
        `translate(${width / 2 + radius * zoomLevel},${height / 2}) rotate(${newOffsetAngle}) scale(${zoomLevel})`,
      );
    } else {
      applyTransition(g, 800, d3.easeCubicInOut).attr(
        'transform',
        `translate(${width / 2},${height / 2}) rotate(0) scale(${zoomLevel})`,
      );
    }

    svg.on('click', handleDeselect);
  }, [nodes, links, offsetAngle, selectedNode, zoomLevel, isDataReady]);

  const getInitiatives = async (userId: string | null) => {
    try {
      const initiativesRef = collection(db, 'initiatives');
      const q = query(initiativesRef, where('status', '==', 'published'));
      const querySnapshot = await getDocs(q);
      const initiativeList: Initiative[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return { ...data, id: doc.id } as Initiative;
      });
      setInitiatives(initiativeList);

      // Map initiatives to graph nodes
      const graphNodes: GraphNode[] = initiativeList.map((initiative) => {
        // Assign a random intervention_type
        const randomInterventionType =
          interventionTypes[
            Math.floor(Math.random() * interventionTypes.length)
          ];
        return {
          id: initiative.id,
          name: initiative.title,
          description: initiative.sectionTranscripts[0] || '',
          // Initiative has selectedStudies; add them as tags
          tags:
            initiative?.selectedStudies &&
            Array.isArray(initiative.selectedStudies)
              ? initiative.selectedStudies.map((study) => study)
              : [],
          size: 20, // Default size, adjust based on your needs
          color: initiative.userId === userId ? '#FC78F3' : '#6FE9D6',
          isOwnNode: userId ? initiative.userId === userId : false,
          intervention_type: randomInterventionType, // Added intervention_type
        };
      });

      // Generate links based on shared tags
      const { links: newLinks, nodes: sizedNodes } =
        generateLinksAndSizes(graphNodes);
      setLinks(newLinks);
      setNodes(sizedNodes);
      handleDeselect();
      setIsDataReady(true);

      console.log('Nodes:', sizedNodes);
      console.log('Links:', newLinks);
    } catch (error) {
      console.error('Error fetching initiatives: ', error);
      setIsDataReady(true);
    }
  };

  const calculateNodePositions = (radius: number): Map<string, number> => {
    const nodePositions = new Map<string, number>();
    const nodesByType = new Map<InterventionType, GraphNode[]>();

    // Initialize map
    interventionTypes.forEach((type) => {
      nodesByType.set(type, []);
    });

    // Fill the map
    nodes.forEach((node) => {
      const type = node.intervention_type;
      const list = nodesByType.get(type);
      if (list) {
        list.push(node);
      }
    });

    const sectorAngle = 360 / interventionTypes.length;
    let currentStartAngle = 0; // Starting angle

    interventionTypes.forEach((type) => {
      const typeNodes = nodesByType.get(type) || [];
      const numNodes = typeNodes.length;
      const angleIncrement = numNodes > 0 ? sectorAngle / (numNodes + 1) : 0;

      typeNodes.forEach((node, index) => {
        const angle = currentStartAngle + angleIncrement * (index + 1); // Avoid edges
        nodePositions.set(node.id, angle);
      });

      currentStartAngle += sectorAngle;
    });

    return nodePositions;
  };

  const handleNodeSelected = (nodeId: string) => {
    const node = nodes.find((n) => n.id === nodeId);
    if (!node) return;

    if (selectedNode === node && selectedNode?.id) {
      navigate(`/initiative/${selectedNode?.id}`);
      return;
    }

    setSelectedNode(node);
    setShowInfoPanel(true);
    if (!graphRef.current) return;

    const width = window.innerWidth;
    const height = window.innerHeight;
    const radius = Math.min(width, height) / 3;

    const nodePositions = calculateNodePositions(radius);
    const nodeAngle = nodePositions.get(nodeId) ?? 0;

    // Calculate new offset angle to bring the selected node to -90 degrees (left side)
    const newOffsetAngle = 180 - nodeAngle;
    setOffsetAngle(newOffsetAngle);

    // Set zoom level
    setZoomLevel(3);

    // Update node sizes
    applyTransition(
      graphRef.current.selectAll<SVGCircleElement, GraphNode>('circle.node'),
      800,
      d3.easeCubicInOut,
    ).attr('r', (d) => (d.id === nodeId ? (d.size || 0) * 2 : d.size || 0));

    // Update label positions
    applyTransition(
      graphRef.current.selectAll<SVGTextElement, GraphNode>('text.label'),
      800,
      d3.easeCubicInOut,
    )
      .attr('x', (d) => {
        const angle = degreesToRadians(nodePositions.get(d.id) || 0);
        const nodeRadius = d.id === nodeId ? (d.size || 0) * 2 : d.size || 0;
        return (radius + nodeRadius + 10) * Math.cos(angle);
      })
      .attr('y', (d) => {
        const angle = degreesToRadians(nodePositions.get(d.id) || 0);
        const nodeRadius = d.id === nodeId ? (d.size || 0) * 2 : d.size || 0;
        return (radius + nodeRadius + 10) * Math.sin(angle);
      });
  };

  const handleDeselect = () => {
    setSelectedNode(null);
    setShowInfoPanel(false);
    setOffsetAngle(0);
    setZoomLevel(1);
    if (!graphRef.current) return;

    const width = window.innerWidth;
    const height = window.innerHeight;

    // Reset node sizes
    applyTransition(
      graphRef.current.selectAll<SVGCircleElement, GraphNode>('circle.node'),
      800,
      d3.easeCubicInOut,
    ).attr('r', (d) => d.size || 0);

    // Reset label positions
    const radius = Math.min(width, height) / 3;
    const nodePositions = calculateNodePositions(radius);

    applyTransition(
      graphRef.current.selectAll<SVGTextElement, GraphNode>('text.label'),
      800,
      d3.easeCubicInOut,
    )
      .attr(
        'x',
        (d) =>
          (radius + (d.size || 0) + 10) *
          Math.cos(degreesToRadians(nodePositions.get(d.id) || 0)),
      )
      .attr(
        'y',
        (d) =>
          (radius + (d.size || 0) + 10) *
          Math.sin(degreesToRadians(nodePositions.get(d.id) || 0)),
      );
  };

  const handleInfoPanelClose = () => {
    setSelectedNode(null);
  };

  const handleMakeNewScience = () => {
    navigate('/flow-3-wireframes/step-2');
  };

  return (
    <div className="relative w-full h-screen bg-[#1F1F23] overflow-hidden">
      {isDataReady ? (
        <svg ref={svgRef} className="absolute inset-0 w-full h-full"></svg>
      ) : (
        <div className="absolute inset-0 flex items-center justify-center">
          <p className="text-white text-xl">Loading...</p>
        </div>
      )}
      <div className="relative z-10 w-full h-full pointer-events-none">
        <Header />

        <main className="w-full h-full pt-16 flex">
          {/* Info Column */}
          <NodeInfoPanel
            initiativeId={selectedNode?.id || null}
            onClose={handleInfoPanelClose}
            onRefresh={() => getInitiatives(auth.currentUser?.uid || '')}
          />

          {/* Graph Area */}
          <div className="flex-grow"></div>
        </main>

        {/* Make New Science Button */}
        {currentUser && (
          <div className="absolute bottom-8 right-8 pointer-events-auto">
            <MakeNewScienceButton onClick={handleMakeNewScience} />
          </div>
        )}
      </div>
    </div>
  );
};

export default InitiativeGraphHomePage;
