import { KeyboardEventHandler, useState } from 'react';
import logoImage from '@/images/logo_gray.svg';
import { useNavigate } from 'react-router-dom';
import plusIcon from '@/images/plus-lg.svg';
import arrowDownIcon from '@/images/arrow-down.svg';
import { useLogout } from '@/pages/auth/AuthContext';

type Props = {
  title: string;
};

export function ProjectsTopBar(props: Props) {
  const { title } = props;
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleAvatarClick = () => {
    setMenuOpen(!menuOpen);
  };

  const logout = useLogout();

  return (
    <div className="z-20 w-full h-14 bg-zinc-800 border-b border-[#494855] justify-start items-center inline-flex">
      <div className="grow shrink basis-0 h-14 justify-start items-center flex">
        <div className="hidden sm:inline-flex px-5 py-4 border-r border-[#494855] flex-col justify-start items-start gap-2">
          <img
            src={logoImage}
            className="w-[116px] cursor-pointer"
            onClick={() => navigate('/')}
          />
        </div>

        <div className="grow shrink basis-0 self-stretch px-5 justify-start items-center gap-2 flex">
          <div className="text-white text-xl font-bold leading-7">{title}</div>
        </div>
      </div>

      <div className="px-5 justify-start items-center gap-4 flex">
        {/* 
        <div className="hidden md:flex w-[264px] h-9 p-2 rounded border border-neutral-700 justify-start items-center gap-2 flex-row flex-grow">
          <img className="w-4 h-4" src={searchIcon} />
          <input
            placeholder="Search"
            className="grow shrink basis-0 text-zinc-400 bg-zinc-800 ring-0 border-0 focus:outline-none focus:ring-0 text-sm font-normal leading-tight"
          />
        </div>

        <div className="p-2.5 bg-zinc-700 rounded-md justify-start items-start gap-2 flex">
          <img className="w-4 h-4" src={bellIcon} />
        </div>
        */}

        <button
          className="btn btn-primary btn-sm text-neutral-800 font-normal"
          onClick={() => navigate('/wireframes/create-project')}
        >
          Create Study <img src={plusIcon} className="inline-flex w-4 h-4" />
        </button>

        <button
          className="btn btn-primary btn-sm text-neutral-800 font-normal"
          // onClick={() => navigate('/initiatives')}
          onClick={() => navigate('/graph')}
        >
          Initiatives
        </button>

        <div
          className="relative flex items-center cursor-pointer"
          onClick={handleAvatarClick}
        >
          <div className="w-9 h-9 rounded-full bg-gray-200/70 flex items-center justify-center">
            <span className="text-black text-sm">RE</span>
          </div>
          <img src={arrowDownIcon} className="ml-1 w-4 h-4" alt="arrow down" />
          {menuOpen && (
            <div className="absolute right-0 mt-20 w-60 bg-white rounded-md shadow-lg z-50">
              {' '}
              {/* Adjusted mt-2 to mt-10 */}
              <ul className="py-1">
                <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                  Profile
                </li>
                <li className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer">
                  Settings
                </li>
                <li
                  className="px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 cursor-pointer"
                  onClick={logout}
                  tabIndex={0} // Makes the element focusable
                  onKeyDown={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      logout();
                    }
                  }}
                  role="button"
                >
                  Logout
                </li>
              </ul>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
