import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../lib/constants';

export interface StudiesData {
  id: string;
  name: string;
}
export const studiesApi = createApi({
  reducerPath: 'studiesApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getStudyById: builder.query<StudiesData, string>({
      query: (id: string) => `studies-by-name-or-id/?name_or_id=${id}`,
    }),
  }),
});

export const { useGetStudyByIdQuery } = studiesApi;
