import RandView from '../RandView';
import { useSelectedSite } from '../../../../features/enrollment_scenario/enrollment_scenario_slice';
import fireIcon from '../../../../images/fire.svg';
import infinityIcon from '../../../../images/infinity.svg';

export default function SiteEnrollmentMetricsBar() {
  const selectedSite = useSelectedSite();

  if (!selectedSite) {
    return null;
  }

  return (
    <div className="hidden xl:flex flex-col absolute top-8 right-0 mr-6 gap-x-12 items-end z-10 px-5 py-3">
      <div className="text-2xl mb-4" style={{ color: '#DFDEE5' }}>
        <span className="font-medium">{selectedSite.patients.current}</span>
        <span className="mx-0.5">/</span>
        {selectedSite.patients.max} Patients
      </div>
      <div className="flex flex-row h-[70px] items-center">
        <div
          className="flex flex-col gap-y-2 justify-center pl-5 pr-2 rounded-l-xl items-center h-[70px]"
          style={{ backgroundColor: '#302F37' }}
        >
          <RandView
            value={selectedSite.rand}
            imageClassName="h-[24px]"
            textClassName="text-[24px]"
          />
          <div className="text-sm font-medium" style={{ color: '#BFBDC9' }}>
            Patients / month
          </div>
        </div>
        <div
          className="flex flex-col gap-y-1 pl-2.5 pr-4 items-center justify-center rounded-r-xl h-[70px]"
          style={{ backgroundColor: '#29282F' }}
        >
          <div className="flex flex-row text-2xl font-normal items-center gap-x-1">
            <div>{selectedSite.historicalStudies.current}</div>
            <img src={fireIcon} className="h-4" />/
            <div>{selectedSite.historicalStudies.max}</div>
            <img src={infinityIcon} className="h-4" />
          </div>
          <div className="font-medium text-sm" style={{ color: '#BFBDC9' }}>
            Historical Studies
          </div>
        </div>
      </div>
    </div>
  );
}
