export const ConditionsList: string[] = [
  // Added manually
  'Diabetes',
  'Premature Ejaculation',
  'Erectile Dysfunction',
  'Hypertension',
  // Unsorted old list
  'Myotonic Dystrophy',
  'Becker Dystrophy',
  'Duchenne Dystrophy',
  'Non-Hodgkin lymphoma (NHL)',
  'Non-small cell lung cancer (NSCLC)',
  'Solid tumor indications',
  'Multiple myeloma',
  'Melanoma',
  'Breast cancer',
  'Leukaemia, acute myeloid (AML)',
  'COVID-19 prophylaxis',
  'COVID-19 treatment',
  'Prostate cancer',
  "Parkinson's disease",
  'Ovarian cancer',
  'Soft tissue sarcoma',
  "Alzheimer's disease",
  'Pancreatic cancer',
  'Nonalcoholic steatohepatitis (NASH)',
  'Bladder cancer',
  'Head & neck cancers',
  'Pulmonary hypertension',
  'Obesity',
  'Other metabolic indications',
  'Sickle cell disease',
  'Duchenne muscular dystrophy',
  'Chemotherapy-induced side effects',
  'Leukaemia, chronic lymphocytic (CLL)',
  'Small cell lung cancer (SCLC)',
  'Wet age-related macular degeneration (AMD)',
  'Hyperlipidaemia',
  'Pain, chronic',
  'Schizophrenia',
  'Pemphigus vulgaris',
  'Diabetes, type 2',
  'Haemophilia B',
  'Hereditary angioedema',
  'Bone repair & regeneration',
  'Biliary cancer',
  'Colorectal cancer',
  'Gastro-intestinal stromal tumors (giST)',
  'Myelodysplastic syndrome (MDS)',
  'Stomach cancer',
  'Hepatitis B treatment',
  'General cardiovascular indications',
  'Amyotrophic lateral sclerosis (ALS)',
  'Dementia, frontotemporal',
  'Depression',
  'Dravet syndrome (epilepsy)',
  "Friedreich's ataxia",
  'Other neurological indications',
  'Post-traumatic stress disorder',
  'Relapsing-Remitting MS (RRMS)',
  'Acne',
  'Chronic wounds',
  'Eczema/Dermatitis',
  'Epidermolysis bullosa (EB)',
  'Hidradenitis suppurativa',
  'Amyloidosis',
  'Diabetes, type 1',
  'Fabry disease',
  'General liver disorders',
  'Hunter syndrome (Mucopolysaccharidosis II or MPS II)',
  'Phenylketonuria (PKU)',
  'Clostridium difficile-associated diarrhoea (CDAD)',
  'Inflammatory bowel disease (IBD), ulcerative colitis',
  'Irritable bowel syndrome, constipation-predominant (IBS-C)',
  'Mucositis',
  'Anaemia in chronic kidney disease (CKD) (dialysis)',
  'Anaemia, haemolytic',
  'Graft vs host disease (GvHD)',
  'Systemic lupus erythematosus (SLE)',
  'Myotonic dystrophy',
  'Osteoarthritis',
  'Other musculoskeletal disorders',
  'Spinal muscular atrophy',
  'Cervical cancer',
  'General blood malignancies',
  'General cancer indications',
  'Glioma',
  'Lung cancer',
  'Neuroblastoma',
  'Renal cell carcinoma (RCC)',
  'Cystic fibrosis (CF)',
  'Retinitis pigmentosa',
  'COVID-19 prophylactic',
  'Hepatitis B prophylaxis',
  'Staphylococcal infections',
  'Abdominal aortic aneurysm',
  'BAG3-associated DCM',
  'Chronic heart failure (CHF)',
  'Coronary artery disease (CAD)',
  'Heart Failure',
  'Heart failure with preserved ejection fraction (HFpEF)',
  'Hypertension (HTN)',
  'Ischemic heart failure (IHF)',
  'Peripheral vascular disease (PVD)',
  'Vasculitis',
  'Agitation',
  'Chronic fatigue syndrome',
  'Circadian rhythm sleep disorders',
  'CNS disorders',
  'Diabetic neuropathy',
  'DM1',
  'Down syndrome',
  'Epilepsy, general',
  'Essential tremor',
  'IgA nephropathy',
  'Migraine',
  'Multiple sclerosis (MS) unspecified',
  'Obsessive compulsive disorders (OCDs)',
  'Obstructive sleep apnoea (OSA)',
  'Pain, acute',
  'Pain, neuropathic',
  'Pain, post-operative',
  "Parkinson's disease (PD)",
  'Peripheral neuropathy',
  'Post-herpetic neuralgia',
  'Progressive multifocal leukoencephalopathy (PML)',
  'Relapsing-remitting multiple sclerosis and Amyotrophic lateral sclerosis (ALS)',
  'Rett syndrome',
  'Smoking cessation',
  'Social anxiety disorder',
  'Stroke, acute',
  'Alopecia/Male pattern baldness',
  'Atopic Dermatitis',
  'Facial wrinkles/Nasolabial folds',
  'Polymyositis & dermatomyositis',
  'Psoriasis',
  'Skin wounds',
  'Urticaria',
  'Acromegaly',
  'Cystinuria',
  "Gaucher's disease",
  "Graves' disease",
  'Hepatic encephalopathy',
  'Hypoglycaemia',
  'Hypoparathyroidism',
  'Hypophosphataemic rickets',
  'Metachromatic leukodystrophy',
  'Methylmalonic acidaemia (MMA)',
  'Mitochondrial disease',
  'Other lysosomal storage disorders',
  'Polycythaemia vera',
  'Prader-Willi syndrome',
  'Short stature in children',
  'Cholestasis',
  'Constipation',
  "Inflammatory bowel disease (IBD), ulcerative colitis & Crohn's disease",
  'Inflammatory bowel disease (IBD),unspecified',
  "Inflammatory bowl disease (IBD, Crohn's",
  'Niemann-Pick disease',
  'Other gastro-intestinal disorders',
  'Sclerosing cholangitis',
  'Short bowel syndrome',
  'ADPKD',
  'Haemolytic uremic syndrome (HUS)',
  'Hyperuricaemia/Gout',
  "Inflammatory bowel disease (IBD), Crohn's",
  'Inflammatory bowel disorder (IBD), Ulcerative colitis',
  'Kidney transplantation',
  'Musculoskeletal',
  'Premature delivery',
  'Primary biliary cirrhosis (PBC)',
  'Sexual dysfunction, female',
  'Urinary tract infections (UTIs)',
  'Anaemia in chronic kidney disease (CKD) (non-dialysis)',
  'Anaemia, other',
  'Blood substitution',
  'General haematological indications',
  'Haemophilia A',
  'Haemorrhagic conditions',
  'Hemlophilia A',
  'Hyperphosphataemia',
  'Myelofibrosis',
  'Paroxysmal nocturnal haemoglobinuria (PNH)',
  'Pulmonary alveolar proteinosis',
  'Thalassaemia',
  'Thrombocytopaenia',
  'Thrombocytopaenic purpura, idiopathic (ITP)',
  'Anthrax treatment',
  'Dermatomyositis and Lupus',
  'Fibromyalgia',
  'Lambert-Eaton myasthenic syndrome',
  'Leukocyte Adhesion Deficiency-I',
  'Other immune indications',
  'SCID',
  'Severe combined immunodeficiency disease (SCID)',
  "Sjögren's syndrome",
  'Ankylosing spondylitis',
  'CNS',
  'DMD',
  'Duchenne Muscular Dystrophy (DMD)',
  "Dupuytren's contracture",
  'Progeria/Hutchinson-gilford syndrome',
  'Spinal muscular atrophy (SMA)',
  'Spine fusion',
  'Tendinitis & Bursitis',
  'Actinic keratosis',
  'AML, MDS, ALL',
  'Anal cancer',
  'Atopic dermatitis',
  'B cell malignancies',
  'Basal cell carcinoma (BCC)',
  'Benign prostatic hyperplasia (BPH)',
  'Bladder and solid tumors',
  'Bone cancer (Osteosarcoma)',
  'Breast cancer, metastatic',
  'Cancer-related bone loss',
  'Cholangiocarcinoma and urothelial carcinoma',
  'Fallopian tube cancer',
  'Glioblastoma multiforme',
  'Hematologic malignancies',
  'Hepatocellular Carcinoma',
  'Hepatoma, liver cancer',
  'HRAS Mutant Head and Neck Squamous Cell Carcinoma (HNSCC)',
  'Leukaemia, acute lymphocytic (ALL)',
  'Leukaemia, chronic myeloid (CML)',
  'mCSCC & laCSCC',
  'Mesothelioma',
  'Metastatic castration-resistant prostate cancer (mCRPC)',
  'Milroy disease MLLr/mNPM1 acute leukemias',
  'Non-Hodgkins Lymphoma, r/r (r/r NHL)',
  'NSCLC, Breast',
  'NSCLC, SCLC, hematological malignancies',
  'Nuclear imaging',
  'Oncology indications',
  'Pancreatic cancer and MDR breast cancer',
  'Polycystic ovarian syndrome',
  'Radiotherapy-induced side effects',
  'RAS-mutated tumors',
  'RR EVB+ PTLD following SOT or HCT',
  'Sarcoidosis',
  'Skin cancer, non-melanoma',
  'Solid tumors',
  'Uterine cancer',
  "Waldenström's macroglobulinaemia",
  'Alpha-1 antitrypsin (AAT) congenital deficiency',
  'Asthma',
  'General respiratory disorders',
  'Lung injury, acute',
  'Chronic nasal congestions',
  'Conjunctivitis, allergic',
  'Diabetic retinopathy',
  'Dry age-related macular degeneration (AMD)',
  'Dry age-related macular degeneration (AMD) with geographic atrophy',
  'Dry eye',
  'Hearing loss/Deafness',
  'OTOF-mediated hearing loss',
  'X-linked retinitis pigmentosa (XLRP)',
  'Adenovirus infection',
  'Anthrax prophylaxis',
  'Candidiasis',
  'Dental infections',
  'Ebola virus disease (EVD) prophylaxis',
  'General bacterial indications',
  'Hepatitis C treatment',
  'HIV',
  'HIV treatment',
  'Human papillomavirus (HPV) infections',
  'Methicillin resistant Staphylococcus aureus (MRSA) infections',
  'Norovirus prophylaxis',
  'Pneumococcal infection prophylaxis',
  'Prevention of Acute Otitis Media (AOM) resulding from S. pneumoniae infections',
  'Primary immunodeficiency',
  'Recurrent C. difficile',
  'Shingles',
  'Wound infection prophylaxis',
  "Arthritis, rheumatoid; Inflammatory bowel disease (IBD), Crohn's disease; psoriasis",
];
