import { StudyDetails } from '@/types/Library';

import arrowTopRightCircleIcon from '@/images/arrow_top_right_circle.svg';

const StudyCard: React.FC<{
  study: StudyDetails;
  phase?: string;
}> = ({ study, phase }) => {
  return (
    <div className="mb-6 last:mb-0">
      <div className="flex items-start mb-2">
        <div className="w-1 min-w-1 h-1 min-h-1 rounded-full bg-[#00C7A8] m-3"></div>
        <div className="flex-grow pr-0">
          <div className="flex items-start justify-between">
            <p className="text-white text-lg flex-grow">{study.title}</p>
            <a
              href={`https://clinicaltrials.gov/study/${study.nct_id}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="ml-2 p-1 hover:bg-[#353539] rounded transition-colors flex-shrink-0"
            >
              <img
                src={arrowTopRightCircleIcon}
                alt="Open study"
                className="w-4 h-4 text-[#9491a7]"
              />
            </a>
          </div>
          <span className="block text-[#9491a7] text-sm">
            {phase || ''} Completed with {study.num_patients} patients
          </span>
          <span className="block text-[#9491a7] text-sm">
            {(() => {
              const totalSites = study.country_breakdown.reduce(
                (acc, curr) => acc + curr.num_sites,
                0,
              );
              return `${totalSites} ${totalSites === 1 ? 'Site' : 'Sites'}. `;
            })()}
            {study.country_breakdown.map((cb, i) => (
              <span key={i}>
                {cb.num_sites} {cb.country}
                {i < study.country_breakdown.length - 1 ? ', ' : '.'}
              </span>
            ))}
          </span>
        </div>
      </div>
    </div>
  );
};

export default StudyCard;
