import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NeurouteLogo from '@/components/NeurouteLogo';
import ScrollableContainer from '@/components/ScrollableContainer';
import { parseSRT } from './components/srtParser';
import { convertToSRT } from './components/srtConverter';
import Ruler from './components/Ruler';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';
import CommentSection from './components/CommentSection';
import { doc, getDoc } from 'firebase/firestore';
import { auth, db } from '@/core/setup_firebase';

import {
  Author,
  Comment,
  SubtitleEntry,
  Initiative,
  Scene,
} from '@/types/index';

import { mockedSections as sections } from '@/mocks/mockedSections';
import AuthorInfo from '@/components/AuthorInfo';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '@/pages/auth/AuthContext';
import ReactPlayer from 'react-player';

const InitiativeViewPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [initiative, setInitiative] = useState<Initiative | null>(null);
  const [author, setAuthor] = useState<Author | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  // const [currentUser, setCurrentUser] = useState<Author>(mockedCurrentUser);
  const [currentTimeMs, setCurrentTimeMs] = useState<number>(0);
  const [subtitles, setSubtitles] = useState<SubtitleEntry[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);

  const playerRef = useRef<ReactPlayer>(null);
  const showVideo = true;
  const subtitlesRef = useRef<HTMLDivElement>(null);

  const [scenes, setScenes] = useState<Scene[]>([]);

  useEffect(() => {
    const fetchInitiativeAndAuthor = async () => {
      if (id) {
        try {
          const docRef = doc(db, 'initiatives', id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const initiativeData = docSnap.data() as Initiative;
            console.log('initiativeData', initiativeData);
            const srt = convertToSRT(initiativeData.sectionTranscripts);
            setInitiative({ ...initiativeData, srt });
            setSubtitles(parseSRT(initiativeData.srt));
            setScenes(initiativeData.scenes || []);

            // Fetch author data
            if (initiativeData.userId) {
              const authorRef = doc(db, 'users', initiativeData.userId);
              const authorSnap = await getDoc(authorRef);
              if (authorSnap.exists()) {
                setAuthor(authorSnap.data() as Author);
              }
            }
          } else {
            setError('Initiative not found');
          }
        } catch (err) {
          setError('Error fetching initiative');
          console.error('Error fetching initiative:', err);
        } finally {
          setIsLoading(false);
        }
      }
    };

    fetchInitiativeAndAuthor();
  }, [id]);

  useEffect(() => {
    scrollToHighlightedSubtitle();
  }, [currentTimeMs]);

  useEffect(() => {
    const parsedSubtitles = parseSRT(initiative?.srt || '');
    setSubtitles(parsedSubtitles);
  }, [initiative?.srt]);

  useEffect(() => {
    scrollToHighlightedSubtitle();
  }, [currentTimeMs]);

  const handleRulerChange = (section: string, ms: number) => {
    setCurrentTimeMs(ms);
    if (playerRef.current) {
      playerRef.current.seekTo(ms / 1000, 'seconds');
    }
  };

  const handleSubtitleClick = (startTimeMs: number) => {
    console.log(startTimeMs);
    setCurrentTimeMs(startTimeMs);
  };

  const scrollToHighlightedSubtitle = () => {
    if (subtitlesRef.current && currentSubtitle) {
      const container = subtitlesRef.current;
      const highlightedElement = container.querySelector(
        '.opacity-100',
      ) as HTMLElement;

      if (highlightedElement) {
        const containerRect = container.getBoundingClientRect();
        const highlightedRect = highlightedElement.getBoundingClientRect();

        const relativeTop = highlightedRect.top - containerRect.top;
        const centerPosition =
          relativeTop - containerRect.height / 2 + highlightedRect.height / 2;

        container.scrollTop += centerPosition;
      }
    }
  };

  const handleProgress = (state: { playedSeconds: number }) => {
    const ms = Math.floor(state.playedSeconds * 1000);
    console.log(ms);
    setCurrentTimeMs(ms);
  };

  const handleReady = () => {
    setIsReady(true);
    setError(null);
  };

  const handleError = (e: any) => {
    console.error('ReactPlayer error:', e);
    setError(
      'An error occurred while loading the video. Please try again later.',
    );
  };

  const currentSection = useMemo(() => {
    return (
      sections.find(
        (section) =>
          currentTimeMs >= section.startTime &&
          currentTimeMs <= section.endTime,
      ) || sections[0]
    ); // Default to the first section if no match is found
  }, [currentTimeMs]);

  const currentSubtitle = useMemo(() => {
    return subtitles.find(
      (subtitle, index, arr) =>
        currentTimeMs >= subtitle.startTime &&
        (index === arr.length - 1 || currentTimeMs < arr[index + 1].startTime),
    );
  }, [subtitles, currentTimeMs]);

  const renderSubtitleParts = () => {
    return subtitles.map((subtitle, index) => {
      const isActive = subtitle === currentSubtitle;
      return (
        <span
          key={index}
          className={`
            text-white 
            transition-all 
            duration-300 
            ease-in-out 
            cursor-pointer 
            ${isActive ? 'opacity-100' : 'opacity-50'}
            hover:opacity-70
          `}
          onClick={() => handleSubtitleClick(subtitle.startTime)}
        >
          {subtitle.text}{' '}
        </span>
      );
    });
  };

  // Function to count distinct comment authors
  const countDistinctAuthors = (comments: Comment[]): number => {
    return 2;
  };

  const getCurrentScene = useMemo(() => {
    return (
      scenes.find((scene, index) => {
        const startTime = index * 12000; // 12 seconds per scene
        const endTime = startTime + 12000;
        return currentTimeMs >= startTime && currentTimeMs < endTime;
      }) || null
    );
  }, [scenes, currentTimeMs]);

  const handlePlay = () => {
    console.log('Playing video');
    setIsPlaying(true);
    playerRef.current?.getInternalPlayer().play();
  };

  const handlePause = () => {
    console.log('Pausing video');
    setIsPlaying(false);
    playerRef.current?.getInternalPlayer().pause();
  };

  const togglePlayPause = () => {
    if (!isPlaying) {
      handlePlay();
    } else {
      handlePause();
    }
  };

  if (!initiative) return null;

  const { currentUser, loading } = useAuth();

  return (
    <div className="flex flex-col w-full bg-[#1F1F23] overflow-hidden">
      <div className="flex flex-row h-full">
        {/* Left column start */}
        <div className="w-1/3 flex-shrink-0 p-4 pl-8 flex flex-col h-full">
          <div className="h-16 flex items-center justify-between mb-4">
            <div className="flex flex-row gap-x-4 items-center">
              <NeurouteLogo />
            </div>
            <img
              src={ArrowLeftCircleIcon}
              alt="Back"
              className="w-8 h-8 cursor-pointer"
              // onClick={() => navigate('/initiatives')}
              onClick={() => navigate('/graph')}
            />
          </div>

          <h1 className="mt-16 mb-16 text-white text-5xl leading-none font-medium tracking-tighter">
            {initiative.title}
          </h1>

          <div className="mb-16">
            <AuthorInfo userId={initiative.userId} initiative={initiative} />
          </div>

          {/* Subtitle parts start - Scrollable */}
          <div
            className="flex flex-col overflow-hidden"
            style={{ height: 'calc(100vh - 500px)' }}
          >
            <div className="text-white text-1xl font-bold mb-4">
              {currentSection.text} {Math.floor(currentTimeMs / 1000)}s
            </div>
            <ScrollableContainer ref={subtitlesRef}>
              <div className="text-3xl">{renderSubtitleParts()}</div>
            </ScrollableContainer>
          </div>
          {/* Subtitle parts end */}
        </div>

        {/* Main content */}
        <main className="w-1/3 flex-shrink-0 p-4 flex flex-col">
          <div
            className="pb-4 relative"
            style={{ width: '100%', height: '736px' }}
          >
            {/* Default image with padding and reduced opacity */}
            <div className="absolute inset-0 flex items-center justify-center p-16 z-0">
              <img
                src="/assets/base-default-image.png"
                alt="Default scene"
                className="w-full h-full object-contain opacity-10"
              />
            </div>

            {/* Current scene image */}

            {!showVideo && getCurrentScene && getCurrentScene.imageUrl && (
              <img
                src={getCurrentScene.imageUrl}
                alt={`Scene ${getCurrentScene.id}`}
                className="w-full h-full object-cover absolute top-0 left-0 z-10 transition-opacity duration-300"
                style={{ opacity: getCurrentScene ? 1 : 0 }}
              />
            )}

            <div
              onClick={togglePlayPause}
              className="absolute top-0 left-0 w-full h-full z-20 cursor-pointer"
            ></div>

            {showVideo && (
              <ReactPlayer
                ref={playerRef}
                url="/assets/invideo-ai-1080_Ozempic_for_Parkinson_2024-08-09-compressed.mp4"
                width="100%"
                height="100%"
                maxWidth="200px"
                maxHeight="736px"
                muted={true}
                controls={false}
                //playing={isPlaying}
                onReady={handleReady}
                // onClick={togglePlayPause}
                // onPlay={handlePlay}
                // onPause={handlePause}
                onProgress={handleProgress}
                onError={handleError}
                progressInterval={100}
                /*
                config={{
                  youtube: {
                    playerVars: { origin: window.location.origin },
                  },
                }}
                */
              />
            )}
          </div>

          <div className="flex-grow pt-8 pr-8">
            {/* Add more content here if needed */}
          </div>
        </main>

        {/* Right column start */}
        <div className="w-1/3 flex-shrink-0 p-4 flex flex-col">
          <div className="h-16 flex items-center justify-between mb-4">
            <div className="flex flex-row items-center justify-between w-full">
              <div className="flex-grow overflow-hidden whitespace-nowrap relative">
                <div className="absolute right-0 top-0 bottom-0 w-8 bg-gradient-to-l from-[#1F1F23] to-transparent"></div>
                <span className="text-3xl leading-tight font-normal text-white opacity-60">
                  work together,
                </span>
                <span className="text-3xl leading-tight font-normal text-white opacity-100 pl-1">
                  make magic
                </span>
              </div>
              <HamburgerMenu />
            </div>
          </div>

          <div className="flex flex-col">
            <div className="pb-4">
              <Ruler valueMs={currentTimeMs} onChange={handleRulerChange} />
            </div>
            <CommentSection resource={`initiatives/${id}`} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InitiativeViewPage;
