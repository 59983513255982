import React, { useEffect } from 'react';

interface CarouselProps {
  items: string[];
  value: string;
  onChange: (value: string) => void;
}

const Carousel: React.FC<CarouselProps> = ({ items, value, onChange }) => {
  const selectedIndex = items.indexOf(value);

  const handleScroll = (event: WheelEvent) => {
    event.preventDefault();
    if (event.deltaY > 0) {
      moveSelection(1);
    } else {
      moveSelection(-1);
    }
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'ArrowDown') {
      event.preventDefault();
      moveSelection(1);
    } else if (event.key === 'ArrowUp') {
      event.preventDefault();
      moveSelection(-1);
    }
  };

  const moveSelection = (direction: number) => {
    const newIndex = (selectedIndex + direction + items.length) % items.length;
    onChange(items[newIndex]);
  };

  useEffect(() => {
    window.addEventListener('wheel', handleScroll, { passive: false });
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('wheel', handleScroll);
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [selectedIndex, items]);

  const getFontSize = (index: number): string => {
    const distance = Math.abs(selectedIndex - index);
    if (distance === 0) return 'text-4xl';
    if (distance === 1 || distance === items.length - 1) return 'text-3xl';
    if (distance === 2 || distance === items.length - 2) return 'text-2xl';
    return 'text-xl';
  };

  const getPadding = (index: number): string => {
    const distance = Math.abs(selectedIndex - index);
    if (distance === 0) return 'pl-10';
    if (distance === 1) return 'pl-8';
    if (distance === 2) return 'pl-6';
    return 'pl-4';
  };

  return (
    <div className="flex flex-col items-start">
      <div className="flex flex-col items-start space-y-4">
        {items.map((item, index) => (
          <div
            key={index}
            onClick={() => onChange(item)}
            className={`cursor-pointer transition-all duration-300 ${getFontSize(index)} ${getPadding(index)} ${
              item === value
                ? 'text-green-500 font-bold'
                : 'text-gray-500 hover:text-gray-400'
            }`}
          >
            {item}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
