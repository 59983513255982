import RandView from '../RandView';
import {
  setActiveScenario,
  useSelectedScenario,
} from '../../../../features/enrollment_scenario/enrollment_scenario_slice';
import { useAppDispatch } from '../../../../app/hooks';

type ItemProps = {
  months: number;
  title: string;
  icon?: string;
  onClick?: () => void;
};

function Item({ icon, title, months, onClick }: ItemProps) {
  return (
    <div
      className="flex flex-row items-center hover:bg-gray-700 p-2 rounded cursor-pointer"
      onClick={onClick}
    >
      <img src={icon} className="h-6 w-6 mr-2" />
      <div className="flex flex-col items-start">
        <div className="text-sm font-medium text-start">
          {months} month{months === 1 ? '' : 's'}
        </div>
        <div className="flex flex-row">
          <div
            className="inline-flex py-0.5 px-1 text-[10px] rounded-sm mr-2 whitespace-nowrap"
            style={{ backgroundColor: '#484550', color: '#9491A7' }}
          >
            {title}
          </div>
          <RandView value={0.75} />
        </div>
      </div>
    </div>
  );
}

export default function EnrollmentMetricsBar() {
  const selectedScenario = useSelectedScenario();
  const dispatch = useAppDispatch();

  if (!selectedScenario) {
    return null;
  }

  const goTo = (type: 'mostLikely' | 'bestCase' | 'worstCase') => {
    dispatch(setActiveScenario({ id: selectedScenario.id, type }));
  };

  return (
    <div className="hidden xl:flex flex-row mr-8 xl:mr-8 2xl:mr-10 3xl:mr-14 gap-x-12">
      <Item
        title="Most Likely"
        icon={selectedScenario?.color.mostLikelyIcon}
        months={6}
        onClick={() => goTo('mostLikely')}
      />
      <Item
        title="Best Case"
        icon={selectedScenario?.color.bestCaseIcon}
        months={5}
        onClick={() => goTo('bestCase')}
      />
      <Item
        title="Worst Case"
        icon={selectedScenario?.color.worstCaseIcon}
        months={7}
        onClick={() => goTo('worstCase')}
      />
    </div>
  );
}
