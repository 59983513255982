import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
} from 'react';
import { getAuth, onAuthStateChanged, signOut, User } from 'firebase/auth';
import { getApp } from 'firebase/app';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase'; // Ensure this path is correct
import { useAppDispatch } from '../../app/hooks';
import { clearPayments } from '@/features/payments/payments_slice';
import { clearEnrollmentScenarioData } from '@/features/enrollment_scenario/enrollment_scenario_slice';
import { clearProfile } from '@/features/profile/profile_slice';
import { UserProfile } from '@/types/UserProfile';

type NeurouteUser = UserProfile & { role: string };

type AuthContextData = {
  currentUser: NeurouteUser | null;
  authUser: User | null;
  loading: boolean;
};

const AuthContext = createContext<AuthContextData>({
  currentUser: null,
  authUser: null,
  loading: true,
});

interface AuthProviderProps {
  redirectToSignIn?: boolean;
  children: ReactNode;
}

const getNeurouteUser = async (user: User): Promise<NeurouteUser> => {
  const userDocRef = doc(db, 'users', user.uid);
  const userDoc = await getDoc(userDocRef);
  const role = userDoc.exists() ? userDoc.data().role || 'viewer' : 'viewer';

  return {
    id: user.uid,
    email: user.email || '',
    displayName: user.displayName || '',
    photoURL: user.photoURL || '',
    role: role,
  };
};

const userKey = 'current_user';

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [currentUser, setCurrentUser] = useState<NeurouteUser | null>(() => {
    const data = localStorage.getItem(userKey);
    if (data) {
      try {
        return JSON.parse(data);
      } catch (error) {
        console.error('Error parsing user data from localStorage:', error);
        localStorage.removeItem(userKey); // Remove invalid data
      }
    }
    return null;
  });

  const [authUser, setAuthUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const app = getApp();
    const auth = getAuth(app);
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const neurouteUser = await getNeurouteUser(user);
          setCurrentUser(neurouteUser);
          localStorage.setItem(userKey, JSON.stringify(neurouteUser));
        } catch (error) {
          console.error('Error fetching user role:', error);
          setCurrentUser(null);
          localStorage.removeItem(userKey);
        }
      } else {
        setCurrentUser(null);
        localStorage.removeItem(userKey);
      }

      setAuthUser(user);
      setLoading(false);
    });

    return unsubscribe;
  }, []);

  return (
    <AuthContext.Provider value={{ currentUser, authUser, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): AuthContextData => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};

export const useLogout = () => {
  const dispatch = useAppDispatch();

  const logout = useCallback(() => {
    const app = getApp();
    const auth = getAuth(app);

    signOut(auth)
      .then(() => {
        dispatch(clearPayments());
        dispatch(clearEnrollmentScenarioData());
        dispatch(clearProfile());
      })
      .catch((error) => {
        console.error(error);
      });
  }, [dispatch]);

  return logout;
};
