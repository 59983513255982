import { useState, useCallback } from 'react';
import { doc, getDoc, Timestamp } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { Brainstorm, FreeTextSearchResponse } from '@/types/Brainstorm';

export const initBrainstormData: Brainstorm = {
  id: '',
  userId: '',
  name: '',
  currentStep: 'indication.edit',
  completedSteps: [],
  createdAt: Timestamp.now(),
  hasLibrary: false,
  hasSiteMap: false,
  hasRoute: false,
  //
  //
  indicationFreeText: '',
  similarConditions: [],
  similarConditionsCount: 0,
  indication: '',
  interventionFreeText: '', // Step 2
  interventionType: '',
  relevance: '5 Years',
  relevanceText: '', // Step 3
  phase: 'Phase 1',
  phaseText: '', // Step 4
  phaseAvgNumPatients: 0, // Step 4
  patientCount: 0,
  availableInclusionCriteria: [],
  availableExclusionCriteria: [],
  inclusionCriteria: [],
  exclusionCriteria: [],
  outcomes: 0,
  timing: '',
  average_rand: 0,
  //
  searchResponse: {} as FreeTextSearchResponse,
  primaryOutcome: '',
  secondaryOutcomes: [],
  targetStartDate: new Date().toISOString(),
  studyMethods: [],
};

export const useLoadBrainstorm = (
  initData: Brainstorm = initBrainstormData,
) => {
  const [brainstorm, setBrainstorm] = useState<Brainstorm>(initData);
  const [loading, setLoading] = useState(false);

  const fetchBrainstorm = useCallback(
    async (brainstormId: string) => {
      setLoading(true);
      try {
        console.log('Fetching brainstorm with id:', brainstormId);
        const brainstormRef = doc(db, 'brainstorms', brainstormId);
        const brainstormSnap = await getDoc(brainstormRef);

        if (brainstormSnap.exists()) {
          const data = brainstormSnap.data() as Brainstorm;
          setBrainstorm({
            ...initData,
            ...data,
            id: brainstormSnap.id,
          });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error fetching brainstorm: ', error);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [initData],
  );

  return { brainstorm, setBrainstorm, loading, fetchBrainstorm };
};
