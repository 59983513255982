import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  selectAgeMax,
  selectAgeMin,
  setAgeMax,
  setAgeMin,
} from '../../../features/create_study/create_study_slice';

const AgeRangeSelector = () => {
  const minAge = useAppSelector(selectAgeMin);
  const maxAge = useAppSelector(selectAgeMax);
  const dispatch = useAppDispatch();

  return (
    <div className="">
      <div className="text-[#b8b9c1] text-xs font-medium leading-tight text-start mb-1">
        Age Range
      </div>

      <div className="flex flex-row items-center gap-2">
        <input
          type="number"
          placeholder="Min age"
          value={minAge}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 0) {
              return dispatch(setAgeMin(0));
            }
            if (value > 120) {
              return dispatch(setAgeMin(120));
            }
            return dispatch(setAgeMin(value));
          }}
          className="w-full bg-[#41414B] border-0 text-white placeholder-gray-400 px-3 py-2 rounded-md mb-2"
        />

        <div className="text-white text-sm font-medium mb-0.5">to</div>

        <input
          type="number"
          placeholder="Max age"
          value={maxAge}
          onChange={(e) => {
            const value = parseInt(e.target.value);
            if (value < 0) {
              return dispatch(setAgeMax(0));
            }
            if (value > 120) {
              return dispatch(setAgeMax(120));
            }
            return dispatch(setAgeMax(value));
          }}
          className="w-full bg-[#41414B] border-0 text-white placeholder-gray-400 px-3 py-2 rounded-md mb-2"
        />
      </div>
    </div>
  );
};

export default AgeRangeSelector;
