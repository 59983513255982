import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
  Area,
  AreaChart,
  Bar,
  BarChart,
  ComposedChart,
} from 'recharts';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  setActiveScenario,
  setHoveredScenario,
  useActiveScenario,
} from '@/features/enrollment_scenario/enrollment_scenario_slice';
import ActiveEnrollmentMetricsBar from './ActiveEnrollmentMetricsBar';
import { convertMMYYToDate, convertToQuarter } from '@/utils/date';
import FullscreenButton from './FullscreenButton';
import { useChartSize } from './hooks/useChartSize';
import NoChartDataMessageView from './NoChartDataMessageView';
import { ScenarioItemData } from '../sidebar/sidebar_data_fixtures';
import { useFakeChartData } from './hooks/useFakeChartData';

const data = [
  { name: 'Page A', uv: 10 },
  { name: 'Page B', uv: 15 },
  { name: 'Page C', uv: 20 },
  { name: 'Page D', uv: 30 },
  { name: 'Page E', uv: 50 },
  { name: 'Page F', uv: 70 },
  { name: 'Page G', uv: 100 },
];

const CustomLabel = ({
  viewBox,
  value,
  title,
}: {
  viewBox: any;
  value: string;
  title: string;
}) => {
  const { x, y } = viewBox;
  const labelY = y + 4;
  const labelX = x;

  return (
    <g>
      {/* <rect
        x={labelX}
        y={labelY - 26}
        width="60"
        height="14"
        fill="#27262B"
        rx={4}
        ry={4}
      /> */}
      <text
        x={labelX}
        y={labelY - 10}
        fill="#BFBDC9"
        textAnchor="left"
        fontSize={10}
      >
        {title} {value}
      </text>
    </g>
  );
};

const CustomTick = ({
  x,
  y,
  payload,
  fill,
  showQuaters,
  index,
  visibleTicksCount,
}: any) => {
  const displayValue = showQuaters
    ? convertToQuarter(payload.value)
    : payload.value;

  return (
    <text
      x={x}
      y={y + 10}
      textAnchor="middle"
      fill={fill || '#BFBDC9'}
      fontSize={11}
    >
      {displayValue}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
  if (active && payload && payload.length) {
    const { x, y } = coordinate;
    return (
      <div>
        {/* Tooltip box */}
        <div
          style={{
            position: 'absolute',
            top: y - 60,
            left: x,
            backgroundColor: '#2f2f2f',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            textAlign: 'center',
            transform: 'translate(-50%, -100%)',
          }}
        >
          {`${payload[0].value} Patients`}
          <div
            style={{
              content: '',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              marginLeft: '-10px',
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid #2f2f2f',
            }}
          />
        </div>
        {/* Vertical line */}
        <svg
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          <line
            x1={x}
            y1={y}
            x2={x}
            y2="100%"
            stroke="#7CFC00"
            strokeWidth="2"
          />
          <rect
            x={x - 5}
            y={y - 5}
            width="10"
            height="10"
            fill="#7CFC00"
            stroke="black"
            strokeWidth="1"
          />
        </svg>
      </div>
    );
  }

  return null;
};

function Graph() {
  const data = useFakeChartData();
  const selectedScenarioId = useAppSelector(
    (s) => s.enrollmentScenario.selectedScenarioId,
  );
  const colors = useAppSelector((s) => s.enrollmentScenario.colors);
  const [isGridVisible, setIsGridVisible] = useState(false);

  const cohortSize = useAppSelector((s) => s.enrollmentScenario.cohortSize);

  const dataKeys = Object.keys(data && data.length > 0 && data[0]).filter(
    (k) => k !== 'name',
  );

  const sortedDataKeys = useMemo(() => {
    if (!selectedScenarioId) {
      return dataKeys;
    }

    const activeScenarioIndex = dataKeys.findIndex(
      (d) => d === selectedScenarioId,
    );
    const sortedData = [...dataKeys];
    const [activeScenarioDataKey] = sortedData.splice(activeScenarioIndex, 1);
    sortedData.unshift(activeScenarioDataKey);

    return sortedData;
  }, [data, selectedScenarioId]);

  const [isXAxisHovered, setIsXAxisHovered] = useState(false);
  const { chartContainerRef, width, height } = useChartSize();

  const rightMargin = 30;
  const leftMargin = 20;

  const isChartAnimationEnabled = useAppSelector(
    (s) => s.enrollmentScenario.isChartAnimationEnabled,
  );

  return (
    <div
      ref={chartContainerRef}
      className="w-full absolute top-0 left-0 right-0 bottom-0"
      style={{ height }}
      onMouseEnter={() => setIsGridVisible(true)}
      onMouseLeave={() => setIsGridVisible(false)}
    >
      <NoChartDataMessageView data={data} />
      <ComposedChart
        width={width}
        height={height}
        data={data}
        margin={{
          top: 5,
          right: rightMargin,
          left: leftMargin,
          bottom: 5,
        }}
      >
        <CartesianGrid
          stroke={isGridVisible ? '#47464B' : 'transparent'}
          horizontal
          vertical
        />
        <XAxis
          dataKey="name"
          tickSize={0}
          tickMargin={12}
          tick={<CustomTick fill="#BFBDC9" showQuaters={isXAxisHovered} />}
          onMouseEnter={() => setIsXAxisHovered(true)}
          onMouseLeave={() => setIsXAxisHovered(false)}
          scale="point"
        />
        <YAxis
          tickSize={0}
          tickMargin={12}
          axisLine={false}
          tick={{ fontSize: 10, fill: '#EEEDF1' }}
        >
          <Label
            value="Est. Patients Enrolled"
            angle={-90}
            position="insideLeft"
            style={{
              textAnchor: 'middle',
              fontSize: 12,
              fill: '#BFBDC9',
              letterSpacing: 0.5,
            }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />

        <Area
          type="linear"
          dataKey={sortedDataKeys[0] + '.mostLikely'}
          stroke={colors[0]['mostLikely']}
          fill={colors[0]['mostLikely']}
          fillOpacity={0.2}
          isAnimationActive={isChartAnimationEnabled}
        />

        <Bar
          dataKey={sortedDataKeys[0] + '.mostLikely'}
          fill={colors[0]['bestCase']}
          width={1}
          barSize={1}
          isAnimationActive={isChartAnimationEnabled}
        />

        <ReferenceLine
          y={cohortSize.target}
          stroke="#FFFFFF"
          strokeDasharray={'2 2'}
          opacity={0.5}
          label={({ viewBox }) => (
            <CustomLabel
              viewBox={viewBox}
              title="Target"
              value={cohortSize.target.toString()}
            />
          )}
        />
        <ReferenceLine
          y={cohortSize.min}
          stroke="#FFFFFF"
          strokeDasharray={'2 2'}
          opacity={0.5}
          label={({ viewBox }) => (
            <CustomLabel
              viewBox={viewBox}
              title="Min"
              value={cohortSize.min.toString()}
            />
          )}
        />
      </ComposedChart>
    </div>
  );
}

export default function ActiveEnrollmentScenarioGraph() {
  const { scenario, type } = useActiveScenario();

  if (!scenario || !type) {
    return null;
  }

  const typeString = {
    mostLikely: 'Most Likely',
    bestCase: 'Best Case',
    worstCase: 'Worst Case',
  }[type];

  return (
    <div
      className="flex flex-col w-full relative m-4 p-4 border border-gray-700 rounded-xl text-white"
      style={{ backgroundColor: '#2C2B32' }}
    >
      <div className="p-2 flex flex-row items-start relative">
        <div
          className="absolute top-0 bottom-0 left-0 w-1.5"
          style={{ backgroundColor: scenario.color[type] }}
        ></div>
        <div className="relative mr-5"></div>

        <div className="flex flex-col flex-grow items-start mr-5">
          <div className="flex flew-row items-center">
            <h1 className="text-xl font-medium mr-5 text-left">
              {scenario.name}{' '}
              <span className="font-normal">{typeString} Scenario </span>
            </h1>
            <p className="text-base text-gray-400 text-start">6 months</p>
          </div>
          <p className="text-xs text-gray-400">CWER Study</p>
        </div>

        <ActiveEnrollmentMetricsBar />
      </div>

      <div className={'flex w-full relative mt-10 mb-6 pr-4 flex-1'}>
        <Graph />
      </div>

      <FullscreenButton />
    </div>
  );
}
