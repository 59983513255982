import React, { useState } from 'react';
import globeIcon from '../../../../images/globe-americas.svg';
import geoIcon from '../../../../images/geo-alt.svg';
import {
  setSitesTab,
  SitesTab,
} from '../../../../features/enrollment_scenario/enrollment_scenario_slice';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import clsx from 'clsx';

type Props = {
  allSitesCount: number;
  savedSitesCount: number;
  onTabChange?: (tab: SitesTab) => void;
};

const SiteNavigation = ({
  allSitesCount,
  savedSitesCount,
  onTabChange,
}: Props) => {
  const selectedTab = useAppSelector((s) => s.enrollmentScenario.sitesTab);
  const dispatch = useAppDispatch();

  const handleTabClick = (tab: SitesTab) => {
    dispatch(setSitesTab(tab));
    if (onTabChange) {
      onTabChange(tab);
    }
  };

  type TabButtonProps = {
    id: SitesTab;
    icon: string;
    label: string;
    count: number;
  };

  const TabButton = ({ id, icon, label, count }: TabButtonProps) => {
    const isSelected = selectedTab === id;

    return (
      <button
        onClick={() => handleTabClick(id)}
        className={`flex flex-row items-center px-4 py-2 h-full min-h-full relative ${isSelected ? 'text-white' : 'text-gray-400 hover:text-white'}`}
        aria-selected={isSelected}
        role="tab"
      >
        <img className="mr-2 w-4 h-4 min-h-4 min-w-4" src={icon} />
        <span className="mr-2">{label}</span>
        <span
          className={clsx(
            'bg-gray-700 px-2 py-0.5 rounded-full text-xs text-white',
            isSelected && 'bg-primary',
          )}
        >
          {count}
        </span>
        {selectedTab === id && (
          <span className="absolute bottom-0 left-0 right-0 w-full h-0.5 bg-primary" />
        )}
      </button>
    );
  };

  return (
    <nav className="h-full flex flex-col justify-center">
      <div className="flex relative h-full" role="tablist">
        <TabButton
          id="all_sites"
          icon={globeIcon}
          label="All Sites"
          count={allSitesCount}
        />
        <TabButton
          id="saved_sites"
          icon={geoIcon}
          label="Saved Sites"
          count={savedSitesCount}
        />
      </div>
    </nav>
  );
};

export default SiteNavigation;
