import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import InputStep from '../sections/InputStep';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';

const PatientCountSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  return (
    <>
      {isSection('patientCount') && (
        <HeaderTitle
          prefix="with around"
          value={`${brainstorm.patientCount || 'no'} patients`}
          className="mt-14 mb-8"
        />
      )}

      {isCurrentStep('patientCount.edit') && (
        <InputStep
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          placeholder="Patients"
          field="patientCount"
          onNextStep={() => {
            nextStep('patientCount.correct');
          }}
        />
      )}

      {isCurrentStep('patientCount.correct') && (
        <>
          <CorrectMeBlock
            onClick={() => {
              removeStep('patientCount*');
              setCurrentStep('patientCount.edit');
            }}
            text="Correct me if you had a specific number in mind p.s. we'll make this a range anyways."
          />
          <ThatLooksRightToMe
            onNextStep={() => nextStep('inclusionCriteria.search')}
          />
        </>
      )}
    </>
  );
};

export default PatientCountSection;
