import React from 'react';
import { Brainstorm } from '@/types/Brainstorm';

import { convertDateToMMYY } from '@/utils/date';

interface SideLabelViewProps {
  brainstorm: Brainstorm;
  field: keyof Brainstorm;
  label: string;
}

const SideLabelView: React.FC<SideLabelViewProps> = ({
  field,
  label,
  brainstorm,
}) => {
  const value = brainstorm[field];

  let displayValue = '';
  if (Array.isArray(value)) {
    displayValue = `${value.length}`;
  } else if (typeof value === 'number') {
    displayValue = value.toString();
  } else if (field === 'timing') {
    displayValue = convertDateToMMYY(value as string) as string;
  } else {
    displayValue = String(value);
  }

  return (
    <div className="mt-6">
      <div className="text-[#9491a7] text-lg font-normal uppercase">
        {label}
      </div>

      <div className="text-white text-lg font-normal capitalize">
        {displayValue}
      </div>
    </div>
  );
};

export default SideLabelView;
