import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import { ProjectLayoutCard } from './components/ProjectLayoutCard';
import inclusionImg from '../../images/inclusions.svg';
import targetIcon from '../../images/target_icon.svg';
import pencilIcon from '../../images/pencil.svg';
import crossIcon from '../../images/x-Ig.svg';
import longArrowRightIcon from '../../images/long_arrow_right.svg';
import leftArrowIcon from '../../images/left-arrow-white.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectInclusionCriteria,
  addInclusionCriterion,
  updateInclusionCriterion,
  removeInclusionCriterion,
} from '../../features/create_study/create_study_slice';

interface Criterion {
  id: string;
  text: string;
}

function IconButton(props: {
  icon: string;
  className?: string;
  onClick: () => void;
}) {
  return (
    <button onClick={props.onClick} className={props.className}>
      <img src={props.icon} alt="icon" className="w-4 h-4 min-w-4" />
    </button>
  );
}

const AddInclusionCriteriaPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const criteria = useSelector(selectInclusionCriteria);

  const [newCriterion, setNewCriterion] = useState('');
  const [editingId, setEditingId] = useState<string | null>(null);

  const handleAddOrUpdateCriterion = () => {
    if (newCriterion.trim()) {
      if (editingId) {
        dispatch(
          updateInclusionCriterion({
            id: editingId,
            text: newCriterion.trim(),
          }),
        );
        setEditingId(null);
      } else {
        dispatch(addInclusionCriterion(newCriterion.trim()));
      }
      setNewCriterion('');
    }
  };

  const handleRemoveCriterion = (id: string) => {
    dispatch(removeInclusionCriterion(id));
  };

  const handleEditCriterion = (criterion: Criterion) => {
    setNewCriterion(criterion.text);
    setEditingId(criterion.id);
  };

  const handleNext = () => {
    navigate('/wireframes/add-exclusion-criteria-page');
  };

  return (
    <ProjectLayout title="Projects">
      <div className="min-h-screen flex flex-col">
        <ProjectLayoutCard width={800} className="gap-y-4">
          <div className="flex flex-row w-full justify-center">
            <img
              src={inclusionImg}
              alt="Inclusion"
              className="w-40 h-40 mb-2"
            />
          </div>

          <div className="flex flex-row w-full">
            <div className="flex flex-col w-full">
              <h1 className="w-full text-center text-2xl font-medium mb-2 text-white ">
                Add inclusion criteria
              </h1>
              <div className="w-full text-center text-zinc-400 text-sm font-medium leading-tight mb-6 ">
                Add additional filters that will be considered for your
                scenario.
              </div>
            </div>
          </div>

          <div className="flex flex-row w-full gap-4">
            <input
              type="text"
              className="input bg-[#41414b]  flex-grow text-white w-full"
              placeholder="e.g. Age < 18 years, >85"
              value={newCriterion}
              onChange={(e) => setNewCriterion(e.target.value)}
            />
            <button
              className="btn btn-primary disabled:opacity-50 disabled:bg-primary font-normal text-[#1f1f23] disabled:text-[#1f1f23]"
              disabled={!newCriterion.trim() || criteria.length >= 3}
              onClick={handleAddOrUpdateCriterion}
            >
              {editingId ? 'Update' : 'Add'}
            </button>
          </div>

          <div className="h-4 justify-center items-center gap-2 flex flex-row w-full mt-6 mb-1">
            <div className="grow shrink basis-0 h-[0px] border border-[#41414b] border-dashed"></div>
            <div className="text-white text-xs font-medium font-['Satoshi Variable'] leading-none">
              Selected Criteria
            </div>
            <div className="justify-start items-center gap-0.5 flex">
              <div className="text-white text-xs font-medium font-['Satoshi Variable'] leading-none">
                {criteria.length}
              </div>
              <div className="text-[#737584] text-xs font-medium font-['Satoshi Variable'] leading-none">
                /
              </div>
              <div className="text-[#b8b9c1] text-xs font-medium font-['Satoshi Variable'] leading-none">
                3
              </div>
            </div>
            <div className="grow shrink basis-0 h-[0px] border border-[#41414b] border-dashed"></div>
          </div>

          <ul className="space-y-4 w-full">
            {criteria.map((criterion) => (
              <li
                key={criterion.id}
                className="flex flex-row w-full items-start"
              >
                <div className="w-1.5 h-1.5 mt-2.5 mr-2 ml-3 bg-[#0ac2a1] rounded-full border border-[#1f1f23]" />

                <div className="flex flex-row flex-grow text-white mr-4">
                  {criterion.text}
                </div>

                <IconButton
                  icon={pencilIcon}
                  className="mr-4"
                  onClick={() => handleEditCriterion(criterion)}
                />

                <IconButton
                  icon={crossIcon}
                  onClick={() => handleRemoveCriterion(criterion.id)}
                />
              </li>
            ))}
          </ul>
          <div className="flex flex-row justify-between items-center mt-10 w-full">
            <div
              className="btn bg-zinc-700 rounded-md gap-2 flex flex-row justify-center items-center hover:bg-zinc-900 text-white font-normal"
              onClick={() => navigate('/wireframes/create-scenario-form')}
            >
              <img src={leftArrowIcon} className="w-4 h-4 inline-flex mr-2" />
              Cancel
            </div>

            <button
              type="submit"
              className="btn btn-primary text-[#1f1f23] font-normal disabled:opacity-50 disabled:bg-primary disabled:text-[#1f1f23]"
              onClick={() => handleNext()}
            >
              Next
              <img
                src={longArrowRightIcon}
                className="inline-flex w-4 h-4 ml-2"
              />
            </button>
          </div>
        </ProjectLayoutCard>
      </div>
    </ProjectLayout>
  );
};

export default AddInclusionCriteriaPage;
