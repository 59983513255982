import React from 'react';
import Sidebar from './KadenceBio/Sidebar';

type Props = {
  title: string;
  children: React.ReactNode;
  activeNavItem: string;
  onNavItemClick: (item: string) => void;
};

export function ProjectLayout(props: Props) {
  const { title, children, activeNavItem, onNavItemClick } = props;

  return (
    <div className="flex flex-col h-screen w-full bg-black">
      <div className="absolute inset-2 rounded-2xl overflow-hidden bg-[#1F1F23] flex">
        <Sidebar activeItem={activeNavItem} onNavItemClick={onNavItemClick} />
        <div className="flex-1 flex flex-col">
          <div className="flex-1 overflow-y-auto p-6">{children}</div>
        </div>
      </div>
    </div>
  );
}
