import { baseApi } from './api/api';
import { configureStore } from '@reduxjs/toolkit';
import { enrollmentScenarioSlice } from '@/features/enrollment_scenario/enrollment_scenario_slice';
import { flow1Slice } from '@/features/flow_1/flow_1_slice';
import { flow3Slice } from '@/features/flow_3/flow_3_slice';
import { profileSlice } from '@/features/profile/profile_slice';
import { paymentsSlice } from '@/features/payments/payments_slice';
import { studyCreationSlice } from '@/features/create_study/create_study_slice';
import { siteApi } from './api/slices/dashboard/siteApiSlice';
import { siteRandApi } from './api/slices/dashboard/siteApiSlice';
import { studiesApi } from './api/slices/dashboard/studiesApiSlice';
import { initiativeSlice } from '@/features/initiative/initiative_slice';
import { healthProfileSlice } from '@/features/health_profile/health_profile_slice';

export const store = configureStore({
  reducer: {
    // The baseApi reducer allows us to cache relevant end points locally in the Redux store
    [baseApi.reducerPath]: baseApi.reducer,
    profile: profileSlice.reducer,
    enrollmentScenario: enrollmentScenarioSlice.reducer,
    flow1: flow1Slice.reducer,
    flow3: flow3Slice.reducer,
    payments: paymentsSlice.reducer,
    studyCreation: studyCreationSlice.reducer,
    [siteApi.reducerPath]: siteApi.reducer,
    [siteRandApi.reducerPath]: siteRandApi.reducer,
    [studiesApi.reducerPath]: studiesApi.reducer,
    initiative: initiativeSlice.reducer,
    healthProfile: healthProfileSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(baseApi.middleware)
      .concat(siteApi.middleware)
      .concat(siteRandApi.middleware)
      .concat(studiesApi.middleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
