import React from 'react';
import tickInCircleIcon from '../../../images/tick_in_circle_icon.svg';
import crossIcon from '../../../images/x-Ig.svg';
import { useDispatch, useSelector } from 'react-redux';
import {
  setExclCriteria,
  setInclCriteria,
  setPatientNumber,
  setPhases,
  setCountries,
} from '@/features/flow_3/flow_3_slice';
import { RootState } from '../../../app/store';

const AdvancedOptionsDialog: React.FC<{
  onClose: () => void;
  children?: React.ReactNode;
}> = ({ onClose, children }) => {
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow3);

  const togglePhase = (phase: string) => {
    const newPhases = data.selectedPhases.includes(phase)
      ? data.selectedPhases.filter((p) => p !== phase)
      : [...data.selectedPhases, phase];
    dispatch(setPhases(newPhases));
  };

  const handleInclChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setInclCriteria(e.target.value.split(',')));
  };

  const handleExclChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setExclCriteria(e.target.value.split(',')));
  };

  const handlePatientNumberChange = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(
      setPatientNumber(e.target.value == '' ? null : Number(e.target.value)),
    );
  };

  const handleCountriesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dispatch(setCountries(e.target.value.split(',')));
  };

  const isSelected = (phase: string) => data.selectedPhases.includes(phase);

  return (
    <div
      onClick={onClose}
      className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center"
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="w-[720px] flex flex-col items-start justify-start text-start p-6 bg-zinc-800 rounded-xl shadow border border-neutral-700 mx-auto my-auto"
      >
        <div className="w-full flex justify-between items-center mb-4">
          <div>
            <span className="text-white">Advanced Parameters</span>
            <span className="text-[#9491A7]"> - everything is optional</span>
          </div>
          <div
            className="w-8 h-8 p-2 bg-zinc-700 rounded-md justify-center items-center inline-flex hover:bg-zinc-900 cursor-pointer"
            onClick={onClose}
          >
            <img className="w-4 h-4" src={crossIcon} alt="Close" />
          </div>
        </div>

        <div className="mb-4 w-full flex items-center space-x-4">
          <input
            type="number"
            value={data.patientNumber ?? undefined}
            onChange={handlePatientNumberChange}
            placeholder="Input Patient No."
            className="w-1/3 p-4 rounded border border-neutral-700 bg-zinc-900 text-white"
          />
          {['Phase I', 'Phase II', 'Phase III', 'Phase IV'].map((phase) => (
            <button
              key={phase}
              className={`px-4 py-2 rounded border border-white text-white ${
                isSelected(phase) ? 'bg-green-500' : 'bg-zinc-900'
              }`}
              onClick={() => togglePhase(phase)}
            >
              {phase}
            </button>
          ))}
        </div>
        <div className="mb-4 w-full">
          <textarea
            placeholder="Type Inclusion Criteria separated by comma"
            value={data.inclCriteria.join(',')}
            className="w-full p-4 rounded border border-neutral-700 bg-zinc-900 text-white"
            onChange={handleInclChange}
          />
        </div>
        <div className="mb-4 w-full">
          <textarea
            placeholder="Type Exclusion Criteria separated by comma"
            value={data.exclCriteria.join(',')}
            className="w-full p-4 rounded border border-neutral-700 bg-zinc-900 text-white"
            onChange={handleExclChange}
          />
        </div>
        <div className="mb-4 w-full">
          <textarea
            placeholder="Type Countries separated by comma"
            value={data.countries.join(',')}
            onChange={handleCountriesChange}
            className="w-full p-4 rounded border border-neutral-700 bg-zinc-900 text-white"
          />
        </div>
        <div className="flex w-full justify-end">
          <img
            src={tickInCircleIcon}
            alt="OK"
            className="cursor-pointer hover:opacity-75 transition-opacity duration-200"
            onClick={onClose}
          />
        </div>
      </div>
    </div>
  );
};

export default AdvancedOptionsDialog;
