import { SubtitleEntry } from '@/types/SubtitleEntry';

function parseSRT(srtContent: string): SubtitleEntry[] {
  const subtitles: SubtitleEntry[] = [];
  try {
    const entries = srtContent.trim().split('\n\n');

    entries.forEach((entry) => {
      const [id, timing, ...textLines] = entry.split('\n');
      const [start, end] = timing.split(' --> ');

      subtitles.push({
        id: parseInt(id),
        startTime: timeToMilliseconds(start),
        endTime: timeToMilliseconds(end),
        text: textLines.join(' '),
      });
    });

    console.log('parsed', subtitles);

    return subtitles;
  } catch (e) {
    return subtitles;
  }
}

function timeToMilliseconds(timeString: string): number {
  const [hours, minutes, seconds] = timeString.split(':');
  const [secs, ms] = seconds.split(',');
  return (
    parseInt(hours) * 3600000 +
    parseInt(minutes) * 60000 +
    parseInt(secs) * 1000 +
    parseInt(ms)
  );
}

export { parseSRT };
