import './App.css';
import 'leaflet/dist/leaflet.css';
import { usePayments } from './features/payments/payments_slice';
import AuthGuard from './pages/auth/AuthGuard';
import DashboardPage from './pages/dashboard/DashboardPage';
import DashboardV2Page from './pages/dashboard/DashboardV2Page';

type Props = {
  old?: boolean;
};

function AppView(props: Props) {
  usePayments();

  return (
    <div className="App absolute w-full h-full">
      {props.old ? <DashboardPage /> : <DashboardV2Page />}
    </div>
  );
}

function App(props: Props) {
  return (
    <AuthGuard>
      <AppView {...props} />
    </AuthGuard>
  );
}

export default App;
