import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import InputStep from '../sections/InputStep';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import { fetchPhaseText } from '@/api/brainstormApi';

const PhaseSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  return (
    <>
      {isSection('phase') && (
        <HeaderTitle
          prefix="I am starting from"
          value={brainstorm.phase}
          className="mt-14"
        />
      )}
      {/* 
      {isCurrentStep('phase.edit') && (
        <InputStep
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          placeholder="Phase"
          field="phase"
          onNextStep={() => {
            nextStep('phase.search');
          }}
        />
      )}
 */}
      {isCurrentStep('phase.search') && (
        <SearchAndDisplay
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          query={async () => {
            const data = await fetchPhaseText({
              condition: [brainstorm.indication],
              relevance: brainstorm.relevance,
              phase: brainstorm.phase,
              intervention_type: brainstorm.interventionType,
            });
            setBrainstorm({
              ...brainstorm,
              phaseText: data.phase_text,
              phaseAvgNumPatients: data.avg_num_patients,
              patientCount: data.avg_num_patients,
            });
          }}
          searchText="Thinking about the timelines of these studies..."
          onNextStep={() => nextStep('phase.correct')}
        />
      )}

      {(isCurrentStep('phase.correct') || hasStep('phase.correct')) && (
        <>
          <DescriptionBlock>{brainstorm.phaseText}</DescriptionBlock>
        </>
      )}

      {isCurrentStep('phase.correct') && (
        <>
          <CorrectMeBlock
            text="if I'm wrong on your progress."
            onClick={() => {
              removeStep('phase*');
              setCurrentStep('phase.edit');
            }}
          />
          <ThatLooksRightToMe
            onNextStep={() => {
              nextStep('patientCount.correct');
            }}
          />
        </>
      )}
    </>
  );
};

export default PhaseSection;
