import React from 'react';
import { FieldValue, Timestamp } from 'firebase/firestore';
import { useAuthor } from '@/core/hooks/useAuthor';
import AuthorAvatar from './AuthorAvatar';

const formatTimeAgo = (timestamp: Timestamp | FieldValue): string => {
  let date: Date;

  if (timestamp instanceof Timestamp) {
    date = timestamp.toDate();
  } else if (
    typeof timestamp === 'object' &&
    timestamp !== null &&
    'seconds' in timestamp
  ) {
    const seconds = timestamp.seconds;
    if (typeof seconds === 'number') {
      date = new Date(seconds * 1000);
    } else {
      return 'Invalid date';
    }
  } else {
    return 'Pending...';
  }

  const now = new Date();
  const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000);

  const minute = 60;
  const hour = minute * 60;
  const day = hour * 24;
  const week = day * 7;
  const month = day * 30;
  const year = day * 365;

  const formatUnit = (value: number, unit: string): string => {
    return `${value} ${unit}${value !== 1 ? 's' : ''} ago`;
  };

  switch (true) {
    case secondsAgo < minute:
      return 'Just now';
    case secondsAgo < hour:
      return formatUnit(Math.floor(secondsAgo / minute), 'minute');
    case secondsAgo < day:
      return formatUnit(Math.floor(secondsAgo / hour), 'hour');
    case secondsAgo < week:
      return formatUnit(Math.floor(secondsAgo / day), 'day');
    case secondsAgo < month:
      return formatUnit(Math.floor(secondsAgo / week), 'week');
    case secondsAgo < year:
      return formatUnit(Math.floor(secondsAgo / month), 'month');
    default:
      return formatUnit(Math.floor(secondsAgo / year), 'year');
  }
};

interface Initiative {
  collaborators?: number;
  lastUpdated?: Timestamp | FieldValue;
}

interface AuthorInfoProps {
  userId: string;
  initiative?: Initiative;
}

const AuthorInfo: React.FC<AuthorInfoProps> = ({ userId, initiative }) => {
  const { author, isLoading, error } = useAuthor(userId);

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  if (!author) return null;

  return (
    <div className="flex items-center space-x-4">
      <AuthorAvatar author={author} />
      <div>
        <h2 className="text-white text-md">{author.displayName}</h2>
        {initiative && (
          <p className="text-white text-sm opacity-80">
            {initiative.collaborators !== undefined && (
              <>
                {initiative.collaborators} collaborator
                {initiative.collaborators !== 1 ? 's' : ''}
                {initiative.lastUpdated && <span className="mx-1">•</span>}
              </>
            )}
            {initiative.lastUpdated && formatTimeAgo(initiative.lastUpdated)}
          </p>
        )}
      </div>
    </div>
  );
};

export default AuthorInfo;
