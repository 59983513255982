import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from 'app/hooks';

export const useChartSize = () => {
  const fullScreen = useAppSelector((s) => s.enrollmentScenario.fullScreen);

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const chartContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const updateSize = () => {
      if (chartContainerRef.current) {
        setWidth(chartContainerRef.current.parentElement!.offsetWidth);
        setHeight(chartContainerRef.current.parentElement!.offsetHeight);
      }
    };

    const resizeObserver = new ResizeObserver(() => {
      updateSize();
    });

    if (chartContainerRef.current) {
      resizeObserver.observe(chartContainerRef.current.parentElement!);
    }

    updateSize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [fullScreen]);

  return { width, height, chartContainerRef };
};
