import { useAppSelector } from '../../../../../app/hooks';
import { ScenarioItemData } from '../../sidebar/sidebar_data_fixtures';

export const useFakeChartData = () => {
  const dates = useAppSelector((s) => s.enrollmentScenario.dates);
  const data = useAppSelector((s) => {
    const rawData = s.enrollmentScenario.data;

    const filteredData = rawData
      .map((i) => {
        if (
          dates.start &&
          new Date((i['a'] as any)!.date) < new Date(dates.start)
        ) {
          return null;
        }

        if (
          dates.end &&
          new Date((i['a'] as any)!.date) > new Date(dates.end)
        ) {
          return null;
        }

        return i;
      })
      .filter((i) => i !== null)
      .map((i) => i as ScenarioItemData);

    return filteredData;
  });

  return data;
};
