export type ScenarioItemData = {
  name: string;
  color: string;
  key: string;
};

export const scenariosFixture: ScenarioItemData[] = [
  { name: 'UK Only', color: '#00C7A8', key: 'a' },
  { name: 'UK and EU', color: '#938CFF', key: 'b' },
  { name: 'UK, EU, and APAC', color: '#FFC700', key: 'c' },
];

export const leadSiteFixture = [
  { name: 'Royal Free Hospital', color: '#00C7A8', hideIcon: true },
];

export const selectedSitesFixture = [
  { id: '1', name: 'Mayo Clinic', color: '#EC9D41', hideIcon: false },
  { id: '2', name: 'Guy`s Hospital', color: '#938CFF', hideIcon: false },
  {
    id: '3',
    name: 'Royal Marsden Hospital',
    color: '#059B83',
    hideIcon: false,
  },
  // { id: '4', name: 'St. Thomas Hospital', color: '#059B83', hideIcon: false },
];

export const directCompetingSitesFixture = [
  { id: '5', name: 'Royal Marsden Hospital', color: '', hideIcon: true },
  { id: '6', name: 'St. Thatcher Hospital', color: '#EC9D41', hideIcon: true },
];

export const indirectCompetingSitesFixture = [
  { id: '7', name: 'Royal Base Hospital', color: '#938CFF', hideIcon: true },
  { id: '8', name: 'St. Thomas Hospital', color: '#059B83', hideIcon: true },
];
