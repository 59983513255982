import { useAppSelector } from '../../../../app/hooks';

type Props = {
  data: any[];
};

export default function NoChartDataMessageView(props: Props) {
  const { data } = props;
  const dates = useAppSelector((s) => s.enrollmentScenario.dates);
  let message = '';

  if (data.length === 0 && dates.start && dates.end) {
    message = 'No data available.\nPlease select a different date range.';
  } else if (data.length === 0) {
    message = 'No data available.';
  }

  if (message === '') {
    return null;
  }

  return (
    <div className="absolute top-0 left-0 right-0 bottom-0 flex items-center justify-center">
      <p className="text-white text-lg">{message}</p>
    </div>
  );
}
