import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseApi } from '../../api';
import { InterventionType } from '@/types/InterventionType';

/**
 * Interface representing the response for a search query.
 * Currently, the structure of the response is not specified.
 */
interface SearchResponse {
  phase: number;
  conditions: Array<{
    condition: string;
    similar_conditions: string[];
  }>;
  interventions: InterventionType[];
  countries: string[];
}

/**
 * Type for the query parameters used in performing a search.
 */
type GetSitesQueryParams = {
  /**
   * The search term used to perform the search.
   */
  search: string;
};

/**
 * Defines the API endpoints for search data using baseApi.
 */
export const searchApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Endpoint to perform a search based on a search term.
     * @param {GetSitesQueryParams} params - The query parameters for the search.
     * @returns {string | FetchArgs} The URL with query parameters.
     *
     * @example
     * // Example usage:
     * const { data, error } = useSearchQuery({ search: "Breast cancer in United Kingdom in phase 2, they should be drug" });
     *
     * // API URL generated:
     * // https://aact-api-2mwrpa6dza-ew.a.run.app/free-text-search?search=Breast%20cancer%20in%20United%20Kingdom%20in%20phase%202,%20they%20should%20be%20drug
     */
    search: builder.query<SearchResponse, GetSitesQueryParams>({
      query: (params: GetSitesQueryParams): string | FetchArgs => {
        const { search } = params;

        // Initialize URLSearchParams with the search term
        const queryParams: URLSearchParams = new URLSearchParams();

        if (search) {
          queryParams.append('search', search);
        }

        // Construct the full URL with query parameters
        return `free-text-search?${queryParams}`;
      },
    }),
  }),
});

// Export the hook for performing a search query
export const { useSearchQuery } = searchApi;
