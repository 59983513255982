import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';

interface ProfileState {
  name: string;
  isSubscriptionDialogOpen: boolean;
}

const initialState: ProfileState = {
  name: 'John Doe',
  isSubscriptionDialogOpen: false,
};

export const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    clearProfile: (state) => {
      state.name = initialState.name;
    },
    setSubscriptionDialogOpen: (state, action: PayloadAction<boolean>) => {
      state.isSubscriptionDialogOpen = action.payload;
    },
  },
});

export const { setName, clearProfile, setSubscriptionDialogOpen } =
  profileSlice.actions;

export const selectProfileName = (state: RootState) => state.profile.name;

export default profileSlice.reducer;
