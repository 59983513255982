import React from 'react';
import styled from 'styled-components';
import ArrowRightSvg from '../../../images/arrow_right.svg';

export const InputLikeElement = styled.div`
  width: 720px;
  height: 56px;
  padding: 2px 8px 2px 24px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 32px;
  border: 1px solid #676578;
  background-color: #2c2b32;
`;

export const InnerText = styled.span`
  font-size: 18px;
  font-weight: 400;
  line-height: 24.3px;
  text-align: left;
  color: var(--Text-weak, #9491a7);
`;

export const InvisibleInput = styled.input`
  font-size: 18px;
  font-weight: 400;
  line-height: 24.3px;
  text-align: left;
  color: white;
  background: none;
  border: none;
  outline: none;
  box-shadow: none;
  flex-grow: 1;
  padding-left: 0;

  &:focus {
    color: white;
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:active {
    border: none;
    outline: none;
    box-shadow: none;
  }
  &:hover {
    border: none;
    outline: none;
    box-shadow: none;
  }
`;

export const CircleButton = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 24px;
  background: #00c7a81a;
  cursor: pointer;
`;

export const ArrowRightIcon: React.FC = () => {
  return (
    <>
      <img className="w-6 h-6" src={ArrowRightSvg} />
    </>
  );
};

export const CornerPositioned = styled.div`
  position: absolute;
  top: 24px;
  left: 40px;
  display: flex;
  flex-direction: row;
`;

export const SearchingBlock = styled.div`
  padding-left: 24px;
  width: 100%;
  min-width: 720px;
  display: inline;
  font-size: 18px;
  font-weight: 400;
  line-height: 24.3px;
  text-align: left;

  span {
    color: white;
  }
`;
