import AuthLayout from '@/pages/auth/components/AuthLayout';
import { getApp } from 'firebase/app';
import { useForm } from 'react-hook-form';
import { useAuth } from './AuthContext';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { getAuth, updateProfile } from 'firebase/auth';
import logoBrandImg from '@/images/logo-brand.svg';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';
import Input from '@/pages/auth/components/Input';
import emailSvg from '@/images/email.svg';
import nameSvg from '@/images/emotion-smile.svg';
import ActionButton from '@/components/buttons/ActionButton';
import UploadImageToS3WithReactS3 from '@/pages/dashboard/components/users/UploadImageToS3WithReactS3';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import defaultUserImg from '@/images/user.png';
import { ProjectLayout } from '../kadencebio/components/ProjectsLayout';

const schema = z
  .object({
    displayName: z.string().optional(),
  })
  .required();

interface ProfileHeaderProps {
  navigate: NavigateFunction;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({ navigate }) => (
  <div className="py-6">
    <div className="flex items-center space-x-4 mb-8">
      <button
        onClick={() => navigate(-1)}
        className="p-2 hover:bg-gray-700 rounded-full transition-colors duration-200 flex items-center justify-center"
      >
        <img src={ArrowLeftCircleIcon} alt="Back" className="w-8 h-8" />
      </button>
      <img className="h-10 w-auto" src={logoBrandImg} alt="Neuroute Logo" />
    </div>
    <div className="text-white text-[40px] font-normal">
      Update your profile
    </div>
    <h2 className="mt-1.5 text-base leading-9 tracking-tight text-gray-100">
      Enter your display name and upload an image for your profile icon
    </h2>
  </div>
);

export function ProfileForm() {
  const { currentUser, loading } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, errors },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const [photoURL, setPhotoURL] = useState('');
  const [previewURL, setPreviewURL] = useState(
    currentUser?.photoURL || defaultUserImg,
  );

  const navigate = useNavigate();

  const handleImageUpload = (url: string) => {
    setPhotoURL(url);
    setPreviewURL(url);
  };

  useEffect(() => {
    if (currentUser?.photoURL) {
      setPreviewURL(currentUser.photoURL);
    }
  }, [currentUser?.photoURL]);

  const onSubmit = async (data: any) => {
    const app = getApp();
    const auth = getAuth(app);
    const currentUser = auth.currentUser;

    try {
      if (currentUser) {
        // Only include displayName in update if it was provided
        const userDataToUpdate: { displayName?: string; photoURL?: string } =
          {};

        if (data.displayName?.trim()) {
          userDataToUpdate.displayName = data.displayName.trim();
        }

        if (photoURL) {
          userDataToUpdate.photoURL = photoURL;
        }

        // Only proceed with update if there are changes
        if (Object.keys(userDataToUpdate).length > 0) {
          await updateProfile(currentUser, userDataToUpdate);
          await updateDoc(doc(db, 'users', currentUser.uid), userDataToUpdate);
        }
      } else {
        setError('root', {
          type: 'manual',
          message: 'No user is logged in.',
        });
      }

      navigate('/profile');
    } catch (error: any) {
      switch (error.code) {
        case 'auth/network-request-failed':
          setError('root', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        default:
          setError('root', {
            type: 'manual',
            message: 'Internal error. Try again later.',
          });
          console.error(error);
          break;
      }
    }
  };

  return (
    <ProjectLayout title="User Management">
      <div className="max-w-2xl mx-auto">
        <ProfileHeader navigate={navigate} />

        <div className="mt-10">
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            className="space-y-6"
          >
            <div className="flex flex-col space-y-2">
              <span className="text-sm font-medium text-gray-400">
                Email Address
              </span>
              <div className="flex items-center space-x-3">
                <img src={emailSvg} alt="Email" className="w-5 h-5" />
                <span className="text-gray-200">{currentUser?.email}</span>
              </div>
            </div>

            <Input
              id="displayName"
              title="Display Name"
              type="text"
              placeholder={currentUser?.displayName || 'John Doe'}
              autoComplete="name"
              inputProps={register('displayName')}
              icon={<img src={nameSvg} alt="Name" />}
            />

            <div className="flex items-center space-x-6">
              <div>
                <div className="flex flex-col space-y-2">
                  <span className="text-sm font-medium text-gray-400">
                    Upload Profile Picture
                  </span>
                  <div className="flex items-center space-x-4">
                    <UploadImageToS3WithReactS3
                      onImageUpload={handleImageUpload}
                    />
                    <div>
                      <img
                        src={previewURL}
                        alt="Profile"
                        className="h-9 w-9 rounded-full object-cover"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ActionButton
              title="Update your profile"
              isLoading={isSubmitting}
            />

            {errors?.root && (
              <div className="text-red-500 text-sm">{errors.root.message}</div>
            )}

            {isSubmitted && !errors.root && (
              <div className="text-primary text-sm">
                Your profile is updated.
              </div>
            )}
          </form>
        </div>
      </div>
    </ProjectLayout>
  );
}

export default function ProfilePage() {
  return (
    <AuthLayout>
      <ProfileForm />
    </AuthLayout>
  );
}
