import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { Brainstorm } from '@/types/Brainstorm';

export const getBrainstorms = async (userId: string): Promise<Brainstorm[]> => {
  try {
    const brainstormsRef = collection(db, 'brainstorms');
    const q = query(brainstormsRef, where('userId', '==', userId));
    const querySnapshot = await getDocs(q);
    const brainstormList: Brainstorm[] = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return { ...data, id: doc.id } as Brainstorm;
    });
    return brainstormList;
  } catch (error) {
    console.error('Error fetching brainstorms: ', error);
    return [];
  }
};

// Helper function to format brainstorm display name
export const getDisplayName = (brainstorm: Brainstorm): string => {
  const indication = brainstorm.indication || 'N/A';
  const intervention =
    brainstorm.interventionType || brainstorm.interventionFreeText || 'N/A';
  return `${indication} - ${intervention}`;
};
