import React, { useState } from 'react';
import { ReactComponent as AtomIcon } from '@/images/atom.svg';
import { useNavigate } from 'react-router-dom';
import chevronImage from '../../images/chevron_gray_icon.svg';
import clsx from 'clsx';

const SignUpBirthdayPage: React.FC = () => {
  const [birthday, setBirthday] = useState<string>('');
  const navigate = useNavigate();

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setBirthday(e.target.value);
  };

  const handleNextClick = () => {
    navigate('/sign-up/phone-or-email', { state: { birthday } });
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <div className="flex h-screen w-full bg-[#1F1F23] items-center justify-center">
      <div className="w-[580px] bg-[#2c2b32] rounded-lg shadow">
        <div className="px-12 py-12 flex flex-col gap-6">
          {/* Header */}
          <div className="flex items-center mb-4">
            <button onClick={handleBackClick} className="mr-4">
              <img
                src={chevronImage}
                className={clsx('w-4 h-4 trasnform rotate-90')}
              />
            </button>
            <h2 className="text-xl text-[#9491a7] font-medium">Sign up</h2>
          </div>

          {/* Title */}
          <h1 className="text-3xl text-white font-semibold mb-2">
            When’s your birthday?
          </h1>
          <p className="text-[#9491a7] mb-6">
            Your birthday won’t be shown publicly.
          </p>

          {/* Date Picker */}
          <div className="mb-6">
            <input
              type="date"
              value={birthday}
              onChange={handleDateChange}
              className="w-full bg-transparent border-b border-[#9491a7] text-white text-lg py-2 focus:outline-none"
            />
          </div>

          {/* Instruction Text */}
          <p className="text-[#9491a7] mb-6">
            If this date is correct, tap “next”. If needed, you can edit the
            date before continuing.
          </p>

          {/* Next Button */}
          <button
            onClick={handleNextClick}
            className="w-full bg-[#00c7a8] text-[#101117] gap-2 py-3 rounded-lg flex items-center justify-center hover:bg-[#00b89b] transition"
          >
            <span className="text-lg font-medium">Next</span>
            <AtomIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default SignUpBirthdayPage;
