import styled from 'styled-components';
const ScrollableContainer = styled.div`
  flex-grow: 1;
  overflow-y: auto;

  &::-webkit-scrollbar {
    width: 10px;
  }
  &::-webkit-scrollbar-track {
    background: #2c2c2c;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 20px;
    border: 3px solid #2c2c2c;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  /* For Firefox */
  scrollbar-width: thin;
  scrollbar-color: #888 #2c2c2c;
`;

export default ScrollableContainer;
