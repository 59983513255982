import ReactPlayer from 'react-player';

const NeurouteAnimatedSearching: React.FC = () => {
  return (
    <div
      className="flex items-center justify-center relative"
      style={{
        position: 'relative',
        width: '30px',
        height: '30px',
      }}
    >
      {/* Glowing background effect */}
      <div
        style={{
          position: 'absolute',
          width: '14px', // Size of the glowing effect
          height: '14px',
          backgroundColor: '#37ddc3', // Green color
          borderRadius: '50%',
          filter: 'blur(14px)', // Blurring to create the glowing effect
          zIndex: 0,
        }}
      />
      {/* Masked container for the video */}
      <div
        className="flex items-center justify-center relative"
        style={{
          position: 'relative',
          width: '22px',
          height: '22px',
          overflow: 'hidden',
          borderRadius: '50%',
          zIndex: 1,
        }}
      >
        <ReactPlayer
          url="/assets/animated-ball.mp4"
          playing
          loop
          muted
          width="30px"
          height="30px"
          className="inline-block"
          style={{
            position: 'absolute',
            width: '30px',
            height: '30px',
          }}
        />
      </div>
    </div>
  );
};

export default NeurouteAnimatedSearching;
