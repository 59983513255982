import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase'; // Import the Firestore database setup
import { Author } from '@/types/Author';

const getAuthorDetails = async (userId: string): Promise<Author | null> => {
  try {
    // Reference to the user's document in the 'users' collection
    const userDocRef = doc(db, 'users', userId);

    // Fetch the document snapshot
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();

      // Return the user data mapped to the Author interface
      return {
        id: userId,
        displayName: userData.displayName || 'Unknown User', // Fallback if displayName is missing
        photoURL:
          userData.photoURL ||
          `https://api.dicebear.com/6.x/initials/svg?seed=${userId}`, // Fallback if photoURL is missing
      };
    } else {
      console.error('No such user!');
      return null;
    }
  } catch (error) {
    console.error('Error fetching user details:', error);
    return null;
  }
};

export { getAuthorDetails };
export type { Author };
