import React, { useState } from 'react';
import Logo from './Logo';
import NavItem from './NavItem';
import { useAuth } from '@/pages/auth/AuthContext';
import AuthorAvatar from '@/components/AuthorAvatar';

// Import custom icons
import brainstormIcon from '@/images/storm_icon.svg';
import libraryIcon from '@/images/library_icon.svg';
import mapIcon from '@/images/map_icon.svg';
import recruitmentIcon from '@/images/recruitment_icon.svg';
import routeIcon from '@/images/route_icon.svg';
import iconSrc from '@/images/kadence_icon.svg';

interface SidebarProps {
  activeItem: string;
  onNavItemClick: (item: string) => void;
}

const Sidebar: React.FC<SidebarProps> = ({ activeItem, onNavItemClick }) => {
  const { currentUser } = useAuth();
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={`fixed top-0 left-0 h-full bg-[#2c2b32] transition-all duration-300 ease-in-out ${isExpanded ? 'w-64' : 'w-16'}`}
      onMouseEnter={() => setIsExpanded(true)}
      onMouseLeave={() => setIsExpanded(false)}
    >
      <div className="flex flex-col h-full p-4">
        {/*<div className="mb-8">*/}
        {/*  <img src={iconSrc} alt="Kadence" className="w-8 h-8" />*/}
        {/*</div>*/}
        <nav className="flex-grow space-y-6">
          <NavItem
            iconSrc={iconSrc}
            label={isExpanded ? '' : ''}
            isActive={activeItem === 'dashboard'}
            onClick={() => onNavItemClick('dashboard')}
          />
          <NavItem
            iconSrc={brainstormIcon}
            label={isExpanded ? 'Brainstorms' : ''}
            isActive={activeItem === 'dashboard'}
            onClick={() => onNavItemClick('dashboard')}
          />
          <NavItem
            iconSrc={libraryIcon}
            label={isExpanded ? 'Libraries' : ''}
            isActive={activeItem === 'libraries'}
            onClick={() => onNavItemClick('libraries')}
          />
          <NavItem
            iconSrc={mapIcon}
            label={isExpanded ? 'Site Maps' : ''}
            isActive={activeItem === 'siteMaps'}
            onClick={() => onNavItemClick('siteMaps')}
          />
          <NavItem
            iconSrc={recruitmentIcon}
            label={isExpanded ? 'Recruitment' : ''}
            isActive={activeItem === 'recruitment'}
            onClick={() => onNavItemClick('recruitment')}
          />
          <div className="h-[1px] bg-[#525160] my-4"></div>
          <NavItem
            iconSrc={routeIcon}
            label={isExpanded ? 'Routes' : ''}
            isActive={activeItem === 'routes'}
            onClick={() => onNavItemClick('routes')}
          />
        </nav>
        <div className="mt-auto">
          {isExpanded && <AuthorAvatar author={currentUser} />}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
