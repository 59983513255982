import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '@/core/setup_firebase';
import { doc, getDoc, deleteDoc } from 'firebase/firestore';
import { Initiative } from '@/types/Initiative';
import AuthorInfo from '@/components/AuthorInfo';

interface NodeInfoPanelProps {
  initiativeId: string | null;
  onClose: () => void;
  onRefresh: () => void;
}

const NodeInfoPanel: React.FC<NodeInfoPanelProps> = ({
  initiativeId,
  onClose,
  onRefresh,
}) => {
  const navigate = useNavigate();
  const [initiative, setInitiative] = useState<Initiative | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [isVisible, setIsVisible] = useState(false);

  const isAuthor = auth.currentUser?.uid === initiative?.userId;

  useEffect(() => {
    if (initiativeId) {
      setIsVisible(true);
      fetchInitiative(initiativeId);
    } else {
      setIsVisible(false);
    }
  }, [initiativeId]);

  useEffect(() => {
    if (!isVisible) {
      const timer = setTimeout(() => {
        setInitiative(null);
        setError(null);
      }, 300); // Match this with the CSS transition duration
      return () => clearTimeout(timer);
    }
  }, [isVisible]);

  const fetchInitiative = async (id: string) => {
    setLoading(true);
    setError(null);
    try {
      const docRef = doc(db, 'initiatives', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        setInitiative({ id: docSnap.id, ...docSnap.data() } as Initiative);
      } else {
        setError('Initiative not found');
      }
    } catch (err) {
      setError('Error fetching initiative');
      console.error('Error fetching initiative:', err);
    } finally {
      setLoading(false);
    }
  };

  const handleView = () => {
    if (initiativeId) {
      navigate(`/initiative/${initiativeId}`);
    }
  };

  const handleEdit = () => {
    if (initiativeId) {
      navigate(`/initiative/edit/${initiativeId}`);
    }
  };

  const handleDelete = async () => {
    if (
      initiativeId &&
      window.confirm('Are you sure you want to delete this initiative?')
    ) {
      try {
        await deleteDoc(doc(db, 'initiatives', initiativeId));
        console.log('Initiative deleted successfully');
        onClose();
        onRefresh();
      } catch (error) {
        console.error('Error deleting initiative:', error);
        setError('Error deleting initiative');
      }
    }
  };

  return (
    <div
      className={`w-1/3 h-full overflow-y-auto  text-white transition-opacity duration-300 ease-in-out ${
        isVisible ? 'opacity-100' : 'opacity-0'
      }`}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        bottom: 0,
        pointerEvents: isVisible ? 'auto' : 'none',
      }}
    >
      <div className="p-6 h-full flex flex-col ">
        {error && <p className="text-red-500">{error}</p>}
        {initiative && (
          <>
            <h1 className="mt-16 mb-16 text-5xl leading-none font-medium tracking-tighter">
              {initiative.title}
            </h1>
            <AuthorInfo userId={initiative.userId} initiative={initiative} />
            <p className="text-sm opacity-80 mb-4 mt-8">
              {initiative.sectionTranscripts.join(' ') ||
                'No description available'}
            </p>
            {/* 
            <div>
              <button
                className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mr-2"
                onClick={handleView}
              >
                View
              </button>
              {isAuthor && (
                <>
                  <button
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mr-2"
                    onClick={handleEdit}
                  >
                    Edit
                  </button>
                  <button
                    className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                    onClick={handleDelete}
                  >
                    Delete
                  </button>
                </>
              )}
            </div>
            */}
          </>
        )}
      </div>
    </div>
  );
};

export default NodeInfoPanel;
