import { useState, useEffect } from 'react';
import { doc, getDoc, DocumentSnapshot } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { Author } from '@/types/Author';

export const useAuthor = (userId: string) => {
  const [author, setAuthor] = useState<Author | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchAuthor = async () => {
      setIsLoading(true);
      try {
        const authorRef = doc(db, 'users', userId);
        const authorSnap = await getDoc(
          authorRef.withConverter({
            fromFirestore: (snapshot: DocumentSnapshot): Author => {
              const data = snapshot.data();
              return {
                id: snapshot.id,
                displayName: data?.displayName || 'No name',
                photoURL: data?.photoURL || '',
              };
            },
            toFirestore: (author: Author) => author,
          }),
        );

        if (authorSnap.exists()) {
          setAuthor(authorSnap.data());
        } else {
          setError('Author not found');
        }
      } catch (err) {
        setError('Error fetching author');
        console.error('Error fetching author:', err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchAuthor();
  }, [userId]);

  return { author, isLoading, error };
};
