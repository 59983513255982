import React, { useEffect, useState } from 'react';
import AWS from 'aws-sdk';
import photo from '@/images/user.png';

const S3_BUCKET = process.env.REACT_APP_S3_BUCKET || 'default-bucket-name';
const REGION = process.env.REACT_APP_REGION;
const ACCESS_KEY = process.env.REACT_APP_ACCESS_KEY;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;

AWS.config.update({
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  region: REGION,
});

const s3 = new AWS.S3();

interface UploadImageToS3Props {
  onImageUpload: (url: string) => void;
}

const UploadImageToS3WithReactS3: React.FC<UploadImageToS3Props> = ({
  onImageUpload,
}) => {
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [isUploading, setIsUploading] = useState(false);

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      setSelectedFile(e.target.files[0]);
    }
  };

  useEffect(() => {
    const handleUpload = async (file: File | null) => {
      if (!file) {
        console.error('No file selected');
        return;
      }

      const bucketName = process.env.REACT_APP_S3_BUCKET;
      if (!bucketName) {
        console.error('S3 Bucket name is not defined in environment variables');
        return;
      }

      setIsUploading(true);

      const params = {
        Bucket: bucketName,
        Key: file.name,
        Body: file,
        ContentType: file.type,
      };

      try {
        s3.upload(params, (err: Error, data: AWS.S3.ManagedUpload.SendData) => {
          setIsUploading(false);
          if (err) {
            console.error('Upload error:', err);
            return;
          }
          setImageUrl(data?.Location);
          onImageUpload(data?.Location);
        });
      } catch (err) {
        setIsUploading(false);
        console.error('Upload error:', err);
      }
    };

    if (selectedFile) {
      handleUpload(selectedFile);
    }
  }, [selectedFile]);

  return (
    <div className="relative">
      <input
        type="file"
        onChange={handleFileInput}
        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
      />
      <button
        className={`px-4 py-2 text-sm font-medium text-gray-400 bg-[#2c2b32] border border-gray-600 rounded-lg transition-colors duration-200 ${
          isUploading ? 'opacity-50 cursor-not-allowed' : 'hover:bg-gray-700'
        }`}
        disabled={isUploading}
      >
        {isUploading ? 'Uploading...' : 'Choose file'}
      </button>
    </div>
  );
};

export default UploadImageToS3WithReactS3;
