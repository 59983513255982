import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import plusIcon from '@/images/plus-lg.svg';
import { Library } from '@/types/KadenceBio';
import { auth, db } from '@/core/setup_firebase';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import sortIcon from '@/images/sorting_icon.svg';
import clsx from 'clsx';
import { ProjectLayout } from './components/ProjectsLayout';

type SortIconProps = {
  column: keyof Library;
  currentColumn: keyof Library;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}

const LibrariesCRUDPage: React.FC = () => {
  const navigate = useNavigate();
  const [libraries, setLibraries] = useState<Library[]>([]);
  const [user, setUser] = useState(auth.currentUser);
  const [sortColumn, setSortColumn] = useState<keyof Library>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        getLibraries(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const getLibraries = async (userId: string) => {
    try {
      const librariesRef = collection(db, 'libraries');
      const q = query(librariesRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const libraryList: Library[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return { ...data, id: doc.id } as Library;
      });
      setLibraries(libraryList);
    } catch (error) {
      console.error('Error fetching libraries: ', error);
    }
  };

  const handleNameClick = (id: string) => {
    navigate(`/kadencebio/library/edit/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/kadencebio/library/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'libraries', id));
      setLibraries(libraries.filter((library) => library.id !== id));
      console.log('Library deleted successfully');
    } catch (error) {
      console.error('Error deleting library:', error);
    }
  };

  const handleSort = (column: keyof Library) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedLibraries = [...libraries].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? 1 : -1;
    if (bValue == null) return sortDirection === 'asc' ? -1 : 1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (Array.isArray(aValue) && Array.isArray(bValue)) {
      return sortDirection === 'asc'
        ? aValue.length - bValue.length
        : bValue.length - aValue.length;
    }

    return 0;
  });

  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ProjectLayout title="KadenceBio">
      <div className="flex flex-col w-full bg-[#1F1F23] min-h-screen text-white">
        <div className="flex flex-row p-6">
          <main className="w-full flex-shrink-0 pl-8 pt-2 flex flex-col">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-4xl font-semibold tracking-tight">
                Libraries
              </h1>
            </div>
            <div className="overflow-auto">
              <table className="min-w-full bg-[#1F1F23] text-gray-300">
                <thead>
                  <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('name')}
                    >
                      Brainstorm
                      <SortIcon
                        column="name"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('selectedSponsors')}
                    >
                      Sponsors
                      <SortIcon
                        column="selectedSponsors"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('savedStudies')}
                    >
                      Studies
                      <SortIcon
                        column="savedStudies"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('savedSites')}
                    >
                      Sites
                      <SortIcon
                        column="savedSites"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th className="p-2 font-normal">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedLibraries.map((library) => (
                    <tr
                      key={library.id}
                      className="border-b border-gray-700 text-start text-sm"
                    >
                      <td className="p-2">
                        <span
                          className="cursor-pointer hover:text-blue-400 transition-colors duration-200"
                          onClick={() => handleNameClick(library.id)}
                        >
                          {library.name}
                        </span>
                      </td>
                      <td className="p-2">{library.sponsors?.length || 0}</td>
                      <td className="p-2">
                        {library.sponsors?.reduce(
                          (sum, sponsor) => sum + sponsor.total_num_studies,
                          0,
                        ) || 0}
                      </td>
                      <td className="p-2">
                        {library.sponsors?.reduce(
                          (sum, sponsor) =>
                            sum +
                            sponsor.studies.reduce(
                              (siteSum, study) =>
                                siteSum +
                                study.country_breakdown.reduce(
                                  (countrySum, country) =>
                                    countrySum + country.num_sites,
                                  0,
                                ),
                              0,
                            ),
                          0,
                        ) || 0}
                      </td>
                      <td className="p-2">
                        <button
                          className="text-green-500 hover:bg-green-500 hover:text-white px-3 py-1 rounded-md mr-2 transition-colors duration-200"
                          onClick={() => handleEdit(library.id)}
                        >
                          Edit
                        </button>
                        <button
                          className="text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded-md transition-colors duration-200"
                          onClick={() => handleDelete(library.id)}
                        >
                          Delete
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </ProjectLayout>
  );
};

export default LibrariesCRUDPage;
