import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import NeurouteLogo from '@/components/NeurouteLogo';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '@/pages/auth/AuthContext';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { currentUser } = useAuth();

  const handleSignInOut = () => {
    if (currentUser) {
      navigate('/sign-out');
    } else {
      const currentPath = location.pathname + location.search;
      const encodedFrom = encodeURIComponent(currentPath);
      navigate(`/login?from=${encodedFrom}`);
    }
  };

  return (
    <div className="absolute top-0 left-0 right-0 h-16 flex items-center justify-between px-8 pointer-events-auto">
      <div className="flex flex-row items-center space-x-6">
        <NeurouteLogo />
      </div>

      {/*       
      <input
        type="text"
        placeholder="Search..."
        className="w-1/3 px-4 py-2 rounded-lg bg-[#2C2C30] text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-[#38DEC4]"
      /> 
      */}

      <div className="flex flex-row items-center">
        <button
          onClick={handleSignInOut}
          className="px-4 py-2 rounded-lg bg-[#38DEC4] text-[#1F1F23] font-semibold hover:bg-[#2FC4AC] transition-colors mr-2"
        >
          {currentUser ? 'Sign Out' : 'Sign In'}
        </button>
        <HamburgerMenu />
      </div>
    </div>
  );
};

export default Header;
