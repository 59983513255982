import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import projectsIcon from '../../images/project_icon.svg';
import projectImage from '../../images/project_view.png';
import { useAppDispatch } from '../../app/hooks';
import {
  addExclusionCriterion,
  addInclusionCriterion,
  clearStudyCreation,
  setAgeMax,
  setAgeMin,
  setStudyName,
} from '../../features/create_study/create_study_slice';
import { auth, db } from '../../core/setup_firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

interface Criterion {
  id: string;
  text: string;
}

const ProjectCard = (props: { project: Project }) => {
  const { project } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleProjectClick = () => {
    dispatch(clearStudyCreation());

    dispatch(setStudyName(project.name));
    if (project.minAge) {
      dispatch(setAgeMin(project.minAge!));
    }
    if (project.maxAge) {
      dispatch(setAgeMax(project.maxAge));
    }

    for (const inclusion of project.inclusions) {
      dispatch(addInclusionCriterion(inclusion));
    }

    for (const exclusion of project.exclusions) {
      dispatch(addExclusionCriterion(exclusion));
    }

    navigate(`/dashboard/${project.id}`);
  };

  return (
    <div
      className="bg-[#2C2B32] flex flex-col text-white p-1 rounded-lg shadow-lg max-w-[364px] w-[364px] min-w-[300px] cursor-pointer"
      onClick={handleProjectClick}
    >
      <h1 className="text-sm font-normal px-2 mt-1.5 mb-2.5">{project.name}</h1>
      <img
        src={projectImage}
        className="w-full rounded-md border border-zinc-700"
      />
      <div className="text-white px-2 pt-2 pb-1 flex justify-between items-center rounded-t-lg">
        <div className="flex items-center space-x-3" />
        <div className="flex items-center space-x-2 text-sm font-normal">
          <span>{project.date}</span>
        </div>
      </div>
    </div>
  );
};

type Project = {
  id: string;
  name: string;
  scenarios: number;
  date: string;
  inclusions: Criterion[];
  exclusions: Criterion[];
  minAge?: number;
  maxAge?: number;
};

const ProjectsPage: React.FC = () => {
  const navigate = useNavigate();
  const [projects, setProjects] = useState<Project[]>([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const projectsRef = collection(db, 'projects');
        const q = query(
          projectsRef,
          where('userId', '==', auth.currentUser?.uid),
        );
        const querySnapshot = await getDocs(q);
        const projectsList: Project[] = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            name: data.projectName,
            date: data.date,
            inclusions: data.inclusionCriteria ?? [],
            exclusions: data.exclusionCriteria ?? [],
            minAge: data.ageMin,
            maxAge: data.ageMax,
          } as Project;
        });
        setProjects(projectsList);
      } catch (error) {
        console.error('Error fetching projects: ', error);
      }
    };

    fetchUsers();
  }, []);

  return (
    <ProjectLayout title="Studies Library">
      <div className="min-h-screen flex flex-col">
        <div className="flex flex-wrap gap-4 p-4">
          {projects.map((project, index) => (
            <ProjectCard key={project.id} project={project} />
          ))}
        </div>
      </div>
    </ProjectLayout>
  );
};

export default ProjectsPage;
