import { getApp } from 'firebase/app';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import ActionButton from '../../components/buttons/ActionButton';
import AuthLayout from './components/AuthLayout';
import emailSvg from '../../images/email.svg';
import Input from './components/Input';
import logoBrandImg from '../../images/logo-brand.svg';

const schema = z
  .object({
    email: z.string().email(),
  })
  .required();

export function ForgotPasswordForm() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isSubmitted, errors },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = async (data: any) => {
    const app = getApp();
    const auth = getAuth(app);

    try {
      await sendPasswordResetEmail(auth, data.email);
    } catch (error: any) {
      switch (error.code) {
        case 'auth/user-not-found':
          setError('email', {
            type: 'manual',
            message: 'No user found with this email address.',
          });
          break;

        case 'auth/invalid-email':
          setError('email', {
            type: 'manual',
            message: 'Invalid email address.',
          });
          break;

        case 'auth/too-many-requests':
          setError('email', {
            type: 'manual',
            message: 'Too many requests. Try again later.',
          });
          break;

        case 'auth/network-request-failed':
          setError('email', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        default:
          setError('email', {
            type: 'manual',
            message: 'Internal error. Try again later.',
          });
          console.error('Error: ', error);
          break;
      }
    }
  };

  return (
    <>
      <div>
        <img className="h-10 w-auto" src={logoBrandImg} alt="Neuroute Logo" />
        <div className="text-white text-[40px] font-normal mt-6">
          Reset your password
        </div>
        <h2 className="mt-1.5 text-base leading-9 tracking-tight text-gray-100">
          Enter your email and we'll send you a link to reset your password
        </h2>
      </div>

      <div className="mt-10">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            className="space-y-6"
          >
            <Input
              id="email"
              title="Email Address"
              type="email"
              placeholder="john@example.com"
              autoComplete="email"
              required
              inputProps={register('email')}
              icon={<img src={emailSvg} alt="Email" />}
            />

            <ActionButton
              title="Reset your password"
              isLoading={isSubmitting}
            />

            <p className="mt-6 text-sm leading-6 text-gray-300">
              Not a member?{' '}
              <a
                href="/sign-up"
                className="font-normal text-primary hover:text-secondary"
              >
                Sign up
              </a>
            </p>

            {errors && (
              <div className="text-red-500 text-sm">
                {errors.email?.message?.toString()}
              </div>
            )}

            {isSubmitted && !errors.email && (
              <div className="text-primary text-sm">
                If an account with this email exists, we'll send you a link to
                reset your password.
              </div>
            )}
          </form>
        </div>
      </div>
    </>
  );
}

export default function ForgotPasswordPage() {
  return (
    <AuthLayout>
      <ForgotPasswordForm />
    </AuthLayout>
  );
}
