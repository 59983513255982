import React from 'react';

interface AudioTrackCardProps {
  title: string;
  tag: string;
  song?: string;
}

const AudioTrackCard: React.FC<AudioTrackCardProps> = ({
  title,
  tag,
  song,
}) => {
  const hasSong = !!song;
  const backgroundColor = hasSong ? '#00C7A8' : '#DEF2EE';
  const textColor = hasSong ? 'white' : '#00C7A8';
  const backgroundImage = hasSong
    ? '/assets/audio-wave-white.png'
    : '/assets/audio-wave-green.png';

  return (
    <div
      className="h-[47px] w-full rounded-md overflow-hidden"
      style={{ backgroundColor }}
    >
      <div className="flex items-center h-[22px]">
        <div
          className="text-[11px] pl-2 whitespace-nowrap mr-2"
          style={{ color: textColor }}
        >
          {title}
        </div>
        <div
          className="text-[10px] pl-0 pr-0 mr-2 flex-grow border-t-0 border-l-0 border-r-0 border-b-solid border-b-[1px] border-b-opacity-50"
          style={{
            color: textColor,
            borderColor: textColor,
          }}
        >
          <div className="flex justify-between items-center text-[7px] leading-[-0.5]">
            <span style={{ color: textColor }}>{tag}</span>
            {song && (
              <div>
                <span style={{ color: textColor }}>{song}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="h-[25px] relative">
        <div
          className="absolute inset-0 bg-repeat-x mx-2"
          style={{ backgroundImage: `url('${backgroundImage}')` }}
        ></div>
      </div>
    </div>
  );
};

export default AudioTrackCard;
