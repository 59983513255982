import React from 'react';
import {
  SiteData,
  SiteRandResponse,
} from '@/api/slices/dashboard/siteApiSlice';
import { useAppSelector } from '../../../../app/hooks';
import { useSelectedSite } from '@/features/enrollment_scenario/enrollment_scenario_slice';
import SiteEnrollmentMetricsBar from './SiteInfoBar';
import FullscreenButton from './FullscreenButton';
import Graph from './Graph';

type SiteDataProps = SiteData | SiteRandResponse;
interface SiteEnrollmentScenarioGraphProps {
  siteData: SiteData | SiteRandResponse | undefined;
}

// Adapter function to convert SiteRandResponse to SiteData
const adaptSiteRandToSiteData = (siteRand: SiteRandResponse): SiteData => ({
  id: siteRand.site_rand, // Using site_rand as id
  name: siteRand.site_rand, // Using site_rand as name
  // Add any other required SiteData properties
});

const SiteEnrollmentScenarioGraph: React.FC<
  SiteEnrollmentScenarioGraphProps
> = ({ siteData: rawSiteData }) => {
  const selectedSite = useSelectedSite();
  const scenario = useAppSelector((s) => {
    const scenarioId = selectedSite?.scenarioId;
    if (!scenarioId) {
      return null;
    }
    return s.enrollmentScenario.scenariosMap[scenarioId];
  });

  if (!scenario || !selectedSite) {
    return null;
  }

  // Convert the data if it's a SiteRandResponse
  const siteData =
    rawSiteData && 'site_rand' in rawSiteData
      ? adaptSiteRandToSiteData(rawSiteData)
      : rawSiteData;

  // Helper function to get site name
  const getSiteName = (data: SiteDataProps | undefined) => {
    if (!data) return '';
    if ('name' in data) return data.name;
    if ('site_rand' in data) return data.site_rand;
    return '';
  };

  return (
    <div
      className="flex flex-col w-full relative m-4 p-4 border border-gray-700 rounded-xl text-white"
      style={{ backgroundColor: '#2C2B32' }}
    >
      <h1>{getSiteName(siteData)}</h1>
      <div className="p-2 flex flex-row items-start relative">
        <div
          className="absolute top-0 bottom-0 left-0 w-1.5"
          style={{ backgroundColor: scenario.color['mostLikely'] }}
        ></div>
        <div className="relative mr-5"></div>
        <div className="flex flex-col flex-grow items-start mr-5">
          <div className="flex flew-row items-center">
            <h1 className="text-xl font-medium mr-5 text-left">
              {selectedSite.name}
              <span className="font-normal ml-3" style={{ color: '#9491A7' }}>
                {scenario.name} Most Likely Scenario{' '}
              </span>
            </h1>
            <p className="text-base text-gray-400">6 months</p>
          </div>
          <p className="text-xs text-gray-400">CWER Study</p>
        </div>
        <SiteEnrollmentMetricsBar />
      </div>
      <div className={'flex w-full relative mt-10 mb-6 pr-4 flex-1'}>
        <Graph />
      </div>
      <FullscreenButton />
    </div>
  );
};

export default SiteEnrollmentScenarioGraph;
