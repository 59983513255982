/**
 * Converts an array of strings to an SRT (SubRip Subtitle) format string.
 * Each subtitle item has a duration of 12 seconds.
 *
 * @param texts An array of strings, each representing a subtitle text.
 * @returns A string in SRT format.
 */
export function convertToSRT(texts: string[]): string {
  return texts
    .map((text, index) => {
      const startTime = index * 12000; // 12 seconds in milliseconds
      const endTime = startTime + 12000;

      const startTimeFormatted = formatTime(startTime);
      const endTimeFormatted = formatTime(endTime);

      return `${index + 1}\n${startTimeFormatted} --> ${endTimeFormatted}\n${text}`;
    })
    .join('\n\n');
}

/**
 * Formats a time in milliseconds to the SRT time format (HH:MM:SS,mmm).
 *
 * @param ms Time in milliseconds.
 * @returns Formatted time string.
 */
function formatTime(ms: number): string {
  const hours = Math.floor(ms / 3600000);
  const minutes = Math.floor((ms % 3600000) / 60000);
  const seconds = Math.floor((ms % 60000) / 1000);
  const milliseconds = ms % 1000;

  return `${padZero(hours)}:${padZero(minutes)}:${padZero(seconds)},${padZero(milliseconds, 3)}`;
}

/**
 * Pads a number with leading zeros.
 *
 * @param num Number to pad.
 * @param places Number of places to pad to (default is 2).
 * @returns Padded number string.
 */
function padZero(num: number, places: number = 2): string {
  return num.toString().padStart(places, '0');
}

// Example usage:
// const subtitles = [
//   "Parkinson's disease has long been a source of suffering for millions,",
//   "progressively robbing individuals of their motor functions.",
//   "However, recent developments offer a new ray of hope.",
//   // ... more subtitle texts
// ];
//
// const srtString = convertToSRT(subtitles);
// console.log(srtString);
