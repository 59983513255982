function CountrySelector() {
  return (
    <select className="select select-accent w-full max-w-xs text-white">
      <option disabled>Country</option>
      <option>🇺🇸 United States</option>
      <option>🇨🇦 Canada</option>
      <option>🇬🇧 United Kingdom</option>
      <option>🇦🇺 Australia</option>
    </select>
  );
}

function PostalCodeInput() {
  return (
    <input
      type="text"
      placeholder="Postal Code"
      className="input input-accent w-full max-w-xs text-white"
    />
  );
}

function IndicationInput() {
  return (
    <input
      type="text"
      placeholder="Indication"
      className="input input-accent w-full max-w-xs text-white"
    />
  );
}

function InterventionInput() {
  return (
    <input
      type="text"
      placeholder="Intervention"
      className="input input-accent w-full max-w-xs text-white"
    />
  );
}

function ResearchTable() {
  return (
    <div className="overflow-x-auto">
      <table className="table table-zebra">
        <thead>
          <tr>
            <th></th>
            <th>Location</th>
            <th>Site</th>
            <th>Site competition</th>
            <th>Site Risk</th>
            <th>Specialty Score</th>
            <th>Avg recruitment rate</th>
            <th># Completed Studies</th>
            <th>Previous Studies</th>
            <th>Nearby Sites</th>
          </tr>
        </thead>
        <tbody className="text-white">
          <tr>
            <th>1</th>
            <td>New York</td>
            <td>NYU Langone Health</td>
            <td>High</td>
            <td>High</td>
            <td>7.5</td>
            <td>5</td>
            <td>3</td>
            <td>2</td>
            <td>3</td>
          </tr>
          <tr>
            <th>2</th>
            <td>Los Angeles</td>
            <td>UCLA Health</td>
            <td>High</td>
            <td>High</td>
            <td>7.5</td>
            <td>5</td>
            <td>3</td>
            <td>2</td>
            <td>3</td>
          </tr>
          <tr>
            <th>3</th>
            <td>Chicago</td>
            <td>Northwestern Medicine</td>
            <td>High</td>
            <td>High</td>
            <td>7.5</td>
            <td>5</td>
            <td>3</td>
            <td>2</td>
            <td>3</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
}

export default function ResearchList() {
  return (
    <div className="pb-6">
      <h1 className="text-white text-3xl font-medium text-left p-6 pb-2">
        Research List
      </h1>

      <div
        className="m-4 p-4 border border-gray-700 rounded-xl"
        style={{ backgroundColor: '#2c2b32' }}
      >
        <div className="flex flex-row gap-x-6">
          <CountrySelector />
          <PostalCodeInput />
          <IndicationInput />
          <InterventionInput />
        </div>

        <div className="mt-4">
          <ResearchTable />
        </div>
      </div>
    </div>
  );
}
