import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCountries } from '@/features/flow_3/flow_3_slice';
import { RootState } from '../../../../app/store';

const CountrySelector = () => {
  const dispatch = useDispatch();

  const countries = useSelector((state: RootState) => state.flow3.countries);
  const [input, setInput] = useState('');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && input.trim()) {
      dispatch(setCountries([...countries, input.trim()]));
      setInput('');
    }
  };

  const removeCountry = (country: string) => {
    dispatch(setCountries(countries.filter((c) => c !== country)));
  };

  return (
    <div className="p-4 rounded-lg">
      <div className="text-white text-sm font-medium leading-tight text-start mb-3">
        Countries
      </div>

      <input
        type="text"
        placeholder="Add country..."
        value={input}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        className="w-full bg-[#41414B] border-0 text-white placeholder-gray-400 px-3 py-2 rounded-md mb-2"
      />
      <div className="flex flex-wrap gap-2">
        {countries.map((country) => (
          <button
            key={country}
            onClick={() => removeCountry(country)}
            className="bg-[#41414B] text-white px-3 py-1 rounded-md flex items-center"
          >
            <span className="mr-2 text-sm text-gray-300">{country}</span>
            <span className="text-gray-400">×</span>
          </button>
        ))}
      </div>
    </div>
  );
};

export default CountrySelector;
