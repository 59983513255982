import React, { useState, useRef, useEffect, useCallback } from 'react';

interface RulerProps {
  valueMs: number | null;
  onChange: (section: string, millisecond: number) => void;
}

const Ruler: React.FC<RulerProps> = ({ valueMs, onChange }) => {
  const sections = ['Why', 'What', 'Know', 'When', 'Will'];
  const timeMarks = ['0s', '12s', '24s', '36s', '48s', '60s'];
  const [hoveredSection, setHoveredSection] = useState<number | null>(null);
  const [mouseX, setMouseX] = useState<number | null>(null);
  const [isMouseDown, setIsMouseDown] = useState<boolean>(false);
  const svgRef = useRef<SVGSVGElement>(null);

  const smallLineSpacing = 24;
  const extension = 120;
  const svgWidth = 740;
  const svgHeight = 90;
  const translateX = 70;
  const rulerStart = 0;
  const rulerEnd = 600;
  const millisecondsTotal = 60000; // 60 seconds in milliseconds

  const getColor = useCallback(
    (sectionIndex: number) =>
      hoveredSection === sectionIndex ? 'rgb(255, 255, 255)' : 'currentColor',
    [hoveredSection],
  );

  const calculateMillisecond = useCallback((x: number): number => {
    const normalizedX = Math.max(0, Math.min(x, rulerEnd));
    return Math.round((normalizedX / rulerEnd) * millisecondsTotal);
  }, []);

  const handleMouseMove = useCallback(
    (event: MouseEvent) => {
      const svg = svgRef.current;
      if (!svg) return;

      const rect = svg.getBoundingClientRect();
      const scaleX = svgWidth / rect.width;
      const mouseXInSVG = (event.clientX - rect.left) * scaleX;
      const newMouseX = mouseXInSVG - translateX;
      setMouseX(newMouseX);

      const sectionIndex = Math.floor(newMouseX / 120);
      const millisecond = calculateMillisecond(newMouseX);

      if (sectionIndex >= 0 && sectionIndex < sections.length) {
        setHoveredSection(sectionIndex);
        if (isMouseDown) {
          onChange(sections[sectionIndex], millisecond);
        }
      }
    },
    [sections, calculateMillisecond, isMouseDown, onChange],
  );

  const handleMouseLeave = useCallback(() => {
    setMouseX(null);
    setHoveredSection(null);
    setIsMouseDown(false);
  }, []);

  const handleMouseDown = useCallback(() => {
    setIsMouseDown(true);
  }, []);

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  const handleClick = useCallback(
    (event: React.MouseEvent<SVGSVGElement>) => {
      const svg = svgRef.current;
      if (!svg) return;

      const rect = svg.getBoundingClientRect();
      const scaleX = svgWidth / rect.width;
      const mouseXInSVG = (event.clientX - rect.left) * scaleX;
      const clickedX = mouseXInSVG - translateX;

      // Clamp the clickedX value to the ruler bounds
      const clampedX = Math.max(0, Math.min(clickedX, rulerEnd));

      const sectionIndex = Math.floor(clampedX / 120);
      const millisecond = calculateMillisecond(clampedX);

      // Always trigger onChange, even if click is outside bounds
      if (sectionIndex >= 0 && sectionIndex < sections.length) {
        onChange(sections[sectionIndex], millisecond);
      } else if (clampedX <= 0) {
        // If clicked before the start, use the first section
        onChange(sections[0], 0);
      } else {
        // If clicked after the end, use the last section
        onChange(sections[sections.length - 1], millisecondsTotal);
      }
    },
    [onChange, sections, calculateMillisecond, rulerEnd, millisecondsTotal],
  );

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;

    svg.addEventListener('mousemove', handleMouseMove);
    svg.addEventListener('mouseleave', handleMouseLeave);
    window.addEventListener('mouseup', handleMouseUp);

    return () => {
      svg.removeEventListener('mousemove', handleMouseMove);
      svg.removeEventListener('mouseleave', handleMouseLeave);
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [handleMouseMove, handleMouseLeave, handleMouseUp]);

  return (
    <svg
      ref={svgRef}
      width="100%"
      height="90"
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      className="text-gray-400 select-none cursor-pointer"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
    >
      <g transform={`translate(${translateX}, 10)`}>
        {/* Main line */}
        <line
          x1={rulerStart - extension}
          y1="40"
          x2={rulerEnd + extension}
          y2="40"
          stroke="currentColor"
        />

        {/* Start small line */}
        <line
          x1={rulerStart - smallLineSpacing}
          y1="30"
          x2={rulerStart - smallLineSpacing}
          y2="50"
          stroke={getColor(0)}
        />

        {/* Time marks */}
        {timeMarks.map((mark, index) => {
          const x = index * 120;
          return (
            <g key={mark}>
              <line
                x1={x}
                y1="20"
                x2={x}
                y2="60"
                stroke={
                  getColor(index) === 'rgb(255, 255, 255)' ||
                  getColor(index - 1) === 'rgb(255, 255, 255)'
                    ? 'rgb(255, 255, 255)'
                    : 'currentColor'
                }
              />
              <text
                x={x}
                y="15"
                textAnchor="middle"
                fill={
                  getColor(index) === 'rgb(255, 255, 255)' ||
                  getColor(index - 1) === 'rgb(255, 255, 255)'
                    ? 'rgb(255, 255, 255)'
                    : 'currentColor'
                }
                fontSize="12"
              >
                {mark}
              </text>
            </g>
          );
        })}

        {/* Sections */}
        {sections.map((section, index) => {
          const x = index * 120 + 60;
          return (
            <g key={section}>
              <rect
                x={index * 120}
                y="0"
                width="120"
                height="90"
                fill="transparent"
              />
              {[1, 2, 3, 4].map((tick) => (
                <line
                  key={tick}
                  x1={x + tick * smallLineSpacing - 60}
                  y1="30"
                  x2={x + tick * smallLineSpacing - 60}
                  y2={tick % 2 !== 0 ? 45 : 50}
                  stroke={getColor(index)}
                />
              ))}
              <text
                x={x}
                y="75"
                textAnchor="middle"
                fill={getColor(index)}
                fontSize="12"
              >
                {section}
              </text>
            </g>
          );
        })}

        {/* End small line */}
        <line
          x1={rulerEnd + smallLineSpacing}
          y1="30"
          x2={rulerEnd + smallLineSpacing}
          y2="50"
          stroke={getColor(4)}
        />

        {/* Mouse position line with time label */}
        {mouseX !== null && (
          <g>
            <line
              x1={Math.max(0, Math.min(mouseX, rulerEnd))}
              y1="0"
              x2={Math.max(0, Math.min(mouseX, rulerEnd))}
              y2={svgHeight}
              stroke="rgb(255, 255, 255)"
              strokeWidth="1"
            />
            <text
              x={Math.max(0, Math.min(mouseX, rulerEnd))}
              y="-5"
              textAnchor="middle"
              fill="rgb(255, 255, 255)"
              fontSize="12"
            >
              {(
                (Math.max(0, Math.min(mouseX, rulerEnd)) / rulerEnd) *
                60
              ).toFixed(1)}
              s
            </text>
          </g>
        )}

        {/* Selected value indicator */}
        {valueMs !== null && (
          <circle
            cx={(valueMs / millisecondsTotal) * rulerEnd}
            cy="40"
            r="5"
            fill="rgb(255, 255, 255)"
          />
        )}
      </g>
    </svg>
  );
};

export default React.memo(Ruler);
