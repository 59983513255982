import { StudyPage } from '@/types/StudyPage';

import { mockedStudyAuthor } from '@/mocks/mockedStudyAuthor';

const mockedStudyData: StudyPage = {
  title: "Ozempic for Parkinson's Disease",
  author: mockedStudyAuthor,
  collaborators: 2, // updated dynamically when comments change
  lastUpdated: '2 weeks ago',
  transcript: `Parkinson's disease has long been a source of suffering for millions worldwide, progressively robbing individuals of their motor functions. However, recent developments offer a new ray of hope. Ozempic, a medication originally developed for type 2 diabetes, has shown promising results in potentially slowing Parkinson's progression. This unexpected breakthrough aligns with our growing understanding of the connections between metabolic processes and neurological health. The active ingredient, semaglutide, belongs to a class of drugs known as GLP-1 receptor agonists, which have demonstrated neuroprotective properties in preclinical studies. These compounds might help preserve dopamine-producing neurons, the very cells progressively lost in Parkinson's disease. While we must temper our expectations, this research opens an exciting new frontier in our fight against neurodegenerative disorders.`,

  srt: `1
00:00:00,000 --> 00:00:05,000
Parkinson's disease has long been a source of suffering for millions,

2
00:00:05,000 --> 00:00:10,000
progressively robbing individuals of their motor functions.

3
00:00:10,000 --> 00:00:15,000
However, recent developments offer a new ray of hope.

4
00:00:15,000 --> 00:00:20,000
Ozempic, a diabetes medication, shows promise

5
00:00:20,000 --> 00:00:25,000
in potentially slowing Parkinson's progression.

6
00:00:25,000 --> 00:00:30,000
This aligns with our understanding of connections

7
00:00:30,000 --> 00:00:35,000
between metabolic processes and neurological health.

8
00:00:35,000 --> 00:00:40,000
The active ingredient, semaglutide, is a GLP-1 receptor agonist

9
00:00:40,000 --> 00:00:45,000
with neuroprotective properties in preclinical studies.

10
00:00:45,000 --> 00:00:50,000
These compounds might help preserve dopamine-producing neurons,

11
00:00:50,000 --> 00:00:55,000
the very cells progressively lost in Parkinson's disease.

12
00:00:55,000 --> 00:01:00,000
This research opens an exciting frontier in neurodegenerative disorders.`,
};

export { mockedStudyData };
