import { getApp } from '@firebase/app';
import {
  Product,
  Price,
  Subscription,
} from '@invertase/firestore-stripe-payments';
import { getAuth } from 'firebase/auth';
import {
  collection,
  getFirestore,
  where,
  query,
  getDocs,
  addDoc,
  doc,
  onSnapshot,
} from 'firebase/firestore';

// USE THIS LINK TO CHECK THE DOCS OF THE STRIPE PAYMENTS EXTENSION
// https://console.firebase.google.com/u/0/project/neuroute-dev/extensions/instances/firestore-stripe-payments?tab=usage
//
// IMPORTANT:
// The @invertase/firestore-stripe-payments it is a wrapper library, and it seems to have internal error for loading products and prices from the database,
// so I had to use the code snippets from the docs (link above) to get the working examples and get data from the database directly.

export class PaymentManager {
  private static instance: PaymentManager;

  private constructor() {}

  public static getInstance(): PaymentManager {
    if (!PaymentManager.instance) {
      PaymentManager.instance = new PaymentManager();
    }

    return PaymentManager.instance;
  }

  public goToCustomerPortal() {
    const link = 'https://billing.stripe.com/p/login/test_eVa2byfLh3AIcWA4gg';
    window.open(link, '_blank');
  }

  public async getMySubscriptions(): Promise<Subscription[]> {
    const app = getApp();
    const auth = getAuth(app);
    const currentUser = auth.currentUser;
    const db = getFirestore(app);

    if (!currentUser) {
      throw new Error('User not authenticated');
    }

    const subscriptionsRef = collection(
      doc(collection(db, 'stripe_customers'), currentUser.uid),
      'subscriptions',
    );
    const q = query(
      subscriptionsRef,
      where('status', 'in', ['trialing', 'active']),
    );

    const querySnapshot = await getDocs(q);
    const subscriptions = <Subscription[]>[];

    querySnapshot.docs.forEach((doc) => {
      const subscription = doc.data() as Subscription;
      subscriptions.push(subscription);
    });

    return subscriptions;
  }

  public async getProducts(): Promise<Product[]> {
    const app = getApp();
    const db = getFirestore(app);

    const col = collection(db, 'stripe_products');
    const q = query(col, where('active', '==', true));
    const querySnapshot = await getDocs(q);

    let products = <Product[]>[];

    for (const doc of querySnapshot.docs) {
      const product: Product = {
        ...(doc.data() as any),
        id: doc.id,
        prices: [],
      };

      const priceCol = collection(doc.ref, 'prices');
      const priceSnapshot = await getDocs(priceCol);
      priceSnapshot.docs.forEach((doc) => {
        const price = { ...(doc.data() as any), id: doc.id } as Price;
        product.prices.push(price);
      });

      products = [...products, product];
    }

    return products;
  }

  async subscribeUser(priceId: string) {
    return new Promise<void>(async (resolve, reject) => {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      const db = getFirestore();
      const currentUserUid = currentUser?.uid;

      if (!currentUserUid) {
        throw new Error('User not authenticated');
      }

      const checkoutSessionRef = await addDoc(
        collection(
          doc(collection(db, 'stripe_customers'), currentUserUid),
          'checkout_sessions',
        ),
        {
          price: priceId,
          trial_from_plan: false,
          trial_period_days: 7,
          success_url: window.location.origin,
          cancel_url: window.location.origin,
        },
      );

      onSnapshot(checkoutSessionRef, (snap) => {
        const { error, url } = snap.data() as any;
        if (error) {
          alert(`An error occurred: ${error.message}`);
          reject(error);
        }

        if (url) {
          window.location.assign(url);
          resolve();
        }
      });
    });
  }
}
