import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from '../../app/store';
import {
  fakeEnrollmentScenarioData2,
  FakeSites,
  ScenariosFakeData,
} from './fake_data';
import { ScenarioColor } from './models/scenario_color';
import { ScenarioColors } from './colors_data';
import { ScenarioData } from './models/scenario_data';
import { Scenario } from './models/scenario';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Site } from './models/site';
import { useCallback, useEffect, useMemo } from 'react';
import { useGetSiteRandQuery } from '@/api/slices/site/SiteSlice';

interface ScenarioDataItem {
  name: string | number;
  date?: number | string;
  [key: string]: ScenarioData | string | number | undefined;
}

type ScenarioSelection = {
  id: string;
  type: 'mostLikely' | 'bestCase' | 'worstCase';
};

export type SitesTab = 'all_sites' | 'saved_sites';
export type ViewMode = 'model' | 'map' | 'table' | 'timeline';

interface EnrollmentScenarioState {
  data: ScenarioDataItem[];
  colors: ScenarioColor[];

  scenariosIds: string[];
  scenariosMap: Record<string, Scenario>;

  selectedStudyNameOrId: string | null; // The name of the site in the site search box
  selectedScenarioId: string | null;
  activeScenario: ScenarioSelection | null;
  hoveredScenario: ScenarioSelection | null;

  sitesIds: string[];
  sitesMap: Record<string, Site>;
  selectedSiteId: string | null;

  cohortSize: {
    min: number;
    target: number;
  };

  dates: {
    start: string | null;
    end: string | null;
  };

  fullScreen: boolean;
  isChartAnimationEnabled: boolean;
  viewMode: ViewMode;
  sitesTab: SitesTab;
}

const initialState: EnrollmentScenarioState = {
  data: fakeEnrollmentScenarioData2,

  selectedStudyNameOrId: null,
  selectedScenarioId: ScenariosFakeData[0].id,
  scenariosIds: ScenariosFakeData.map((s) => s.id),
  scenariosMap: ScenariosFakeData.reduce(
    (acc, s) => {
      acc[s.id] = s;
      return acc;
    },
    {} as Record<string, Scenario>,
  ),

  sitesIds: FakeSites.map((s) => s.id),
  sitesMap: FakeSites.reduce(
    (acc, s) => {
      acc[s.id] = s;
      return acc;
    },
    {} as Record<string, Site>,
  ),

  activeScenario: null,
  hoveredScenario: null,
  colors: ScenarioColors,
  selectedSiteId: null,
  cohortSize: {
    min: 20,
    target: 65,
  },
  dates: {
    start: null,
    end: null,
  },

  fullScreen: false,
  isChartAnimationEnabled: true,
  viewMode: 'table',
  sitesTab: 'all_sites',
};

export const enrollmentScenarioSlice = createSlice({
  name: 'enrollment_scenario',
  initialState,
  reducers: {
    setActiveScenario: (
      state,
      action: PayloadAction<ScenarioSelection | null>,
    ) => {
      state.activeScenario = action.payload;
      state.selectedSiteId = null;
      state.hoveredScenario = null;
      state.selectedScenarioId = action.payload?.id || null;
    },
    setHoveredScenario: (
      state,
      action: PayloadAction<ScenarioSelection | null>,
    ) => {
      state.hoveredScenario = action.payload;
    },
    setCohortMinSize: (state, action: PayloadAction<number>) => {
      state.cohortSize.min = action.payload;
    },
    setCohortTargetSize: (state, action: PayloadAction<number>) => {
      state.cohortSize.target = action.payload;
    },
    setSelectedStudyNameOrId: (state, action: PayloadAction<string | null>) => {
      state.selectedStudyNameOrId = action.payload;
    },
    setSelectedSiteId: (state, action: PayloadAction<string | null>) => {
      state.selectedSiteId = action.payload;
      state.activeScenario = null;
      state.hoveredScenario = null;
      state.selectedScenarioId = null;
    },
    setSelectedScenarioId: (state, action: PayloadAction<string | null>) => {
      state.selectedScenarioId = action.payload;
      state.selectedSiteId = null;
      state.hoveredScenario = null;
      state.activeScenario = null;
    },
    setStartDate: (state, action: PayloadAction<string | null>) => {
      state.dates.start = action.payload;

      if (state.dates.end && action.payload) {
        const startDate = new Date(action.payload);
        const endDate = new Date(state.dates.end);
        if (startDate > endDate) {
          state.dates.end = action.payload;
        }
      }
    },
    setEndDate: (state, action: PayloadAction<string | null>) => {
      state.dates.end = action.payload;

      if (state.dates.start && action.payload) {
        const startDate = new Date(state.dates.start);
        const endDate = new Date(action.payload);

        if (endDate.getTime() < startDate.getTime()) {
          state.dates.start = action.payload;
        }
      }
    },
    setFullScreen: (state, action: PayloadAction<boolean>) => {
      state.fullScreen = action.payload;
    },
    setChartAnimationEnabled: (state, action: PayloadAction<boolean>) => {
      state.isChartAnimationEnabled = action.payload;
    },
    setViewMode: (state, action: PayloadAction<ViewMode>) => {
      state.viewMode = action.payload;
    },
    setSitesTab: (state, action: PayloadAction<SitesTab>) => {
      state.sitesTab = action.payload;
    },
    clearEnrollmentScenarioData: (state) => {
      state.data = initialState.data;
      state.selectedScenarioId = initialState.selectedScenarioId;
      state.activeScenario = initialState.activeScenario;
      state.hoveredScenario = initialState.hoveredScenario;
      state.selectedSiteId = initialState.selectedSiteId;
      state.cohortSize = initialState.cohortSize;
      state.dates = initialState.dates;
      state.fullScreen = initialState.fullScreen;
      state.isChartAnimationEnabled = initialState.isChartAnimationEnabled;
      state.viewMode = initialState.viewMode;
      state.scenariosIds = initialState.scenariosIds;
      state.scenariosMap = initialState.scenariosMap;
      state.sitesIds = initialState.sitesIds;
      state.sitesMap = initialState.sitesMap;
    },
  },
});

export const {
  setActiveScenario,
  setHoveredScenario,
  setCohortMinSize,
  setCohortTargetSize,
  setSelectedStudyNameOrId,
  setSelectedSiteId,
  setSelectedScenarioId,
  setStartDate,
  setEndDate,
  setViewMode,
  setSitesTab,
  clearEnrollmentScenarioData,
} = enrollmentScenarioSlice.actions;

export const selectProfileName = (state: RootState) => state.profile.name;

export const useSelectedScenario = () => {
  const selectedScenarioId = useAppSelector(
    (s) => s.enrollmentScenario.selectedScenarioId,
  );

  return useAppSelector((s) => {
    if (!selectedScenarioId) {
      return null;
    }
    return s.enrollmentScenario.scenariosMap[selectedScenarioId];
  });
};

export const useSelectedStudyNameOrId = () => {
  return useAppSelector((s) => {
    return s.enrollmentScenario.selectedStudyNameOrId ?? null;
  });
};

export const useSelectedSite = () => {
  const selectedSiteId = useAppSelector(
    (s) => s.enrollmentScenario.selectedSiteId,
  );

  const { data, isLoading, isError } = useGetSiteRandQuery({
    condition: [''],
    relevance: '',
    phase: '',
    intervention_type: '',
    site_name: selectedSiteId ?? '',
  });

  useEffect(() => {
    console.log(selectedSiteId);
    console.log(data);
  }, [data, selectedSiteId]);

  return useAppSelector((s) => {
    if (!selectedSiteId) {
      return null;
    }
    return s.enrollmentScenario.sitesMap[selectedSiteId];
  });
};

export const useScenarios = () => {
  const ids = useAppSelector((s) => s.enrollmentScenario.scenariosIds);
  return useAppSelector((s) =>
    ids.map((id) => s.enrollmentScenario.scenariosMap[id]),
  );
};

export const useActiveScenario = () => {
  const activeScenarioSelection = useAppSelector(
    (s) => s.enrollmentScenario.activeScenario,
  );

  const scenario = useAppSelector((s) => {
    const scenarioId = activeScenarioSelection?.id;
    if (!scenarioId) {
      return null;
    }
    return s.enrollmentScenario.scenariosMap[scenarioId];
  });

  return { ...activeScenarioSelection, scenario };
};

export const useSetFullScreen = () => {
  const dispatch = useAppDispatch();
  const { setFullScreen, setChartAnimationEnabled } =
    enrollmentScenarioSlice.actions;

  const callback = useCallback(async (fullscreen: boolean) => {
    if (!fullscreen) {
      dispatch(setChartAnimationEnabled(false));
      dispatch(setFullScreen(fullscreen));
      await new Promise((resolve) => setTimeout(resolve, 200));
      // dispatch(setChartAnimationEnabled(true));
    } else {
      dispatch(setFullScreen(fullscreen));
    }
  }, []);

  return callback;
};

export default enrollmentScenarioSlice.reducer;
