import React from 'react';
import { StepSectionProps } from '../stepSections/StepSectionProps';

const BrainstormDoneSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
  handleBuildLibrary,
}) => {
  return (
    <>
      <h2 className="text-white text-[36px] font-normal leading-[60.75px] text-left mb-6">
        I'm happy with this,
        <br />
        show me what's next
      </h2>
      <button
        onClick={handleBuildLibrary}
        className="flex items-center justify-center w-[261.6px] h-[56px] px-[22.4px] py-[11.2px] rounded-[30px] bg-[#00C7A8] text-[#101117] hover:opacity-90 transition-opacity"
      >
        <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em] mr-[11.2px]">
          Build a Library
        </span>
      </button>
    </>
  );
};

export default BrainstormDoneSection;
