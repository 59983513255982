import React, { PropsWithChildren } from 'react';

interface InputLikeElementProps {
  className?: string;
}

const InputLikeElement: React.FC<PropsWithChildren<InputLikeElementProps>> = ({
  children,
  className = '',
}) => {
  return (
    <div
      className={`
      h-14
      py-0.5
      pl-6
      pr-2
      flex
      items-center
      gap-2
      rounded-full
      border
      border-[#676578]
      bg-[#2c2b32]
      ${className}
    `}
    >
      {children}
    </div>
  );
};

export default InputLikeElement;
