import clsx from 'clsx';
import { ReactNode } from 'react';

type Props = {
  title: ReactNode;
  isLoading?: boolean;
  disabled?: boolean;
  type?: 'submit' | 'button';
  className?: string;
  onClick?: () => void;
};

export default function ActionButton(props: Props) {
  const { title, isLoading, disabled, className, type, onClick } = props;

  return (
    <button
      type={type || 'submit'}
      disabled={isLoading || disabled}
      className={clsx(
        'flex w-full justify-center rounded-md bg-primary px-3 py-1.5 text-sm font-medium leading-6 text-white shadow-sm hover:opacity-90 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary',
        className,
      )}
      onClick={onClick}
    >
      {isLoading ? (
        <span className="loading loading-dots loading-sm mt-1"></span>
      ) : (
        title
      )}
    </button>
  );
}
