import Divider from '../Divider';

export default function TopBar() {
  const studyName = 'CWER Study';
  const studyDetails = 'Colorectal Cancer, Phase II and 2 Criteria';

  return (
    <div className="flex flex-col bg-base-300">
      <div className="flex flex-row h-16 items-center">
        <div className="flex flex-row flex-1 justify-center">
          <span className="bg-secondary rounded font-semibold pt-0.5 text-xs text-primary w-5 h-5 mr-2">
            {studyName[0]}
          </span>
          <span className="text-white text-sm font-semibold mr-2">
            {studyName}
          </span>
          <span className="text-gray-200 text-sm">{'/ ' + studyDetails}</span>
        </div>
        <div className="flex flex-row pr-5">
          <button className="btn btn-primary btn-sm">Share</button>
        </div>
      </div>
      <Divider />
    </div>
  );
}
