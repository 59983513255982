import React from 'react';
import logoBrandImg from '@/images/kadence_logo.svg';

const Logo: React.FC = () => {
  return (
    <div className="text-2xl font-bold text-blue-600">
      <img src={logoBrandImg} alt="Kadence Logo" />
    </div>
  );
};

export default Logo;
