import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../auth/AuthContext';
import plusIcon from '@/images/plus-lg.svg';
import { Brainstorm } from '@/types/Brainstorm';
import { auth, db } from '@/core/setup_firebase';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  deleteDoc,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import sortIcon from '@/images/sorting_icon.svg';
import clsx from 'clsx';
import { ProjectLayout } from './components/ProjectsLayout';
import { convertDateToMMYY } from '@/utils/date';

type SortIconProps = {
  column: keyof Brainstorm;
  currentColumn: keyof Brainstorm;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}

const BrainstormsCRUDPage: React.FC = () => {
  const navigate = useNavigate();
  const [brainstorms, setBrainstorms] = useState<Brainstorm[]>([]);
  const [user, setUser] = useState(auth.currentUser);
  const [sortColumn, setSortColumn] = useState<keyof Brainstorm>('name');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        getBrainstorms(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const getBrainstorms = async (userId: string) => {
    try {
      const brainstormsRef = collection(db, 'brainstorms');
      const q = query(brainstormsRef, where('userId', '==', userId));
      const querySnapshot = await getDocs(q);
      const brainstormList: Brainstorm[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return { ...data, id: doc.id } as Brainstorm;
      });
      setBrainstorms(brainstormList);
    } catch (error) {
      console.error('Error fetching brainstorms: ', error);
    }
  };

  const createNewBrainstorm = () => {
    navigate('/kadencebio/brainstorm/create');
  };

  const getDisplayName = (brainstorm: Brainstorm) => {
    const indication = brainstorm.indication || 'N/A';
    const intervention =
      brainstorm.interventionType || brainstorm.interventionFreeText || 'N/A';
    return `${indication} - ${intervention}`;
  };

  const handleNameClick = (id: string) => {
    navigate(`/kadencebio/brainstorm/edit/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/kadencebio/brainstorm/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    try {
      await deleteDoc(doc(db, 'brainstorms', id));
      setBrainstorms(brainstorms.filter((brainstorm) => brainstorm.id !== id));
      console.log('Brainstorm deleted successfully');
    } catch (error) {
      console.error('Error deleting brainstorm:', error);
    }
  };

  const handleSort = (column: keyof Brainstorm) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedBrainstorms = [...brainstorms].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? 1 : -1;
    if (bValue == null) return sortDirection === 'asc' ? -1 : 1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (aValue instanceof Date && bValue instanceof Date) {
      return sortDirection === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }

    return 0;
  });

  const { currentUser, loading } = useAuth();

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ProjectLayout title="KadenceBio">
      <div className="flex flex-col w-full bg-[#1F1F23] min-h-screen text-white">
        <div className="flex flex-row p-6">
          <main className="w-full flex-shrink-0 pl-8 pt-2 flex flex-col">
            <div className="flex justify-between items-center mb-8">
              <h1 className="text-4xl font-semibold tracking-tight">
                Brainstorms
              </h1>
              <div className="flex items-center gap-4">
                <button
                  className="btn btn-primary btn-sm text-neutral-800 font-normal"
                  onClick={createNewBrainstorm}
                >
                  Create Brainstorm{' '}
                  <img src={plusIcon} className="inline-flex w-4 h-4" alt="" />
                </button>
                <div className="h-8 border-r border-zinc-700"></div>
              </div>
            </div>
            <div className="overflow-auto">
              <table className="min-w-full bg-[#1F1F23] text-gray-300">
                <thead>
                  <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('name')}
                    >
                      Name
                      <SortIcon
                        column="name"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('relevance')}
                    >
                      Relevance
                      <SortIcon
                        column="relevance"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('phase')}
                    >
                      Phase
                      <SortIcon
                        column="phase"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('patientCount')}
                    >
                      Patients
                      <SortIcon
                        column="patientCount"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal whitespace-nowrap"
                      onClick={() => handleSort('inclusionCriteria')}
                    >
                      Inclusion Criteria
                      <SortIcon
                        column="inclusionCriteria"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal whitespace-nowrap"
                      onClick={() => handleSort('exclusionCriteria')}
                    >
                      Exclusion Criteria
                      <SortIcon
                        column="exclusionCriteria"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('timing')}
                    >
                      Timing
                      <SortIcon
                        column="timing"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th className="p-2 cursor-pointer font-normal">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedBrainstorms.map((brainstorm) => (
                    <tr
                      key={brainstorm.id}
                      className="border-b border-gray-700 text-start text-sm"
                    >
                      <td className="p-2">
                        <span
                          className="cursor-pointer hover:text-blue-400 transition-colors duration-200"
                          onClick={() => handleNameClick(brainstorm.id)}
                        >
                          {getDisplayName(brainstorm)}
                        </span>
                      </td>
                      <td className="p-2">{brainstorm.relevance}</td>
                      <td className="p-2">{brainstorm.phase}</td>
                      <td className="p-2">{brainstorm.patientCount}</td>
                      <td className="p-2">
                        {brainstorm.inclusionCriteria.length}
                      </td>
                      <td className="p-2">
                        {brainstorm.exclusionCriteria.length}
                      </td>
                      <td className="p-2">
                        {convertDateToMMYY(brainstorm.timing)}
                      </td>
                      <td className="p-2">
                        <button
                          className="text-green-500 hover:bg-green-500 hover:text-white px-3 py-1 rounded-md mr-2 transition-colors duration-200"
                          onClick={() => handleEdit(brainstorm.id)}
                        >
                          Edit
                        </button>
                        <button
                          className="text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded-md mr-2 transition-colors duration-200"
                          onClick={() => handleDelete(brainstorm.id)}
                        >
                          Delete
                        </button>
                        <button
                          className="btn btn-primary btn-xs text-neutral-800 font-normal"
                          onClick={() =>
                            navigate(
                              `/kadencebio/library/create/?brainstormId=${brainstorm.id}`,
                            )
                          }
                        >
                          Library{' '}
                          <img
                            src={plusIcon}
                            className="inline-flex w-3 h-3"
                            alt=""
                          />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </main>
        </div>
      </div>
    </ProjectLayout>
  );
};

export default BrainstormsCRUDPage;
