import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import { fetchRelevanceText } from '@/api/brainstormApi';

type RelevanceType = keyof typeof RelevanceTextBits;

const RelevanceTextBits = {
  'All Time': ['because nothing has', 'ever worked'],
  '10 Years': ['because nothing has worked in the last', '10 years'],
  '5 Years': ['because nothing has worked in the last', '5 years'],
  '3 Years': ['because nothing has worked in the last', '3 years'],
  'Last Year': ['because nothing has worked in the', 'last year'],
};

const RelevanceSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  nextStep,
  setCurrentStep,
}) => {
  const fixedTitle =
    RelevanceTextBits[brainstorm.relevance as RelevanceType][1];
  const fixedPrefix =
    RelevanceTextBits[brainstorm.relevance as RelevanceType][0];

  return (
    <>
      {isSection('relevance') && (
        <HeaderTitle
          prefix={fixedPrefix}
          value={fixedTitle}
          className="mt-14"
        />
      )}
      {/* 
      {isCurrentStep('relevance.edit') && (
        <InputStep
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          placeholder="Tell me your relevance here"
          field="relevance"
          onNextStep={() => {
            nextStep('relevance.search');
          }}
        />
      )}
 */}
      {isCurrentStep('relevance.search') && (
        <SearchAndDisplay
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          query={async () => {
            const data = await fetchRelevanceText({
              condition: [brainstorm.indication],
              relevance: brainstorm.relevance,
              intervention_type: brainstorm.interventionType,
            });
            setBrainstorm({
              ...brainstorm,
              relevanceText: data.relevance_text,
            });
          }}
          searchText="Thinking about the timelines of these studies..."
          onNextStep={() => nextStep('relevance.correct')}
        />
      )}

      {(isCurrentStep('relevance.correct') || hasStep('relevance.correct')) && (
        <>
          <DescriptionBlock>{brainstorm.relevanceText}</DescriptionBlock>
          {/*  <DescriptionBlock>
            The first study conducted in {brainstorm.indication} was in 2001. It
            was a Phase I study titled 'TITLE' that took 21 months to finish.
            Over the last {brainstorm.relevance} this is what the development
            pipeline has looked like 891 studies in Phase 1, 800 in phase 2, 200
            in phase 3 and 4.
          </DescriptionBlock> */}
        </>
      )}

      {isCurrentStep('relevance.correct') && (
        <>
          <CorrectMeBlock
            text="if I'm wrong about your relevance."
            onClick={() => {
              setCurrentStep('relevance.edit');
            }}
          />
          <ThatLooksRightToMe
            onNextStep={() => {
              nextStep('phase.search');
            }}
          />
        </>
      )}
    </>
  );
};

export default RelevanceSection;
