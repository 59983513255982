import { BackTrack } from '@/types/BackTrack';

const BackTrackCard: React.FC<BackTrack & { onClick: () => void }> = ({
  imageUrl,
  title,
  artist,
  duration,
  onClick,
}) => (
  <div
    className="bg-[#2C2B32] rounded-md p-2 pl-3 flex items-center cursor-pointer"
    onClick={onClick}
  >
    <div
      className="w-12 h-12 bg-[#2C2B32] rounded-[6px] mr-4"
      style={{ backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' }}
    ></div>
    <div className="flex flex-col">
      <p className="text-white text-lg font-normal leading-6 tracking-[-1px]">
        {title}
      </p>
      <p className="text-gray-400 text-[10px] font-normal leading-3 mt-1">
        {artist}
      </p>
      <p className="text-white text-xs font-normal leading-4 mt-1">
        {duration}
      </p>
    </div>
  </div>
);

export default BackTrackCard;
