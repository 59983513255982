import { useCallback, useRef } from 'react';
import { useAppSelector } from '../../../../../app/hooks';
import {
  useSelectedScenario,
  useSelectedSite,
} from '../../../../../features/enrollment_scenario/enrollment_scenario_slice';
import ProfileMatchView from './ProfileMatchView';
import ScenarioInfoView, { ScenarioInfoViewProps } from './ScenarioInfoView';
import StudyCompetitionView from './StudyCompetitionView';
import { useOutsideTouchAlerter } from '../../../../../core/hooks/useOutsideTouchAlerter';

type Props = {
  showCompetition: boolean;
  setShowCompetition: (show: boolean) => void;
};

export default function MapViewHeader(props: Props) {
  const { showCompetition, setShowCompetition } = props;

  const activeScenario = useAppSelector(
    (s) => s.enrollmentScenario.activeScenario,
  );
  const selectedSiteId = useAppSelector(
    (s) => s.enrollmentScenario.selectedSiteId,
  );
  const selectedScenario = useSelectedScenario();
  const selectedSite = useSelectedSite();
  const scenariosMap = useAppSelector((s) => s.enrollmentScenario.scenariosMap);
  const target = useAppSelector((s) => s.enrollmentScenario.cohortSize.target);

  const viewMode = useAppSelector((s) => s.enrollmentScenario.viewMode);
  const study = 'CWER Study';

  const getTypeString = (type: string) => {
    const typeString = {
      mostLikely: 'Most Likely',
      bestCase: 'Best Case',
      worstCase: 'Worst Case',
    }[type];
    return typeString;
  };

  const getProps = (): ScenarioInfoViewProps | null => {
    if (selectedSiteId && selectedSite) {
      const scenario = scenariosMap[selectedSite.scenarioId];

      return {
        title: selectedSite.name,
        description: scenario && `${scenario.name} Most Likely Scenario`,
        color: scenario.color.mostLikely,
        study,
      };
    }

    if (activeScenario) {
      const scenario = scenariosMap[activeScenario.id];
      if (!scenario) {
        return null;
      }

      return {
        title: scenario.name,
        subtitle: getTypeString(activeScenario.type) + ' Scenario',
        description: '6 months',
        color: scenario.color.mostLikely,
        study,
      };
    }

    if (selectedScenario) {
      return {
        title: selectedScenario.name,
        subtitle: 'Enrollment Scenario',
        color: selectedScenario.color.mostLikely,
        study,
      };
    }

    return null;
  };

  const scenarioInfoViewProps = getProps();

  const competitionInfoRef = useRef<HTMLDivElement>(null);
  const hideCompetitionInfo = useCallback(() => {
    setShowCompetition(false);
  }, []);

  useOutsideTouchAlerter(competitionInfoRef, hideCompetitionInfo);

  return (
    <div className="absolute top-6 left-6 flex flex-col">
      {scenarioInfoViewProps && (
        <div className="inline-flex">
          <ScenarioInfoView {...scenarioInfoViewProps} />
        </div>
      )}
      {selectedSite && (
        <>
          <ProfileMatchView />

          <div ref={competitionInfoRef}>
            {showCompetition && <StudyCompetitionView />}
          </div>
        </>
      )}
    </div>
  );
}
