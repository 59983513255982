import React from 'react';

interface CircleButtonProps {
  children: React.ReactNode;
  onClick?: () => void;
  title?: string;
}

const CircleButton: React.FC<CircleButtonProps> = ({
  children,
  onClick,
  title,
}) => {
  return (
    <div
      className="w-10 h-10 flex items-center justify-center rounded-full bg-[#00c7a81a] cursor-pointer"
      onClick={onClick}
      title={title || ''}
    >
      {children}
    </div>
  );
};

export default CircleButton;
