import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import {
  doc,
  getDoc,
  updateDoc,
  setDoc,
  serverTimestamp,
  collection,
} from 'firebase/firestore';
import { auth, db } from '@/core/setup_firebase';

import NeurouteLogo from '@/components/NeurouteLogo';
import ScrollableContainer from '@/components/ScrollableContainer';
import { parseSRT } from './components/srtParser';
import Ruler from './components/MainRuler';
import BackTrackCard from './components/BackTrackCard';
import MakeNewScienceButton from '@/components/buttons/MakeNewScienceButton';
import ArrowLeftCircleIcon from '../../images/arrow_left_circle.svg';
import {
  DragLayer,
  ScenesRow,
  BaseRow,
  VoiceRow,
  TuneRow,
  AvailableScenes,
} from './components/MakeSciencePageComponents';
import {
  Initiative,
  BackTrack,
  Scene,
  StudyPage,
  SubtitleEntry,
} from '@/types/index';

import { mockedSections as sections } from '@/mocks/mockedSections';
import { mockedStudyData } from '@/mocks/mockedStudyData';
import { mockedAvailableScenes } from '@/mocks/mockedAvailableScenes';
import { mockedAvailableBackTracks } from '@/mocks/mockedAvailableBackTracks';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '../auth/AuthContext';

const InitiativeCreateOrEditPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams<{ id?: string }>();
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [studyPage, setStudyPage] = useState<StudyPage>({
    ...mockedStudyData,
    title: 'New Title',
  });
  const [currentTimeMs, setCurrentTimeMs] = useState<number>(0);
  const [subtitles, setSubtitles] = useState<SubtitleEntry[]>([]);

  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const titleInputRef = useRef<HTMLTextAreaElement>(null);

  const [availableScenes, setAvailableScenes] = useState<Scene[]>(
    mockedAvailableScenes,
  );
  const [mainScenes, setMainScenes] = useState<(Scene | null)[]>(
    Array(5).fill(null),
  );

  const [selectedVoice, setSelectedVoice] = useState<'Masculine' | 'Feminine'>(
    'Masculine',
  );
  const [selectedBackTrack, setSelectedBackTrack] = useState<BackTrack>(
    mockedAvailableBackTracks[2],
  );

  const [selectedStudies, setSelectedStudies] = useState<string[]>([]);

  const subtitlesRef = useRef<HTMLDivElement>(null);

  const [sectionTranscripts, setSectionTranscripts] = useState<string[]>([]);
  const [editingSection, setEditingSection] = useState<number | null>(null);
  const textareaRefs = useRef<(HTMLTextAreaElement | null)[]>([]);

  const [draggedItemId, setDraggedItemId] = useState<string | null>(null);

  // Use useCallback to create a stable reference function
  const setTextareaRef = useCallback(
    (element: HTMLTextAreaElement | null, index: number) => {
      textareaRefs.current[index] = element;
    },
    [],
  );

  useEffect(() => {
    const fetchInitiative = async () => {
      if (id) {
        try {
          const docRef = doc(db, 'initiatives', id);
          const docSnap = await getDoc(docRef);

          if (docSnap.exists()) {
            const initiativeData = docSnap.data() as Initiative;
            console.log('initiativeData', initiativeData);
            setStudyPage({
              ...studyPage,
              title: initiativeData.title || 'Title',
            });
            setMainScenes((prevScenes) => {
              const newScenes: (Scene | null)[] = [
                ...(initiativeData.scenes || []),
              ];
              while (newScenes.length < 5) {
                newScenes.push(null);
              }
              return newScenes.slice(0, 5);
            });
            setSelectedVoice(initiativeData.selectedVoice || 'Masculine');
            setSelectedBackTrack(
              initiativeData.selectedBackTrack || mockedAvailableBackTracks[2],
            );

            setSelectedStudies(initiativeData?.selectedStudies || []);

            // Load saved section transcripts
            if (
              initiativeData.sectionTranscripts &&
              initiativeData.sectionTranscripts.length > 0
            ) {
              setSectionTranscripts(initiativeData.sectionTranscripts);
            } else {
              // If no saved transcripts, initialize with default values
              setSectionTranscripts(sections.map(() => 'Your Text'));
            }

            // Handle SRT parsing with error checking
            try {
              const parsedSubtitles = parseSRT(initiativeData.srt || '');
              setSubtitles(parsedSubtitles);
            } catch (srtError) {
              console.error('Error parsing SRT:', srtError);
              setSubtitles([]); // Set empty array if parsing fails
            }
          } else {
            setError('Initiative not found');
          }
        } catch (err) {
          setError('Error fetching initiative');
          console.error('Error fetching initiative:', err);
        }
      } else {
        // If there's no id, we're creating a new initiative
        setAvailableScenes(mockedAvailableScenes);
        setSelectedBackTrack(mockedAvailableBackTracks[2]);
        // Initialize section transcripts for new initiative
        setSectionTranscripts(sections.map(() => 'Your Text'));

        const searchParams = new URLSearchParams(location.search);
        // Parse the 'studies' query parameter
        // todo: use selectedStudies from projectData, remove this method
        const studiesParam = searchParams.get('studies');
        if (studiesParam) {
          const studiesArray = studiesParam.split(',');
          setSelectedStudies(studiesArray);
        }

        // Parse the 'projectId' query parameter
        const projectIdParam = searchParams.get('projectId');
        if (projectIdParam) {
          try {
            const projectDocRef = doc(db, 'projects', projectIdParam);
            const projectDocSnap = await getDoc(projectDocRef);

            if (projectDocSnap.exists()) {
              const projectData = projectDocSnap.data();
              console.log('Project Data:', projectData);

              let newTitle = projectData.studySubject || 'Subject';
              if (projectData.helpHow) {
                newTitle += ` ${projectData.helpHow}`;
              }

              setStudyPage((prevState) => ({
                ...prevState,
                title: newTitle,
              }));

              if (projectData.selectedStudies) {
                setSelectedStudies(projectData.selectedStudies);
              }
            } else {
              console.log('No such project!');
            }
          } catch (error) {
            console.error('Error fetching project:', error);
          }
        }
      }
      setIsLoading(false);
    };

    fetchInitiative();
  }, [id, location.search]);

  // Update this effect to use sectionTranscripts instead of generating from subtitles
  useEffect(() => {
    textareaRefs.current = sectionTranscripts.map(() => null);
  }, [sectionTranscripts]);

  const handleDragStart = (id: string) => {
    setDraggedItemId(id);
  };

  const handleDragEnd = (isDropped: boolean, itemId: string) => {
    if (!isDropped) {
      setMainScenes((prevScenes) => {
        return prevScenes.map((scene) => {
          if (scene && scene.id === itemId) {
            return null;
          }
          return scene;
        });
      });
    }
    setDraggedItemId(null);
  };

  const moveScene = (dragIndex: number, hoverIndex: number) => {
    setMainScenes((prevScenes) => {
      const newScenes = [...prevScenes];
      const [removed] = newScenes.splice(dragIndex, 1);
      newScenes.splice(hoverIndex, 0, removed);
      return newScenes;
    });
  };

  const dropScene = (
    item: { id: string; index: number; type: string },
    hoverIndex: number | null,
  ) => {
    setMainScenes((prevScenes) => {
      const newScenes = [...prevScenes];

      if (item.type === 'available') {
        if (hoverIndex !== null) {
          const scene = availableScenes.find((s) => s.id === item.id);
          if (scene) {
            newScenes[hoverIndex] = scene;
          }
        }
      } else if (item.type === 'main') {
        // Remove the scene from its original position
        newScenes[item.index] = null;

        // If dropped inside, place the scene in the new position
        if (hoverIndex !== null) {
          const draggedScene = prevScenes[item.index];
          newScenes[hoverIndex] = draggedScene;
        }
        // If dropped outside (hoverIndex is null), the scene is already removed
      }

      return newScenes;
    });
  };

  const handleTitleDoubleClick = () => {
    setIsEditingTitle(true);
  };

  const handleTitleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setStudyPage((prevState) => ({
      ...prevState,
      title: event.target.value,
    }));
    adjustTextareaHeight(event.target);
  };

  const adjustTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = '30px'; // Set initial height to minimum
    const newHeight = Math.max(element.scrollHeight, 30); // Ensure minimum height of 30px
    element.style.height = `${newHeight}px`;
  };

  const handleTitleBlur = () => {
    setIsEditingTitle(false);
    if (studyPage.title.trim() === '') {
      setStudyPage((prevState) => ({
        ...prevState,
        title: 'Title',
      }));
    }
  };

  useEffect(() => {
    if (isEditingTitle && titleInputRef.current) {
      titleInputRef.current.focus();
      const length = titleInputRef.current.value.length;
      titleInputRef.current.setSelectionRange(length, length);
      adjustTextareaHeight(titleInputRef.current);
    }
  }, [isEditingTitle]);

  useEffect(() => {
    const parsedSubtitles = parseSRT(studyPage.srt);
    setSubtitles(parsedSubtitles);
  }, [studyPage.srt]);

  useEffect(() => {
    scrollToHighlightedSubtitle();
  }, [currentTimeMs]);

  const getCurrentScene = useCallback(() => {
    const currentScene = mainScenes.find((scene, index) => {
      if (scene) {
        const startTime = index * 12000; // 12 seconds per scene
        const endTime = startTime + 12000;
        return currentTimeMs >= startTime && currentTimeMs < endTime;
      }
      return false;
    });
    return currentScene || null;
  }, [mainScenes, currentTimeMs]);

  const handleEditBackTrack = () => {
    const availableTracks = mockedAvailableBackTracks.filter(
      (track) => track.id !== selectedBackTrack.id,
    );
    const randomIndex = Math.floor(Math.random() * availableTracks.length);
    const newBackTrack = availableTracks[randomIndex];
    setSelectedBackTrack(newBackTrack);
  };

  const handleRulerChange = (section: string, ms: number) => {
    setCurrentTimeMs(ms);
  };

  const handleSubtitleClick = (startTimeMs: number) => {
    setCurrentTimeMs(startTimeMs);
  };

  const scrollToHighlightedSubtitle = () => {
    if (subtitlesRef.current && currentSubtitle) {
      const container = subtitlesRef.current;
      const highlightedElement = container.querySelector(
        '.opacity-100',
      ) as HTMLElement;

      if (highlightedElement) {
        const containerRect = container.getBoundingClientRect();
        const highlightedRect = highlightedElement.getBoundingClientRect();

        const relativeTop = highlightedRect.top - containerRect.top;
        const centerPosition =
          relativeTop - containerRect.height / 2 + highlightedRect.height / 2;

        container.scrollTop += centerPosition;
      }
    }
  };

  const currentSubtitle = useMemo(() => {
    return subtitles.find(
      (subtitle, index, arr) =>
        currentTimeMs >= subtitle.startTime &&
        (index === arr.length - 1 || currentTimeMs < arr[index + 1].startTime),
    );
  }, [subtitles, currentTimeMs]);

  // Right column transcript functionality
  /*
  useEffect(() => {
    // Prepare plain text transcripts for each section
    const transcripts = sections.map((section) => {
      const sectionSubtitles = subtitles.filter(
        (subtitle) =>
          subtitle.startTime >= section.startTime &&
          subtitle.startTime < section.endTime,
      );
      const text = sectionSubtitles.map((subtitle) => subtitle.text).join(' ');
      return text.trim() || 'Your Text'; // Use "Your Text" if the section is empty
    });
    setSectionTranscripts(transcripts);
    textareaRefs.current = transcripts.map(() => null);
  }, [subtitles]);
  */

  const handleTranscriptEdit = useCallback(
    (event: React.ChangeEvent<HTMLTextAreaElement>, index: number) => {
      const newText = event.target.value;
      setSectionTranscripts((prev) => {
        const updated = [...prev];
        updated[index] = newText;
        return updated;
      });
      adjustSectionTextareaHeight(event.target);
    },
    [],
  );

  // Ensure adjustTextareaHeight is defined like this:
  const adjustSectionTextareaHeight = (element: HTMLTextAreaElement) => {
    element.style.height = 'auto';
    element.style.height = `${element.scrollHeight}px`;
  };

  const handleTranscriptBlur = useCallback((index: number) => {
    setSectionTranscripts((prev) => {
      const updated = [...prev];
      updated[index] = updated[index].trim() || 'Your Text';
      return updated;
    });
    setEditingSection(null);
  }, []);

  useEffect(() => {
    if (editingSection !== null && textareaRefs.current[editingSection]) {
      const textarea = textareaRefs.current[editingSection]!;
      textarea.focus();
      textarea.setSelectionRange(textarea.value.length, textarea.value.length);
      adjustTextareaHeight(textarea);
    }
  }, [editingSection]);

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>, index: number) => {
      if (event.key === 'Tab') {
        event.preventDefault();
        if (index < sections.length - 1) {
          // Move to next section
          setEditingSection(index + 1);
        } else {
          // Blur the current textarea if it's the last one
          event.currentTarget.blur();
        }
      }
    },
    [sections.length],
  );

  const handleMakeNewScience = async () => {
    const initiativeData = {
      title: studyPage.title,
      status: 'published',
      scenes: mainScenes.map((scene) => scene || null), // Preserve null values
      selectedVoice,
      selectedBackTrack,
      sectionTranscripts, // Include sectionTranscripts in the saved data
      selectedStudies, // Include selectedStudies in the saved data
      srt: subtitles
        .map(
          (subtitle) =>
            `${subtitle.startTime},${subtitle.endTime},${subtitle.text}`,
        )
        .join('\n'),
      lastUpdated: serverTimestamp(),
      userId: auth.currentUser?.uid,
    };

    try {
      let initiativeId: string;
      if (id) {
        // Update existing initiative
        await updateDoc(doc(db, 'initiatives', id), initiativeData);
        initiativeId = id;
      } else {
        // Create new initiative
        const newDocRef = doc(collection(db, 'initiatives'));
        await setDoc(newDocRef, {
          ...initiativeData,
          createdAt: serverTimestamp(),
        });
        initiativeId = newDocRef.id;
      }
      navigate('/graph'); // Redirect to /initiatives
    } catch (error) {
      console.error('Error saving initiative:', error);
      setError('Failed to save initiative');
    }
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.toString()}</div>;
  }

  const currentScene = getCurrentScene();
  const isDefaultImage = !currentScene || !currentScene.imageUrl;
  const { currentUser } = useAuth();

  const wordCount = () => {
    return studyPage.title.split(' ').filter((word) => word !== '').length;
  };

  const isWordLimitExceeded = wordCount() > 5;

  return (
    <DndProvider backend={HTML5Backend}>
      <DragLayer onDragEnd={handleDragEnd} />
      <div className="flex flex-col w-full bg-[#1F1F23] overflow-hidden">
        <div className="flex flex-row h-full">
          {/* Left column start */}
          <div className="w-3/12 flex-shrink-0 p-4 pl-8 flex flex-col h-full">
            <div className="h-16 flex items-center justify-between mb-4">
              <NeurouteLogo />
              <div className="flex flex-row content-between items-center gap-4">
                <HamburgerMenu />
              </div>
              <img
                src={ArrowLeftCircleIcon}
                alt="Back"
                className="w-8 h-8 cursor-pointer"
                // onClick={() => navigate('/initiatives')}
                onClick={() => navigate('/graph')}
              />
            </div>

            <div className="flex mt-8 mb-4">
              {isEditingTitle ? (
                <textarea
                  placeholder="Title"
                  ref={titleInputRef}
                  className="text-[#00C7A8] text-5xl m-0 p-0 leading-none font-medium tracking-tighter bg-transparent resize-none overflow-hidden w-full border-none outline-none shadow-none focus:text-white focus:border-none focus:outline-none focus:shadow-none active:border-none active:outline-none active:shadow-none hover:border-none hover:outline-none hover:shadow-none "
                  value={studyPage.title}
                  onChange={handleTitleChange}
                  onBlur={handleTitleBlur}
                  rows={1}
                  style={{ boxShadow: 'none', color: '#00C7A8' }}
                />
              ) : (
                <div
                  ref={titleRef}
                  className="text-white text-5xl m-0 p-0 leading-none font-medium tracking-tighter cursor-text"
                  onClick={handleTitleDoubleClick}
                >
                  {studyPage.title}
                </div>
              )}
            </div>

            {/* Conditionally change text color */}
            <p
              className={`text-lg font-normal leading-6 tracking-[-1px] mb-8 ${
                isWordLimitExceeded ? 'text-red-500' : 'text-gray-400'
              }`}
            >
              5 words max
            </p>

            {/* New Scenes section */}
            <p className="text-gray-400 text-2xl font-normal leading-6 tracking-[-1px] mb-4">
              Scenes
            </p>

            <AvailableScenes
              scenes={availableScenes}
              onDragStart={handleDragStart}
            />

            {/* Voice section */}
            <p className="text-gray-400 text-2xl font-normal leading-6 tracking-[-1px] mb-4">
              Voice
            </p>

            {/* Clickable text labels */}
            <div className="flex space-x-4 mb-8">
              <button
                className={`text-lg font-normal leading-6 tracking-[-1px] ${
                  selectedVoice === 'Masculine' ? 'text-white' : 'text-gray-400'
                }`}
                onClick={() => setSelectedVoice('Masculine')}
              >
                Masculine
              </button>
              <button
                className={`text-lg font-normal leading-6 tracking-[-1px] ${
                  selectedVoice === 'Feminine' ? 'text-white' : 'text-gray-400'
                }`}
                onClick={() => setSelectedVoice('Feminine')}
              >
                Feminine
              </button>
            </div>

            {/* Back Track section */}
            <p className="text-gray-400 text-2xl font-normal leading-6 tracking-[-1px] mb-4">
              Back Track
            </p>

            <BackTrackCard
              {...selectedBackTrack}
              onClick={handleEditBackTrack}
            />
          </div>

          {/* Main content */}
          <main className="w-6/12 flex-shrink-0 p-4 flex flex-col">
            <div
              className="pb-4 relative"
              style={{ width: '100%', height: '320px' }}
            >
              <div className="relative w-full h-full overflow-hidden ">
                {/* Default image with padding and reduced opacity */}
                <div className="absolute inset-0 flex items-center justify-center p-16 z-0">
                  <img
                    src="/assets/base-default-image.png"
                    alt="Default scene"
                    className="w-full h-full object-contain opacity-10"
                  />
                </div>

                {/* Current scene image */}
                {currentScene && currentScene.imageUrl && (
                  <img
                    src={currentScene.imageUrl}
                    alt={`Scene ${currentScene.id}`}
                    className="w-full h-full object-cover absolute top-0 left-0 z-10 transition-opacity duration-300"
                    style={{ opacity: isDefaultImage ? 0 : 1 }}
                  />
                )}
              </div>
              {error && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white z-20">
                  {typeof error === 'string' ? error : 'An error occurred'}
                </div>
              )}
            </div>

            <div className="mt-4 mb-2">
              <h2 className="text-white text-3xl">
                Explain this in 60 seconds
              </h2>
            </div>

            <div className="mb-4">
              <Ruler valueMs={currentTimeMs} onChange={handleRulerChange} />
            </div>

            <ScenesRow
              scenes={mainScenes}
              moveScene={moveScene}
              dropScene={dropScene}
              onDragStart={handleDragStart}
            />
            <BaseRow />
            <VoiceRow />
            <TuneRow selectedBackTrack={selectedBackTrack} />
          </main>

          {/* Right column start */}
          <div className="w-3/12 flex-shrink-0 p-4 flex flex-col">
            <div className="h-16 flex items-center justify-left mb-4">
              <span className="text-3xl leading-tight font-normal text-white opacity-100 pl-1">
                Transcript
              </span>
            </div>

            {/* Subtitle parts start - Scrollable */}
            <ScrollableContainer
              className="flex overflow-y-auto"
              style={{
                height: 'calc(100vh - 200px)',
                maxHeight: 'calc(100vh - 200px)',
              }}
            >
              <div className="text-white">
                {sections.map((section, index) => (
                  <div key={index} className="mb-6">
                    <div className="text-white text-[18px] font-semibold mb-2">
                      {section.text} {Math.floor(section.startTime / 1000)}s
                    </div>
                    {editingSection === index ? (
                      <textarea
                        autoFocus
                        placeholder="Your Text"
                        className="w-full bg-transparent text-[#00C7A8] text-[32px] leading-[32px] m-0 p-0 resize-none overflow-hidden border-none outline-none shadow-none focus:text-white focus:border-none focus:outline-none focus:shadow-none active:border-none active:outline-none active:shadow-none hover:border-none hover:outline-none hover:shadow-none break-word"
                        value={sectionTranscripts[index]}
                        onChange={(e) => handleTranscriptEdit(e, index)}
                        onBlur={() => handleTranscriptBlur(index)}
                        onKeyDown={(e) => handleKeyDown(e, index)}
                        style={{ boxShadow: 'none', color: '#00C7A8' }}
                      />
                    ) : (
                      <div
                        className="text-white text-[32px] leading-[32px] opacity-70 cursor-text break-word"
                        onClick={() => setEditingSection(index)}
                      >
                        {sectionTranscripts[index]}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            </ScrollableContainer>
            {/* Subtitle parts end */}

            {/* Make New Science Button */}
            <div className="mt-6">
              <MakeNewScienceButton onClick={handleMakeNewScience} />
            </div>
          </div>
        </div>
      </div>
    </DndProvider>
  );
};

export default InitiativeCreateOrEditPage;
