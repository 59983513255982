import React, { useRef, useEffect, useState } from 'react';

interface CommentInputProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const CommentInput: React.FC<CommentInputProps> = ({
  value: propValue,
  onChange,
  ...props
}) => {
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [value, setValue] = useState(propValue || '');

  useEffect(() => {
    setValue(propValue || '');
  }, [propValue]);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };

  useEffect(() => {
    adjustHeight();
  }, [value]);

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue(event.target.value);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <textarea
      {...props}
      ref={textareaRef}
      value={value}
      onChange={handleChange}
      rows={1}
      className="
        text-sm font-normal leading-[24.3px] text-left text-white
        bg-transparent outline-none shadow-none flex-grow
        focus:text-white focus:outline-none focus:shadow-none
        active:outline-none active:shadow-none
        hover:outline-none hover:shadow-none
        border-t-0 border-l-0 border-r-0 border-b border-white/50
        focus:border-white
        pb-[2px]
        pt-[2px]
        resize-none
        overflow-hidden
      "
      style={{
        boxShadow: 'none',
        borderBottomWidth: '1px',
        borderBottomStyle: 'solid',
      }}
    />
  );
};

export default CommentInput;
