import React, { useState, useEffect } from 'react';
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  onSnapshot,
  arrayUnion,
  arrayRemove,
  serverTimestamp,
  Timestamp,
  FieldValue,
} from 'firebase/firestore';
import { auth, db } from '@/core/setup_firebase';
import { onAuthStateChanged } from 'firebase/auth';

import CommentCard from './CommentCard';
import CommentInputWrapper from './CommentInputWrapper';
import CommentInput from './CommentInput';
import CircleButton from './CircleButton';
import ArrowRightIcon from './ArrowRightIcon';
import { getAuthorDetails } from './getAuthorDetails';

import { Comment, NewComment, FirestoreComment, Author } from '@/types/index';
import AuthorAvatar from '@/components/AuthorAvatar';

const getTimestampMillis = (timestamp: Timestamp | FieldValue): number => {
  if (timestamp instanceof Timestamp) {
    return timestamp.toMillis();
  }
  // If it's a FieldValue (serverTimestamp), we can't compare it directly.
  // As a fallback, we'll use the current time.
  return Date.now();
};
interface CommentSectionProps {
  resource: string;
}

const CommentSection: React.FC<CommentSectionProps> = ({ resource }) => {
  const [currentUser, setCurrentUser] = useState(auth.currentUser);
  const [currentAuthor, setCurrentAuthor] = useState<Author | null>(null);
  const [comments, setComments] = useState<Comment[]>([]);
  const [comment, setComment] = useState('');

  useEffect(() => {
    if (currentUser?.uid) {
      const fetchAuthor = async () => {
        const fetchedAuthor = await getAuthorDetails(currentUser.uid);
        setCurrentAuthor(fetchedAuthor);
      };

      fetchAuthor();
    }
  }, [currentUser?.uid]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setCurrentUser(user);
    });

    return () => unsubscribe();
  }, [resource]);

  useEffect(() => {
    const q = query(
      collection(db, 'comments'),
      where('resource', '==', resource),
    );
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedComments: Comment[] = [];
      querySnapshot.forEach((doc) => {
        const commentData = doc.data() as FirestoreComment;
        if (commentData.timestamp instanceof Timestamp) {
          fetchedComments.push({
            ...commentData,
            id: doc.id,
            timestamp: commentData.timestamp,
          });
        }
      });
      setComments(fetchedComments);
    });

    return () => unsubscribe();
  }, [resource]);

  if (!currentUser?.uid) return null;

  const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setComment(e.target.value);
  };

  const handleCommentSubmit = async (parentId: string | null = null) => {
    if (comment.trim() !== '') {
      try {
        const newComment: NewComment = {
          resource,
          userId: currentUser.uid,
          content: comment.trim(),
          timestamp: serverTimestamp(),
          voterIds: [],
          parentId,
        };
        await addDoc(collection(db, 'comments'), newComment);
        setComment('');
      } catch (error) {
        console.error('Error adding comment: ', error);
      }
    }
  };

  const handleReplySubmit = async (parentId: string, content: string) => {
    if (content.trim() !== '') {
      try {
        const newReply: NewComment = {
          resource,
          userId: currentUser.uid,
          content: content.trim(),
          timestamp: serverTimestamp(),
          voterIds: [],
          parentId,
        };
        await addDoc(collection(db, 'comments'), newReply);
      } catch (error) {
        console.error('Error adding reply: ', error);
      }
    }
  };

  const handleUpvote = async (id: string) => {
    try {
      const commentRef = doc(db, 'comments', id);
      await updateDoc(commentRef, {
        voterIds: arrayUnion(currentUser.uid),
      });
    } catch (error) {
      console.error('Error upvoting comment: ', error);
    }
  };

  const handleDownvote = async (id: string) => {
    try {
      const commentRef = doc(db, 'comments', id);
      await updateDoc(commentRef, {
        voterIds: arrayRemove(currentUser.uid),
      });
    } catch (error) {
      console.error('Error downvoting comment: ', error);
    }
  };

  const handleCommentFlagged = async (id: string) => {
    try {
      const commentRef = doc(db, 'comments', id);
      console.log(commentRef);
      await updateDoc(commentRef, {
        isFlagged: 'pending',
        flaggedByUserId: currentUser.uid,
      });
    } catch (error) {
      console.error('Error flagging comment: ', error);
    }
  };

  const sortComments = (commentsToSort: Comment[]): Comment[] => {
    return commentsToSort.sort((a, b) => {
      // First, sort by number of upvotes (descending)
      const voteDifference = b.voterIds.length - a.voterIds.length;
      if (voteDifference !== 0) return voteDifference;

      // If upvotes are equal, sort by timestamp (descending)
      return getTimestampMillis(b.timestamp) - getTimestampMillis(a.timestamp);
    });
  };

  const topLevelComments = sortComments(
    comments.filter((comment) => comment.parentId === null),
  );
  const replies = comments.filter((comment) => comment.parentId !== null);

  const renderCommentWithReplies = (comment: Comment) => {
    const commentReplies = sortComments(
      replies.filter((reply) => reply.parentId === comment.id),
    );
    return (
      <CommentCard
        key={comment.id}
        comment={comment}
        onUpvote={handleUpvote}
        onDownvote={handleDownvote}
        onReply={(content) => handleReplySubmit(comment.id, content)}
        onFlagComment={handleCommentFlagged}
        currentUserId={comment.userId}
        replies={commentReplies}
        renderReplies={renderCommentWithReplies}
      />
    );
  };

  return (
    <>
      <div className="pb-4 text-xs text-white">
        {comments.length === 0
          ? 'Be the first to collaborate'
          : `${comments.length} comment${comments.length > 1 ? 's' : ''}`}
      </div>

      <div className="pb-4">
        <CommentInputWrapper>
          <AuthorAvatar author={currentAuthor} styleVariant="simple" />
          <CommentInput
            value={comment}
            placeholder="tell me why this is important to you"
            onChange={handleCommentChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && e.shiftKey) {
                e.preventDefault();
                handleCommentSubmit();
              }
            }}
          />
          <CircleButton
            onClick={() => handleCommentSubmit()}
            title="Submit comment (or press Shift+Enter)"
          >
            <ArrowRightIcon />
          </CircleButton>
        </CommentInputWrapper>
      </div>

      <div className="pb-4 overflow-y-auto">
        {topLevelComments.map(renderCommentWithReplies)}
      </div>
    </>
  );
};

export default CommentSection;
