import { StudyInCondition } from '@/types/SiteMap';

import arrowTopRightCircleIcon from '@/images/arrow_top_right_circle.svg';

const CompletedStudyCard: React.FC<{
  study: StudyInCondition;
}> = ({ study }) => {
  return (
    <div className="mt-6 mb-0">
      <div className="flex items-start mb-2">
        <div className="w-1 min-w-1 h-1 min-h-1 rounded-full bg-[#00C7A8] m-3"></div>
        <div className="flex-grow pr-0">
          <div className="flex items-start justify-between">
            <p className="text-white text-lg flex-grow">{study.name}</p>
            <a
              href={`https://clinicaltrials.gov/study/${study?.nct_id || '#'}`}
              target="_blank"
              rel="noopener noreferrer"
              onClick={(e) => e.stopPropagation()}
              className="ml-2 p-1 hover:bg-[#353539] rounded transition-colors flex-shrink-0"
            >
              <img
                src={arrowTopRightCircleIcon}
                alt="Open study"
                className="w-4 h-4 text-[#9491a7]"
              />
            </a>
          </div>
          <span className="block text-[#9491a7] text-sm">
            Completed{' '}
            {study.completed_date
              ? new Date(study.completed_date).toLocaleDateString('en-US', {
                  month: 'long',
                  year: 'numeric',
                })
              : ''}
            with {study.num_patients} patients
          </span>
        </div>
      </div>
    </div>
  );
};

export default CompletedStudyCard;
