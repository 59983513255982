import clsx from 'clsx';
import { HTMLInputTypeAttribute, useEffect, useRef } from 'react';

type InputProps = {
  id?: string;
  icon: any;
  name: string;
  value?: any;
  type?: HTMLInputTypeAttribute;
  placeholder: string;
  className?: string;
  minDate?: Date;
  disabled?: boolean;
  maxDate?: Date;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function SidebarInput(props: InputProps) {
  const {
    id,
    icon,
    placeholder,
    type = 'number',
    name,
    value,
    onChange,
    minDate,
    maxDate,
    className,
    disabled,
  } = props;

  const dateInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (dateInputRef.current && id) {
      const minDateStr = minDate!.toISOString().split('T')[0];
      const maxDateStr = maxDate!.toISOString().split('T')[0];

      const input = document.getElementById(id);
      (window as any).jSuites.calendar(input, {
        type: 'year-month-picker',
        format: 'mm/yy',
        validRange: [minDateStr, maxDateStr],
        icon: false,
        onchange: function (instance: any, value: string) {
          onChange?.({ target: { value } } as any);
        },
      });
    }
  }, [dateInputRef.current]);

  return (
    <div className="relative mt-2">
      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
        <img
          className="h-5 w-5 text-gray-400"
          aria-hidden="true"
          alt={placeholder}
          src={icon}
        />
      </div>
      {type === 'date' ? (
        <input
          id={id}
          ref={dateInputRef}
          //   type={type}
          name={name}
          // value={value}
          className={clsx(
            'block rounded-md border-transparent w-[100px] py-1.5 pl-10 text-gray-100 bg-base-300 focus:ring-primary focus:ring-0 placeholder:text-gray-100 sm:text-sm sm:leading-6',
            className,
          )}
          placeholder={placeholder}
          style={{
            appearance: 'textfield',
            MozAppearance: 'textfield',
            WebkitAppearance: 'none',
          }}
          onChange={onChange}
        />
      ) : (
        <input
          type={type}
          name={name}
          value={value}
          className={clsx(
            'block rounded-md border-transparent w-20 py-1.5 pl-10 text-gray-100 bg-base-300 focus:ring-primary focus:ring-0 placeholder:text-gray-100 sm:text-sm sm:leading-6',
            className,
          )}
          placeholder={placeholder}
          style={{
            appearance: 'textfield',
            MozAppearance: 'textfield',
            WebkitAppearance: 'none',
          }}
          disabled={disabled}
          onChange={onChange}
        />
      )}
      {type === 'date' && !value && (
        <div className="absolute flex flex-col justify-center top-0 text-gray-100 bottom-0 left-0 sm:text-sm right-0 text-center pointer-events-none">
          <div>{placeholder}</div>
        </div>
      )}
    </div>
  );
}
