import React, { useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router';

type Props = {
  children: React.ReactNode;
};

export default function AuthGuard({ children }: Props) {
  const { currentUser, loading } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loading && !currentUser) {
      navigate('/login');
    }
  }, [currentUser, loading, navigate]);

  if (loading) {
    // You might want to show a loading spinner or some other indicator here
    return <div>...</div>;
  }

  if (!currentUser) {
    // Return null or a placeholder while redirecting
    return null;
  }

  return <>{children}</>;
}
