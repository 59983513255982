import { createBrowserRouter } from 'react-router-dom';
import ErrorPage from '@/pages/error/ErrorPage';
import BuildProfileGraphPageStep0 from '@/pages/poc-build-profile-1/BuildProfileGraphPageStep0';
import BuildProfileGraphPageStep1 from '@/pages/poc-build-profile-1/BuildProfileGraphPageStep1';
import BuildProfileGraphStep2Page from '@/pages/poc-build-profile-1/BuildProfileGraphStep2Page';
import WaitingForCollision from '@/pages/poc-build-profile-1/WaitingForCollisionPage';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <BuildProfileGraphPageStep0 />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/intro',
    element: <BuildProfileGraphPageStep1 />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/waitlist',
    element: <BuildProfileGraphStep2Page />,
    errorElement: <ErrorPage />,
  },
  {
    path: '/waiting-for-collision',
    element: <WaitingForCollision />,
    errorElement: <ErrorPage />,
  },
]);
