import { useState, useCallback } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { Library } from '@/types/Library';

export const initLibraryData: Library = {
  id: '',
  userId: '',
  name: '',
  brainstormId: '',
  selectedSponsors: [],
  sponsors: [],
  savedStudies: [],
  savedSites: [],
  filters: {},
};

export const useLoadLibrary = (initData: Library = initLibraryData) => {
  const [library, setLibrary] = useState<Library>(initData);
  const [loading, setLoading] = useState(false);

  const fetchLibrary = useCallback(
    async (libraryId: string) => {
      setLoading(true);
      try {
        console.log('Fetching library with id:', libraryId);
        const libraryRef = doc(db, 'libraries', libraryId);
        const librarySnap = await getDoc(libraryRef);

        if (librarySnap.exists()) {
          const data = librarySnap.data() as Library;
          setLibrary({
            ...initData,
            ...data,
            id: librarySnap.id,
          });
          return true;
        }
        return false;
      } catch (error) {
        console.error('Error fetching library: ', error);
        return false;
      } finally {
        setLoading(false);
      }
    },
    [initData],
  );

  return { library, setLibrary, loading, fetchLibrary };
};
