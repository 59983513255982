import { HTMLInputTypeAttribute } from 'react';

type Options = Array<{ value: string | number; label: string } | string>;
type Props = {
  id: string;
  value: string;
  options: Options;
  type?: HTMLInputTypeAttribute;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  className?: string;
};

const normalizeOptions = (options: Options) => {
  return options.map((option) => {
    if (typeof option === 'string') {
      return { value: option, label: option };
    }
    return option;
  });
};

export function FormFieldSelect(props: Props) {
  const {
    id,
    value,
    onChange,
    label,
    type = 'text',
    placeholder,
    required,
    className,
    options,
  } = props;

  const optionList = normalizeOptions(options);

  return (
    <div className="form-control w-full">
      {label && (
        <label
          htmlFor={id}
          className="block mb-1 text-[#b8b9c1] text-xs font-medium"
        >
          {label}
        </label>
      )}
      <select
        id={id}
        className="input bg-[#41414b] w-full text-white h-10"
        value={value}
        onChange={onChange}
        required={required}
      >
        {required && (
          <option value="" className="text-gray-500">
            {placeholder}
          </option>
        )}
        {optionList.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
