import React, { useEffect, useRef, useState, useCallback } from 'react';
import * as d3 from 'd3';
import Header from './components/Header';
import InvisibleInput from './components/InvisibleInput';
import MakeNewScienceButton from '@/components/buttons/MakeNewScienceButton';
import {
  setProfileName,
  setProfileLocation,
  setProfileEmail,
  setCurrentStep,
} from '@/features/health_profile/health_profile_slice';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'app/store';
import '../../../src/GoogleMapsAutocompleteStyles.css';
import { db } from '@/core/setup_firebase';
import { collection, addDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

interface ElementState {
  y: number;
  opacity: number;
}

interface StepState {
  dots: ElementState[];
  inputs: ElementState[];
}

const BuildProfileGraphStep2Page: React.FC = () => {
  const dispatch = useDispatch();
  const { profileName, profileLocation, profileEmail, currentStep } =
    useSelector((state: RootState) => state.healthProfile);
  const [isGoogleMapsLoaded, setIsGoogleMapsLoaded] = useState(false);
  const [googleMapsError, setGoogleMapsError] = useState<string | null>(null);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > window.innerHeight,
  );

  const locationInputRef = useRef<HTMLInputElement>(null);
  const googleMapScriptRef = useRef<HTMLScriptElement | null>(null);

  const loadGoogleMapsScript = useCallback(() => {
    if (!googleMapScriptRef.current) {
      googleMapScriptRef.current = document.createElement('script');
      googleMapScriptRef.current.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&libraries=places`;
      googleMapScriptRef.current.async = true;
      googleMapScriptRef.current.defer = true;

      googleMapScriptRef.current.addEventListener('load', () => {
        console.log('Google Maps API script loaded successfully');
        setIsGoogleMapsLoaded(true);
      });

      googleMapScriptRef.current.addEventListener('error', () => {
        console.error('Failed to load Google Maps API script');
        setGoogleMapsError(
          'Failed to load Google Maps API. Please try refreshing the page.',
        );
      });

      document.head.appendChild(googleMapScriptRef.current);
    }
  }, []);

  useEffect(() => {
    loadGoogleMapsScript();

    return () => {
      if (googleMapScriptRef.current && googleMapScriptRef.current.parentNode) {
        googleMapScriptRef.current.parentNode.removeChild(
          googleMapScriptRef.current,
        );
      }
    };
  }, [loadGoogleMapsScript]);

  useEffect(() => {
    if (isGoogleMapsLoaded && locationInputRef.current) {
      if (window.google && window.google.maps && window.google.maps.places) {
        console.log('Google Maps Places API is available');
        try {
          const autocomplete = new window.google.maps.places.Autocomplete(
            locationInputRef.current,
            { types: ['(cities)'] },
          );

          autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace();
            if (place?.formatted_address) {
              dispatch(setProfileLocation(place.formatted_address));
              if (currentStepRef.current === 2) {
                dispatch(setCurrentStep(currentStepRef.current + 1));
              }
            }
          });

          console.log('Autocomplete instance created successfully');

          // Apply Satoshi font to autocomplete dropdown
          const pacContainer = document.querySelector('.pac-container');
          if (pacContainer) {
            (pacContainer as HTMLElement).style.fontFamily =
              "'Satoshi', sans-serif";
            (pacContainer as HTMLElement).style.fontSize = '32px';
          }
        } catch (error) {
          console.error('Error creating Autocomplete instance:', error);
          setGoogleMapsError(
            'Error initializing location autocomplete. Please try again.',
          );
        }
      } else {
        console.error('Google Maps Places API is not available');
        setGoogleMapsError(
          'Google Maps Places API is not available. Please check your internet connection and try again.',
        );
      }
    }
  }, [isGoogleMapsLoaded, dispatch]);

  const currentStepRef = useRef(currentStep);

  useEffect(() => {
    currentStepRef.current = currentStep;
  }, [currentStep]);

  const svgRef = useRef<SVGSVGElement>(null);
  const nameInputRef = useRef<HTMLInputElement>(null);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const navigate = useNavigate();

  const [emailError, setEmailError] = useState('');
  const [emailSubmitted, setEmailSubmitted] = useState(false);

  const smallCircleRadius = isDesktop ? 45 : 25;
  const newX = isDesktop ? 90 : 45;
  const newY = isDesktop ? 160 : 120;
  const elementSpacing = isDesktop ? 100 : 70;
  const verticalOffset = isDesktop ? 118 : 82;
  const verticalSpacing = isDesktop ? 130 : 100;

  const stepStates: StepState[] = [
    // Initial state (step 0)
    {
      dots: [
        { y: newY, opacity: 0 },
        { y: newY, opacity: 0 },
        { y: newY + 2 * verticalSpacing, opacity: 0 },
        { y: newY + 3 * verticalSpacing, opacity: 0 },
        { y: newY + 4 * verticalSpacing, opacity: 0 },
      ],
      inputs: [
        { y: verticalOffset, opacity: 0 },
        { y: verticalOffset + verticalSpacing, opacity: 0 },
        { y: verticalOffset + 2 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 3 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 4 * verticalSpacing, opacity: 0 },
      ],
    },
    // Step 1
    {
      dots: [
        { y: newY, opacity: 1 },
        { y: newY, opacity: 1 },
        { y: newY + 2 * verticalSpacing, opacity: 0 },
        { y: newY + 3 * verticalSpacing, opacity: 0 },
        { y: newY + 4 * verticalSpacing, opacity: 0 },
      ],
      inputs: [
        { y: verticalOffset, opacity: 1 },
        { y: verticalOffset + verticalSpacing, opacity: 0 },
        { y: verticalOffset + 2 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 3 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 4 * verticalSpacing, opacity: 0 },
      ],
    },
    // Step 2
    {
      dots: [
        { y: newY, opacity: 1 },
        { y: newY + verticalSpacing, opacity: 1 },
        { y: newY + verticalSpacing, opacity: 0 },
        { y: newY + 3 * verticalSpacing, opacity: 0 },
        { y: newY + 4 * verticalSpacing, opacity: 0 },
      ],
      inputs: [
        { y: verticalOffset, opacity: 1 },
        { y: verticalOffset + verticalSpacing, opacity: 1 },
        { y: verticalOffset + 2 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 3 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 4 * verticalSpacing, opacity: 0 },
      ],
    },
    // Step 3
    {
      dots: [
        { y: newY, opacity: 1 },
        { y: newY + verticalSpacing, opacity: 1 },
        { y: newY + 2 * verticalSpacing, opacity: 1 },
        { y: newY + 3 * verticalSpacing, opacity: 0 },
        { y: newY + 4 * verticalSpacing, opacity: 0 },
      ],
      inputs: [
        { y: verticalOffset, opacity: 1 },
        { y: verticalOffset + verticalSpacing, opacity: 1 },
        { y: verticalOffset + 2 * verticalSpacing, opacity: 1 },
        { y: verticalOffset + 3 * verticalSpacing, opacity: 0 },
        { y: verticalOffset + 4 * verticalSpacing, opacity: 0 },
      ],
    },
  ];

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      const height = window.innerHeight;
      setWindowSize({ width, height });
      setIsDesktop(width > height);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (nameInputRef.current) {
      nameInputRef.current.focus();
    }
  }, []);

  const renderDot = (
    svg: d3.Selection<SVGSVGElement, unknown, null, undefined>,
    step: number,
    state: ElementState,
    prevState: ElementState | null,
  ) => {
    const group = svg.select(`.step-${step}`);
    const animate =
      prevState !== null &&
      (prevState.y !== state.y || prevState.opacity !== state.opacity);

    if (step > 1) {
      const verticalLine = group.select(`.vertical-line-${step}`);
      if (animate) {
        verticalLine
          .transition()
          .duration(500)
          .attr('y2', state.y)
          .attr('opacity', state.opacity);
      } else {
        verticalLine.attr('y2', state.y).attr('opacity', state.opacity);
      }
    }

    const horizontalLine = group.select(`.horizontal-line-${step}`);
    const dot = group.select(`.dot-${step}`);

    if (animate) {
      horizontalLine
        .transition()
        .duration(500)
        .attr('y1', state.y)
        .attr('y2', state.y)
        .attr('opacity', state.opacity)
        .attr('x2', newX + elementSpacing + smallCircleRadius);

      dot
        .transition()
        .duration(500)
        .attr('cy', state.y)
        .attr('opacity', state.opacity)
        .attr('cx', newX + elementSpacing + smallCircleRadius);
    } else {
      horizontalLine
        .attr('y1', state.y)
        .attr('y2', state.y)
        .attr('opacity', state.opacity)
        .attr('x2', newX + elementSpacing + smallCircleRadius);

      dot
        .attr('cy', state.y)
        .attr('opacity', state.opacity)
        .attr('cx', newX + elementSpacing + smallCircleRadius);
    }
  };

  // Initial render useEffect
  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    // Remove previous elements only once
    svg.selectAll('*').remove();

    // Render main circle
    svg
      .append('circle')
      .attr('cx', newX)
      .attr('cy', newY)
      .attr('r', smallCircleRadius)
      .attr('fill', '#38DEC4');

    // Initial render
    stepStates[0].dots.forEach((state, index) => {
      const group = svg.append('g').attr('class', `step-${index + 1}`);

      // Vertical line
      if (index > 0) {
        group
          .append('line')
          .attr('class', `vertical-line-${index + 1}`)
          .attr('x1', newX)
          .attr('y1', newY)
          .attr('x2', newX)
          .attr('y2', newY)
          .attr('stroke', '#38DEC4')
          .attr('stroke-width', 1)
          .attr('opacity', 0);
      }

      // Horizontal line
      group
        .append('line')
        .attr('class', `horizontal-line-${index + 1}`)
        .attr('x1', newX)
        .attr('y1', newY)
        .attr('x2', newX + elementSpacing + smallCircleRadius)
        .attr('y2', newY)
        .attr('stroke', '#38DEC4')
        .attr('stroke-width', 1)
        .attr('opacity', 0);

      // Dot
      group
        .append('circle')
        .attr('class', `dot-${index + 1}`)
        .attr('cx', newX + elementSpacing + smallCircleRadius)
        .attr('cy', newY)
        .attr('r', 5)
        .attr('fill', '#38DEC4')
        .attr('opacity', 0);
    });
  }, [isDesktop, windowSize]);

  // D3 Updating
  useEffect(() => {
    if (!svgRef.current) return;

    const svg = d3.select(svgRef.current);

    // Update to current state
    const currentState = stepStates[currentStep];
    const prevState = currentStep > 0 ? stepStates[currentStep - 1] : null;
    currentState.dots.forEach((state, index) => {
      if (prevState) {
        renderDot(svg, index + 1, state, prevState.dots[index]);
      } else {
        renderDot(svg, index + 1, state, null);
      }
    });
  }, [currentStep]);

  const validateEmail = (email: string): boolean => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const renderInput = (
    step: number,
    value: string,
    placeholder: string,
    ref: React.RefObject<HTMLInputElement>,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    state: ElementState,
    error?: string,
    hintText?: string,
  ) => {
    // Determine the opacity based on currentStep
    const isVisible = currentStep >= step;
    const opacity = isVisible ? 1 : 0;

    return (
      <div
        className="w-full absolute transition-all duration-500"
        style={{
          top: `${state.y}px`,
          opacity: opacity,
          transition: 'opacity 0.5s, top 0.5s',
        }}
      >
        <InvisibleInput
          ref={ref}
          placeholder={placeholder}
          value={value}
          onChange={onChange}
          onKeyDown={handleKeyPress}
          className="w-full flex flex-row text-white bg-transparent border-b border-white p-2 focus:outline-none "
          style={{ fontSize: isDesktop ? '36px' : '26px' }}
        />
        {hintText && (
          <p
            className="text-white/60 text-[22px] font-normal font-['Satoshi Variable'] w-3/4"
            style={{
              opacity: emailSubmitted ? 0 : 1,
              transition: 'opacity 0.5s',
            }}
          >
            {hintText}
          </p>
        )}
        {error && <div className="text-red-500">{error}</div>}
      </div>
    );
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    action:
      | typeof setProfileName
      | typeof setProfileLocation
      | typeof setProfileEmail,
  ) => {
    dispatch(action(e.target.value));
  };

  useEffect(() => {
    if (locationInputRef.current) {
      locationInputRef.current.focus();
    }
  }, []);

  const renderLocationInput = (state: ElementState) => {
    const isVisible = currentStep >= 2;
    const opacity = isVisible ? 1 : 0;

    return (
      <div
        className="w-full absolute transition-all duration-500"
        style={{
          top: `${state.y + 16}px`,
          opacity: opacity,
          height: '32px',
          maxWidth: '500px',
          width: '100%',
          pointerEvents: isVisible ? 'auto' : 'none',
        }}
      >
        <input
          ref={locationInputRef}
          type="text"
          placeholder="Where are you from?"
          value={profileLocation}
          onChange={(e) => dispatch(setProfileLocation(e.target.value))}
          onKeyDown={handleKeyPress}
          className="w-full flex-grow text-white bg-transparent border-b border-none p-0 focus:outline-none focus:ring-0"
          style={{
            // caretColor: 'white',
            fontFamily: "'Satoshi', sans-serif",
            fontSize: isDesktop ? '36px' : '22px',
            WebkitTapHighlightColor: 'transparent',
            // WebkitTextFillColor: 'white',
            outline: 'none',
            boxShadow: 'none',
            lineHeight: '36px', // Match the line height to the container height
          }}
        />
      </div>
    );
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      const inputValues = [profileName, profileLocation, profileEmail];

      const currentValue = inputValues[currentStep - 1]?.trim();

      if (!currentValue || currentValue === '') {
        // Do not advance if input is empty
        return;
      }

      if (currentStep === 3) {
        // Validate email
        if (!validateEmail(currentValue)) {
          setEmailError('Please enter a valid email address');
          return;
        } else {
          setEmailError('');
          setEmailSubmitted(true);
          // Keep currentStep at 3 to prevent advancing further
        }
      } else {
        // For steps less than 3
        dispatch(setCurrentStep(currentStep + 1));
        if (currentStep < 3) {
          focusNextInput(currentStep);
        }
      }
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const email = e.target.value;
    dispatch(setProfileEmail(email));
    setEmailError('');
    setEmailSubmitted(false);
  };

  const focusNextInput = (currentStep: number) => {
    const inputRefs = [nameInputRef, locationInputRef, emailInputRef];
    const nextInput = inputRefs[currentStep];
    if (nextInput && nextInput.current) {
      nextInput.current.focus();
    }
  };

  useEffect(() => {
    if (currentStep > 0 && currentStep <= 3) {
      focusNextInput(currentStep - 1);
    }
  }, [currentStep]);

  const handleDMMeLater = async () => {
    try {
      const docRef = await addDoc(collection(db, 'mailing_list'), {
        name: profileName,
        location: profileLocation,
        email: profileEmail,
        timestamp: Date.now(),
      });
      console.log('User added to mailing list with ID: ', docRef.id);
      navigate('/waiting-for-collision');
    } catch (e) {
      console.error('Error adding document: ', e);
    }
  };

  return (
    <div className="relative w-full h-screen bg-[#1F1F23] overflow-hidden">
      <div className="relative z-20 w-full">
        <Header includeAuthButtons={false} />
      </div>
      <div className={`flex h-full ${isDesktop ? 'flex-row' : 'flex-row'}`}>
        <div
          className={`${isDesktop ? 'w-1/2 max-w-[260px]' : 'w-1/2 max-w-[160px]'} relative `}
        >
          <svg
            ref={svgRef}
            className="absolute inset-0 w-full h-full pointer-events-none"
          ></svg>
        </div>
        <div className={`${isDesktop ? 'w-1/2' : 'w-full'} relative`}>
          {renderInput(
            1,
            profileName,
            'Tell me your name',
            nameInputRef,
            (e) => handleInputChange(e, setProfileName),
            stepStates[3].inputs[0],
          )}
          {isGoogleMapsLoaded &&
            renderLocationInput(stepStates[currentStep].inputs[1])}
          {googleMapsError ? (
            <div className="text-red-500 font-['Satoshi']">
              {googleMapsError}
            </div>
          ) : null}
          {renderInput(
            3,
            profileEmail,
            'Type your email address so I can DM you :)',
            emailInputRef,
            (e) => handleInputChange(e, setProfileEmail),
            stepStates[3].inputs[2],
            emailError,
            !emailSubmitted
              ? "If you're in science, healthcare, tech or academia, an org email would be preferable to help us understand your knowledge level. Otherwise, enter your personal email."
              : undefined,
          )}
          <div
            className="absolute transition-opacity duration-500"
            style={{
              top: `${stepStates[3].inputs[2].y + 80}px`,
              opacity: emailSubmitted ? 1 : 0,
              pointerEvents: emailSubmitted ? 'auto' : 'none',
            }}
          >
            <MakeNewScienceButton
              label="OK, DM me later :)"
              onClick={handleDMMeLater}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildProfileGraphStep2Page;
