import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as AtomIcon } from '@/images/atom.svg';
import chevronImage from '../../images/chevron_gray_icon.svg';
import clsx from 'clsx';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import * as z from 'zod';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  getAuth,
  setPersistence,
  signInWithEmailAndPassword,
} from 'firebase/auth';
import { getApp } from 'firebase/app';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

const isMVP = true;

const schema = z
  .object({
    email: z.string().email().optional(),
    phoneNumber: z.string().optional(),
    password: z.string().min(8).max(100),
    rememberMe: z.boolean().nullish(),
  })
  .refine(
    (data) => data.email || data.phoneNumber,
    'Either email or phone number is required',
  );

function SignInPhoneOrEmailPage() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
    setValue,
    watch,
    trigger,
  } = useForm({
    resolver: zodResolver(schema),
  });
  const [selectedTab, setSelectedTab] = useState<'phone' | 'email'>('email');
  const navigate = useNavigate();
  const location = useLocation();

  const app = getApp();
  const auth = getAuth(app);

  const handleTabClick = (tab: 'phone' | 'email') => {
    setSelectedTab(tab);
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const onSubmit = async (data: any) => {
    try {
      const persistence = data.rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await setPersistence(auth, persistence);

      if (selectedTab === 'email') {
        await signInWithEmailAndPassword(auth, data.email, data.password);
      } else {
        // TODO: Handle phone number sign-in
      }

      // Get the 'from' query parameter
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get('from');

      // Redirect to the 'from' URL if it exists and starts with '/', otherwise go to '/graph'
      if (from && from.startsWith('/')) {
        navigate(from);
      } else {
        window.location.href = '/kadencebio';
      }
    } catch (error: any) {
      switch (error.code) {
        case 'auth/user-not-found':
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'No user with these credentials was found.',
          });
          break;

        case 'auth/invalid-email':
          setError('email', {
            type: 'manual',
            message: 'Invalid email address.',
          });
          break;

        case 'auth/wrong-password':
          setError('password', {
            type: 'manual',
            message: 'Incorrect password.',
          });
          break;

        case 'auth/network-request-failed':
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        case 'auth/too-many-requests':
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'Too many requests. Try again later.',
          });
          break;

        default:
          setError(selectedTab === 'email' ? 'email' : 'phoneNumber', {
            type: 'manual',
            message: 'Internal error. Try again later.',
          });
          console.error(error);
          break;
      }
    }
  };

  // Helper function to get the error message
  function getErrorMessage(error: any): string | undefined {
    if (error) {
      if (typeof error.message === 'string') {
        return error.message;
      }
      if (typeof error.message === 'object') {
        // If the message is nested, recursively get the first string message
        for (const key in error.message) {
          if (typeof error.message[key] === 'string') {
            return error.message[key];
          }
        }
      }
    }
    return undefined;
  }

  const handleForgotPassword = () => {
    navigate('/login/forgot-password');
  };

  return (
    <div className="flex h-screen w-full bg-[#1F1F23] items-center justify-center">
      <div className="w-[580px] bg-[#2c2b32] rounded-lg shadow">
        <div className="px-12 py-12 flex flex-col gap-6 relative">
          {/* Back Button */}
          <button onClick={handleBackClick} className="absolute left-12 top-14">
            <img
              src={chevronImage}
              className={clsx('w-4 h-4 transform rotate-90')}
            />
          </button>
          {/* Title */}
          <h2 className="text-center text-[#9491a7] text-[25px] font-medium self-center justify-center">
            Sign In
          </h2>
          {/* Heading */}
          <h1 className="text-white text-[40px] font-normal">
            Login to your account
          </h1>
          {/* Tabs */}
          <div className="flex border-b border-[#676578] mt-6">
            <button
              onClick={() => handleTabClick('email')}
              className={`flex-1 py-4 text-center text-[22px] font-semibold ${
                selectedTab === 'email'
                  ? 'text-white border-b-2 border-white'
                  : 'text-[#9491a7]'
              }`}
            >
              Email
            </button>
            {!isMVP && (
              <button
                onClick={() => handleTabClick('phone')}
                className={`flex-1 py-4 text-center text-[22px] font-semibold ${
                  selectedTab === 'phone'
                    ? 'text-white border-b-2 border-white'
                    : 'text-[#9491a7]'
                }`}
              >
                Phone
              </button>
            )}
          </div>
          {/* Form */}
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="flex flex-col gap-8 mt-8"
          >
            {/* Input Fields */}
            {selectedTab === 'email' && (
              <div>
                {/* Email Input */}
                <div className="flex items-center px-4">
                  <input
                    type="email"
                    placeholder="Email"
                    className="w-full bg-transparent text-white text-[25px] font-normal border-none focus:outline-none autofill:bg-transparent autofill:text-white"
                    {...register('email')}
                  />
                </div>
                <div className="border-b border-[#9491a7] mt-2"></div>
                {errors.email && (
                  <p className="text-red-500 mt-2 text-base">
                    {getErrorMessage(errors.email)}
                  </p>
                )}
              </div>
            )}
            {selectedTab === 'phone' && (
              <div>
                {/* Phone Input */}
                <div className="flex items-center px-4">
                  <PhoneInput
                    placeholder="Enter your phone number"
                    className="w-full"
                    defaultCountry="GB"
                    value={watch('phoneNumber')}
                    {...register('phoneNumber')}
                    onChange={(value) => {
                      setValue('phoneNumber', value); // Update the form value
                      trigger('phoneNumber'); // Trigger validation
                    }}
                    numberInputProps={{
                      className:
                        'bg-transparent text-white text-[25px] font-normal border-none focus:outline-none autofill:bg-transparent autofill:text-white',
                    }}
                  />
                </div>
                <div className="border-b border-[#9491a7] mt-2"></div>
                {errors.phoneNumber && (
                  <p className="text-red-500 mt-2 text-base">
                    {getErrorMessage(errors.phoneNumber)}
                  </p>
                )}
              </div>
            )}
            {/* Password Input */}
            <div>
              <div className="flex items-center px-4">
                <input
                  type="password"
                  placeholder="Password"
                  className="w-full bg-transparent text-white text-[25px] font-normal border-none focus:outline-none autofill:bg-transparent autofill:text-white"
                  {...register('password')}
                />
              </div>
              <div className="border-b border-[#9491a7] mt-2"></div>
              {errors.password && (
                <p className="text-red-500 mt-2 text-base">
                  {getErrorMessage(errors.password)}
                </p>
              )}
            </div>
            {/* Remember Me and Forgot Password */}
            <div className="flex justify-between items-center">
              <label className="flex items-center gap-2">
                <input
                  type="checkbox"
                  className="w-4 h-4 text-[#00c7a8] bg-transparent border border-white rounded focus:ring-0"
                  {...register('rememberMe')}
                />
                <span className="text-white text-sm">Remember Me</span>
              </label>
              <button
                onClick={handleForgotPassword}
                type="button"
                className="text-[#00c7a8] text-sm"
              >
                Forgot password?
              </button>
            </div>
            {/* Next Button */}
            <div className="mt-8 flex flex-col gap-4">
              <button
                type="submit"
                className="w-full py-3 bg-[#00c7a8] rounded-lg flex items-center justify-center gap-2 hover:bg-[#00b89b] transition"
                disabled={isSubmitting}
              >
                <span className="text-[#101117] text-[25px] font-medium leading-7">
                  Next
                </span>
                <AtomIcon />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default SignInPhoneOrEmailPage;
