import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import clsx from 'clsx';
import chevronIcon from '@/images/chevron_gray_icon.svg';
import checkIcon from '@/images/check_green_icon.svg';
import { BackButton } from '@/pages/dashboard/components/v2/BackButton';

type StudyData = {
  id: number;
  name: string;
  date: string;
  selected: boolean;
};

type StudyViewProps = {
  study: StudyData;
  handleStudySelect: (id: number) => void;
};

function StudyView({ study, handleStudySelect }: StudyViewProps) {
  return (
    <div
      key={study.id}
      className="flex flex-row h-8 min-h-8 justify-between items-center pl-0.5 pr-2 bg-[#1F1F23] rounded-md cursor-pointer"
      onClick={() => handleStudySelect(study.id)}
    >
      {study.selected ? (
        <div className="">
          <img src={checkIcon} className="w-8 h-8 min-w-6 min-h-6" alt="" />
        </div>
      ) : (
        <div className="w-4 h-4 ml-2 mr-1 border border-gray-500 rounded"></div>
      )}
      <div
        className={clsx(
          'border-r border-zinc-800 h-full mr-3',
          !study.selected && 'ml-1',
        )}
      />
      <div className="flex flex-row flex-grow gap-x-3 justify-between">
        <span className={clsx('text-sm', study.selected && 'text-emerald-400')}>
          {study.name}
        </span>
        <span className="text-gray-500 text-sm">{study.date}</span>
      </div>
    </div>
  );
}

type StudiesDropdownProps = {
  studies: StudyData[];
  setStudies: React.Dispatch<React.SetStateAction<StudyData[]>>;
};

export default function StudiesDropdown({
  studies,
  setStudies,
}: StudiesDropdownProps) {
  const [selectedStudies, setSelectedStudies] = useState<string[] | null>([]);
  // const [studies, setStudies] = useState<StudyData[]>([]);
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(true);

  const toggleDropdown = () => setIsOpen(!isOpen);

  const handleStudySelect = (id: number) => {
    setStudies(
      studies.map((study) => {
        if (study.id === id) {
          return { ...study, selected: !study.selected };
        }
        return study;
      }),
    );
  };

  const selectedCount = studies.filter((s) => s.selected).length;
  const selectedStudy = studies.find((s) => s.selected);

  return (
    <div className="text-gray-300 p-4 rounded-lg w-full">
      <div
        className="flex items-center justify-between cursor-pointer mb-2"
        onClick={toggleDropdown}
      >
        <div className="flex items-center space-x-2">
          <img
            src={chevronIcon}
            className={clsx('w-3 h-3 min-w-3 min-h-3', isOpen && 'rotate-180')}
            alt=""
          />
          <span className="text-sm">Studies</span>
          <span className="bg-gray-700 px-1.5 py-0.5 rounded-full text-[10px]">
            {selectedCount}
          </span>
        </div>
        {/*<img src={addIcon} className="w-4 h-4" />*/}
        <BackButton onClick={() => navigate(`/flow-3-wireframes/step-3`)} />
      </div>
      {!isOpen && selectedStudy && (
        <StudyView study={selectedStudy} handleStudySelect={() => null} />
      )}
      {isOpen && (
        <div className="mt-2 space-y-2">
          {studies.map((study) => (
            <StudyView
              key={study.id}
              study={study}
              handleStudySelect={handleStudySelect}
            />
          ))}
        </div>
      )}
    </div>
  );
}
