import { useCallback, useRef, useState } from 'react';
import fireIcon from '../../../../../images/fireIcon.svg';
import MatchScoreInfoView from './MatchScoreInfoView';
import { useOutsideTouchAlerter } from '../../../../../core/hooks/useOutsideTouchAlerter';
import { delay } from '../../../../../utils/time';

export default function ProfileMatchView() {
  const matchScoreInfoRef = useRef(null);
  const [isMatchScoreInfoVisible, setIsMatchScoreInfoVisible] = useState(false);
  const [isClickEnabled, setIsClickEnabled] = useState(true);

  const hideMatchScoreInfo = useCallback(async () => {
    if (!isMatchScoreInfoVisible) {
      return;
    }

    setIsClickEnabled(false);
    setIsMatchScoreInfoVisible(false);

    await delay(50);
    setIsClickEnabled(true);
  }, [isMatchScoreInfoVisible]);

  useOutsideTouchAlerter(matchScoreInfoRef, hideMatchScoreInfo);

  console.log('ProfileMatchView rendered: ', isMatchScoreInfoVisible);

  return (
    <>
      <div className="inline-flex flex-row justify-start mt-4">
        <div
          className="relative inline-flex flex-row py-2 pl-3 pr-5 rounded items-center text-start"
          style={{ backgroundColor: '#9ae8dbbb' }}
          onClick={(e) => {
            if (!isClickEnabled) {
              return;
            }

            setIsMatchScoreInfoVisible(!isMatchScoreInfoVisible);
          }}
        >
          <img src={fireIcon} className="h-4 w-4 mr-2" />
          <div className="text-slate-950 text-base font-bold">
            76% Profile Match
          </div>
        </div>
      </div>

      <div ref={matchScoreInfoRef}>
        {isMatchScoreInfoVisible && (
          <MatchScoreInfoView ref={matchScoreInfoRef} />
        )}
      </div>
    </>
  );
}
