import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import crossIcon from '../../images/cross_icon.svg';
import { FormField } from './components/FormField';
import { FormFieldSelect } from './components/FormFieldSelect';
import { studyMethods } from '../../types/studyMethods';
import longArrowRightIcon from '../../images/long_arrow_right.svg';
import buildStudyImage from '../../images/build-study.svg';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectStudyName,
  selectStudySubject,
  selectStudyMethod,
  setStudyName,
  setStudySubject,
  setStudyMethod,
  selectDrugClass,
  setDrugClass,
  selectPeopleCount,
  setPeopleCount,
} from '../../features/create_study/create_study_slice';
import CountrySelector from './components/CountrySelector';
import AgeRangeSelector from './components/AgeRangeSelector';

const CreateStudyFormPage: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const studyName = useSelector(selectStudyName);
  const studySubject = useSelector(selectStudySubject);
  const drugClass = useSelector(selectDrugClass);
  const studyMethod = useSelector(selectStudyMethod);
  const peopleCount = useSelector(selectPeopleCount);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log({ studyName, drugClass, studySubject, studyMethod });
    navigate('/wireframes/add-inclusion-criteria-page');
  };

  return (
    <ProjectLayout title="Studies Library">
      <main className="w-[560px] flex flex-col items-start justify-start text-start py-6 mx-auto my-auto">
        <div className="flex flex-col w-full items-center mb-4">
          <img
            src={buildStudyImage}
            alt="Create Study Image"
            className="w-[160px] h-[160px] mb-6"
          />

          <div className="text-center text-white text-2xl font-bold leading-loose">
            Great! Let’s start building your study
          </div>
          <div className="text-center text-[#b8b9c1] text-sm font-normal leading-tight">
            Let's fill in all the fields to be able to create a study.
          </div>
        </div>

        <form onSubmit={handleSubmit} className="w-full gap-y-4 flex flex-col">
          <FormField
            label="Study name"
            id="studyName"
            value={studyName}
            onChange={(e) => dispatch(setStudyName(e.target.value))}
            required
          />

          <FormField
            label="What condition do you want to study?"
            id="studySubject"
            value={studySubject}
            onChange={(e) => dispatch(setStudySubject(e.target.value))}
            placeholder="e.g. Sepsis"
            required
          />

          <FormFieldSelect
            label="What drug class?"
            id="studyMethod"
            value={studyMethod}
            onChange={(e) => dispatch(setStudyMethod(e.target.value))}
            options={studyMethods}
            placeholder="Please Select"
            required
          />

          <CountrySelector />

          <FormField
            label="How many people do you want in your study?"
            id="studySubject"
            value={peopleCount}
            type="number"
            onChange={(e) => dispatch(setPeopleCount(parseInt(e.target.value)))}
            placeholder="e.g. 10000"
            inputClassName="max-w-[120px]"
            required
          />

          <AgeRangeSelector />

          <div className="flex flex-row justify-between items-center mt-10">
            <div
              className="btn bg-zinc-700 rounded-md gap-2 flex flex-row justify-center items-center hover:bg-zinc-900 text-white font-normal"
              onClick={() => navigate('/wireframes/create-project')}
            >
              Cancel
            </div>

            <button type="submit" className="btn btn-primary text-[#1f1f23]">
              Next
              <img
                src={longArrowRightIcon}
                className="inline-flex w-4 h-4 ml-2"
              />
            </button>
          </div>
        </form>
      </main>
    </ProjectLayout>
  );
};

export default CreateStudyFormPage;
