import React, { useState } from 'react';
import chevronImage from '../../../../images/chevron_gray_icon.svg';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import {
  selectExclusionCriteria,
  selectInclusionCriteria,
} from '@/features/create_study/create_study_slice';
import {
  setInclCriteria,
  setExclCriteria,
} from '@/features/flow_3/flow_3_slice';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../app/store';

type Props = {
  type: 'inclusion' | 'exclusion';
};

const CriteriaView = (props: Props) => {
  const { type } = props;
  const dispatch = useAppDispatch();
  const [input, setInput] = useState('');

  const items = useSelector((state: RootState) =>
    type === 'inclusion' ? state.flow3.inclCriteria : state.flow3.exclCriteria,
  );

  const [isExpanded, setIsExpanded] = useState(items.length > 0);

  const title = type === 'inclusion' ? 'Inclusions' : 'Exclusions';

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const removeCriterion = (index: number) => {
    const updatedItems = items.filter((_, i) => i !== index);
    if (type === 'inclusion') {
      dispatch(setInclCriteria(updatedItems)); // Dispatch updated string array
    } else {
      dispatch(setExclCriteria(updatedItems)); // Dispatch updated string array
    }
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' && input.trim()) {
      if (type === 'inclusion') {
        dispatch(setInclCriteria([...items, input.trim()])); // Add the new criterion to the string array
      } else {
        dispatch(setExclCriteria([...items, input.trim()])); // Add the new criterion to the string array
      }
      setInput('');
    }
  };

  return (
    <div className="rounded-lg p-4 text-white w-full">
      <div className="rounded-lg w-full border border-zinc-700 p-2">
        <div
          className={clsx(
            'flex items-center justify-between mt-0 pl-1',
            isExpanded && 'mb-2',
          )}
        >
          <div
            className="flex items-center cursor-pointer"
            onClick={() => items.length > 0 && setIsExpanded(!isExpanded)}
          >
            <button className="mr-2 focus:outline-none">
              <img
                src={chevronImage}
                className={clsx(
                  'w-4 h-4 inline-flex',
                  isExpanded ? 'transform rotate-180' : '',
                )}
                alt=""
              />
              {/* {isExpanded ? <ChevronUp size={20} /> : <ChevronDown size={20} />} */}
            </button>
            <h2 className="text-sm font-normal">{title}</h2>
            <span className="ml-2 bg-teal-500 text-[10px] font-bold px-2 py-1 rounded-full">
              {items.length}
            </span>
          </div>
          <button className="focus:outline-none">
            {/* <Plus size={20} /> */}
          </button>
        </div>
        {isExpanded && items.length > 0 && (
          <>
            <input
              type="text"
              placeholder={`Add ${title.toLowerCase()}...`}
              value={input}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              className="w-full bg-[#41414B] border-0 text-white placeholder-gray-400 px-3 py-2 rounded-md mb-2"
            />

            <ul className="list-none p-2 space-y-2 bg-zinc-700 rounded-md">
              {items.map((item, index) => (
                <li
                  key={index}
                  className="text-sm px-2 text-start flex flex-row"
                >
                  <div className="flex mt-2 mr-3 min-w-1.5 min-h-1.5 w-1.5 h-1.5 bg-teal-500 rounded-full border border-zinc-800" />
                  <div className="flex-1">{item}</div>
                  <button
                    onClick={() => removeCriterion(index)}
                    className="text-red-400 ml-3"
                  >
                    ×
                  </button>
                </li>
              ))}
            </ul>
          </>
          // <ul className="list-none p-2 space-y-2 bg-zinc-700 rounded-md">
          //   {items.map((item, index) => (
          //     <li key={index} className="text-sm px-2 text-start flex flex-row">
          //       <div className="flex mt-2 mr-3 min-w-1.5 min-h-1.5 w-1.5 h-1.5 bg-teal-500 rounded-full border border-zinc-800" />
          //       <div>{item.text}</div>
          //     </li>
          //   ))}
          // </ul>
        )}
      </div>
    </div>
  );
};

export default CriteriaView;
