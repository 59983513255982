import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseApi } from '../../api';

/**
 * Type representing the response for a list of country breakdowns.
 */
export type CountryBreakdownResponse = CountryBreakdown[];

/**
 * Interface representing the number of sites in a study for a given country
 */
interface CountryBreakdown {
  country: string;
  num_sites: number;
}

/**
 * Type for the query parameters used in getting country breakdown.
 */
type GetCountryBreakdownQueryParams = {
  nct_id: string;
};

/**
 * Defines the API endpoints for country breakdown data using baseApi.
 */
export const countryBreakdownApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Endpoint to get a list of country breakdowns based on query parameters.
     * @param {GetCountryBreakdownQueryParams} params - The query parameters.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getCountryBreakdown: builder.query<
      CountryBreakdownResponse,
      GetCountryBreakdownQueryParams
    >({
      query: (params: GetCountryBreakdownQueryParams): string | FetchArgs => {
        const { nct_id } = params;

        const queryParams: URLSearchParams = new URLSearchParams();

        queryParams.append('nct_id', nct_id);

        // Construct the full URL with query parameters
        return `study-country-breakdown?${queryParams}`;
      },
    }),
  }),
});

// Export the hook for querying studies
export const { useGetCountryBreakdownQuery } = countryBreakdownApi;
