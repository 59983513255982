import React from 'react';
import ReactPlayer from 'react-player';

const NeurouteLogo: React.FC = () => {
  // Increase font size by 20%
  const fontSize = '49px'; // 41px * 1.2 ≈ 49px
  const color = '#3B3944';

  // Increase circle size by 20%
  const circleSize = 26; // 22px * 1.2 ≈ 26px
  const videoSize = 43; // 36px * 1.2 ≈ 43px

  return (
    <div className="flex items-center justify-center pb-2">
      <span style={{ fontSize, color }}>neur</span>
      <div
        className="flex items-center justify-center relative"
        style={{
          position: 'relative',
          width: `${circleSize}px`,
          height: `${circleSize}px`,
          overflow: 'hidden',
          borderRadius: '50%',
          top: '7px', // Slightly increased to account for larger text
        }}
      >
        <ReactPlayer
          url="/assets/animated-ball.mp4"
          playing
          loop
          muted
          width={`${videoSize}px`}
          height={`${videoSize}px`}
          className="inline-block"
          style={{
            position: 'absolute',
            width: `${videoSize}px`,
            height: `${videoSize}px`,
            left: `-${(videoSize - circleSize) / 2}px`,
            top: `-${(videoSize - circleSize) / 2}px`,
          }}
        />
      </div>
      <span style={{ fontSize, color }}>ute</span>
    </div>
  );
};

export default NeurouteLogo;
