import RandView from '../RandView';
import {
  setSelectedSiteId,
  useSelectedScenario,
} from '../../../../features/enrollment_scenario/enrollment_scenario_slice';
import { Site } from '../../../../features/enrollment_scenario/models/site';
import { useAppDispatch } from '../../../../app/hooks';
import { FakeSites } from '../../../../features/enrollment_scenario/fake_data';

type ItemProps = {
  site: Site;
  title: string;
  icon?: string;
};

function Item({ icon, title, site }: ItemProps) {
  const dispatch = useAppDispatch();

  return (
    <div
      className="flex flex-row items-center hover:bg-gray-700 p-2 rounded cursor-pointer"
      onClick={() => dispatch(setSelectedSiteId(site.id))}
    >
      <img src={icon} className="h-6 w-6 mr-2" />
      <div className="flex flex-col items-start">
        <div className="text-sm font-medium text-start">{site.name}</div>
        <div className="inline-flex flex-row">
          <div
            className="inline-flex py-0.5 px-1 text-[10px] rounded-sm mr-2 whitespace-nowrap"
            style={{ backgroundColor: '#484550', color: '#9491A7' }}
          >
            {title}
          </div>
          <RandView value={0.75} />
        </div>
      </div>
    </div>
  );
}

export default function ActiveEnrollmentMetricsBar() {
  const selectedScenario = useSelectedScenario();

  return (
    <div className="hidden xl:flex flex-row mr-8 lg:mr-10 xl:mr-14 gap-x-8">
      <Item
        title="Lead Site"
        icon={selectedScenario?.color.mostLikelyIcon}
        site={FakeSites[0]}
      />
      <Item
        title="Fastest Site"
        icon={selectedScenario?.color.bestCaseIcon}
        site={FakeSites[1]}
      />
      <Item
        title="Slowest Site"
        icon={selectedScenario?.color.worstCaseIcon}
        site={FakeSites[2]}
      />
    </div>
  );
}
