import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseApi } from '../../api';

/**
 * Type representing the response for a list of ranked nct_ids.
 */
export type CriteriaToStudiesResponse = string[];

/**
 * Type for the query parameters used in getting ranked nct_ids.
 */
export type CriteriaToStudiesRequestBody = {
  research_id: string;
  inclusion_list: string[];
  exclusion_list: string[];
  isProd: boolean;
};

/**
 * Defines the API endpoints for criteria to studies data using baseApi.
 */
export const criteriaToStudiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Endpoint to post research data.
     * @param {CriteriaToStudiesRequestBody} body - The request body.
     * @returns {string | FetchArgs} The configuration for the POST request.
     */
    postCriteriaToStudies: builder.mutation<
      CriteriaToStudiesResponse,
      CriteriaToStudiesRequestBody
    >({
      query: (body: CriteriaToStudiesRequestBody): string | FetchArgs => {
        return {
          url: 'criteria_to_studies',
          method: 'POST',
          body,
        };
      },
    }),
  }),
});

// Export the hook for querying studies
export const { usePostCriteriaToStudiesMutation } = criteriaToStudiesApi;
