import { API_BASE_URL } from './config';
import { StudyInCondition, SiteMapSite } from '@/types/SiteMap';

// Response interfaces
interface GetSiteMapsParams {
  sponsor_names: string[];
  condition: string[];
  phase: string;
  relevance: string;
  intervention_type: string;
}

interface GetSiteMapsResponse {
  num_sites: number;
  num_countries: number;
  countries: string[];
  sites: SiteMapSite[];
}

// API function
export const fetchSiteMapsGetSites = async (
  params: GetSiteMapsParams,
): Promise<GetSiteMapsResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/site-maps/get-sites?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch site maps');
  }

  return response.json();
};

// Helper function to build query string (reused from brainstormApi)
const buildQueryString = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        const paramValue =
          key === 'condition' ? v.toLowerCase().replace(/[,;]/g, '').trim() : v;
        searchParams.append(key, paramValue);
      });
    } else if (value !== undefined) {
      const paramValue =
        key === 'condition'
          ? value.toLowerCase().replace(/[,;]/g, '').trim()
          : value;
      searchParams.append(key, paramValue);
    }
  });
  return searchParams.toString();
};

// Export types for use in components
export type { StudyInCondition, SiteMapSite, GetSiteMapsResponse };
