// initiativeApi.ts
import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseApi } from '@/api/api';
import { Initiative } from '@/types/index';

type GetInitiativeParams = {
  id: string;
};

type AddInitiativeParams = Partial<Initiative>;

type UpdateInitiativeParams = Partial<Initiative> & { id: string };

import { mockedInitiative } from '@/mocks/mockedInitiative';
const mockInitiatives: Initiative[] = [
  mockedInitiative,
  {
    ...mockedInitiative,
    id: '2',
    title: 'Second Initiative',
  },
];

export const initiativeApi = baseApi
  .enhanceEndpoints({
    addTagTypes: ['Initiative'],
  })
  .injectEndpoints({
    endpoints: (builder) => ({
      /**
       * Get an initiative by id (mocked version)
       */
      getInitiative: builder.query<Initiative, GetInitiativeParams>({
        queryFn: ({ id }) => {
          // Simulate API delay
          return new Promise((resolve) => {
            setTimeout(() => {
              const initiative = mockInitiatives.find((i) => i.id === id);
              if (initiative) {
                resolve({ data: initiative });
              } else {
                resolve({
                  error: { status: 404, data: 'Initiative not found' },
                });
              }
            }, 300); // Simulate 300ms delay
          });
        },
        providesTags: (result, error, arg) =>
          result ? [{ type: 'Initiative' as const, id: result.id }] : [],
      }),
      /**
       * Get an initiatives (mocked version)
       */
      /**
       * Get all initiatives (mocked version)
       */
      getInitiatives: builder.query<Initiative[], void>({
        queryFn: () => {
          // Simulate API delay
          return new Promise((resolve) => {
            setTimeout(() => {
              resolve({ data: mockInitiatives });
            }, 500); // Simulate 500ms delay
          });
        },
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Initiative' as const,
                  id,
                })),
                { type: 'Initiative' as const, id: 'LIST' },
              ]
            : [{ type: 'Initiative' as const, id: 'LIST' }],
      }),
      /**
       * Get an initiative by id
       */
      /*
      getInitiative: builder.query<Initiative, GetInitiativeParams>({
        query: ({ id }): string | FetchArgs => `initiatives/${id}`,
        providesTags: (result, error, arg) =>
          result ? [{ type: 'Initiative' as const, id: result.id }] : [],
      }),
      */

      /**
       * Get all initiatives
       */
      /*
      getInitiatives: builder.query<Initiative[], void>({
        query: (): string | FetchArgs => 'initiatives',
        providesTags: (result) =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: 'Initiative' as const,
                  id,
                })),
                { type: 'Initiative' as const, id: 'LIST' },
              ]
            : [{ type: 'Initiative' as const, id: 'LIST' }],
      }),
      */

      /**
       * Create an initiative
       */
      createInitiative: builder.mutation<Initiative, AddInitiativeParams>({
        query: (initiative): FetchArgs => ({
          url: 'initiatives',
          method: 'POST',
          body: initiative,
        }),
        invalidatesTags: [{ type: 'Initiative' as const, id: 'LIST' }],
      }),

      /**
       * Update an initiative by id
       */
      updateInitiative: builder.mutation<Initiative, UpdateInitiativeParams>({
        query: ({ id, ...initiative }): FetchArgs => ({
          url: `initiatives/${id}`,
          method: 'PATCH',
          body: initiative,
        }),
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: 'Initiative' as const, id: result.id }] : [],
      }),

      /**
       * Delete an initiative by id
       */
      deleteInitiative: builder.mutation<
        { success: boolean; id: string },
        string
      >({
        query: (id): FetchArgs => ({
          url: `initiatives/${id}`,
          method: 'DELETE',
        }),
        invalidatesTags: (result, error, arg) =>
          result ? [{ type: 'Initiative' as const, id: result.id }] : [],
      }),
    }),
  });

export const {
  useGetInitiativeQuery,
  useGetInitiativesQuery,
  useCreateInitiativeMutation,
  useUpdateInitiativeMutation,
  useDeleteInitiativeMutation,
} = initiativeApi;
