import { BackTrack } from '@/types/BackTrack';

const mockedAvailableBackTracks: BackTrack[] = [
  {
    id: 1,
    imageUrl:
      'https://hiphopgoldenage.com/wp-content/uploads/2018/10/Best-1980s-eighties-hip-hop-songs-1024x1024.jpg',
    title: 'Golden age of 80s',
    artist: 'Sean swadder',
    duration: '1:23.45',
  },
  {
    id: 2,
    imageUrl:
      'https://i.scdn.co/image/ab67616d0000b2731799998e3c1cb3af64bf59a4',
    title: 'Retro Synthwave',
    artist: 'Neon Riders',
    duration: '2:15.30',
  },
  {
    id: 3,
    imageUrl:
      'https://i.pinimg.com/originals/87/f9/52/87f9528eca0ffc1f75baa5713719d2ac.jpg',
    title: 'Funky Disco Nights',
    artist: 'Groovy Beats',
    duration: '3:05.20',
  },
  {
    id: 4,
    imageUrl:
      'https://m.media-amazon.com/images/I/91Ose76GL-L._UF894,1000_QL80_.jpg',
    title: 'Electronic Dreams',
    artist: 'Cyber Pulse',
    duration: '2:45.10',
  },
];

export { mockedAvailableBackTracks };
