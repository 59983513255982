import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { BASE_URL } from '../../../lib/constants';

export interface SiteData {
  id: string;
  name: string;
}

export interface SiteRandParams {
  condition: string[];
  relevance: string;
  phase: string;
  intervention_type: string;
  site_name: string;
}

export interface SiteRandResponse {
  site_rand: string;
}

export const siteApi = createApi({
  reducerPath: 'siteApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSiteById: builder.query<SiteData, string>({
      query: (id: string) => `site-by-id/?id=${id}`,
    }),
  }),
});

export const siteRandApi = createApi({
  reducerPath: 'siteRandApi',
  baseQuery: fetchBaseQuery({
    baseUrl: BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers) => {
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('Content-Type', 'application/json');
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getSiteRand: builder.query<SiteRandResponse, SiteRandParams>({
      query: (params) => ({
        url: 'routes/get-site-rand',
        params: {
          condition: [params.condition],
          relevance: params.relevance,
          phase: params.phase,
          intervention_type: params.intervention_type,
          site_name: params.site_name,
        },
        headers: {
          'Referrer-Policy': 'no-referrer-when-downgrade',
        },
      }),
    }),
  }),
});

export const { useGetSiteByIdQuery } = siteApi;
export const { useGetSiteRandQuery } = siteRandApi;
