import clsx from 'clsx';
import { useState } from 'react';
import backButtonImage from '../../../../images/back_button.svg';

type Props = {
  className?: string;
  onClick?: () => void;
};

export function BackButton(props: Props) {
  const { className, onClick } = props;
  const [isHovered, setIsHovered] = useState(false);

  return (
    <img
      src={backButtonImage}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
      className={clsx(
        'w-8 h-8 cursor-pointer',
        isHovered && 'opacity-85',
        className,
      )}
      alt=""
    />
  );
}
