import React, { useMemo, useState } from 'react';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Area,
  Bar,
  ComposedChart,
  Label,
} from 'recharts';
import { useAppSelector } from '../../../../app/hooks';
import { useChartSize } from './hooks/useChartSize';
import { useFakeChartData } from './hooks/useFakeChartData';
import CustomLabel from './CustomLabel';
import CustomTick from './CustomTick';
import CustomTooltip from './CustomTooltip';
import NoChartDataMessageView from './NoChartDataMessageView';

function Graph() {
  const data = useFakeChartData();
  const selectedScenarioId = useAppSelector(
    (s) => s.enrollmentScenario.selectedScenarioId,
  );
  const colors = useAppSelector((s) => s.enrollmentScenario.colors);
  const cohortSize = useAppSelector((s) => s.enrollmentScenario.cohortSize);
  const [isGridVisible, setIsGridVisible] = useState(false);
  const [isXAxisHovered, setIsXAxisHovered] = useState(false);

  const dataKeys = Object.keys(data?.length > 0 && data[0]).filter(
    (k) => k !== 'name',
  );

  const sortedDataKeys = useMemo(() => {
    if (!selectedScenarioId) return dataKeys;

    const activeScenarioIndex = dataKeys.findIndex(
      (d) => d === selectedScenarioId,
    );
    const sortedData = [...dataKeys];
    const [activeScenarioDataKey] = sortedData.splice(activeScenarioIndex, 1);
    sortedData.unshift(activeScenarioDataKey);

    return sortedData;
  }, [selectedScenarioId, dataKeys]);

  const { chartContainerRef, width, height } = useChartSize();
  const rightMargin = 30;
  const leftMargin = 20;

  const isChartAnimationEnabled = useAppSelector(
    (s) => s.enrollmentScenario.isChartAnimationEnabled,
  );

  interface CustomReferenceLineLabelProps {
    viewBox: {
      x: number;
      y: number;
      width: number;
      height: number;
    };
    title: string;
    value: string;
  }
  const CustomReferenceLineLabel: React.FC<CustomReferenceLineLabelProps> = ({
    viewBox,
    title,
    value,
  }) => <CustomLabel viewBox={viewBox} title={title} value={value} />;

  return (
    <div
      ref={chartContainerRef}
      className="w-full absolute top-0 left-0 right-0 bottom-0"
      style={{ height }}
      role="button"
      tabIndex={0}
      onMouseEnter={() => setIsGridVisible(true)}
      onMouseLeave={() => setIsGridVisible(false)}
      onKeyDown={(e) => {
        if (e.key === 'Enter' || e.key === ' ') {
          setIsGridVisible((prev) => !prev);
        }
      }}
    >
      <NoChartDataMessageView data={data} />
      <ComposedChart
        width={width}
        height={height}
        data={data}
        margin={{ top: 5, right: rightMargin, left: leftMargin, bottom: 5 }}
      >
        <CartesianGrid
          stroke={isGridVisible && data.length > 0 ? '#47464B' : 'transparent'}
          horizontal
          vertical
          opacity={0.5}
        />
        <XAxis
          dataKey="name"
          tickSize={0}
          tickMargin={12}
          tick={
            <CustomTick
              fill="#BFBDC9"
              showQuarters={isXAxisHovered}
              payload={false}
              x={0}
              y={0}
            />
          }
          onMouseEnter={() => setIsXAxisHovered(true)}
          onMouseLeave={() => setIsXAxisHovered(false)}
          scale="point"
        />
        <YAxis
          tickSize={0}
          tickMargin={12}
          axisLine={false}
          tick={{ fontSize: 10, fontVariant: 500, fill: 'white' }}
        >
          <Label
            value="Est. Patients Enrolled"
            angle={-90}
            position="insideLeft"
            style={{
              textAnchor: 'middle',
              fontSize: 12,
              fill: '#BFBDC9',
              letterSpacing: 0.5,
            }}
          />
        </YAxis>
        <Tooltip
          content={
            <CustomTooltip active coordinate={{ x: 0, y: 0 }} payload={null} />
          }
        />

        <Area
          type="linear"
          dataKey={sortedDataKeys[0] + '.mostLikely'}
          stroke={colors[0]['mostLikely']}
          fill={colors[0]['mostLikely']}
          fillOpacity={0.2}
          isAnimationActive={isChartAnimationEnabled}
        />
        <Bar
          dataKey={sortedDataKeys[0] + '.mostLikely'}
          fill={colors[0]['bestCase']}
          width={1}
          barSize={1}
          isAnimationActive={isChartAnimationEnabled}
        />
        <ReferenceLine
          y={cohortSize.target}
          stroke="#FFFFFF"
          strokeDasharray="2 2"
          opacity={0.5}
          label={(props) => (
            <CustomReferenceLineLabel
              viewBox={props.viewBox}
              title="Target"
              value={cohortSize.target.toString()}
            />
          )}
        />
        {/*<ReferenceLine*/}
        {/*  y={cohortSize.min}*/}
        {/*  stroke="#FFFFFF"*/}
        {/*  strokeDasharray={'2 2'}*/}
        {/*  opacity={0.5}*/}
        {/*  label={(props) => (*/}
        {/*    <CustomReferenceLineLabel*/}
        {/*      viewBox={props.viewBox}*/}
        {/*      title="Min"*/}
        {/*      value={cohortSize.min.toString()}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
      </ComposedChart>
    </div>
  );
}

export default Graph;
