import { Initiative } from '@/types/Initiative';

import { mockedStudyAuthor } from './mockedStudyAuthor';
import { mockedAvailableBackTracks } from './mockedAvailableBackTracks';
import { mockedStudyData } from './mockedStudyData';

import { Timestamp } from 'firebase/firestore';

const mockedInitiative: Initiative = {
  id: '1',
  userId: 'RZ7LEWylZQhaRUZ0PGvPhASPSVl2',
  title: 'Initiative Title',
  status: 'draft',
  author: mockedStudyAuthor,
  collaborators: 3,
  lastUpdated: Timestamp.fromDate(new Date('2024-08-21T12:00:00Z')),
  scenes: [],
  selectedVoice: 'Masculine',
  selectedBackTrack: mockedAvailableBackTracks[0],
  sectionTranscripts: ['Transcript 1', 'Transcript 2'],
  srt: mockedStudyData.srt,
  selectedStudies: [],
};

export { mockedInitiative };
