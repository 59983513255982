import { InputHTMLAttributes } from 'react';

type Props = {
  id: string;
  inputProps?: any;
  title: string;
  placeholder?: string;
  icon?: React.ReactNode;
  type?: InputHTMLAttributes<HTMLInputElement>['type'];
  autoComplete?: InputHTMLAttributes<HTMLInputElement>['autoComplete'];
  readOnly?: boolean;
  required?: boolean;
};

export default function Input(props: Props) {
  const {
    id,
    title,
    inputProps,
    placeholder,
    type,
    autoComplete,
    required,
    readOnly,
    icon,
  } = props;

  return (
    <div>
      <label htmlFor={id} className="block text-zinc-400 text-sm font-normal">
        {title}
      </label>
      <div className="mt-2 relative">
        <input
          id={id}
          {...inputProps}
          type={type}
          autoComplete={autoComplete}
          required={required}
          placeholder={placeholder}
          readOnly={readOnly}
          className="block w-full shadow-sm ring-inset ring-0 placeholder:text-zinc-400 focus:ring-2 focus:ring-inset focus:ring-primary sm:text-sm sm:leading-6 h-10 pr-3 pl-10 py-2 text-zinc-700 bg-gray-200 rounded border border-neutral-700"
        />
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {icon}
          </div>
        )}
      </div>
    </div>
  );
}
