import React from 'react';
import { convertToQuarter } from '@/utils/date';

interface CustomTickProps {
  x: number;
  y: number;
  payload: any;
  fill?: string;
  showQuarters: boolean;
}

const CustomTick: React.FC<CustomTickProps> = ({
  x,
  y,
  payload,
  fill = '#BFBDC9',
  showQuarters,
}) => {
  const displayValue = showQuarters
    ? convertToQuarter(payload.value)
    : payload.value;
  return (
    <text x={x} y={y + 10} textAnchor="middle" fill={fill} fontSize={11}>
      {displayValue}
    </text>
  );
};

export default CustomTick;
