type Props = {
  title: string;
  children: React.ReactNode;
};

export function ProjectLayout(props: Props) {
  const { title, children } = props;

  return (
    <div className="flex flex-col h-full w-full bg-black">
      <div className="absolute inset-2 rounded-2xl overflow-hidden bg-[#1F1F23]">
        <div className="z-10 flex flex-col w-full h-full">
          <div className="flex flex-col flex-grow w-full overflow-y-auto">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}
