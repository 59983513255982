import React, { useState, useEffect } from 'react';
import { Brainstorm } from '@/types/Brainstorm';
import SearchingSection from './SearchingSection';
import DescriptionBlock from './DescriptionBlock';

interface SearchAndDisplayProps {
  brainstorm: Brainstorm;
  setBrainstorm: (state: Brainstorm) => void;
  onNextStep: () => void;
  query?: () => Promise<void>;
  searchText: string;
  resultText?: string;
}

const SearchAndDisplay: React.FC<SearchAndDisplayProps> = ({
  brainstorm,
  setBrainstorm,
  onNextStep,
  query,
  searchText,
  resultText,
}) => {
  const [isSearching, setIsSearching] = useState(true);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    if (hasSearched) return;
    if (!query) {
      setIsSearching(false);
      setHasSearched(true);
      return;
    }

    const performSearch = async () => {
      try {
        await query();
        setIsSearching(false);
        setHasSearched(true);
        onNextStep();
      } catch (error) {
        console.error('Search failed:', error);
        setIsSearching(false);
        setHasSearched(true);
      }
    };

    performSearch();
  }, [hasSearched, query, onNextStep]);

  if (isSearching) {
    return <SearchingSection>{searchText}</SearchingSection>;
  }

  return resultText ? <DescriptionBlock>{resultText}</DescriptionBlock> : null;
};

export default SearchAndDisplay;
