import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import { auth } from '@/core/setup_firebase';
import { onAuthStateChanged } from 'firebase/auth';

import RoutesGraph from './graph/RoutesGraph';
import TimelineChart from './graph/TimelineChart';
import { SiteMapSite, TimelineInfo } from '@/types/SiteMap';
import { ScenarioColors } from '@/features/enrollment_scenario/colors_data';
import { useLoadBrainstorm } from '@/hooks/useLoadBrainstorm';
import { useLoadLibrary } from '@/hooks/useLoadLibrary';
import { useLoadSitemap } from '@/hooks/useLoadSitemap';
import { FirebaseDocumentId } from '@/types/KadenceBio';
import { useLoadSiteRand } from '@/hooks/useLoadSiteRand';

const isDebug = false;

/*
--------------------------------------------------------------------------------
Consolidated types
--------------------------------------------------------------------------------
*/

type SitesTab = 'all_sites' | 'saved_sites';
type ViewMode = 'model' | 'map' | 'table' | 'timeline';

type ScenarioColor = {
  mostLikely: string;
  mostLikelyIcon: string;

  bestCase: string;
  bestCaseIcon: string;

  worstCase: string;
  worstCaseIcon: string;
};

type ScenarioDataItem = {
  date: Date;
  value: number;
};

export interface GraphDataInterface {
  dates: {
    start: string | null;
    end: string | null;
  };

  scenarioName: string;
  scenarioData: ScenarioDataItem[];

  cohortSize: {
    min: number;
    target: number;
  };

  colors: ScenarioColor[];
  fullScreen: boolean;
  isChartAnimationEnabled: boolean;
  viewMode: ViewMode;
  sitesTab: SitesTab;
  isGridVisible: boolean;
}

/*
--------------------------------------------------------------------------------
Consolidated fake data
--------------------------------------------------------------------------------
*/

export type RouteData = {
  id: FirebaseDocumentId;
  userId: string;
  name: string;
  brainstormId: string;
  libraryId: string;
  sitemapId: string;
  sitemapSelectedSites: SiteMapSite[]; // all sites
  timing: string;
  actualPatients: number;
  targetPatients: number;
  allConvertedTimelineSites: TimelineInfo[]; // all sites from sitemapSelectedSites
  timelineSites: TimelineInfo[]; // all sites from sitemapSelectedSites
  selectedSites: string[];
  // graph
  graphData: GraphDataInterface;
};

const onlyDate = (date: Date) => {
  return new Date(date.setHours(0, 0, 0, 0));
};

const onlyDateString = (date: Date) => {
  return onlyDate(date).toISOString();
};

const convertSitemapSiteToTimelineInfo = (
  sitemapSite: SiteMapSite,
  timing: string,
): TimelineInfo => {
  return {
    name: sitemapSite.site_name,
    rand: sitemapSite.rand || 0, // this should come from the /routes/get-site-rand
    firstPatientStartDate: onlyDate(new Date()),
    firstPatientEndDate: onlyDate(new Date(timing)),
    isSelected: true,
  };
};
const initRoutesData: RouteData = {
  id: '',
  userId: '',
  name: '',
  brainstormId: '',
  libraryId: '',
  sitemapId: '',
  sitemapSelectedSites: [],
  timing: '2025-07-01',
  actualPatients: 0, // calculated
  targetPatients: 0,
  //
  allConvertedTimelineSites: [],
  timelineSites: [],
  selectedSites: [],
  // Graph Data
  graphData: {
    dates: {
      start: onlyDateString(new Date()),
      end: onlyDateString(new Date()),
    },
    scenarioName: 'Scenario 1',
    scenarioData: [],

    cohortSize: {
      min: 0,
      target: 0,
    },

    colors: ScenarioColors,
    fullScreen: false,
    isChartAnimationEnabled: true,
    viewMode: 'timeline',
    sitesTab: 'all_sites',
    isGridVisible: true,
  },
};

const RoutesCreateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  // loaders
  const { sitemap, loading: sitemapLoading, fetchSitemap } = useLoadSitemap();
  const { library, loading: libraryLoading, fetchLibrary } = useLoadLibrary();
  const {
    brainstorm,
    loading: brainstormLoading,
    fetchBrainstorm,
  } = useLoadBrainstorm();
  const [routeData, setRouteData] = useState<RouteData>(initRoutesData);
  const { fetchSiteRand, loading: siteRandLoading } = useLoadSiteRand();

  const updateGraphData = (newGraphData: Partial<GraphDataInterface>) => {
    setRouteData((prev) => ({
      ...prev,
      graphData: {
        ...prev.graphData,
        ...newGraphData,
      },
    }));
  };

  useEffect(() => {
    const newTimelineSites: TimelineInfo[] = [];
    routeData.sitemapSelectedSites.forEach((site) => {
      newTimelineSites.push(
        convertSitemapSiteToTimelineInfo(site, routeData.timing),
      );
    });
    setRouteData((prev) => ({
      ...prev,
      allConvertedTimelineSites: newTimelineSites,
      timelineSites: newTimelineSites,
      selectedSites: newTimelineSites.map((site) => site.name),
    }));
    console.log(
      'routeData.sitemapSelectedSites',
      routeData.sitemapSelectedSites,
    );
  }, [routeData.sitemapSelectedSites, routeData.timing]);

  // Auth check effect
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/login');
      }
    });
    return () => unsubscribe();
  }, [navigate]);

  // Sitemap loading effect
  useEffect(() => {
    if (!sitemapLoading) {
      const sitemapId = searchParams.get('sitemapId');
      if (sitemapId) {
        fetchSitemap(sitemapId);
      }
    }
  }, [searchParams]);

  // Update routeData from sitemap
  useEffect(() => {
    if (sitemap?.id) {
      setRouteData((prev) => ({
        ...prev,
        sitemapId: sitemap.id,
        brainstormId: sitemap.brainstormId,
        libraryId: sitemap.libraryId,
        sitemapSelectedSites: sitemap.selectedSites || [],
      }));
      fetchLibrary(sitemap.libraryId);
      fetchBrainstorm(sitemap.brainstormId);
    }
  }, [sitemap?.id]);

  // when sitemap, brainstorm and library is loaded
  useEffect(() => {
    if (sitemap?.id && library?.id && brainstorm?.id) {
      console.log('updateSiteRands', routeData.sitemapSelectedSites);
      const updateSiteRands = async () => {
        const updatedSites = await Promise.all(
          routeData.sitemapSelectedSites.map(async (site) => {
            const site_rand = await fetchSiteRand({
              condition: brainstorm.indication,
              intervention_type: brainstorm.interventionType,
              relevance: brainstorm.relevance,
              phase: brainstorm.phase,
              site_name: site.site_name,
            });
            console.log('Site', site.site_name, site_rand);
            return { ...site, rand: site_rand };
          }),
        );

        setRouteData((prev) => ({
          ...prev,
          sitemapSelectedSites: updatedSites,
        }));
      };

      updateSiteRands();
    }
  }, [sitemap?.id, library?.id, brainstorm?.id, fetchSiteRand]);

  // Update routeData from library
  useEffect(() => {
    if (library?.id) {
      setRouteData((prev) => ({
        ...prev,
        libraryId: library.id,
      }));
    }
  }, [library?.id]);

  // Update routeData from brainstorm
  useEffect(() => {
    if (brainstorm?.id) {
      setRouteData((prev) => ({
        ...prev,
        brainstormId: brainstorm.id,
        timing: brainstorm.timing,
        targetPatients: brainstorm.patientCount,
        actualPatients: 0,
        graphData: {
          ...prev.graphData,
          dates: {
            start: onlyDateString(new Date(brainstorm.targetStartDate)),
            end: onlyDateString(new Date(brainstorm.timing)),
          },
          cohortSize: { min: 0, target: brainstorm.patientCount },
        },
      }));
    }
  }, [brainstorm?.id]);

  const handleTimelineChartChange = useCallback(
    (updatedSites: TimelineInfo[]) => {
      setRouteData((prev) => ({
        ...prev,
        timelineSites: updatedSites.map((updatedSite) => {
          // Find matching site from allConvertedTimelineSites
          const originalSite = prev.allConvertedTimelineSites.find(
            (site) => site.name === updatedSite.name,
          );

          // If found, merge original site data with updated dates
          if (originalSite) {
            return {
              ...originalSite,
              firstPatientStartDate: updatedSite.firstPatientStartDate,
              firstPatientEndDate: updatedSite.firstPatientEndDate,
            };
          }

          // Fallback to updated site if no match found
          return updatedSite;
        }),
      }));
    },
    [], // no dependencies needed
  );

  // Add new useEffect to handle calculations when timelineSites changes
  useEffect(() => {
    // Filter timeline sites based on selected sites
    const filteredTimelineSites = routeData.timelineSites.filter((site) =>
      routeData.selectedSites.includes(site.name),
    );

    // no sites selected
    if (filteredTimelineSites.length === 0) {
      setRouteData((prev) => ({
        ...prev,
        actualPatients: 0,
        graphData: {
          ...prev.graphData,
          scenarioData: [],
          dates: { start: null, end: null },
        },
      }));
      return;
    }

    // Get date range
    const startDate = filteredTimelineSites.reduce((earliest, site) => {
      const siteStart = onlyDateString(site.firstPatientStartDate);
      return siteStart < earliest ? siteStart : earliest;
    }, onlyDateString(filteredTimelineSites[0].firstPatientStartDate));

    const endDate = filteredTimelineSites.reduce((latest, site) => {
      const siteEnd = onlyDateString(site.firstPatientEndDate);
      return siteEnd > latest ? siteEnd : latest;
    }, onlyDateString(filteredTimelineSites[0].firstPatientEndDate));

    // Initialize scenarioData with zeros
    const scenarioData: ScenarioDataItem[] = [];
    const perSiteData: { [key: string]: ScenarioDataItem[] } = {};

    // Initialize arrays
    for (
      let date = new Date(startDate);
      date <= new Date(endDate);
      date.setDate(date.getDate() + 1)
    ) {
      scenarioData.push({
        date: new Date(date),
        value: 0,
      });
    }

    // Calculate per-site data
    filteredTimelineSites.forEach((site) => {
      perSiteData[site.name] = [];

      for (
        let date = new Date(startDate);
        date <= new Date(endDate);
        date.setDate(date.getDate() + 1)
      ) {
        const currentDate = onlyDate(new Date(date));
        const siteStartDate = onlyDate(site.firstPatientStartDate);
        const siteEndDate = onlyDate(site.firstPatientEndDate);

        let value = 0;
        if (currentDate >= siteStartDate && currentDate <= siteEndDate) {
          value = site.rand / 30;
        }

        perSiteData[site.name].push({
          date: new Date(date),
          value: value,
        });
      }
    });

    // Sum up all site data into scenarioData with cumulative values
    let runningTotal = 0;
    scenarioData.forEach((_, index) => {
      const dailySum = Object.values(perSiteData).reduce(
        (sum, siteData) => sum + siteData[index].value,
        0,
      );
      runningTotal += dailySum;
      scenarioData[index].value = Math.floor(runningTotal);
    });

    // Update state
    setRouteData((prev) => ({
      ...prev,
      actualPatients: Math.floor(runningTotal),
      graphData: {
        ...prev.graphData,
        scenarioData: scenarioData,
        dates: { start: startDate, end: endDate },
      },
    }));
  }, [routeData.timelineSites, routeData.selectedSites]);

  // Render left column
  const renderLeftColumn = () => {
    const formattedDate = routeData.timing
      ? new Date(routeData.timing).toLocaleString('en-US', {
          month: 'long',
          year: 'numeric',
        })
      : 'December 2025';

    const toggleSite = (siteName: string) => {
      setRouteData((prev) => {
        const isSelected = prev.selectedSites.includes(siteName);

        if (isSelected) {
          // Remove site from selectedSites
          return {
            ...prev,
            selectedSites: prev.selectedSites.filter(
              (site) => site !== siteName,
            ),
          };
        } else {
          // Add site to selectedSites
          return {
            ...prev,
            selectedSites: [...prev.selectedSites, siteName],
          };
        }
      });
    };

    return (
      <div className="w-1/4 flex flex-col min-w-[200px] ml-10 align-top">
        <div className="p-6 pl-0 rounded-lg">
          <h1 className="text-4xl mb-4">
            <span className="text-[#9491A7]">
              This is how I will finish by{' '}
            </span>
            <span className="text-white">{formattedDate}</span>
          </h1>
        </div>

        <div className="flex mb-12 space-x-[10px]">
          <div>
            <div className="text-[#A9ABB2] text-sm mb-2">Actual Patients</div>
            <div className="text-4xl font-bold text-white">
              {routeData.actualPatients}
            </div>
          </div>
          <div>
            <div className="text-[#A9ABB2] text-sm mb-2">Target Patients</div>
            <div className="text-4xl font-bold text-white">
              {routeData.targetPatients}
            </div>
          </div>
        </div>
        <div className="p-6 pl-0 rounded-lg">
          <h2 className="text-[#9491a7] text-2xl mb-4">Selected Sites</h2>
          <div className="space-y-2 mt-3">
            {routeData.allConvertedTimelineSites.map((site) => {
              const isSelected = routeData.selectedSites.includes(site.name);
              return (
                <div
                  key={site.name}
                  className={`pl-2 cursor-pointer transition-colors ${
                    isSelected
                      ? 'text-white hover:text-[#E5E5EA]'
                      : 'text-[#9491a7] hover:text-[#6D6B7E]'
                  }`}
                  onClick={() => toggleSite(site.name)}
                >
                  {site.name}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  };

  // Render main section
  const renderMainSection = () => {
    return (
      <div className="overflow-y-auto mt-4 py-2 pr-0">
        <RoutesGraph
          graphData={routeData.graphData}
          onChange={updateGraphData}
        />

        {/* Timeline */}
        {routeData.selectedSites.length > 0 && (
          <TimelineChart
            key={JSON.stringify(routeData.timelineSites)}
            timelineSites={routeData.timelineSites.filter((site) =>
              routeData.selectedSites.includes(site.name),
            )}
            onChange={handleTimelineChartChange}
          />
        )}

        {/* Existing Data */}

        {isDebug && (
          <>
            <div className="mb-8">
              <h2 className="text-white text-xl mb-4">Dates</h2>
              <pre className="text-white bg-[#2A2A2E] p-4 rounded-lg overflow-x-auto">
                {JSON.stringify(routeData.graphData.dates, null, 2)}
              </pre>
            </div>
            <div className="mb-8">
              <h2 className="text-white text-xl mb-4">Timeline Sites Data</h2>
              <pre className="text-white bg-[#2A2A2E] p-4 rounded-lg overflow-x-auto">
                {JSON.stringify(routeData.timelineSites, null, 2)}
              </pre>
            </div>
            <div className="mb-8">
              <h2 className="text-white text-xl mb-4">routeData</h2>
              <pre className="text-white bg-[#2A2A2E] p-4 rounded-lg overflow-x-auto">
                {JSON.stringify(routeData, null, 2)}
              </pre>
            </div>
          </>
        )}
      </div>
    );
  };

  const loading = sitemapLoading || libraryLoading || brainstormLoading;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ProjectLayout title="KadenceBio">
      <main className="flex-grow flex flex-col p-0 min-h-screen relative">
        <div className="flex p-0 flex-row w-full">
          {renderLeftColumn()}

          <div className="flex flex-col w-3/4">{renderMainSection()}</div>
        </div>
      </main>
    </ProjectLayout>
  );
};

export default RoutesCreateEditPage;
