const studyMethods: string[] = [
  'Behavioral',
  'Biological',
  'Combination Product',
  'Device',
  'Diagnostic Test',
  'Dietary Supplement',
  'Drug',
  'Genetic',
  'Other',
  'Procedure',
  'Radiation',
];

// If you want to create a type from this array:
type StudyMethod = (typeof studyMethods)[number];

export { studyMethods };
export type { StudyMethod };
