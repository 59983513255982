import { Dialog, DialogPanel, DialogTitle } from '@headlessui/react';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSubscriptionDialogOpen } from '@/features/profile/profile_slice';

export default function SubscriptionModal() {
  const open = useAppSelector((s) => s.profile.isSubscriptionDialogOpen);
  const dispatch = useAppDispatch();

  const setOpen = (open: boolean) => {
    dispatch(setSubscriptionDialogOpen(open));
  };

  return (
    <Dialog open={open} onClose={setOpen} className="relative z-10">
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <DialogPanel className="relative transform overflow-hidden rounded-lg bg-[#2C2B32] px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-3xl sm:p-6">
            <div>
              <div className="mt-3 text-center sm:mt-5">
                <DialogTitle
                  as="h3"
                  className="text-2xl font-semibold leading-6 text-white"
                >
                  Subscribe
                </DialogTitle>
                <div className="mt-8 w-full flex flex-row justify-center">
                  <div className="flex flex-row mx-auto gap-x-8"></div>
                </div>
              </div>
            </div>
            <div className="mt-5 sm:mt-6">
              <button
                type="button"
                onClick={() => setOpen(false)}
                className="inline-flex w-full btn btn-outline btn-secondary"
              >
                Go back
              </button>
            </div>
          </DialogPanel>
        </div>
      </div>
    </Dialog>
  );
}
