import { Brainstorm } from '@/types/Brainstorm';
import NeurouteAnimated from '../components/NeurouteAnimated';
import { useState, useEffect } from 'react';

// Styled Elements (you might need to create these)
import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InnerText,
  InvisibleInput,
} from '../components/StyledElements';

// Import list of conditions
import { ConditionsList } from '@/types/ConditionsList';

// Central Section Component
const Step1Indication: React.FC<{
  brainstorm: Brainstorm;
  setBrainstorm: (state: Brainstorm) => void;
  onNextStep: () => void;
}> = ({ brainstorm, setBrainstorm, onNextStep }) => {
  const [suggestions, setSuggestions] = useState<string[]>([]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setBrainstorm({
      ...brainstorm,
      indicationFreeText: value,
      indication: capitalizedValue,
    });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleNextStep();
    }
  };

  const handleNextStep = () => {
    const value = brainstorm.indicationFreeText;
    const capitalizedValue = value.charAt(0).toUpperCase() + value.slice(1);
    setBrainstorm({
      ...brainstorm,
      indicationFreeText: value,
      indication: capitalizedValue,
    });
    onNextStep();
  };

  const handleSuggestionClick = (suggestion: string) => {
    setBrainstorm({
      ...brainstorm,
      indicationFreeText: suggestion,
      indication: suggestion,
    });
    onNextStep(); // Proceed to the next step after selecting a suggestion
  };

  useEffect(() => {
    const value = brainstorm.indicationFreeText.toLowerCase();
    // split the value by space to get words
    if (value.length > 0) {
      const words = value.split(' ');
      // all words must be in the condition item
      const matches = ConditionsList.filter((condition) =>
        words.every((word) => condition.toLowerCase().includes(word)),
      );
      setSuggestions(matches.slice(0, 3));
    } else {
      setSuggestions([]);
    }
  }, [brainstorm.indicationFreeText]);

  return (
    <div className="flex-grow flex flex-col items-center justify-center mt-[-60px] p-0 min-h-screen">
      <NeurouteAnimated />
      <InputLikeElement>
        <InnerText>I want to help patients with</InnerText>
        <InvisibleInput
          placeholder="Type here..."
          value={brainstorm.indicationFreeText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          autoFocus
        />
        <CircleButton onClick={handleNextStep}>
          <ArrowRightIcon />
        </CircleButton>
      </InputLikeElement>

      <div className="w-full relative">
        {brainstorm.indicationFreeText && (
          <div className="absolute top-full mt-2 w-full">
            {suggestions.length > 0 ? (
              <div className="flex flex-col">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="p-2 cursor-pointer text-center text-gray-500"
                    onClick={() => handleSuggestionClick(suggestion)}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            ) : (
              <div className="text-center text-gray-500">
                (Didn't quite catch that, try again)
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Step1Indication;
