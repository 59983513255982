import { API_BASE_URL } from './config';

// Enums matching the Python FastAPI enums
export enum RelevanceEnum {
  ALL_TIME = 'All Time',
  TEN_YEARS = '10 Years',
  FIVE_YEARS = '5 Years',
  THREE_YEARS = '3 Years',
  LAST_YEAR = 'Last Year',
}

export enum PhaseEnum {
  EARLY_PHASE1 = 'Early Phase 1',
  PHASE1 = 'Phase 1',
  PHASE1_PHASE2 = 'Phase 1/Phase 2',
  PHASE2 = 'Phase 2',
  PHASE2_PHASE3 = 'Phase 2/Phase 3',
  PHASE3 = 'Phase 3',
  PHASE3_PHASE4 = 'Phase 3/Phase 4',
  PHASE4 = 'Phase 4',
  NA = 'N/A',
}

export enum InterventionTypeEnum {
  BEHAVIORAL = 'Behavioral',
  BIOLOGICAL = 'Biological',
  COMBINATION_PRODUCT = 'Combination Product',
  DEVICE = 'Device',
  DIAGNOSTIC_TEST = 'Diagnostic Test',
  DIETARY_SUPPLEMENT = 'Dietary Supplement',
  DRUG = 'Drug',
  GENETIC = 'Genetic',
  OTHER = 'Other',
  PROCEDURE = 'Procedure',
  RADIATION = 'Radiation',
}

// Request parameter interfaces
export interface BaseQueryParams {
  condition: string[];
  relevance: RelevanceEnum | string;
  intervention_type: InterventionTypeEnum | string;
}

export interface PhaseQueryParams extends BaseQueryParams {
  phase: PhaseEnum | string;
}

// Response interfaces
export type InferInterventionTypeResponse = string;

export interface RelevanceTextResponse {
  relevance_text: string;
}

export interface NumStudiesInInterventionResponse {
  num_studies: number;
}

export type PhaseTextResponse = {
  phase_text: string;
  avg_num_patients: number;
};

export type CriteriaResponse = string[];

export interface AvgRandResponse {
  average_rand: number;
}

// Helper function to build query string
export const buildQueryString = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        const paramValue =
          key === 'condition' ? v.toLowerCase().replace(/[,;]/g, '').trim() : v;
        searchParams.append(key, paramValue);
      });
    } else if (value !== undefined) {
      const paramValue =
        key === 'condition'
          ? value.toLowerCase().replace(/[,;]/g, '').trim()
          : value;
      searchParams.append(key, paramValue);
    }
  });
  return searchParams.toString();
};

// API functions
export const fetchSimilarConditions = async (
  condition: string,
): Promise<string[]> => {
  const response = await fetch(
    `${API_BASE_URL}/similar-conditions?condition=${encodeURIComponent(condition)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch similar conditions');
  }

  return response.json();
};

export const fetchInferInterventionType = async (
  description: string,
): Promise<InferInterventionTypeResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/infer-intervention-type?description=${encodeURIComponent(description)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to infer intervention type');
  }

  return response.json();
};

export const fetchRelevanceText = async (
  params: BaseQueryParams,
): Promise<RelevanceTextResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/relevance-text?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch relevance text');
  }

  return response.json();
};

export const fetchNumStudiesInIntervention = async (
  params: Omit<BaseQueryParams, 'relevance'>,
): Promise<NumStudiesInInterventionResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/num-studies-in-intervention?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch number of studies');
  }

  return response.json();
};

export const fetchPhaseText = async (
  params: PhaseQueryParams,
): Promise<PhaseTextResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/phase-text?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    return {
      phase_text: 'No phase text available',
      avg_num_patients: 0,
    };
    // throw new Error('Failed to fetch phase text');
  }

  return response.json();
};

export const fetchInclusionCriteria = async (
  params: PhaseQueryParams,
): Promise<CriteriaResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/get-inclusion-criteria?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch inclusion criteria');
  }

  return response.json();
};

export const fetchExclusionCriteria = async (
  params: PhaseQueryParams,
): Promise<CriteriaResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/get-exclusion-criteria?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch exclusion criteria');
  }

  return response.json();
};

export const fetchAvgRand = async (
  params: PhaseQueryParams,
): Promise<AvgRandResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/brainstorm/get-avg-rand?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch average rand score');
  }

  return response.json();
};
