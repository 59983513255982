import React from 'react';
import { ReactComponent as AtomIcon } from '@/images/atom.svg';

interface MakeNewScienceButtonProps {
  label?: string;
  onClick?: () => void;
}

const MakeNewScienceButton: React.FC<MakeNewScienceButtonProps> = ({
  label = 'Make New Science',
  onClick,
}) => (
  <button
    className="flex items-center justify-center w-[261.6px] h-[56px] px-[22.4px] py-[11.2px] rounded-[30px] bg-[#00C7A8] text-[#101117] hover:opacity-90 transition-opacity"
    onClick={onClick}
  >
    <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em] mr-[11.2px]">
      {label}
    </span>
    <AtomIcon />
  </button>
);

export default MakeNewScienceButton;
