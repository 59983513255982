import React from 'react';
import {
  CountryBreakdownResponse,
  useGetCountryBreakdownQuery,
} from '@/api/slices/study/CountryBreakdownSlice';
import { StudyDetails } from '@/api/slices/types';
import arrowTopRightCircleIcon from '../../../images/arrow_top_right_circle.svg';

interface SelectedStudyProps {
  study: StudyDetails;
  toggleStudy: (nct_id: string) => void;
  openStudy: (nct_id: string) => void;
}

const SelectedStudy: React.FC<SelectedStudyProps> = ({
  study,
  toggleStudy,
  openStudy,
}) => {
  const { data: countryBreakdown, isLoading: isLoadingBreakdown } =
    useGetCountryBreakdownQuery({ nct_id: study.nct_id });

  const formatDate = (date?: string) => {
    const parts: string[] = date!.split('-');
    return `${parts[1]}/${parts[0]}`;
  };

  function calculateMonthsBetweenDates(startDate?: string, endDate?: string) {
    const start = new Date(startDate!);
    const end = new Date(endDate!);
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();
    const totalMonths = yearsDifference * 12 + monthsDifference;
    return `${totalMonths} month${totalMonths === 1 ? '' : 's'}`;
  }

  const formatCountryBreakdown = (breakdown?: CountryBreakdownResponse) => {
    if (!breakdown || breakdown.length === 0)
      return 'No country breakdown available';

    const totalSites = breakdown.reduce(
      (sum, country) => sum + country.num_sites,
      0,
    );

    // Create a copy of the array before sorting
    const sortedCountries = [...breakdown].sort(
      (a, b) => b.num_sites - a.num_sites,
    );

    if (totalSites === 1) {
      // If there's only one site, it will be in the first (and only) country
      return `1 site in ${sortedCountries[0].country}`;
    }

    const breakdownString = sortedCountries
      .map((country) => `${country.num_sites} ${country.country}`)
      .join(', ');

    return `${totalSites} Sites. ${breakdownString}.`;
  };

  return (
    <div key={study.nct_id} className="p-6 rounded-lg relative w-full">
      <img
        src={arrowTopRightCircleIcon}
        alt="Details"
        className="absolute top-4 right-4 w-6 h-6 cursor-pointer"
        onClick={(e) => {
          e.stopPropagation();
          openStudy(study.nct_id);
        }}
        role="button"
      />
      <div
        className="flex mb-4 cursor-pointer"
        onClick={() => toggleStudy(study.nct_id)}
        role="button"
      >
        <div className="w-2/3 pr-4 border-r border-[#4B4A55]">
          <h3 className="text-white text-[45px] font-normal leading-[60.75px] text-left line-clamp-2">
            {study.name}
          </h3>
        </div>
        <div className="w-1/3 pl-6 pt-4">
          <p className="text-white text-[18px] font-medium leading-[26px] text-left">
            {study.num_patients} patients in{' '}
            {calculateMonthsBetweenDates(study.start_date, study.end_date)}
          </p>
          <p className="text-white text-lg font-normal font-['Satoshi Variable'] leading-relaxed">
            {formatDate(study.start_date)} - {formatDate(study.end_date)}
          </p>
        </div>
      </div>
      {isLoadingBreakdown ? (
        <p className="text-gray-400 text-lg font-normal font-['Satoshi Variable'] leading-relaxed">
          Loading country breakdown...
        </p>
      ) : (
        <p className="text-white text-lg font-normal font-['Satoshi Variable'] leading-relaxed">
          {formatCountryBreakdown(countryBreakdown)}
        </p>
      )}
    </div>
  );
};

export default SelectedStudy;
