import { API_BASE_URL } from './config';
import { PhaseQueryParams } from './brainstormApi';
import { StudyDetails } from '@/types/Library';

// Response interfaces
interface CountryBreakdown {
  country: string;
  num_sites: number;
}

interface Sponsor {
  total_num_studies: number;
  sponsor_name: string;
  studies: StudyDetails[];
}

interface GetSponsorsResponse {
  sponsors: Sponsor[];
}

// Helper function to build query string (reused from brainstormApi)
const buildQueryString = (params: Record<string, any>): string => {
  const searchParams = new URLSearchParams();
  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach((v) => {
        const paramValue =
          key === 'condition' ? v.toLowerCase().replace(/[,;]/g, '').trim() : v;
        searchParams.append(key, paramValue);
      });
    } else if (value !== undefined) {
      const paramValue =
        key === 'condition'
          ? value.toLowerCase().replace(/[,;]/g, '').trim()
          : value;
      searchParams.append(key, paramValue);
    }
  });
  return searchParams.toString();
};

// API function
export const fetchSponsors = async (
  params: PhaseQueryParams,
): Promise<GetSponsorsResponse> => {
  const response = await fetch(
    `${API_BASE_URL}/libraries/get-sponsors?${buildQueryString(params)}`,
  );

  if (!response.ok) {
    throw new Error('Failed to fetch sponsors');
  }

  return response.json();
};

// Export types for use in components
export type { CountryBreakdown, StudyDetails, Sponsor, GetSponsorsResponse };
