// initiativeSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Initiative, Scene, BackTrack } from '@/types/index';

// Create the slice
export const initiativeSlice = createSlice({
  name: 'initiative',
  initialState: {} as Initiative,
  reducers: {
    setInitiative: (state, action: PayloadAction<Initiative>) => {
      return action.payload;
    },
    updateTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    },
    updateScenes: (state, action: PayloadAction<Scene[]>) => {
      state.scenes = action.payload;
    },
    updateVoice: (state, action: PayloadAction<'Masculine' | 'Feminine'>) => {
      state.selectedVoice = action.payload;
    },
    updateBackTrack: (state, action: PayloadAction<BackTrack>) => {
      state.selectedBackTrack = action.payload;
    },
    updateSectionTranscripts: (state, action: PayloadAction<string[]>) => {
      state.sectionTranscripts = action.payload;
    },
    updateCollaborators: (state, action: PayloadAction<number>) => {
      state.collaborators = action.payload;
    },
  },
});

export const {
  setInitiative,
  updateTitle,
  updateScenes,
  updateVoice,
  updateBackTrack,
  updateSectionTranscripts,
  updateCollaborators,
} = initiativeSlice.actions;

export default initiativeSlice.reducer;
