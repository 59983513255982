import React from 'react';
import ReactPlayer from 'react-player';

const NeurouteAnimatedSearching: React.FC = () => {
  return (
    <div
      style={{
        display: 'inline-block',
        position: 'relative',
        width: '22px', // Smaller mask radius
        height: '22px', // Smaller mask radius
        overflow: 'hidden',
        borderRadius: '50%',
        top: '6px',
        marginRight: '8px',
      }}
    >
      <ReactPlayer
        url="/assets/animated-ball.mp4"
        playing
        loop
        muted
        width="36px"
        height="36px"
        className="inline-block"
        style={{
          position: 'absolute',
          width: '36px',
          height: '36px',
          left: '-7px',
          top: '-7px',
        }}
      />
    </div>
  );
};

export default NeurouteAnimatedSearching;
