const StatusCell = ({ status }: { status: string }) => {
  let bgColor, textColor, borderColor, label;

  switch (status?.toLowerCase()) {
    case 'draft':
      bgColor = 'bg-yellow-100';
      textColor = 'text-yellow-800';
      borderColor = 'border-yellow-200';
      label = 'Draft';
      break;
    case 'published':
      bgColor = 'bg-green-100';
      textColor = 'text-green-800';
      borderColor = 'border-green-200';
      label = 'Published';
      break;
    default:
      bgColor = 'bg-gray-100';
      textColor = 'text-gray-800';
      borderColor = 'border-gray-200';
      label = 'Draft';
  }

  return (
    <span
      className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${bgColor} ${textColor} ${borderColor} border`}
    >
      {label}
    </span>
  );
};

export default StatusCell;
