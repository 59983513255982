import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useSearchParams } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import { auth, db } from '@/core/setup_firebase';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { SiteMapSite } from '@/types/SiteMap';
import { fetchSiteMapsGetSites } from '@/api/sitemapsApi';
import { useLoadBrainstorm } from '@/hooks/useLoadBrainstorm';
import { useLoadSitemap } from '@/hooks/useLoadSitemap';

import libraryIcon from '@/images/library_icon.svg';
import mapIcon from '@/images/map_icon.svg';
import SiteCard from './components/SiteCard';
import { useLoadLibrary } from '@/hooks/useLoadLibrary';

const isDebug = false;

const SiteMapCreateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const {
    sitemap: sitemap,
    loading: sitemapLoading,
    fetchSitemap,
    setSitemap,
  } = useLoadSitemap();
  const { library, loading: libraryLoading, fetchLibrary } = useLoadLibrary();
  const {
    brainstorm,
    loading: brainstormLoading,
    fetchBrainstorm,
  } = useLoadBrainstorm();
  const [loadingSites, setLoadingSites] = useState(false);

  // Add state for selected country
  const [selectedCountry, setSelectedCountry] = useState<string | 'all'>('all');

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (id && id !== 'create') {
          await fetchSitemap(id);
        } else {
          const libraryId = searchParams.get('libraryId');
          if (libraryId) {
            setSitemap((prev) => ({
              ...prev,
              libraryId,
            }));
          }
        }
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [id, navigate, searchParams]);

  // Update sitemap from library
  useEffect(() => {
    if (sitemap?.libraryId) {
      console.log('Fetching library', sitemap.libraryId);
      fetchLibrary(sitemap.libraryId);
    }
  }, [sitemap?.libraryId]);

  // Update sitemap from brainstorm
  useEffect(() => {
    if (library?.brainstormId) {
      console.log('Fetching brainstorm', library.brainstormId);
      fetchBrainstorm(library.brainstormId);
    }
  }, [library?.brainstormId]);

  // Add new useEffect to fetch sites when library and brainstorm are loaded
  useEffect(() => {
    if (brainstorm?.id && library?.id && library.selectedSponsors.length > 0) {
      console.log('Fetching sites', library.selectedSponsors.length);
      const fetchSites = async () => {
        setLoadingSites(true);
        try {
          const sites = await fetchSiteMapsGetSites({
            sponsor_names: library.selectedSponsors,
            condition: [brainstorm.indication],
            phase: brainstorm.phase,
            relevance: brainstorm.relevance,
            intervention_type: brainstorm.interventionType,
          });

          setSitemap((prev) => ({
            ...prev,
            libraryId: library.id,
            brainstormId: brainstorm.id,
            sites: sites.sites as SiteMapSite[],
          }));
        } catch (error) {
          console.error('Error fetching sites:', error);
        }
        setLoadingSites(false);
      };
      fetchSites();
    }
  }, [brainstorm?.id, library?.id, library.selectedSponsors.length]);

  const renderLeftColumn = () => {
    return (
      <div className="w-1/4 flex flex-col min-w-[200px] ml-10 align-top">
        <div className="p-6 rounded-lg">
          <div className="flex items-start space-x-3 mb-4">
            <div>
              <h3 className="text-[#9491a7] font-medium text-lg mb-1">
                All Relevant Sites Used By
              </h3>
              {library?.selectedSponsors.length === 0 ? (
                <p className="text-[#9491a7] text-sm italic">
                  Select 3 sponsors then press arrow to open map
                </p>
              ) : (
                <div className="space-y-2 mt-3">
                  {library?.selectedSponsors.map((sponsorName) => (
                    <div key={sponsorName} className="text-[#9491a7] pl-3">
                      {sponsorName}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMainSection = () => {
    // Add this check at the start of renderMainSection

    if (loadingSites) {
      return (
        <div className="overflow-y-auto max-h-[80vh] mt-4 py-2 pr-6">
          <div className="text-white text-4xl mb-4">Loading sites...</div>
        </div>
      );
    }

    const uniqueCountries = Array.from(
      new Set(sitemap.sites.map((site) => site.country)),
    ).sort();

    // Group and sort sites
    const groupedSites = sitemap.sites
      .sort((a, b) => {
        // First sort by country
        const countryCompare = a.country.localeCompare(b.country);
        // If same country, sort by site name
        return countryCompare !== 0
          ? countryCompare
          : a.site_name.localeCompare(b.site_name);
      })
      .reduce(
        (groups, site) => {
          const country = site.country;
          groups[country] = groups[country] || [];
          groups[country].push(site);
          return groups;
        },
        {} as Record<string, SiteMapSite[]>,
      );

    return (
      <div className="overflow-y-auto max-h-[80vh] mt-4 py-2 pr-6">
        <h1 className="text-white text-4xl mb-4">
          {sitemap.sites.length} Sites across {uniqueCountries.length} countries
        </h1>

        {sitemap.sites.length > 0 && (
          <div className="mb-8">
            <span
              className={`cursor-pointer ${
                selectedCountry === 'all' ? 'text-white' : 'text-[#9491a7]'
              }`}
              onClick={() => setSelectedCountry('all')}
            >
              All
            </span>
            {uniqueCountries.map((country, index) => (
              <React.Fragment key={country}>
                <span className="text-[#9491a7] mr-2">,</span>
                <span
                  className={`cursor-pointer ${
                    selectedCountry === country
                      ? 'text-white'
                      : 'text-[#9491a7]'
                  }`}
                  onClick={() => setSelectedCountry(country)}
                >
                  {country}
                </span>
              </React.Fragment>
            ))}
          </div>
        )}

        <div className="mb-8">
          <div className="space-y-8">
            {Object.entries(groupedSites)
              .filter(
                ([country]) =>
                  selectedCountry === 'all' || country === selectedCountry,
              )
              .map(([country, sites]) => (
                <div key={country} className="space-y-4">
                  <h3 className="text-[#9491a7] text-lg sp">{country}</h3>
                  {sites.map((site, index) => {
                    // Add test completed studies data
                    /*
                    site.completed_studies = [
                      {
                        name: 'Pharmacodynamic Study of TPN171H Tablets in Patients With Mild to Moderate Erectile Dysfunction',
                        completed_date: '2023-02-17',
                        num_patients: 84,
                      },
                      {
                        name: 'Efficacy and Safety of TPN171H in the Patients With Erectile Dysfunction',
                        completed_date: '2021-07-26',
                        num_patients: 255,
                      },
                    ];
                    */

                    return (
                      <SiteCard
                        key={index}
                        site={site}
                        onToggleSelect={handleSiteToggle}
                      />
                    );
                  })}
                </div>
              ))}
          </div>
        </div>

        {/* Existing Library Data and Connected Brainstorm sections */}
        {isDebug && (
          <>
            {sitemap && (
              <div>
                <h2 className="text-white text-xl mb-4">Site Map</h2>
                <pre className="text-white bg-[#2A2A2E] p-4 rounded-lg overflow-x-auto">
                  {JSON.stringify(sitemap, null, 2)}
                </pre>
              </div>
            )}

            {library && (
              <div>
                <h2 className="text-white text-xl mb-4">Connected Library</h2>
                <pre className="text-white bg-[#2A2A2E] p-4 rounded-lg overflow-x-auto">
                  {JSON.stringify(library, null, 2)}
                </pre>
              </div>
            )}

            {brainstorm && (
              <div>
                <h2 className="text-white text-xl mb-4">
                  Connected Brainstorm
                </h2>
                <pre className="text-white bg-[#2A2A2E] p-4 rounded-lg overflow-x-auto">
                  {JSON.stringify(brainstorm, null, 2)}
                </pre>
              </div>
            )}
          </>
        )}
      </div>
    );
  };

  const renderRightSection = () => {
    return (
      <div className="flex flex-col min-w-[200px]">
        <div className="p-6 rounded-lg">
          <h2 className="text-[#9491a7] text-2xl mb-6">Routes</h2>

          <div className="flex items-start space-x-3 mb-4">
            <div>
              <h3 className="text-[#9491a7] font-medium text-lg mb-1">
                Selected Sites
              </h3>

              {sitemap.selectedSites.length === 0 ? (
                <p className="text-[#9491a7] text-sm italic">
                  Select 3 sites then press Save Site Map
                </p>
              ) : (
                <div className="space-y-2 mt-3">
                  {sitemap.selectedSites.map((site, index) => (
                    <div
                      key={index}
                      className=" pl-3 text-[#9491a7] hover:text-white cursor-pointer transition-colors"
                      onClick={() => handleSiteToggle(site)}
                    >
                      {site.site_name}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handleSaveSiteMap = async (navigateTo?: string) => {
    try {
      const siteMapData = {
        ...sitemap,
        userId: auth.currentUser?.uid,
        updatedAt: new Date(),
      };
      let newSitemapId = id;

      if (id && id !== 'create') {
        // Edit mode: Update existing sitemap
        await setDoc(doc(db, 'sitemaps', id), siteMapData);
        console.log('Site map updated successfully');
      } else {
        // Create mode: Add new sitemap
        const newSiteMapRef = await addDoc(collection(db, 'sitemaps'), {
          ...siteMapData,
          createdAt: new Date(),
        });
        // Update id for navigation
        newSitemapId = newSiteMapRef.id;
      }

      // Navigate if destination provided
      if (navigateTo) {
        navigate(navigateTo + newSitemapId);
      } else if (!id || id === 'create') {
        // Default navigation for new sitemaps
        navigate(`/kadencebio/sitemap/edit/${newSitemapId}`);
      }
    } catch (error) {
      console.error('Error saving site map:', error);
    }
  };

  const handleSiteToggle = (site: SiteMapSite) => {
    setSitemap((prev) => {
      const isSelected = prev.selectedSites.some(
        (s) => s.site_name === site.site_name,
      );

      if (isSelected) {
        // Remove site if already selected
        return {
          ...prev,
          selectedSites: prev.selectedSites.filter(
            (s) => s.site_name !== site.site_name,
          ),
        };
      } else {
        // Add site if not selected
        return {
          ...prev,
          selectedSites: [...prev.selectedSites, site],
        };
      }
    });
  };

  const loading = sitemapLoading || libraryLoading || brainstormLoading;

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <ProjectLayout title="KadenceBio">
      <main className="flex-grow flex flex-col p-0 min-h-screen relative">
        {/* Header Row - Moved above columns */}
        <div className="w-full h-[100px] px-10 flex items-center gap-4">
          <button
            className="flex items-center gap-2 bg-[#2D2C36] text-white px-4 py-2 rounded-lg"
            onClick={() =>
              library?.id && navigate(`/kadencebio/library/edit/${library.id}`)
            }
          >
            <span className="text-[#9491a7]">
              <img
                src={libraryIcon}
                alt="Library"
                className="w-4 h-4 [filter:invert(60%)_sepia(11%)_saturate(709%)_hue-rotate(208deg)_brightness(87%)_contrast(86%)]"
              />
            </span>
            <span className="text-[#938EA4] font-medium text-[18px]">
              Back to Library
            </span>
          </button>
        </div>

        {/* Three Column Layout */}
        <div className="flex p-0 flex-row w-full">
          {/* Left Column */}
          {renderLeftColumn()}

          {/* Main Column */}
          <div className="flex flex-col w-2/4">{renderMainSection()}</div>

          {/* Right Column */}
          <div className="flex flex-col w-1/4">{renderRightSection()}</div>
        </div>

        {/* Save buttons */}
        {sitemap.selectedSites.length > 0 && (
          <div className="fixed bottom-8 right-8 flex flex-col gap-4">
            <button
              className="bg-[#00C7A8]/10 text-[#00C7A8] px-[22.4px] py-[11.2px] rounded-[30px] flex items-center gap-[11.2px] hover:bg-[#00C7A8]/20 transition-colors"
              onClick={() => handleSaveSiteMap()}
            >
              <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em]">
                Save Site Map
              </span>
              <img
                src={mapIcon}
                alt="Map"
                className="w-[24px] h-[24px] [filter:invert(72%)_sepia(40%)_saturate(463%)_hue-rotate(118deg)_brightness(95%)_contrast(101%)]"
              />
            </button>
            <button
              className="bg-[#00C7A8]/10 text-[#00C7A8] px-[22.4px] py-[11.2px] rounded-[30px] flex items-center gap-[11.2px] hover:bg-[#00C7A8]/20 transition-colors"
              onClick={() =>
                handleSaveSiteMap(`/kadencebio/route/create/?sitemapId=`)
              }
            >
              <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em]">
                Create Routes
              </span>
              <img
                src={mapIcon}
                alt="Map"
                className="w-[24px] h-[24px] [filter:invert(72%)_sepia(40%)_saturate(463%)_hue-rotate(118deg)_brightness(95%)_contrast(101%)]"
              />
            </button>
          </div>
        )}
      </main>
    </ProjectLayout>
  );
};

export default SiteMapCreateEditPage;
