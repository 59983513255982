import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NeurouteLogo from '@/components/NeurouteLogo';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '../auth/AuthContext';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';
import { Comment } from '@/types/Comment';
import { auth, db } from '@/core/setup_firebase';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  updateDoc,
  Timestamp,
  FieldValue,
  writeBatch,
  getDoc,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import sortIcon from '@/images/sorting_icon.svg';
import clsx from 'clsx';

type SortIconProps = {
  column: keyof CommentWithEmail;
  currentColumn: keyof CommentWithEmail;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}

interface CommentWithEmail extends Comment {
  userEmail: string;
}

const CommentManagementPage: React.FC = () => {
  const navigate = useNavigate();
  const [comments, setComments] = useState<CommentWithEmail[]>([]);
  const [user, setUser] = useState(auth.currentUser);
  const [sortColumn, setSortColumn] =
    useState<keyof CommentWithEmail>('content');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

  const { currentUser } = useAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        getFlaggedComments();
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const getFlaggedComments = async () => {
    try {
      const commentsRef = collection(db, 'comments');
      const q = query(commentsRef, where('isFlagged', '==', true));
      const querySnapshot = await getDocs(q);
      const commentList: CommentWithEmail[] = await Promise.all(
        querySnapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data() as Comment;
          const userRef = doc(db, 'users', data.userId);
          const userDoc = await getDoc(userRef);
          const userEmail = userDoc.exists()
            ? (userDoc.data() as { email: string }).email
            : 'Email not found';
          return { ...data, id: docSnapshot.id, userEmail } as CommentWithEmail;
        }),
      );
      setComments(commentList);
    } catch (error) {
      console.error('Error fetching flagged comments: ', error);
    }
  };

  const formatDate = (timestamp: Timestamp | FieldValue) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleString();
    } else if (
      timestamp &&
      typeof timestamp === 'object' &&
      'seconds' in timestamp
    ) {
      return new Date((timestamp as any).seconds * 1000).toLocaleString();
    }
    return 'Pending...';
  };

  const handleModerate = async (
    id: string,
    action: 'approved' | 'rejected' | 'pending' | 'archived',
  ) => {
    try {
      await updateDoc(doc(db, 'comments', id), {
        isFlagged: action,
        moderatedByUserId: user?.uid,
      });
      setComments(comments.filter((comment) => comment.id !== id));
      console.log(`Comment ${action} successfully`);
    } catch (error) {
      console.error(`Error ${action} comment:`, error);
    }
  };

  const handleArchiveAll = async () => {
    try {
      const batch = writeBatch(db);
      comments.forEach((comment) => {
        const commentRef = doc(db, 'comments', comment.id);
        batch.update(commentRef, {
          isFlagged: 'archived',
          moderatedByUserId: user?.uid,
        });
      });
      await batch.commit();
      setComments([]);
      console.log('All comments archived successfully');
    } catch (error) {
      console.error('Error archiving comments:', error);
    }
  };

  const handleSort = (column: keyof CommentWithEmail) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedComments = [...comments].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? 1 : -1;
    if (bValue == null) return sortDirection === 'asc' ? -1 : 1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (aValue instanceof Date && bValue instanceof Date) {
      return sortDirection === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    }

    return 0;
  });

  if (currentUser?.role) {
    if (!['super-admin', 'moderator'].includes(currentUser.role)) {
      return <>You don't have access to this page.</>;
    }
  }

  return (
    <div className="flex flex-col w-full bg-[#1F1F23] min-h-screen text-white">
      <div className="flex flex-row p-6">
        <div className="w-3/12 flex-shrink-0">
          <div className="h-16 flex items-center justify-between mb-4">
            <NeurouteLogo />
            <img
              src={ArrowLeftCircleIcon}
              alt="Back"
              className="w-8 h-8 cursor-pointer hover:opacity-80 transition-opacity"
              onClick={() => navigate('/')}
            />
          </div>
        </div>

        <main className="w-9/12 flex-shrink-0 pl-8 pt-2 flex flex-col">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-4xl font-semibold tracking-tight">
              Comment Management
            </h1>
            <div className="flex items-center gap-4">
              {comments.length > 0 && (
                <button
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded"
                  onClick={handleArchiveAll}
                >
                  Archive All
                </button>
              )}
              <div className="h-8 border-r border-zinc-700"></div>
              <HamburgerMenu />
            </div>
          </div>
          <div className="overflow-auto">
            {comments.length > 0 ? (
              <table className="min-w-full bg-[#1F1F23] text-gray-300">
                <thead>
                  <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('content')}
                    >
                      Content
                      <SortIcon
                        column="content"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th className="p-2 font-normal">Location</th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('userEmail')}
                    >
                      User Email
                      <SortIcon
                        column="userEmail"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('timestamp')}
                    >
                      Timestamp
                      <SortIcon
                        column="timestamp"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th
                      className="p-2 cursor-pointer font-normal"
                      onClick={() => handleSort('isFlagged')}
                    >
                      Status
                      <SortIcon
                        column="isFlagged"
                        currentColumn={sortColumn}
                        direction={sortDirection}
                      />
                    </th>
                    <th className="p-2 font-normal">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {sortedComments.map((comment) => (
                    <tr
                      key={comment.id}
                      className="border-b border-gray-700 text-start text-sm"
                    >
                      <td className="p-2">{comment.content}</td>
                      <td className="p-2">
                        <a
                          href={`${window.location.origin}/${comment.resource}`}
                          rel="noopener noreferrer"
                          className="text-blue-400 hover:text-blue-300 transition-colors duration-200"
                        >
                          View in context
                        </a>
                      </td>
                      <td className="p-2">{comment.userEmail}</td>
                      <td className="p-2">{formatDate(comment.timestamp)}</td>
                      <td className="p-2">{comment.isFlagged || 'Pending'}</td>
                      <td className="p-2">
                        <button
                          className="text-green-500 hover:bg-green-500 hover:text-white px-3 py-1 rounded-md transition-colors duration-200 mr-2"
                          onClick={() => handleModerate(comment.id, 'approved')}
                        >
                          Approve
                        </button>
                        <button
                          className="text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded-md transition-colors duration-200"
                          onClick={() => handleModerate(comment.id, 'rejected')}
                        >
                          Reject
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <div className="flex justify-center items-center h-64">
                <p className="text-2xl text-gray-400">
                  All good. Have a nice day.
                </p>
              </div>
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default CommentManagementPage;
