import { useState } from 'react';
import expandIcon from '../../../../images/expand.svg';
import clsx from 'clsx';
import { useAppDispatch, useAppSelector } from '../../../../app/hooks';
import { useSetFullScreen } from '@/features/enrollment_scenario/enrollment_scenario_slice';

type Props = {};

export default function FullscreenButton(props: Props) {
  const fullScreen = useAppSelector((s) => s.enrollmentScenario.fullScreen);
  const [isHovered, setIsHovered] = useState(false);
  const dispatch = useAppDispatch();
  const setFullScreen = useSetFullScreen();

  return (
    <div
      className={clsx(
        'p-1.5 absolute right-8 bottom-4 rounded-md',
        isHovered && 'opacity-70',
      )}
      style={{ backgroundColor: '#2D2C34' }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => setFullScreen(!fullScreen)}
    >
      <img src={expandIcon} className="h-6 w-6" />
    </div>
  );
}
