import KadenceBioSidebar from './KadenceBioSidebar';
interface ProjectLayoutProps {
  title: string;
  children: React.ReactNode;
}

export function ProjectLayout(props: ProjectLayoutProps) {
  const { title, children } = props;

  return (
    <div className="w-full h-screen bg-[#1f1f23] flex">
      <div className="z-10 flex flex-col w-full h-full">
        <KadenceBioSidebar />
        <div className="pl-[60px] pr-[30px] flex flex-col flex-grow w-full overflow-y-auto">
          {children}
        </div>
      </div>
    </div>
  );
}
