import React from 'react';

interface CustomTooltipProps {
  active: boolean;
  payload: any;
  coordinate: { x: number; y: number };
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  coordinate,
}) => {
  if (active && payload?.length) {
    const { x, y } = coordinate;
    return (
      <div>
        <div
          style={{
            position: 'absolute',
            top: y - 60,
            left: x,
            backgroundColor: '#2f2f2f',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            textAlign: 'center',
            transform: 'translate(-50%, -100%)',
          }}
        >
          {`${payload[0].value} Patients`}
          <div
            style={{
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              marginLeft: '-10px',
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid #2f2f2f',
            }}
          />
        </div>
        <svg
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          <line
            x1={x}
            y1={y}
            x2={x}
            y2="100%"
            stroke="#7CFC00"
            strokeWidth="2"
          />
          <rect
            x={x - 5}
            y={y - 5}
            width="10"
            height="10"
            fill="#7CFC00"
            stroke="black"
            strokeWidth="1"
          />
        </svg>
      </div>
    );
  }

  return null;
};

export default CustomTooltip;
