import clsx from 'clsx';
import { HTMLInputTypeAttribute } from 'react';

type Props = {
  id: string;
  value: string | number;
  type?: HTMLInputTypeAttribute;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: string;
  placeholder?: string;
  required?: boolean;
  min?: number;
  max?: number;
  className?: string;
  inputClassName?: string;
};

export function FormField(props: Props) {
  const {
    id,
    value,
    onChange,
    label,
    type = 'text',
    placeholder,
    required,
    min,
    max,
    className,
  } = props;

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className="block mb-1 text-[#b8b9c1] text-xs font-medium"
        >
          {label}
        </label>
      )}
      <input
        id={id}
        type={type}
        className={clsx(
          'input w-full text-white bg-[#41414b] h-10',
          props.inputClassName,
        )}
        value={value}
        onChange={onChange}
        required={required}
        min={min}
        max={max}
        placeholder={placeholder}
      />
    </div>
  );
}
