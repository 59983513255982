import NeurouteAnimated from '../flow-3-wireframes/components/NeurouteAnimated';

const WaitingForCollision: React.FC = () => {
  return (
    <div className="w-full h-full self-center align-middle content-center">
      <NeurouteAnimated />
    </div>
  );
};

export default WaitingForCollision;
