import React, { useState } from 'react';
import InputLikeElement from '@/components/InputLikeElement';
import InvisibleInput from './InvisibleInput';
import CircleButton from './CircleButton';
import { useAuthor } from '@/core/hooks/useAuthor';

// Import SVG as React components
import { ReactComponent as CommentsDownVote } from '@/images/comments-downvote.svg';
import { ReactComponent as CommentsFlagIt } from '@/images/comments-right-bullet.svg';
import { ReactComponent as CommentsReply } from '@/images/comments-reply.svg';
import { ReactComponent as CommentsUpvote } from '@/images/comments-upvote.svg';

import { Comment } from '@/types/index';
import { Timestamp } from 'firebase/firestore';
import AuthorAvatar from '@/components/AuthorAvatar';

interface CommentCardProps {
  comment: Comment;
  onUpvote: (id: string) => void;
  onDownvote: (id: string) => void;
  onReply: (content: string) => void;
  onFlagComment: (id: string) => void;
  currentUserId: string;
  replies: Comment[];
  renderReplies: (comment: Comment) => React.ReactNode;
}

const CommentCard: React.FC<CommentCardProps> = ({
  comment,
  onUpvote,
  onDownvote,
  onReply,
  onFlagComment,
  currentUserId,
  replies,
  renderReplies,
}) => {
  const { author } = useAuthor(comment.userId);
  const [showReplyInput, setShowReplyInput] = useState(false);
  const [replyContent, setReplyContent] = useState('');

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      if (e.shiftKey) {
        // SHIFT+ENTER: submit the reply
        e.preventDefault();
        handleReplySubmit();
      } else {
        // ENTER without SHIFT: add a newline
        setReplyContent((prev) => prev + '\n');
      }
    } else if (e.key === 'Escape') {
      handleReplyCancel();
    }
  };

  const handleReplySubmit = () => {
    if (replyContent.trim()) {
      onReply(replyContent);
      setReplyContent('');
    }
    setShowReplyInput(false);
  };

  const handleReplyCancel = () => {
    setReplyContent('');
    setShowReplyInput(false);
  };

  const numberOfVotes = comment.voterIds.length;

  const formatTimestamp = (timestamp: Timestamp): string => {
    return timestamp.toDate().toLocaleString();
  };

  if (!author) return null;

  const isCommentVisible =
    !comment.isFlagged || comment.isFlagged === 'approved';

  const renderCommentContent = () => {
    if (comment?.isFlagged === 'rejected') {
      return (
        <p className="text-white text-[18px] mb-2 italic opacity-60">
          This comment has been removed by a moderator.
        </p>
      );
    } else if (!isCommentVisible) {
      return (
        <p className="text-white text-[18px] mb-2 italic opacity-60">
          This comment is awaiting moderation.
        </p>
      );
    } else {
      return <p className="text-white text-[18px] mb-2">{comment.content}</p>;
    }
  };

  // do not render the comment if it is rejected
  if (comment?.isFlagged === 'rejected') {
    return null;
  }

  return (
    <div className="mb-4">
      <div className="flex space-x-4">
        {/* Left column: User avatar */}
        <div className="flex-shrink-0">
          <AuthorAvatar author={author} styleVariant="simple" />
        </div>

        {/* Middle column: Voting, content, and author */}
        <div className="flex-grow">
          {/* Voting row */}
          <div className="flex items-center space-x-2 mb-2">
            <button
              onClick={() => onUpvote(comment.id)}
              className="group"
              disabled={!isCommentVisible}
            >
              <CommentsUpvote
                className={`w-2.5 h-2.5 ${!isCommentVisible ? 'fill-gray-600' : 'fill-gray-400 group-hover:fill-white'} transition-colors duration-200`}
              />
            </button>
            <span className="text-white opacity-60 text-[13px]">
              {numberOfVotes}
            </span>
            <button
              onClick={() => onDownvote(comment.id)}
              className="group"
              disabled={!isCommentVisible}
            >
              <CommentsDownVote
                className={`w-2.5 h-2.5 ${!isCommentVisible ? 'fill-gray-600' : 'fill-gray-400 group-hover:fill-white'} transition-colors duration-200`}
              />
            </button>
          </div>

          {/* Comment content */}
          {renderCommentContent()}

          {/* Author name and timestamp */}
          <p className="text-[12px] text-white opacity-60 mb-2">
            {`${author.displayName} • ${formatTimestamp(comment.timestamp as Timestamp)}`}
          </p>
        </div>

        {/* Right column: Action icons */}
        <div className="flex items-start pt-9 space-x-1">
          {isCommentVisible && (
            <button onClick={() => setShowReplyInput(true)} className="group">
              <CommentsReply className="w-3 h-3 mt-[3px] fill-gray-400 group-hover:fill-white transition-colors duration-200" />
            </button>
          )}
        </div>

        {/* Far right column: Circle icon */}
        <div className="flex items-start pt-9">
          <button className="group" disabled={!isCommentVisible}>
            <CommentsFlagIt
              onClick={() => {
                if (isCommentVisible) {
                  onFlagComment(comment.id);
                }
              }}
              className={`w-5 h-5 ${!isCommentVisible ? 'fill-gray-600' : 'fill-gray-400 group-hover:fill-white'} transition-colors duration-200`}
            />
          </button>
        </div>
      </div>

      {/* Reply input */}
      {showReplyInput && isCommentVisible && (
        <div className="mt-2 ml-14">
          <InputLikeElement>
            <InvisibleInput
              value={replyContent}
              placeholder="Write a reply..."
              onChange={(e) => setReplyContent(e.target.value)}
              onKeyDown={handleKeyDown}
              autoFocus={true}
            />
            <CircleButton onClick={handleReplySubmit} title="Submit reply">
              <CommentsReply className="w-4 h-4" />
            </CircleButton>
          </InputLikeElement>
        </div>
      )}

      {/* Nested replies */}
      {replies.length > 0 && (
        <div className="mt-4 ml-14">{replies.map(renderReplies)}</div>
      )}
    </div>
  );
};

export default CommentCard;
