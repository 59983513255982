import React from 'react';
import { Brainstorm } from '@/types/Brainstorm';
import { StepSectionProps } from '../stepSections/StepSectionProps';

const ExclusionCriteriaListSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const handleCriteriaClick = (criteria: string) => {
    const updatedCriteria = brainstorm.exclusionCriteria?.includes(criteria)
      ? brainstorm.exclusionCriteria.filter((item) => item !== criteria)
      : [...(brainstorm.exclusionCriteria || []), criteria];

    setBrainstorm({
      ...brainstorm,
      exclusionCriteria: updatedCriteria,
    });
  };

  return (
    <div className="mb-8">
      <h2 className="text-white text-2xl font-medium mb-4">
        Exclusion Criteria
      </h2>
      <p className="text-[#9491a7] mb-4">
        All E/C across relevant studies related to {brainstorm.interventionType}
      </p>
      <ul className="list-disc pl-6">
        {brainstorm.availableExclusionCriteria?.map((criteria, index) => (
          <li
            key={index}
            className={`text-[#9491a7] mb-2 cursor-pointer ${
              brainstorm.exclusionCriteria?.includes(criteria)
                ? 'bg-[#D9D9D9] bg-opacity-10'
                : ''
            }`}
            onClick={() => handleCriteriaClick(criteria)}
          >
            {criteria}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ExclusionCriteriaListSection;
