import boxIcon from '../../../../images/white_box.svg';
import clsx from 'clsx';

type Props = {
  name: string;
  isSelected: boolean;
  hideIcon?: boolean;
  color: string;
  onClick?: () => void;
};

export default function ScenarioItem(props: Props) {
  const icon = props.hideIcon ? null : props.isSelected ? ( //
    <img src={boxIcon} alt="Selected" className="w-3 h-3" />
  ) : (
    <div
      className="w-2 h-2 rounded-full"
      style={{ backgroundColor: props.color }}
    />
  );

  return (
    <div
      className={clsx(
        'flex flex-row items-center py-2 px-7',
        props.onClick && 'cursor-pointer',
      )}
      onClick={props.onClick}
    >
      <div className="w-6">{icon}</div>
      <div
        className={clsx(
          'text-white text-sm',
          props.onClick && 'hover:font-semibold',
          props.isSelected && 'font-semibold',
        )}
      >
        {props.name}
      </div>
    </div>
  );
}
