import { Section } from '@/types/Section';

const mockedSections: Section[] = [
  {
    label: 'Why',
    text: 'Why is it important?',
    startTime: 0,
    endTime: 12000,
  },
  {
    label: 'What',
    text: "What's your science?",
    startTime: 12000,
    endTime: 24000,
  },
  {
    label: 'Know',
    text: 'What should you know?',
    startTime: 24000,
    endTime: 36000,
  },
  {
    label: 'When',
    text: 'When does it apply?',
    startTime: 36000,
    endTime: 48000,
  },
  {
    label: 'Will',
    text: 'Will it make a difference?',
    startTime: 48000,
    endTime: 60000,
  },
];

export { mockedSections };
