export function convertToQuarter(dateString: string) {
  const [month, year] = dateString.split('/').map(Number);
  const quarter = Math.ceil(month / 3);
  return `Q${quarter} 20${year}`;
}

export function convertMMYYToDate(quarter: string): Date {
  const [month, year] = quarter.split('/');
  return new Date(`20${year}-${month}-01`);
}

export const parseMMYYDate = (value: string | null) => {
  try {
    return value ? new Date(value).toISOString() : null;
  } catch (e) {
    return null;
  }
};

export const convertDateToMMYY = (dateValue: string | null) => {
  const date = dateValue ? new Date(dateValue) : null;
  const string = date
    ? `${date.getMonth() + 1}/${date.getFullYear().toString().slice(-2)}`
    : undefined;
  return string;
};
