import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import InputStep from '../sections/InputStep';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import { fetchInclusionCriteria } from '@/api/brainstormApi';

const InclusionCriteriaSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const handleCriteriaClick = (criteria: string) => {
    const updatedCriteria = brainstorm.inclusionCriteria?.includes(criteria)
      ? brainstorm.inclusionCriteria.filter((item) => item !== criteria)
      : [...(brainstorm.inclusionCriteria || []), criteria];

    setBrainstorm({
      ...brainstorm,
      inclusionCriteria: updatedCriteria,
    });
  };

  return (
    <>
      {isSection('inclusionCriteria') && (
        <HeaderTitle prefix="I am helping" value="" className="mt-14" />
      )}

      {isCurrentStep('inclusionCriteria.search') && (
        <SearchAndDisplay
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          query={async () => {
            const data = await fetchInclusionCriteria({
              condition: [brainstorm.indication],
              relevance: brainstorm.relevance,
              phase: brainstorm.phase,
              intervention_type: brainstorm.interventionType,
            });
            console.log(data);
            if (data) {
              setBrainstorm({
                ...brainstorm,
                availableInclusionCriteria: data,
              });
            }
          }}
          searchText="searching for relevant inclusion criteria"
          onNextStep={() => nextStep('inclusionCriteria.correct')}
        />
      )}

      {(isCurrentStep('inclusionCriteria.edit') ||
        isCurrentStep('inclusionCriteria.correct')) && (
        <>
          <DescriptionBlock>
            I am selecting the following criteria related to patient profile:
            <ul className="list-disc pl-6 space-y-2">
              {brainstorm.inclusionCriteria &&
              brainstorm.inclusionCriteria.length > 0 ? (
                brainstorm.inclusionCriteria.map((criteria, index) => (
                  <li
                    key={index}
                    onClick={() => handleCriteriaClick(criteria)}
                    className="cursor-pointer hover:text-red-400 transition-colors"
                  >
                    {criteria}
                  </li>
                ))
              ) : (
                <li className="text-gray-500 italic">
                  No inclusion criteria selected yet. You can select criteria
                  from the list on the right.
                </li>
              )}
            </ul>
          </DescriptionBlock>
        </>
      )}

      {(isCurrentStep('inclusionCriteria.edit') ||
        isCurrentStep('inclusionCriteria.correct')) && (
        <>
          <CorrectMeBlock
            onClick={() => {
              setCurrentStep('inclusionCriteria.edit');
            }}
            text="Modify inclusion criteria"
          />
          <ThatLooksRightToMe
            onNextStep={() =>
              nextStep('exclusionCriteria.search', 'inclusionCriteria')
            }
          />
        </>
      )}

      {hasStep('inclusionCriteria') && (
        <>
          <DescriptionBlock>
            You selected the following inclusion criteria
            <ul className="list-disc pl-6 space-y-2">
              {brainstorm.inclusionCriteria &&
              brainstorm.inclusionCriteria.length > 0 ? (
                brainstorm.inclusionCriteria.map((criteria, index) => (
                  <li key={index}>{criteria}</li>
                ))
              ) : (
                <li className="text-gray-500 italic">
                  No inclusion criteria selected.
                </li>
              )}
            </ul>
          </DescriptionBlock>
        </>
      )}
    </>
  );
};

export default InclusionCriteriaSection;
