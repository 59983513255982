import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import calendarIcon from '@/images/calendar.svg';
import CalendarInput from '@/pages/kadencebio/components/CalendarInput';
import { parseMMYYDate, convertDateToMMYY } from '@/utils/date';
import { fetchAvgRand } from '@/api/brainstormApi';

const TimingSection: React.FC<
  StepSectionProps & { saveBrainstorm: () => Promise<void> }
> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  nextStep,
  setCurrentStep,
  saveBrainstorm,
}) => {
  // if timing is set as date, calculate the number of months from now until timing date in the future
  const numberOfMonths = brainstorm.timing
    ? Math.ceil(
        (new Date(brainstorm.timing).getTime() - new Date().getTime()) /
          (1000 * 60 * 60 * 24 * 30),
      )
    : 0;

  const numberOfPatients = brainstorm.patientCount;
  const numberOfSites = Math.ceil(
    numberOfPatients / (numberOfMonths * brainstorm.average_rand),
  );

  return (
    <>
      {isSection('timing') && (
        <HeaderTitle
          prefix="I need to finish this by"
          value={convertDateToMMYY(brainstorm.timing)}
          className="mt-14"
        />
      )}

      {isCurrentStep('timing.edit') && (
        <div className="mt-4">
          <CalendarInput
            id="timing-input"
            icon={calendarIcon}
            name="timing"
            placeholder="MM/YY"
            type="date"
            value={convertDateToMMYY(brainstorm.timing)}
            minDate={new Date()}
            maxDate={new Date('2030-12-31')}
            onChange={(e) => {
              const date = parseMMYYDate(e.target.value || null) || '';
              setBrainstorm({ ...brainstorm, timing: date });
              nextStep('timing.search');
            }}
          />
        </div>
      )}

      {isCurrentStep('timing.search') && (
        <SearchAndDisplay
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          query={async () => {
            const data = await fetchAvgRand({
              condition: [brainstorm.indication],
              relevance: brainstorm.relevance,
              phase: brainstorm.phase,
              intervention_type: brainstorm.interventionType,
            });
            if (data?.average_rand) {
              setBrainstorm({
                ...brainstorm,
                average_rand: data.average_rand,
              });
            }
          }}
          searchText="searching the timelines of these studies"
          onNextStep={() => nextStep('timing.correct')}
        />
      )}

      {isCurrentStep('timing.correct') && (
        <>
          <DescriptionBlock>
            To finish within the next {numberOfMonths} months without delay,
            realistically, you will need to recruit {numberOfPatients} patients
            within {numberOfMonths} months. If we take the average RAND rate of{' '}
            {brainstorm.average_rand} patients per site per month, that means
            you will need{' '}
            <span className="text-white">at least {numberOfSites} sites</span>{' '}
            for this study.
          </DescriptionBlock>
          <CorrectMeBlock
            text="if I'm wrong about your timeline."
            onClick={() => {
              setCurrentStep('timing.edit');
            }}
          />
          <ThatLooksRightToMe
            onNextStep={async () => {
              await saveBrainstorm();
              nextStep('brainstorm.done', 'timing');
            }}
          />
        </>
      )}
    </>
  );
};

export default TimingSection;
