import React, { PropsWithChildren } from 'react';

interface CommentInputWrapperProps {
  className?: string;
}

const CommentInputWrapper: React.FC<
  PropsWithChildren<CommentInputWrapperProps>
> = ({ children, className = '' }) => {
  return (
    <div
      className={`
      py-0.5
      pl-0
      pr-2
      flex
      items-start
      gap-2
      rounded-full
      ${className}
    `}
    >
      {children}
    </div>
  );
};

export default CommentInputWrapper;
