import outlookImage from '../../images/outlook.png';
import * as z from 'zod';
import {
  browserLocalPersistence,
  browserSessionPersistence,
  createUserWithEmailAndPassword,
  getAuth,
  setPersistence,
  updateProfile,
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { getApp } from 'firebase/app';
import ActionButton from '../../components/buttons/ActionButton';
import { useNavigate } from 'react-router-dom';
import AuthLayout from './components/AuthLayout';
import logoBrandImg from '../../images/logo-brand.svg';
import Input from './components/Input';
import emailSvg from '../../images/email.svg';
import lockSvg from '../../images/lock.svg';
import permIdentitySvg from '../../images/perm_identity.svg';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../../core/setup_firebase';

const schema = z
  .object({
    name: z.string().min(3).max(100),
    email: z.string().email(),
    password: z.string().min(8).max(100),
    isAgree: z.boolean().refine((value) => value === true, {
      message: 'You must agree to the terms and conditions.',
    }),
  })
  .required();

function SignUpForm() {
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, errors },
    setError,
  } = useForm({
    resolver: zodResolver(schema),
  });
  const navigate = useNavigate();

  const googleProvider = new GoogleAuthProvider();
  const app = getApp();
  const auth = getAuth(app);

  const signUpWithGoogle = async () => {
    signInWithPopup(auth, googleProvider).then(async (result) => {
      if (auth.currentUser) {
        await setDoc(doc(db, 'users', result.user.uid), {
          email: result.user.email,
        });
      }
    });
    if (auth.currentUser) {
      navigate('/initiatives');
    }
  };

  const onSubmit = async (data: any) => {
    try {
      const credentials = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password,
      );

      await updateProfile(credentials.user, {
        displayName: data.name,
      });

      // Create Firestore document
      await setDoc(doc(db, 'users', credentials.user.uid), {
        email: credentials.user.email,
      });

      const persistence = data.rememberMe
        ? browserLocalPersistence
        : browserSessionPersistence;
      await setPersistence(auth, persistence);

      // Get the 'from' query parameter
      const searchParams = new URLSearchParams(location.search);
      const from = searchParams.get('from');

      // Redirect to the 'from' URL if it exists and starts with '/', otherwise go to '/initiatives'
      if (from && from.startsWith('/')) {
        navigate(from);
      } else {
        // navigate('/graph');
        window.location.href = '/graph';
      }
    } catch (error: any) {
      switch (error.code) {
        case 'auth/email-already-in-use':
          setError('email', {
            type: 'manual',
            message: 'Email address is already in use. Try signing in.',
          });
          break;

        case 'auth/invalid-email':
          setError('email', {
            type: 'manual',
            message: 'Invalid email address.',
          });
          break;

        case 'auth/network-request-failed':
          setError('email', {
            type: 'manual',
            message: 'Network error. Try again later.',
          });
          break;

        case 'auth/weak-password':
          setError('password', {
            type: 'manual',
            message: 'Password is too weak.',
          });
          break;

        default:
          setError('email', {
            type: 'manual',
            message: 'Internal error. Try again later.',
          });
          console.error(error);
          break;
      }
    }
  };

  return (
    <>
      <div>
        <img className="h-10 w-auto" src={logoBrandImg} alt="Neuroute Logo" />
        <div className="text-white text-[40px] font-normal mt-6">
          Hi, Welcome to Neuroute
        </div>
        <h2 className="mt-1.5 text-base leading-9 tracking-tight text-gray-100">
          Create an account to get started
        </h2>
      </div>

      <div className="mt-10">
        <div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            method="POST"
            className="space-y-6"
          >
            <Input
              id="name"
              title="Name"
              type="text"
              placeholder="John Doe"
              autoComplete="email"
              icon={
                <img src={permIdentitySvg} alt="Identity" className="h-5 w-5" />
              }
              inputProps={register('name')}
              required
            />

            <Input
              id="email"
              title="Email Address"
              type="email"
              placeholder="john@example.com"
              autoComplete="email"
              icon={<img src={emailSvg} alt="Email" className="h-5 w-5" />}
              inputProps={register('email')}
              required
            />

            <Input
              id="password"
              title="Password"
              type="password"
              autoComplete="password"
              placeholder="Min. 8 characters"
              icon={<img src={lockSvg} alt="Lock" className="h-5 w-5" />}
              inputProps={register('password')}
              required
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <input
                  id="is-agree"
                  type="checkbox"
                  className="h-4 w-4 rounded border-base-300 text-primary focus:ring-primary"
                  {...register('isAgree')}
                />
                <label
                  htmlFor="is-agree"
                  className="ml-3 block text-sm leading-6 text-gray-300"
                >
                  I agree to the{' '}
                  <a
                    href="#"
                    target="_blank"
                    rel="noopenner noreferrer"
                    className="text-primary"
                  >
                    Terms & Conditions
                  </a>
                </label>
              </div>
            </div>

            <ActionButton title="Sign up" isLoading={isSubmitting} />

            <p className="mt-6 text-sm leading-6 text-gray-300">
              Already have an Account?{' '}
              <a
                href="/sign-in"
                className="font-normal text-primary hover:text-secondary"
              >
                Login
              </a>
            </p>

            {errors && (
              <div className="text-red-500 text-sm flex flex-col">
                {errors.name && (
                  <div>Name: {errors.name?.message?.toString()}</div>
                )}
                {errors.email && <div>{errors.email?.message?.toString()}</div>}
                {errors.password && (
                  <div>Password: {errors.password?.message?.toString()}</div>
                )}
                {errors.isAgree && (
                  <div>{errors.isAgree?.message?.toString()}</div>
                )}
              </div>
            )}
          </form>
        </div>

        <div className="mt-10">
          <div className="relative">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-700" />
            </div>
            <div className="relative flex justify-center text-sm font-medium leading-6">
              <span className="bg-base-300 rounded-xl px-6 text-gray-100">
                Or continue with
              </span>
            </div>
          </div>

          <div className="mt-6 grid grid-cols-2 gap-4">
            <button
              className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
              onClick={signUpWithGoogle}
            >
              <svg className="h-5 w-5" aria-hidden="true" viewBox="0 0 24 24">
                <path
                  d="M12.0003 4.75C13.7703 4.75 15.3553 5.36002 16.6053 6.54998L20.0303 3.125C17.9502 1.19 15.2353 0 12.0003 0C7.31028 0 3.25527 2.69 1.28027 6.60998L5.27028 9.70498C6.21525 6.86002 8.87028 4.75 12.0003 4.75Z"
                  fill="#EA4335"
                />
                <path
                  d="M23.49 12.275C23.49 11.49 23.415 10.73 23.3 10H12V14.51H18.47C18.18 15.99 17.34 17.25 16.08 18.1L19.945 21.1C22.2 19.01 23.49 15.92 23.49 12.275Z"
                  fill="#4285F4"
                />
                <path
                  d="M5.26498 14.2949C5.02498 13.5699 4.88501 12.7999 4.88501 11.9999C4.88501 11.1999 5.01998 10.4299 5.26498 9.7049L1.275 6.60986C0.46 8.22986 0 10.0599 0 11.9999C0 13.9399 0.46 15.7699 1.28 17.3899L5.26498 14.2949Z"
                  fill="#FBBC05"
                />
                <path
                  d="M12.0004 24.0001C15.2404 24.0001 17.9654 22.935 19.9454 21.095L16.0804 18.095C15.0054 18.82 13.6204 19.245 12.0004 19.245C8.8704 19.245 6.21537 17.135 5.2654 14.29L1.27539 17.385C3.25539 21.31 7.3104 24.0001 12.0004 24.0001Z"
                  fill="#34A853"
                />
              </svg>
              <span className="text-sm font-semibold leading-6">Google</span>
            </button>

            <a
              href="#"
              className="flex w-full items-center justify-center gap-3 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus-visible:ring-transparent"
            >
              <img src={outlookImage} alt="Outlook" className="h-5 w-5" />
              <span className="text-sm font-semibold leading-6">Outlook</span>
            </a>
          </div>
        </div>
      </div>
    </>
  );
}

export default function SignUpPage() {
  return (
    <AuthLayout>
      <SignUpForm />
    </AuthLayout>
  );
}
