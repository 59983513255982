const CorrectMeBlock: React.FC<{
  onClick?: () => void;
  text: string;
}> = ({ onClick, text = "if I'm wrong about your intervention." }) => (
  <p className="mt-6">
    <span
      className="text-[22px] text-white font-medium cursor-pointer"
      onClick={() => {
        onClick && onClick();
      }}
    >
      {' '}
      Correct me{' '}
    </span>{' '}
    <span className="text-[22px] text-[#bab9c4] font-light">{text}</span>
  </p>
);

export default CorrectMeBlock;
