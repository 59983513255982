import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';
import MapView from './components/map_view/MapView';
import TimelineChart from './components/TimelineChart';
import Sidebar from './components/v2/Sidebar';
import StudyTable from './components/v2/StudyTable';
import { TopBar } from './components/v2/TopBar';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '@/core/setup_firebase';
import { Project } from '@/types/Project';

type StudyData = {
  id: number;
  name: string;
  date: string;
  selected: boolean;
};

export default function DashboardV2Page() {
  const viewMode = useAppSelector((s) => s.enrollmentScenario.viewMode);
  const { id } = useParams<{ id?: string }>();
  const [studies, setStudies] = useState<StudyData[]>([]);

  useEffect(() => {
    const fetchProject = async (id: string) => {
      const docRef = doc(db, 'projects', id);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        const projectData = docSnap.data() as Project;
        if (Array.isArray(projectData?.selectedStudies)) {
          const studiesFromDB = projectData?.selectedStudies.map(
            (studyId, index) => ({
              id: studyId,
              name: `${studyId}`,
              date: `2024-0${index + 5}-15`,
              selected: true,
            }),
          );
          setStudies(studiesFromDB);
          console.log('LINE 41 DashboardV2Page studies: ', studiesFromDB);
        } else {
          console.log('Invalid format for selectedStudies');
        }
      } else {
        console.log('No such project!');
      }
    };

    if (id) {
      fetchProject(id);
    }
  }, [id]);

  useEffect(() => {
    console.log('LINE 53 DashboardV2Page studies: ', studies);
  }, [studies]);

  return (
    <div className="flex flex-col h-full w-full bg-black">
      <div className="absolute inset-2 rounded-2xl overflow-auto bg-[#1F1F23]">
        <div className="z-10 flex flex-col w-full h-full">
          <TopBar projectId={id} />

          <div className="flex flex-row flex-grow overflow-hidden">
            <Sidebar studies={studies} />

            <div className="flex flex-col flex-grow overflow-auto">
              {viewMode === 'table' && <StudyTable studies={studies} />}
              {viewMode === 'map' && <MapView />}
              {viewMode === 'timeline' && <TimelineChart />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
