import React from 'react';

interface NavItemProps {
  iconSrc: string;
  label: string;
  isActive?: boolean;
  onClick: () => void;
  className?: string;
}

const NavItem: React.FC<NavItemProps> = ({
  iconSrc,
  label,
  isActive,
  onClick,
  className,
}) => {
  return (
    <div
      className={`
                flex items-center p-4 cursor-pointer
                ${isActive ? 'text-white' : 'text-gray-400 hover:text-gray-200'}
                ${className}
              `}
      onClick={onClick}
    >
      <img src={iconSrc} alt={label} className="w-6 h-6" />
      {label && <span className="ml-4 text-sm">{label}</span>}
    </div>
  );
};

export default NavItem;
