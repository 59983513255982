import { useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ReferenceLine,
  Label,
} from 'recharts';
import EnrollmentMetricsBar from './EnrollmentMetricsBar';
import { convertToQuarter } from '@/utils/date';
import { useChartSize } from './hooks/useChartSize';
import NoChartDataMessageView from './NoChartDataMessageView';
import { GraphDataInterface } from '../RoutesCreateEditPage';
import { ScenarioColors } from '@/features/enrollment_scenario/colors_data';

/*
--------------------------------------------------------------------------------
Custom components
--------------------------------------------------------------------------------
*/
const CustomLabel = ({
  viewBox,
  value,
  title,
}: {
  viewBox: any;
  value: string;
  title: string;
}) => {
  const { x, y } = viewBox;
  const labelY = y + 4;
  const labelX = x;

  return (
    <g>
      {/* <rect
        x={labelX}
        y={labelY - 26}
        width="60"
        height="14"
        fill="#27262B"
        rx={4}
        ry={4}
      /> */}
      <text
        x={labelX}
        y={labelY - 10}
        fill="#BFBDC9"
        textAnchor="left"
        fontSize={10}
      >
        {title} {value}
      </text>
    </g>
  );
};

const CustomTick = ({
  x,
  y,
  payload,
  showQuaters,
  index,
  visibleTicksCount,
  data,
}: any) => {
  // Format the date from data.date (assuming it's a valid date string)
  const date = new Date(data.date);
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}`;
  return (
    <text x={x} y={y + 10} textAnchor="middle" fill="#BFBDC9" fontSize={11}>
      {formattedDate}
    </text>
  );
};

const CustomTooltip = ({ active, payload, label, coordinate }: any) => {
  if (active && payload && payload.length) {
    const { x, y } = coordinate;
    const data = payload[0].payload;
    return (
      <div>
        {/* Tooltip box */}
        <div
          style={{
            position: 'absolute',
            top: y - 60,
            left: x,
            backgroundColor: '#2f2f2f',
            color: 'white',
            padding: '5px 10px',
            borderRadius: '5px',
            textAlign: 'center',
            transform: 'translate(-50%, -100%)',
          }}
        >
          {`${data.value} Patients`}
          <div
            style={{
              content: '',
              position: 'absolute',
              bottom: '-10px',
              left: '50%',
              marginLeft: '-10px',
              width: '0',
              height: '0',
              borderLeft: '10px solid transparent',
              borderRight: '10px solid transparent',
              borderTop: '10px solid #2f2f2f',
            }}
          />
        </div>
        {/* Vertical line */}
        <svg
          style={{
            position: 'absolute',
            left: 0,
            top: 0,
            width: '100%',
            height: '100%',
            pointerEvents: 'none',
          }}
        >
          <line
            x1={x}
            y1={y}
            x2={x}
            y2="100%"
            stroke="#7CFC00"
            strokeWidth="2"
          />
          <rect
            x={x - 5}
            y={y - 5}
            width="10"
            height="10"
            fill="#7CFC00"
            stroke="black"
            strokeWidth="1"
          />
        </svg>
      </div>
    );
  }

  return null;
};

interface GraphProps {
  graphData: GraphDataInterface;
  onChange: (graphData: GraphDataInterface) => void;
}

function Graph({ graphData, onChange }: GraphProps) {
  const {
    dates,
    scenarioData,
    cohortSize,
    isGridVisible,
    isChartAnimationEnabled,
  } = graphData;

  const setGraphData = (newData: GraphDataInterface) => {
    onChange(newData);
  };

  /*
  const quarters = [];

  if (dates.start && dates.end) {
    const startDate = new Date(dates.start);
    const endDate = new Date(dates.end);

    let currentDate = new Date(startDate);
    while (currentDate <= endDate) {
      const quarter = Math.floor(currentDate.getMonth() / 3) + 1;
      const year = currentDate.getFullYear();
      quarters.push(`Q${quarter} ${year}`);
      currentDate.setMonth(currentDate.getMonth() + 3);
    }
  }
  */

  const { chartContainerRef, width, height } = useChartSize();
  const [isXAxisHovered, setIsXAxisHovered] = useState(false);

  console.log('scenarioData', scenarioData);

  return (
    <div
      ref={chartContainerRef}
      className="w-full absolute top-0 left-0 right-0 bottom-0"
      style={{ height }}
      onMouseEnter={() => setGraphData({ ...graphData, isGridVisible: true })}
      onMouseLeave={() => setGraphData({ ...graphData, isGridVisible: false })}
    >
      <NoChartDataMessageView graphData={graphData} />
      <LineChart
        width={width}
        height={height}
        data={scenarioData}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          stroke={isGridVisible ? '#47464B' : 'transparent'}
          horizontal
          vertical={false}
        />
        <XAxis
          dataKey="value"
          tickSize={0}
          tickMargin={15}
          ticks={scenarioData
            .filter(
              (_, index) => index % Math.ceil(scenarioData.length / 10) === 0,
            )
            .slice(0, 10)
            .map((item) => item.value)}
          tick={(props) => (
            <CustomTick
              {...props}
              data={
                scenarioData[props.index * Math.ceil(scenarioData.length / 10)]
              }
              showQuaters={isXAxisHovered}
            />
          )}
          onMouseEnter={() => setIsXAxisHovered(true)}
          onMouseLeave={() => setIsXAxisHovered(false)}
        />
        <YAxis
          tickSize={0}
          tickMargin={12}
          axisLine={false}
          tick={{ fontSize: 10, fill: '#EEEDF1' }}
        >
          <Label
            value="Est. Patients Enrolled"
            angle={-90}
            position="insideLeft"
            style={{
              textAnchor: 'middle',
              fontSize: 12,
              fill: '#BFBDC9',
              letterSpacing: 0.5,
            }}
          />
        </YAxis>
        <Tooltip content={<CustomTooltip />} />

        <Line
          dataKey="value"
          type="monotone"
          strokeWidth={2}
          isAnimationActive={isChartAnimationEnabled}
          dot={false}
          cursor="pointer"
          stroke={ScenarioColors[0].mostLikely}
        />

        <ReferenceLine
          y={cohortSize.target}
          stroke="#FFFFFF"
          strokeDasharray={'2 2'}
          opacity={0.5}
          label={({ viewBox }) => (
            <CustomLabel
              viewBox={viewBox}
              title="Target"
              value={cohortSize.target.toString()}
            />
          )}
        />
        {/*<ReferenceLine*/}
        {/*  y={cohortSize.min}*/}
        {/*  stroke="#FFFFFF"*/}
        {/*  strokeDasharray={'2 2'}*/}
        {/*  opacity={0.5}*/}
        {/*  label={({ viewBox }) => (*/}
        {/*    <CustomLabel*/}
        {/*      viewBox={viewBox}*/}
        {/*      title="Min"*/}
        {/*      value={cohortSize.min.toString()}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*/>*/}
      </LineChart>
    </div>
  );
}

/*
--------------------------------------------------------------------------------
Header
--------------------------------------------------------------------------------
*/
interface HeaderProps {
  graphData: GraphDataInterface;
  onChange: (graphData: GraphDataInterface) => void;
}
function Header({ graphData, onChange }: HeaderProps) {
  const target = graphData.cohortSize.target;

  return (
    <div className="p-2 flex flex-row items-start relative">
      {/*       <div
        className="absolute top-0 bottom-0 left-0 w-1.5"
        style={{ backgroundColor: selectedScenario?.color.mostLikely }}
      ></div> */}
      <div className="relative mr-5"></div>

      <div className="flex flex-col flex-grow items-start mr-5">
        <div className="flex flew-row items-center">
          <h1 className="text-xl font-medium mr-5 text-left">
            {graphData.scenarioName}
          </h1>
          <p className="text-base text-gray-400">n={target}</p>
        </div>
        <p className="text-xs text-gray-400">Enrollment Scenario</p>
      </div>
    </div>
  );
}

interface RoutesGraphProps {
  graphData: GraphDataInterface;
  onChange: (graphData: GraphDataInterface) => void;
}

function RoutesGraph({ graphData, onChange }: RoutesGraphProps) {
  const handleGraphDataChange = (newData: GraphDataInterface) => {
    onChange(newData);
  };

  return (
    <div
      className="flex flex-col relative p-4 border border-gray-700 rounded-xl text-white w-full"
      style={{
        backgroundColor: '#3B3944',
        height: '400px',
      }}
      onClick={() => console.log('EnrollmentScenarioGraph click')}
    >
      <Header graphData={graphData} onChange={handleGraphDataChange} />

      <div className={'flex w-full relative flex-1'}>
        <Graph graphData={graphData} onChange={handleGraphDataChange} />
      </div>

      {/* <FullscreenButton /> */}
    </div>
  );
}

export default RoutesGraph;
