import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NeurouteAnimated from './components/NeurouteAnimated';

const BuildProfileGraphPageStep0: React.FC = () => {
  const navigate = useNavigate();
  const [animationStage, setAnimationStage] = useState(0);
  const [isDesktop, setIsDesktop] = useState(
    window.innerWidth > window.innerHeight,
  );
  const [containerSize, setContainerSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [logoSize, setLogoSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > window.innerHeight);
      updateSizes();
    };

    window.addEventListener('resize', handleResize);
    updateSizes();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const updateSizes = () => {
    const isMobile = window.innerWidth < window.innerHeight;
    setContainerSize({ width: window.innerWidth, height: window.innerHeight });

    if (isMobile) {
      const maxWidth = window.innerWidth * 0.9;
      const maxHeight = window.innerHeight * 0.4;
      const aspectRatio = 3.5; // Adjust this based on your logo's aspect ratio
      let width = maxWidth;
      let height = width / aspectRatio;

      if (height > maxHeight) {
        height = maxHeight;
        width = height * aspectRatio;
      }

      setLogoSize({ width, height });
    } else {
      setLogoSize({ width: window.innerWidth, height: window.innerHeight });
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => setAnimationStage(1), 100);
    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    if (animationStage === 1) {
      const timer = setTimeout(() => {
        setAnimationStage(2);
      }, 2000);

      return () => clearTimeout(timer);
    }
  }, [animationStage]);

  const handleClick = () => {
    if (animationStage === 1) {
      setAnimationStage(2);
    }
  };

  useEffect(() => {
    if (animationStage === 2) {
      const timer = setTimeout(() => {
        navigate('/intro');
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [animationStage, navigate]);

  const getContainerStyle = () => {
    const baseStyle = {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'all 1000ms ease-in-out',
      padding: 0,
      margin: 0,
      // border: '1px solid blue', // Blue border for testing
    };

    if (animationStage === 2) {
      return {
        ...baseStyle,
        width: '191px',
        height: '81px',
        position: 'absolute' as const,
        top: '-12px',
        left: '2rem',
      };
    } else {
      return {
        ...baseStyle,
        width: `${containerSize.width}px`,
        height: `${containerSize.height}px`,
        position: 'absolute' as const,
        top: '0',
        left: '0',
      };
    }
  };

  const getLogoStyle = () => {
    // 1. 587x226
    // 2. 191x81
    // if width is less then 587 then scale down initSize to
    const initSize = isDesktop ? 1 : Math.min(logoSize.width / 587, 1);
    const size = animationStage === 2 ? 0.325 : initSize;
    const translateX = animationStage === 2 ? '0' : '0';
    const translateY = animationStage === 2 ? '0' : '0';

    return {
      width: '587px',
      height: '226px',
      opacity: animationStage === 0 ? 0 : 1,
      transition: 'all 1000ms ease-in-out',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transform: `scale(${size}) translate(${translateX}, ${translateY})`,
      // border: '1px solid red', // Red border for testing
    };
  };

  return (
    <div
      className="w-full h-screen bg-[#1F1F23] overflow-hidden flex items-center justify-center"
      onClick={handleClick}
    >
      <div style={getContainerStyle()}>
        <div style={getLogoStyle()}>
          <NeurouteAnimated />
        </div>
      </div>
    </div>
  );
};

export default BuildProfileGraphPageStep0;
