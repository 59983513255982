import { useSearchQuery } from '@/api/slices/search/SearchSlice';
import { useGetStudiesQuery } from '@/api/slices/study/StudySlice';
import { useSelector } from 'react-redux';
import { RootState } from '../../app/store';
import { constructSearchString } from './search_util'; // adjust import path as needed
import { db } from '@/core/setup_firebase';
import { addDoc, collection, doc, writeBatch } from 'firebase/firestore';
import {
  CriteriaToStudiesRequestBody,
  usePostCriteriaToStudiesMutation,
} from '@/api/slices/study/CriteriaToStudiesSlice';
import { useEffect } from 'react';

export const useFlow3Studies = () => {
  const searchString = useSelector((state: RootState) =>
    constructSearchString(state),
  );

  const searchResult = useSearchQuery({ search: searchString });
  const [postCriteriaToStudies] = usePostCriteriaToStudiesMutation();

  const studiesResult = useGetStudiesQuery(
    {
      condition:
        searchResult.data?.conditions
          .map((item) => [item.condition, ...item.similar_conditions])
          .flat() || [],
      intervention_type: searchResult.data?.interventions || [],
      include_num_patients: true,
      include_start_date: true,
      include_end_date: true,
    },
    { skip: !searchResult.data },
  );

  const inclCriteria = useSelector(
    (state: RootState) => state.flow3.inclCriteria,
  );
  const exclCriteria = useSelector(
    (state: RootState) => state.flow3.exclCriteria,
  );

  useEffect(() => {
    const processCriteria = async () => {
      const nct_ids = studiesResult.data?.studies.map((study) => study.nct_id);

      const docRef = await addDoc(collection(db, 'study-searches'), {
        timestamp: new Date(),
      });

      const studiesCollectionRef = collection(docRef, 'studies');

      const batchSize = 500; // Firestore limit for a single batch
      const batches = [];

      for (let i = 0; i < (nct_ids ?? []).length; i += batchSize) {
        const batch = writeBatch(db);
        const chunk = (nct_ids ?? []).slice(i, i + batchSize);

        chunk.forEach((nct_id) => {
          const docRef = doc(studiesCollectionRef, nct_id);
          batch.set(docRef, {});
        });

        batches.push(batch.commit());
      }

      // Execute all batches in parallel
      await Promise.all(batches);

      if (
        (inclCriteria && inclCriteria.length !== 0) ||
        (exclCriteria && exclCriteria.length !== 0)
      ) {
        try {
          const criteriaToStudiesRequestBody: CriteriaToStudiesRequestBody = {
            research_id: docRef.id,
            inclusion_list: inclCriteria,
            exclusion_list: exclCriteria,
            isProd: true,
          };
          const response = await postCriteriaToStudies(
            criteriaToStudiesRequestBody,
          ).unwrap();
          console.log(`omg the response ISSSSS:\n${response}`);
        } catch (error) {
          console.log(error);
        }
      }
    };

    if (studiesResult.data && !studiesResult.isLoading) {
      // processCriteria();
    }
  }, [inclCriteria, exclCriteria, studiesResult.data, postCriteriaToStudies]);

  return {
    studiesResult,
    isLoading: searchResult.isLoading || studiesResult.isLoading,
    isError: searchResult.isError || studiesResult.isError,
  };
};
