import clsx from 'clsx';
import { useMemo, useState } from 'react';

type Study = {
  name: string;
};

export default function StudyCompetitionView() {
  const [isShowingAll, setIsShowingAll] = useState(false);

  const data = useMemo(() => {
    return [
      {
        name: 'S0000D: Effect of Vitamin E and/or',
      },
      {
        name: 'S0001D: Effect of Vitamin C and/or',
      },
      {
        name: 'S0000D: Effect of Vitamin A and/or',
      },

      {
        name: 'S0000D: Effect of Vitamin E and/or',
      },
      {
        name: 'S0001D: Effect of Vitamin C and/or',
      },
      {
        name: 'S0000D: Effect of Vitamin A and/or',
      },
    ];
  }, []);

  const visibleData = isShowingAll ? data : data.slice(0, 2);

  return (
    <div className="flex flex-row justify-start mt-4">
      <div className="flex flex-col mt-1 items-start px-8 py-6 text-white bg-neutral-900/50 backdrop-blur-[6px] rounded">
        <div className="text-lg font-semibold leading-tight">
          Study Competition
        </div>

        <div className="text-sm font-medium mt-1 text-white/90">
          There are currently {data.length} stud
          {data.length === 1 ? 'y' : 'ies'} recruiting for the same patient
          population in a 5Km radius
        </div>

        <div className="flex flex-col max-h-48 overflow-y-auto w-full mt-4">
          {visibleData.map((study, index) => (
            <div
              className={clsx(
                'flex flex-row justify-start gap-x-2',
                index !== visibleData.length - 1 && 'mb-4',
              )}
            >
              <div className="w-10 h-10 bg-zinc-600/50 rounded flex items-center justify-center">
                {index + 1}
              </div>
              <div className="flex flex-col items-start">
                <div className="text-sm font-normal leading-normal">
                  {study.name}
                </div>
                <div className="opacity-90 text-white text-sm font-medium leading-[18px]">
                  View Study
                </div>
              </div>
            </div>
          ))}
        </div>

        {data.length > 2 && (
          <div
            className="flex flex-row w-full justify-center mt-4 text-sm items-center cursor-pointer"
            onClick={() => setIsShowingAll(!isShowingAll)}
          >
            {isShowingAll ? 'Hide all studies' : 'View all studies'}
          </div>
        )}
      </div>
    </div>
  );
}
