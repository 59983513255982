import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import NeurouteLogo from '@/components/NeurouteLogo';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '../auth/AuthContext';
import StatusCell from './components/StatusCell';
import plusIcon from '@/images/plus-lg.svg';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';
import { Initiative } from '@/types/Initiative';
import { auth, db } from '@/core/setup_firebase';
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  addDoc,
  deleteDoc,
  serverTimestamp,
  Timestamp,
  FieldValue,
} from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { mockedAvailableBackTracks } from '@/mocks/mockedAvailableBackTracks';
import sortIcon from '@/images/sorting_icon.svg';
import clsx from 'clsx';
import { setInitiativeId } from '@/features/flow_3/flow_3_slice';
import { useDispatch } from 'react-redux';

type SortIconProps = {
  column: keyof Initiative;
  currentColumn: keyof Initiative;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}
const InitiativesPage: React.FC = () => {
  const navigate = useNavigate();
  const [initiatives, setInitiatives] = useState<Initiative[]>([]);
  const [user, setUser] = useState(auth.currentUser);
  const [sortColumn, setSortColumn] = useState<keyof Initiative>('title');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
  const dispatch = useDispatch();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      if (user) {
        getInitiatives(user.uid);
      } else {
        navigate('/login');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const getInitiatives = async (userId: string) => {
    try {
      // console.log('auth uid', auth.currentUser?.uid);
      const projectsRef = collection(db, 'initiatives');
      const q = query(
        projectsRef,
        where('userId', '==', auth.currentUser?.uid),
      );
      const querySnapshot = await getDocs(q);
      const initiativeList: Initiative[] = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return { ...data, id: doc.id } as Initiative;
      });
      setInitiatives(initiativeList);
    } catch (error) {
      console.error('Error fetching initiatives: ', error);
    }
  };

  const createNewInitiative = async () => {
    try {
      const defaultSectionTexts = [
        "Explore why this topic is crucial in today's world. Consider its impact on society, the environment, or scientific progress.",
        'Describe your scientific approach or hypothesis. What are you investigating, and how does it contribute to the field?',
        'Outline key concepts, theories, or background information essential for understanding your science.',
        'Discuss the contexts or situations where your research applies. Consider both current and potential future applications.',
        'Reflect on the potential outcomes and significance of your work. How might it influence future research or real-world practices?',
      ];

      const newInitiative: Omit<Initiative, 'id'> = {
        userId: auth.currentUser?.uid || '',
        status: 'draft',
        title: `New Initiative ${Math.floor(Math.random() * 1000)}`,
        collaborators: Math.floor(Math.random() * 5),
        lastUpdated: serverTimestamp(),
        scenes: [],
        selectedVoice: Math.random() > 0.5 ? 'Masculine' : 'Feminine',
        selectedBackTrack: mockedAvailableBackTracks[0],
        sectionTranscripts: defaultSectionTexts,
        srt: defaultSectionTexts
          .map((text, index) => {
            const startTime = index * 12000;
            const endTime = startTime + 12000;
            return `${startTime},${endTime},${text}`;
          })
          .join('\n'),
        selectedStudies: [],
      };

      const docRef = await addDoc(collection(db, 'initiatives'), newInitiative);
      console.log('New initiative created with ID: ', docRef.id);

      // Add the new initiative to the local state
      setInitiatives([
        ...initiatives,
        { ...newInitiative, id: docRef.id } as Initiative,
      ]);

      dispatch(setInitiativeId(docRef.id));
      navigate(`/flow-3-wireframes/step-2`);
    } catch (error) {
      console.error('Error creating new initiative: ', error);
    }
  };

  const formatDate = (timestamp: Timestamp | FieldValue) => {
    if (timestamp instanceof Timestamp) {
      return timestamp.toDate().toLocaleString();
    } else if (
      timestamp &&
      typeof timestamp === 'object' &&
      'seconds' in timestamp
    ) {
      // This is a workaround for FieldValue, which we can't directly convert
      return new Date((timestamp as any).seconds * 1000).toLocaleString();
    }
    return 'Pending...';
  };

  const handleTitleClick = (id: string) => {
    navigate(`/initiative/${id}`);
  };

  const handleEdit = (id: string) => {
    navigate(`/initiative/edit/${id}`);
  };

  const handleDelete = async (id: string) => {
    if (window.confirm('Are you sure you want to delete this initiative?')) {
      try {
        await deleteDoc(doc(db, 'initiatives', id));
        setInitiatives(
          initiatives.filter((initiative) => initiative.id !== id),
        );
        console.log('Initiative deleted successfully');
      } catch (error) {
        console.error('Error deleting initiative:', error);
      }
    }
  };

  const handleSort = (column: keyof Initiative) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  const sortedInitiatives = [...initiatives].sort((a, b) => {
    const aValue = a[sortColumn];
    const bValue = b[sortColumn];

    if (aValue == null && bValue == null) return 0;
    if (aValue == null) return sortDirection === 'asc' ? 1 : -1;
    if (bValue == null) return sortDirection === 'asc' ? -1 : 1;

    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return sortDirection === 'asc'
        ? aValue.localeCompare(bValue)
        : bValue.localeCompare(aValue);
    }

    if (aValue instanceof Date && bValue instanceof Date) {
      return sortDirection === 'asc'
        ? aValue.getTime() - bValue.getTime()
        : bValue.getTime() - aValue.getTime();
    }

    if (typeof aValue === 'number' && typeof bValue === 'number') {
      return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
    }

    // If we can't compare the values, return 0 (no change in order)
    return 0;
  });

  const { currentUser, loading } = useAuth();
  // const app = getApp();
  // const auth = getAuth(app);
  // const currentUser = auth.currentUser;

  console.log('LINE 219 currentUser: ', currentUser);

  return (
    <div className="flex flex-col w-full bg-[#1F1F23] min-h-screen text-white">
      <div className="flex flex-row p-6">
        {/* Add the HamburgerMenu on the top left */}
        <div className="w-3/12 flex-shrink-0">
          <div className="h-16 flex items-center justify-between mb-4">
            <NeurouteLogo />
            <img
              src={ArrowLeftCircleIcon}
              alt="Back"
              className="w-8 h-8 cursor-pointer hover:opacity-80 transition-opacity"
              onClick={() => navigate('/dashboard')}
            />
          </div>
        </div>

        <main className="w-9/12 flex-shrink-0 pl-8 pt-2 flex flex-col">
          <div className="flex justify-between items-center mb-8">
            <h1 className="text-4xl font-semibold tracking-tight">
              Initiatives
            </h1>
            <div className="flex items-center gap-4">
              <button
                className="btn btn-primary btn-sm text-neutral-800 font-normal"
                onClick={createNewInitiative}
              >
                Create Initiative{' '}
                <img src={plusIcon} className="inline-flex w-4 h-4" alt="" />
              </button>
              <div className="h-8 border-r border-zinc-700"></div>

              <HamburgerMenu />
            </div>
          </div>
          <div className="overflow-auto">
            <table className="min-w-full bg-[#1F1F23] text-gray-300">
              <thead>
                <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
                  <th
                    className="p-2 cursor-pointer font-normal"
                    onClick={() => handleSort('title')}
                  >
                    Title
                    <SortIcon
                      column="title"
                      currentColumn={sortColumn}
                      direction={sortDirection}
                    />
                  </th>
                  <th
                    className="p-2 cursor-pointer font-normal"
                    onClick={() => handleSort('status')}
                  >
                    Status
                    <SortIcon
                      column="status"
                      currentColumn={sortColumn}
                      direction={sortDirection}
                    />
                  </th>
                  <th
                    className="p-2 cursor-pointer font-normal"
                    onClick={() => handleSort('lastUpdated')}
                  >
                    Last Updated
                    <SortIcon
                      column="lastUpdated"
                      currentColumn={sortColumn}
                      direction={sortDirection}
                    />
                  </th>
                  <th className="p-2 font-normal">Actions</th>
                </tr>
              </thead>
              <tbody>
                {sortedInitiatives.map((initiative, index) => (
                  <tr
                    key={initiative.id}
                    className="border-b border-gray-700 text-start text-sm"
                  >
                    <td className="p-2">
                      <span
                        className="cursor-pointer hover:text-blue-400 transition-colors duration-200"
                        onClick={() => handleTitleClick(initiative.id)}
                      >
                        {initiative.title}
                      </span>
                    </td>
                    <td className="p-2">
                      <StatusCell status={initiative.status} />
                    </td>
                    <td className="p-2">
                      {formatDate(initiative.lastUpdated)}
                    </td>
                    <td className="p-2">
                      <button
                        className="text-green-500 hover:bg-green-500 hover:text-white px-3 py-1 rounded-md mr-2 transition-colors duration-200"
                        onClick={() => handleEdit(initiative.id)}
                      >
                        Edit
                      </button>
                      <button
                        className="text-red-500 hover:bg-red-500 hover:text-white px-3 py-1 rounded-md transition-colors duration-200"
                        onClick={() => handleDelete(initiative.id)}
                      >
                        Delete
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </main>
      </div>
    </div>
  );
};

export default InitiativesPage;
