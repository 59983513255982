import ModelView from './components/model_view/ModelView';
import Sidebar from './components/sidebar/Sidebar';
import SubscriptionModal from './components/subscription/SubscriptionModal';

export default function DashboardPage() {
  return (
    <div className="h-full w-full relative">
      <div className="flex flex-row h-full w-full">
        <Sidebar />

        <div className="flex flex-col flex-1 overflow-x-hidden">
          <ModelView />
        </div>
      </div>

      <SubscriptionModal />
    </div>
  );
}
