import React from 'react';
import HeaderTitle from '../sections/HeaderTitle';
import InputStep from '../sections/InputStep';
import SearchAndDisplay from '../sections/SearchAndDisplay';
import DescriptionBlock from '../sections/DescriptionBlock';
import CorrectMeBlock from '../sections/CorrectMe';
import ThatLooksRightToMe from '../sections/ThatLooksRightToMe';
import { StepSectionProps } from './StepSectionProps';
import { fetchInferInterventionType } from '@/api/brainstormApi';

const InterventionTypeSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const fixedTitleText = brainstorm.interventionFreeText?.replace(
    /^by\s+/i,
    '',
  );

  return (
    <>
      {isCurrentStep('interventionType.freeTextEdit') && (
        <>
          <HeaderTitle prefix="by" value="doing what?" className="mt-14 mb-8" />
          <InputStep
            brainstorm={brainstorm}
            setBrainstorm={setBrainstorm}
            placeholder="Tell me your intervention type here"
            field="interventionFreeText"
            onNextStep={() => nextStep('interventionType.search')}
          />
        </>
      )}

      {isCurrentStep('interventionType.edit') && (
        <HeaderTitle
          prefix="by"
          value={fixedTitleText}
          className="mt-14 mb-8"
        />
      )}

      {isCurrentStep('interventionType.search') && (
        <>
          <HeaderTitle
            prefix="by"
            value={fixedTitleText}
            className="mt-14 mb-8"
          />
          <SearchAndDisplay
            brainstorm={brainstorm}
            setBrainstorm={setBrainstorm}
            query={async () => {
              const interventionType = await fetchInferInterventionType(
                brainstorm.interventionFreeText,
              );
              setBrainstorm({
                ...brainstorm,
                interventionType: interventionType,
              });
            }}
            searchText={`searching for all the ways people have listed studies in ${brainstorm.interventionFreeText}`}
            onNextStep={() => nextStep('interventionType.correct')}
          />
        </>
      )}

      {isCurrentStep('interventionType.correct') && (
        <>
          <HeaderTitle
            prefix="by"
            value={fixedTitleText}
            className="mt-14 mb-8"
          />
          <DescriptionBlock>
            We're assuming this is a {brainstorm.interventionType} intervention.
          </DescriptionBlock>
          <CorrectMeBlock
            text="if I'm wrong about your intervention."
            onClick={() => {
              removeStep('interventionType*');
              setCurrentStep('interventionType.edit');
            }}
          />
          <ThatLooksRightToMe
            onNextStep={() => {
              nextStep('relevance.search', 'interventionType');
            }}
          />
        </>
      )}

      {hasStep('interventionType') && (
        <>
          <HeaderTitle
            prefix="by"
            value={fixedTitleText}
            className="mt-14 mb-8"
          />
          <DescriptionBlock>
            We're assuming this is a {brainstorm.interventionType} intervention.
            {/*             
            There have been {brainstorm.historicalInsights.averagePatientCount}{' '}
            studies on drug interventions for {brainstorm.indication}. 
            */}
          </DescriptionBlock>
        </>
      )}
    </>
  );
};

export default InterventionTypeSection;
