import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styled, { keyframes } from 'styled-components';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimatedSmall from './components/NeurouteAnimatedSmall';
import NeurouteAnimatedSearching from './components/NeurouteAnimatedSearching';
import arrowTopRightCircleIcon from '../../images/arrow_top_right_circle.svg';
import { CornerPositioned } from './components/StyledElements';
import { RootState } from '../../app/store';
import { useParams } from 'react-router-dom';
import {
  selectStudyMethodText,
  selectYearsText,
} from '@/features/flow_3/flow_3_slice';
import { useFlow3Studies } from '@/features/flow_3/use_flow_3_studies';
import SelectedStudy from './components/SelectedStudy';
import { useNavigate } from 'react-router-dom';
import { addDoc, collection, doc, getDoc, updateDoc } from 'firebase/firestore';
import { auth, db } from '@/core/setup_firebase';
import MakeNewScienceButton from '@/components/buttons/MakeNewScienceButton';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '../auth/AuthContext';

const escapeSpecialCharacters = (str: string): string => {
  return str.replace(/\\/g, '\\\\').replace(/`/g, '\\`').replace(/\//g, '\\/');
};

const blink = keyframes`
    0% { opacity: .2; }
    20% { opacity: 1; }
    100% { opacity: .2; }
  `;

const AnimatedDots = styled.span`
  span {
    animation-name: ${blink};
    animation-duration: 1.4s;
    animation-iteration-count: infinite;
    animation-fill-mode: both;
  }

  span:nth-child(2) {
    animation-delay: 0.2s;
  }

  span:nth-child(3) {
    animation-delay: 0.4s;
  }
`;

const Flow3Step6and7Page: React.FC = () => {
  const data = useSelector((state: RootState) => state.flow3);
  const studyMethodText = useSelector(selectStudyMethodText);
  const yearsText = useSelector(selectYearsText);
  const [selectedStudies, setSelectedStudies] = useState<string[]>([]);
  const { currentUser } = useAuth();
  const { projectId } = useParams<{ projectId?: string }>();

  const navigate = useNavigate();

  const openStudy = (nct_id: string) => {
    const url = `https://clinicaltrials.gov/study/${nct_id}`;
    window.open(url, '_blank', 'noreferrer');
  };

  const goToFlow6 = async () => {
    const docRef = await saveProjectOnFirestore();
    navigate(`/dashboard/${docRef.id}`);
  };

  const makeNewScience = async () => {
    const docRef = await saveProjectOnFirestore();
    navigate(
      `/initiative/create/?projectId=${docRef.id}&studies=${escapeSpecialCharacters(selectedStudies.join(','))}`,
    );
  };

  const saveProjectOnFirestore = async () => {
    if (projectId) {
      // Update existing document
      const docRef = doc(db, 'projects', projectId);
      await updateDoc(docRef, {
        studySubject: data.disease,
        helpHow: data.helpHow,
        studyMethod: data.studyMethod,
        countries: data.countries,
        userId: auth.currentUser?.uid,
        inclusionCriteria: data.inclCriteria,
        exclusionCriteria: data.exclCriteria,
        selectedStudies: selectedStudies,
      });
      return docRef;
    } else {
      // Create new document
      const docRef = await addDoc(collection(db, 'projects'), {
        studySubject: data.disease,
        helpHow: data.helpHow,
        studyMethod: data.studyMethod,
        countries: data.countries,
        userId: auth.currentUser?.uid,
        inclusionCriteria: data.inclCriteria,
        exclusionCriteria: data.exclCriteria,
        selectedStudies: selectedStudies,
      });
      return docRef;
    }
  };

  useEffect(() => {
    const fetchProject = async () => {
      if (projectId) {
        try {
          const docRef = doc(db, 'projects', projectId);
          const docSnap = await getDoc(docRef);
          const projectData = docSnap.data();
          if (projectData && projectData.selectedStudies) {
            setSelectedStudies(projectData.selectedStudies);
          }
        } catch (err) {
          console.error(`Error fetching project: ${err}`);
        }
      }
    };

    fetchProject();
  }, [projectId]);

  const { studiesResult, isLoading, isError } = useFlow3Studies();

  const toggleStudy = (nct_id: string) => {
    setSelectedStudies((prev) =>
      prev.includes(nct_id)
        ? prev.filter((id) => id !== nct_id)
        : [...prev, nct_id],
    );
  };

  const isStudySelected = (nct_id: string) => selectedStudies.includes(nct_id);

  const yearsTextTrimmed = yearsText
    .replace(/in /g, '')
    .replace(/the /g, '')
    .replace(/of /g, '');

  const helpHowTextTrimmed = data.helpHow.replace(/by /g, '');

  const formatDate = (date?: string) => {
    const parts: string[] = date!.split('-');
    return `${parts[1]}/${parts[0]}`;
  };

  function calculateMonthsBetweenDates(startDate?: string, endDate?: string) {
    // Parse the input strings into Date objects
    const start = new Date(startDate!);
    const end = new Date(endDate!);

    // Calculate the difference in years and months
    const yearsDifference = end.getFullYear() - start.getFullYear();
    const monthsDifference = end.getMonth() - start.getMonth();

    // Total months difference
    const totalMonths = yearsDifference * 12 + monthsDifference;

    return `${totalMonths} month${totalMonths === 1 ? '' : 's'}`;
  }

  console.log(selectedStudies);

  return (
    <ProjectLayout title="Projects">
      <div className="flex flex-row relative w-full">
        <CornerPositioned>
          <NeurouteAnimatedSmall />
          <div className="flex flex-row content-between items-center gap-4">
            <HamburgerMenu />
          </div>
        </CornerPositioned>

        <div className="flex flex-row w-screen gap-32">
          <div className="flex flex-col w-1/4 pl-[40px] pt-32">
            {!isLoading && !isError && (
              <div className="text-[#9491a7] text-4xl font-normal font-['Satoshi'] mb-8">
                Performance Rank
              </div>
            )}
            <div className="h-screen overflow-y-auto">
              {studiesResult?.data?.studies.map((study) => (
                <div
                  className={`relative w-full mb-12 cursor-pointer ${isStudySelected(study.nct_id) ? '' : 'opacity-50'}`}
                  key={study.nct_id}
                  onClick={() => toggleStudy(study.nct_id)}
                  role="button"
                >
                  <div className="flex flex-row items-center justify-between">
                    <div className="text-white text-lg font-medium font-['Satoshi Variable'] line-clamp-1">
                      {study.name}
                    </div>
                    <img
                      src={arrowTopRightCircleIcon}
                      alt="Details"
                      color="bg-white"
                      className="w-6 h-6 cursor-pointer"
                      role="button"
                      onClick={() => openStudy(study.nct_id)}
                    />
                  </div>
                  <div className="w-[99px] h-[1px] bg-[#4B4A55] my-2" />
                  <div>
                    <span className="text-white text-lg font-medium font-['Satoshi Variable'] leading-relaxed line-clamp-1">
                      {study.num_patients} patients in{' '}
                      {calculateMonthsBetweenDates(
                        study.start_date!,
                        study.end_date!,
                      )}
                      <br />
                    </span>
                    <span className="text-white text-lg font-normal font-['Satoshi Variable'] leading-relaxed line-clamp-1">
                      {formatDate(study.start_date!)} -{' '}
                      {formatDate(study.end_date!)}
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="w-3/4 flex flex-col pt-10">
            {isLoading && (
              <div className="text-gray-500 text-4xl mb-2 pl-6">
                I want to help patients with
              </div>
            )}
            {isLoading && (
              <div className="text-white text-4xl mb-2 pl-6">
                {data.disease}
              </div>
            )}
            {isLoading && (
              <div className="text-gray-500 text-4xl mb-2 pl-6">
                by <span className="text-white ">{helpHowTextTrimmed}</span>
              </div>
            )}
            {!isLoading && !isError && (
              <div className="text-gray-500 text-4xl mb-8 pl-6">
                It's sort of like...
              </div>
            )}

            {isLoading && (
              <div className="pl-6 w-full min-w-[720px] text-[18px] font-normal leading-[24.3px] text-left flex flex-row items-start">
                <div className="flex-none">
                  <NeurouteAnimatedSearching />
                </div>
                <div className="flex-grow ml-4">
                  searching for historical studies in{' '}
                  <span className="text-white">{data.disease}</span> and{' '}
                  <span className="text-white">{studyMethodText}</span> in{' '}
                  <span className="text-white">{yearsTextTrimmed}</span>,{' '}
                  <span className="text-white">advanced (1)</span>{' '}
                  <AnimatedDots>
                    <span>.</span>
                    <span>.</span>
                    <span>.</span>
                  </AnimatedDots>
                </div>
              </div>
            )}

            {isError && <div>Error loading studies</div>}

            {!isError && !isLoading && (
              <>
                <div className="pl-6 text-[18px] font-normal leading-[24.3px] text-left text-gray-500 text-4xl mb-2">
                  Reference {selectedStudies.length} historical studies out of{' '}
                  {studiesResult?.data?.studies.length} in{' '}
                  <span className="text-white">{data.disease}</span> and{' '}
                  <span className="text-white">{studyMethodText}</span>{' '}
                  interventions in the{' '}
                  <span className="text-white">{yearsTextTrimmed}</span>,{' '}
                  <span className="text-white">advanced (1)</span>
                </div>

                {/* Study Cards Section */}
                <div className="mt-12 space-y-8 w-full max-w-4xl">
                  {studiesResult?.data?.studies
                    .filter((study) => isStudySelected(study.nct_id))
                    .map((study) => (
                      <SelectedStudy
                        study={study}
                        toggleStudy={toggleStudy}
                        openStudy={openStudy}
                      />
                    ))}
                </div>
              </>
            )}
          </div>
        </div>
        {!isLoading && !isError && (
          <button
            className="fixed right-20 bottom-20 flex items-center justify-center px-[22.4px] py-[11.2px] rounded-[30px] bg-[#00C7A8] text-[#101117] hover:opacity-90 transition-opacity"
            onClick={goToFlow6}
          >
            <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em] mr-[11.2px]">
              Go to Flow 6
            </span>
          </button>
        )}
        {!isLoading && !isError && (
          <div className="fixed right-20 bottom-40 flex items-center justify-center  rounded-[30px]  hover:opacity-90 transition-opacity">
            <MakeNewScienceButton
              onClick={makeNewScience}
              label="Make new science"
            />
          </div>
        )}
      </div>
    </ProjectLayout>
  );
};

export default Flow3Step6and7Page;
