import AuthGuard from '@/pages/auth/AuthGuard';
import ErrorPage from '@/pages/error/ErrorPage';
import BrainstormsCRUDPage from '@/pages/kadencebio/BrainstormsCRUDPage';
import BrainstormCreateEditPage from '@/pages/kadencebio/BrainstormCreateEditPage';
import LibrariesCRUDPage from '@/pages/kadencebio/LibrariesCRUDPage';
import LibraryCreateEditPage from '@/pages/kadencebio/LibraryCreateEditPage';
import SiteMapsCRUDPage from '@/pages/kadencebio/SiteMapsCRUDPage';
import SiteMapsCreateEditPage from '@/pages/kadencebio/SiteMapCreateEditPage';
import RoutesCRUDPage from '@/pages/kadencebio/RoutesCRUDPage';
import RoutesCreateEditPage from '@/pages/kadencebio/RoutesCreateEditPage';

const prefixed = (path: string, Component: React.FC) => ({
  path: `/kadencebio${path}`,
  element: (
    <AuthGuard>
      <Component />
    </AuthGuard>
  ),
  errorElement: <ErrorPage />,
});

const KadenceBioRoutes = [
  prefixed('', BrainstormCreateEditPage),
  prefixed('/brainstorms', BrainstormsCRUDPage),
  prefixed('/brainstorm/create', BrainstormCreateEditPage),
  prefixed('/brainstorm/edit/:id', BrainstormCreateEditPage),
  prefixed('/libraries', LibrariesCRUDPage),
  prefixed('/library/create', LibraryCreateEditPage),
  prefixed('/library/edit/:id', LibraryCreateEditPage),
  prefixed('/sitemaps', SiteMapsCRUDPage),
  prefixed('/sitemap/create', SiteMapsCreateEditPage),
  prefixed('/sitemap/edit/:id', SiteMapsCreateEditPage),
  prefixed('/routes', RoutesCRUDPage),
  prefixed('/route/create', RoutesCreateEditPage),
  prefixed('/route/edit/:id', RoutesCreateEditPage),
];

export default KadenceBioRoutes;
