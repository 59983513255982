import { useState } from 'react';
import {
  setViewMode,
  ViewMode,
} from '../../../../features/enrollment_scenario/enrollment_scenario_slice';
import { useAppDispatch } from '../../../../app/hooks';

type Button = {
  name: string;
  viewMode: ViewMode;
};

const buttons: Button[] = [
  { name: 'Table', viewMode: 'table' },
  { name: 'Map', viewMode: 'map' },
  { name: 'Timeline', viewMode: 'timeline' },
];

export default function ViewModeToggle() {
  const [activeButton, setActiveButton] = useState('Table');
  const dispatch = useAppDispatch();

  const changeViewMode = (button: Button) => {
    setActiveButton(button.name);
    dispatch(setViewMode(button.viewMode));
  };

  return (
    <div className="bg-[#41414B] p-1 rounded-lg inline-flex">
      {buttons.map((button) => (
        <button
          key={button.name}
          className={`px-4 py-2 rounded-md text-sm font-medium transition-colors duration-200 ${
            activeButton === button.name
              ? 'bg-primary text-gray-800'
              : 'bg-transparent text-gray-300 hover:text-white'
          }`}
          onClick={() => changeViewMode(button)}
        >
          {button.name}
        </button>
      ))}
    </div>
  );
}
