import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setInclCriteria,
  setExclCriteria,
  setPatientNumber,
  setCountries,
  setInitiativeId,
  clearFlow3,
} from '@/features/flow_3/flow_3_slice';
import { RootState } from '../../../../app/store';
import CountrySelector from './CountrySelector';
import CriteriaView from './CriteriaView';
import StudiesDropdown from '@/pages/dashboard/components/v2/StudySelector';

type StudyData = {
  id: number;
  name: string;
  date: string;
  selected: boolean;
};

type StudyTableProps = {
  studies: StudyData[];
};

export default function Sidebar({ studies: initialStudies }: StudyTableProps) {
  const dispatch = useDispatch();

  console.log('Sidebar LINE 30 initialStudies: ', initialStudies);

  const [studies, setStudies] = useState<StudyData[]>(initialStudies);
  const countries = useSelector((state: RootState) => state.flow3.countries);
  const inclusions = useSelector(
    (state: RootState) => state.flow3.inclCriteria,
  );
  const exclusions = useSelector(
    (state: RootState) => state.flow3.exclCriteria,
  );

  useEffect(() => {
    dispatch(setCountries(countries));
    dispatch(setInclCriteria(inclusions));
    dispatch(setExclCriteria(exclusions));
  }, [dispatch]);

  useEffect(() => {
    console.log('Sidebar LINE 46: ', studies);
  }, [studies]);

  return (
    <div className="flex flex-col w-[296px] min-w-[296px] max-w-[296px] overflow-y-auto py-4 bg-zinc-800 border-r border-[#494855]">
      <StudiesDropdown studies={initialStudies} setStudies={setStudies} />
      <CountrySelector />
      {/*<AgeRangeSelector />*/}

      <CriteriaView type="inclusion" />
      <CriteriaView type="exclusion" />
    </div>
  );
}
