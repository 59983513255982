import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import NeurouteLogo from '@/components/NeurouteLogo';
import ScrollableContainer from '@/components/ScrollableContainer';
import ReactPlayer from 'react-player';
import { parseSRT } from './components/srtParser';
import Ruler from './components/Ruler';
import ArrowLeftCircleIcon from '@/images/arrow_left_circle.svg';

import { Author, Comment, StudyPage, SubtitleEntry } from '@/types/index';

import { mockedSections as sections } from '@/mocks/mockedSections';
import { mockedStudyData } from '@/mocks/mockedStudyData';

import {
  useGetInitiativeQuery,
  useCreateInitiativeMutation,
  useUpdateInitiativeMutation,
} from '@/api/slices/initiative/InitiativeApi';

const IndividualPage: React.FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const {
    data: initiative,
    isLoading,
    error: fetchError,
  } = useGetInitiativeQuery({ id: id || '' }, { skip: !id });

  const [studyPage, setStudyPage] = useState<StudyPage>(mockedStudyData);
  const [comments] = useState<Comment[]>([]);
  const [currentUser, setCurrentUser] = useState<Author>();
  const [currentTimeMs, setCurrentTimeMs] = useState<number>(0);
  const [subtitles, setSubtitles] = useState<SubtitleEntry[]>([]);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [isReady, setIsReady] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null);

  const subtitlesRef = useRef<HTMLDivElement>(null);
  const playerRef = useRef<ReactPlayer>(null);

  useEffect(() => {
    if (initiative) {
      /*
      setStudyPage({
        title: initiative.title,
        author: initiative.author,
        collaborators: initiative.collaborators,
        lastUpdated: initiative.lastUpdated,
        transcript: '',
        srt: initiative.srt,
        // Add other fields as necessary
      });
      */
      setSubtitles(parseSRT(initiative.srt || ''));
    }
  }, [initiative]);

  useEffect(() => {
    scrollToHighlightedSubtitle();
  }, [currentTimeMs]);

  useEffect(() => {
    const parsedSubtitles = parseSRT(studyPage.srt);
    setSubtitles(parsedSubtitles);
  }, [studyPage.srt]);

  useEffect(() => {
    scrollToHighlightedSubtitle();
  }, [currentTimeMs]);

  const handleRulerChange = (section: string, ms: number) => {
    setCurrentTimeMs(ms);
    if (playerRef.current) {
      playerRef.current.seekTo(ms / 1000, 'seconds');
    }
  };

  const handleSubtitleClick = (startTimeMs: number) => {
    console.log(startTimeMs);
    setCurrentTimeMs(startTimeMs);
    if (playerRef.current) {
      playerRef.current.seekTo(startTimeMs / 1000, 'seconds');
    }
  };

  const scrollToHighlightedSubtitle = () => {
    if (subtitlesRef.current && currentSubtitle) {
      const container = subtitlesRef.current;
      const highlightedElement = container.querySelector(
        '.opacity-100',
      ) as HTMLElement;

      if (highlightedElement) {
        const containerRect = container.getBoundingClientRect();
        const highlightedRect = highlightedElement.getBoundingClientRect();

        const relativeTop = highlightedRect.top - containerRect.top;
        const centerPosition =
          relativeTop - containerRect.height / 2 + highlightedRect.height / 2;

        container.scrollTop += centerPosition;
      }
    }
  };

  const handleProgress = (state: { playedSeconds: number }) => {
    const ms = Math.floor(state.playedSeconds * 1000);
    console.log(ms);
    setCurrentTimeMs(ms);
  };

  const handleReady = () => {
    setIsReady(true);
    setError(null);
  };

  const handleError = (e: any) => {
    console.error('ReactPlayer error:', e);
    setError(
      'An error occurred while loading the video. Please try again later.',
    );
  };

  const currentSection = useMemo(() => {
    return (
      sections.find(
        (section) =>
          currentTimeMs >= section.startTime &&
          currentTimeMs <= section.endTime,
      ) || sections[0]
    ); // Default to the first section if no match is found
  }, [currentTimeMs]);

  const currentSubtitle = useMemo(() => {
    return subtitles.find(
      (subtitle, index, arr) =>
        currentTimeMs >= subtitle.startTime &&
        (index === arr.length - 1 || currentTimeMs < arr[index + 1].startTime),
    );
  }, [subtitles, currentTimeMs]);

  const renderSubtitleParts = () => {
    return subtitles.map((subtitle, index) => {
      const isActive = subtitle === currentSubtitle;
      return (
        <span
          key={index}
          className={`
            text-white 
            transition-all 
            duration-300 
            ease-in-out 
            cursor-pointer 
            ${isActive ? 'opacity-100' : 'opacity-50'}
            hover:opacity-70
          `}
          onClick={() => handleSubtitleClick(subtitle.startTime)}
        >
          {subtitle.text}{' '}
        </span>
      );
    });
  };

  // Function to count distinct comment authors
  const countDistinctAuthors = (comments: Comment[]): number => {
    return 1;
  };

  const handleCommentsChanged = (comments: Comment[]) => {
    // Update studyPage.collaborators when comments change
    const collaboratorsCount = countDistinctAuthors(comments);
    setStudyPage((prevStudyPage) => ({
      ...prevStudyPage,
      collaborators: collaboratorsCount,
    }));
  };

  return (
    <div className="flex flex-col w-full bg-[#1F1F23] overflow-hidden">
      <div className="flex flex-row h-full">
        {/* Left column start */}
        <div className="w-1/3 flex-shrink-0 p-4 pl-8 flex flex-col h-full">
          <div className="h-16 flex items-center justify-between mb-4">
            <NeurouteLogo />
            <img
              src={ArrowLeftCircleIcon}
              alt="Back"
              className="w-8 h-8 cursor-pointer"
              // onClick={() => navigate('/initiatives')}
              onClick={() => navigate('/graph')}
            />
          </div>

          <h1 className="mt-16 mb-16 text-white text-5xl leading-none font-medium tracking-tighter">
            {studyPage.title}
          </h1>

          <div className="mb-16">
            <div className="flex items-center space-x-4">
              <div className="rounded-full p-0.25 border border-white border-opacity-50 bg-none">
                <div className="rounded-full p-1 bg-gray-900">
                  <img
                    // src={studyPage.author.avatar}
                    alt="Avatar"
                    className="w-10 h-10 rounded-full"
                  />
                </div>
              </div>
              <div>
                <h2 className="text-white text-md">
                  {/* {studyPage.author.firstName} {studyPage.author.lastName} */}
                </h2>
                <p className="text-white text-sm opacity-80">
                  {studyPage.collaborators} collaborators{' '}
                  <span className="mx-1">•</span> {studyPage.lastUpdated}
                </p>
              </div>
            </div>
          </div>

          {/* Subtitle parts start - Scrollable */}
          <div
            className="flex flex-col overflow-hidden"
            style={{ height: 'calc(100vh - 500px)' }}
          >
            <div className="text-white text-1xl font-bold mb-4">
              {currentSection.text} {Math.floor(currentTimeMs / 1000)}s
            </div>
            <ScrollableContainer ref={subtitlesRef}>
              <div className="text-3xl">{renderSubtitleParts()}</div>
            </ScrollableContainer>
          </div>
          {/* Subtitle parts end */}
        </div>

        {/* Main content */}
        <main className="w-1/3 flex-shrink-0 p-4 flex flex-col">
          <div
            className="pb-4 relative"
            style={{ width: '100%', height: '736px' }}
          >
            <ReactPlayer
              ref={playerRef}
              url="/assets/invideo-ai-1080_Ozempic_for_Parkinson_2024-08-09-compressed.mp4"
              width="100%"
              height="100%"
              autoPlay={true}
              muted={true}
              controls
              playing={isPlaying}
              onReady={handleReady}
              onPlay={() => setIsPlaying(true)}
              onPause={() => setIsPlaying(false)}
              onProgress={handleProgress}
              onError={handleError}
              progressInterval={100}
              /*
              config={{
                youtube: {
                  playerVars: { origin: window.location.origin },
                },
              }}
                */
            />
            {!isReady && !error && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
                Loading video...
              </div>
            )}
            {error && (
              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white">
                {error}
              </div>
            )}
            {/* 
            <div className="absolute inset-0 bg-black opacity-50 pointer-events-none"></div>
            <SubtitleOverlay
              subtitles={subtitles}
              currentTimeMs={currentTimeMs}
            />
            */}
          </div>

          <div className="flex-grow pt-8 pr-8">
            {/* Add more content here if needed */}
          </div>
        </main>

        {/* Right column start */}
        <div className="w-1/3 flex-shrink-0 p-4 flex flex-col">
          <div className="h-16 flex items-center justify-left mb-4">
            <span className="text-3xl leading-tight font-normal text-white opacity-60">
              work together,
            </span>
            <span className="text-3xl leading-tight font-normal text-white opacity-100 pl-1">
              make magic
            </span>
          </div>

          <div className="flex flex-col">
            <div className="pb-4">
              <Ruler valueMs={currentTimeMs} onChange={handleRulerChange} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndividualPage;
