import clsx from 'clsx';
import randImage from '../../../images/rand.svg';

type Props = {
  value: number;
  bold?: boolean;
  medium?: boolean;
  imageClassName?: string;
  textClassName?: string;
};

export default function RandView({
  value,
  bold,
  medium,
  imageClassName,
  textClassName,
}: Props) {
  return (
    <div className="flex flex-row h-min items-center">
      <img
        src={randImage}
        alt="rand icon"
        className={clsx('h-3 mr-1.5', imageClassName)}
      />
      <span
        className={clsx(
          `text-base leading-5`,
          bold && 'font-semibold',
          medium && 'font-medium',
          textClassName,
        )}
        style={{ color: '#00C7A8' }}
      >
        {value}
      </span>
    </div>
  );
}
