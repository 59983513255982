import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import NeurouteAnimated from './components/NeurouteAnimated';
import { RootState } from '../../app/store';
import { setDisease } from '@/features/flow_3/flow_3_slice';

import {
  ArrowRightIcon,
  CircleButton,
  InputLikeElement,
  InnerText,
  InvisibleInput,
} from './components/StyledElements';
import HamburgerMenu from '@/components/HamburgerMenu';
import { useAuth } from '../auth/AuthContext';

const Step2Page: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state: RootState) => state.flow3);
  const { currentUser } = useAuth();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(setDisease(e.target.value));
  };

  const handleNextStep = () => {
    navigate('/flow-3-wireframes/step-3');
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.stopPropagation();
      handleNextStep();
    }
  };

  return (
    <ProjectLayout title="Projects">
      <div className="flex flex-row content-between items-center gap-4">
        <HamburgerMenu />
      </div>
      <main className="flex-grow flex flex-col items-center justify-center p-4">
        <NeurouteAnimated />

        <InputLikeElement>
          <InnerText>I want to help patients with</InnerText>
          <InvisibleInput
            placeholder="Type here..."
            value={data.disease}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            autoFocus
          />
          <CircleButton onClick={handleNextStep}>
            <ArrowRightIcon />
          </CircleButton>
        </InputLikeElement>
      </main>
    </ProjectLayout>
  );
};

export default Step2Page;
