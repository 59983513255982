import { RootState } from '../../app/store';
import { mappedStudyMethodsToText, mappedYearsToText } from './flow_3_slice';

export const constructSearchString = (state: RootState): string => {
  const { disease, helpHow, studyMethod, years } = state.flow3;
  const studyMethodText = mappedStudyMethodsToText[studyMethod];
  const yearsText = mappedYearsToText[years];

  return `${disease} ${helpHow} using ${studyMethodText} ${yearsText}`.trim();
};
