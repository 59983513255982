import React from 'react';

interface HeaderTitleProps {
  prefix: string;
  value?: string;
  className?: string;
}

const HeaderTitle: React.FC<HeaderTitleProps> = ({
  prefix,
  value,
  className = '',
}) => {
  return (
    <h2
      className={`text-[45px] font-normal font-satoshi-variable leading-[1.2em] ${className}`}
    >
      <span className="text-[#9491a7]">{prefix} </span>
      {value && <span className="text-white">{value}</span>}
    </h2>
  );
};

export default HeaderTitle;
