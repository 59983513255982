import bgImg from '../../../images/bg.png';
import loginImg from '../../../images/login_image.png';

type Props = {
  children: React.ReactNode;
};

export default function AuthLayout(props: Props) {
  const { children } = props;

  return (
    <>
      <div className="flex min-h-full flex-1 bg-[#1F1F23]">
        <img
          src={bgImg}
          alt="bg"
          className="absolute inset-0 w-full h-full object-cover"
        />

        <div className="flex flex-row w-full px-2 md:px-0 md:w-auto md:mx-auto my-auto h-[840px] max-h-[840px] rounded-lg shadow-lg overflow-hidden z-10">
          <div className="flex flex-col w-full md:w-auto justify-center px-12 py-12 sm:px-12 lg:flex-none bg-[#2C2B32]">
            <div className="sm:mx-auto w-full max-w-[484px] lg:w-[484px]">
              {children}
            </div>
          </div>

          <div className="hidden sm:block">
            <img
              className="object-cover lg:max-w-[444px] lg:w-[444px] sm:max-w-[140px] sm:w-[140px] md:max-w-[240px] h-full bg-[#00C7A8]"
              src={loginImg}
              alt="Auth Image"
            />
          </div>
        </div>
      </div>
    </>
  );
}
