import { useState } from 'react';
import logoImage from '../../../../images/logo_gray.svg';
import ViewModeToggle from './ViewModeToggle';
import { BackButton } from './BackButton';
import { MoreButton } from './MoreButton';
import { useNavigate } from 'react-router-dom';
import SiteNavigation from './SiteNavigation';
import { useAppSelector } from '../../../../app/hooks';
import { selectStudyName } from '@/features/create_study/create_study_slice';
import HamburgerMenu from '@/components//HamburgerMenu';
import { useAuth } from '@/pages/auth/AuthContext';

type Props = {
  projectId?: string;
};

export function TopBar({ projectId }: Props) {
  const navigate = useNavigate();
  const studyName = useAppSelector(selectStudyName);
  const { currentUser } = useAuth();

  return (
    <div className="z-20 w-full h-14 bg-zinc-800 border-b border-[#494855] justify-start items-center inline-flex">
      <div className="grow shrink basis-0 h-14 justify-start items-center flex">
        <div className="hidden sm:inline-flex px-5 py-4 border-r border-[#494855] flex-col justify-start items-start gap-2 w-[296px]">
          <img
            src={logoImage}
            className="w-[116px] cursor-pointer"
            onClick={() => navigate('/graph')}
            alt=""
          />
        </div>

        <div className="grow shrink basis-0 self-stretch px-5 justify-start items-center flex">
          {/*<BackButton*/}
          {/*  onClick={() => navigate(`/flow-3-wireframes/step-6-7/${projectId}`)}*/}
          {/*/>*/}
          <div className="text-white text-xl font-bold ml-3 whitespace-nowrap">
            {studyName}
          </div>
          {/*<MoreButton className="ml-2" />*/}
        </div>
      </div>

      <div className="flex flex-row grow justify-center items-center h-full">
        <SiteNavigation allSitesCount={6} savedSitesCount={3} />
      </div>

      <div className="px-5 justify-start items-center gap-4 flex">
        <ViewModeToggle />

        <div className="h-8 border-r border-zinc-700"></div>

        <HamburgerMenu />
      </div>
    </div>
  );
}
