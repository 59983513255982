import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface HealthProfileState {
  currentStep: number;
  profileName: string;
  profileLocation: string;
  // profileOccupation: string;
  profileEmail: string;
  // profilePhone: string;
}

const initialState: HealthProfileState = {
  currentStep: 1,
  profileName: '',
  profileLocation: '',
  // profileOccupation: '',
  profileEmail: '',
  // profilePhone: '',
};

export const healthProfileSlice = createSlice({
  name: 'healthProfile',
  initialState,
  reducers: {
    setCurrentStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setProfileName: (state, action: PayloadAction<string>) => {
      state.profileName = action.payload;
    },
    setProfileLocation: (state, action: PayloadAction<string>) => {
      state.profileLocation = action.payload;
    },
    // setProfileOccupation: (state, action: PayloadAction<string>) => {
    //   state.profileOccupation = action.payload;
    // },
    setProfileEmail: (state, action: PayloadAction<string>) => {
      state.profileEmail = action.payload;
    },
    // setProfilePhone: (state, action: PayloadAction<string>) => {
    //   state.profilePhone = action.payload;
    // },
  },
});

export const {
  setCurrentStep,
  setProfileName,
  setProfileLocation,
  // setProfileOccupation,
  setProfileEmail,
  // setProfilePhone,
} = healthProfileSlice.actions;

export default healthProfileSlice.reducer;
