import { createSlice, PayloadAction, nanoid } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { set } from 'zod';

interface Criterion {
  id: string;
  text: string;
}

interface StudyCreationState {
  studyName: string;
  studySubject: string;
  studyMethod: string;
  drugClass: string;
  countries: string; // todo: should be array of strings
  ageMin: number;
  ageMax: number;
  peopleCount: number;
  scenarioName: string;
  inclusionCriteria: Criterion[];
  exclusionCriteria: Criterion[];
}

const initialState: StudyCreationState = {
  studyName: '',
  studySubject: '',
  studyMethod: '',
  drugClass: '',
  countries: '',
  ageMin: 10,
  ageMax: 90,
  peopleCount: 10000,
  scenarioName: '',
  inclusionCriteria: [],
  exclusionCriteria: [],
  /*
  inclusionCriteria: [
    {
      id: '1',
      text: 'Antibiotics ordered or administered (as surrogate for suspected or confirmed infection as primary reason for hospital admission as determined by primary team)',
    },
    {
      id: '2',
      text: 'Serum lactate ≥ 2.0 mmol/L or transient hypotension (any systolic blood pressure < 100 mmHg).',
    },
    {
      id: '3',
      text: 'Identification within 12 hours of a qualifying lactate measurement or hypotension episode.',
    },
    {
      id: '4',
      text: 'Hospital admission planned for suspected infection as primary reasons for admission (per emergency medicine team).',
    },
  ],
  exclusionCriteria: [
    {
      id: '1',
      text: 'Antibiotics ordered or administered (as surrogate for suspected or confirmed infection as primary reason for hospital admission as determined by primary team)',
    },
    {
      id: '2',
      text: 'Serum lactate ≥ 2.0 mmol/L or transient hypotension (any systolic blood pressure < 100 mmHg).',
    },
    {
      id: '3',
      text: 'Identification within 12 hours of a qualifying lactate measurement or hypotension episode.',
    },
    {
      id: '4',
      text: 'Hospital admission planned for suspected infection as primary reasons for admission (per emergency medicine team).',
    },
  ],
  */
};

const isCriterion = (payload: any): payload is Criterion => {
  return (
    (payload as Criterion).id !== undefined &&
    (payload as Criterion).text !== undefined
  );
};

export const studyCreationSlice = createSlice({
  name: 'studyCreation',
  initialState,
  reducers: {
    clearStudyCreation: (state) => {
      return initialState;
    },
    // step 1
    setStudyName: (state, action: PayloadAction<string>) => {
      state.studyName = action.payload;
    },
    setStudySubject: (state, action: PayloadAction<string>) => {
      state.studySubject = action.payload;
    },
    setStudyMethod: (state, action: PayloadAction<string>) => {
      state.studyMethod = action.payload;
    },
    setDrugClass: (state, action: PayloadAction<string>) => {
      state.drugClass = action.payload;
    },
    // step 2
    setCountries: (state, action: PayloadAction<string>) => {
      state.countries = action.payload;
    },
    setAgeMin: (state, action: PayloadAction<number>) => {
      state.ageMin = action.payload;
    },
    setAgeMax: (state, action: PayloadAction<number>) => {
      state.ageMax = action.payload;
    },
    setPeopleCount: (state, action: PayloadAction<number>) => {
      state.peopleCount = action.payload;
    },
    setScenarioName: (state, action: PayloadAction<string>) => {
      state.scenarioName = action.payload;
    },
    // step 3
    addInclusionCriterion: (
      state,
      action: PayloadAction<Criterion | string>,
    ) => {
      if (isCriterion(action.payload)) {
        state.inclusionCriteria.push({
          id: action.payload.id,
          text: action.payload.text,
        });
      } else {
        state.inclusionCriteria.push({ id: nanoid(), text: action.payload });
      }
    },
    updateInclusionCriterion: (state, action: PayloadAction<Criterion>) => {
      const index = state.inclusionCriteria.findIndex(
        (c) => c.id === action.payload.id,
      );
      if (index !== -1) {
        state.inclusionCriteria[index] = action.payload;
      }
    },
    removeInclusionCriterion: (state, action: PayloadAction<string>) => {
      state.inclusionCriteria = state.inclusionCriteria.filter(
        (c) => c.id !== action.payload,
      );
    },
    // step 4
    addExclusionCriterion: (
      state,
      action: PayloadAction<Criterion | string>,
    ) => {
      if (isCriterion(action.payload)) {
        state.exclusionCriteria.push({
          id: action.payload.id,
          text: action.payload.text,
        });
      } else {
        state.exclusionCriteria.push({ id: nanoid(), text: action.payload });
      }
    },
    updateExclusionCriterion: (state, action: PayloadAction<Criterion>) => {
      const index = state.exclusionCriteria.findIndex(
        (c) => c.id === action.payload.id,
      );
      if (index !== -1) {
        state.exclusionCriteria[index] = action.payload;
      }
    },
    removeExclusionCriterion: (state, action: PayloadAction<string>) => {
      state.exclusionCriteria = state.exclusionCriteria.filter(
        (c) => c.id !== action.payload,
      );
    },
  },
});

export const {
  clearStudyCreation,
  setStudyName,
  setStudySubject,
  setStudyMethod,
  setDrugClass,
  setCountries,
  setAgeMin,
  setAgeMax,
  setPeopleCount,
  setScenarioName,
  addInclusionCriterion,
  updateInclusionCriterion,
  removeInclusionCriterion,
  addExclusionCriterion,
  updateExclusionCriterion,
  removeExclusionCriterion,
} = studyCreationSlice.actions;

export const selectStudyName = (state: RootState) =>
  state.studyCreation.studyName;
export const selectStudySubject = (state: RootState) =>
  state.studyCreation.studySubject;
export const selectDrugClass = (state: RootState) =>
  state.studyCreation.drugClass;
export const selectStudyMethod = (state: RootState) =>
  state.studyCreation.studyMethod;
export const selectCountries = (state: RootState) =>
  state.studyCreation.countries;
export const selectAgeMin = (state: RootState) => state.studyCreation.ageMin;
export const selectAgeMax = (state: RootState) => state.studyCreation.ageMax;
export const selectPeopleCount = (state: RootState) =>
  state.studyCreation.peopleCount;
export const selectScenarioName = (state: RootState) =>
  state.studyCreation.scenarioName;
export const selectInclusionCriteria = (state: RootState) => {
  return state.studyCreation.inclusionCriteria;
};
export const selectExclusionCriteria = (state: RootState) => {
  return state.studyCreation.exclusionCriteria;
};

export default studyCreationSlice.reducer;
