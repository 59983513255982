import React, { useCallback, useState } from 'react';
import { debounce } from 'lodash';
import { useAppDispatch } from '../../../../app/hooks';
import {
  setSelectedStudyNameOrId,
  useSelectedStudyNameOrId,
} from '../../../../features/enrollment_scenario/enrollment_scenario_slice';

export const StudyNameOrIdSelector = () => {
  // This is the term we began typing, it is saved in the Redux store for use globally across the application
  const selectedStudyNameOrId: string | null = useSelectedStudyNameOrId();

  // Local state to manage the input value
  const [inputValue, setInputValue] = useState(selectedStudyNameOrId ?? '');

  // Dispatch allows us to make changes to the Redux store
  const dispatch = useAppDispatch();

  // Debounced function to handle input change
  const debouncedHandleInputChange = useCallback(
    debounce((value: string) => {
      dispatch(setSelectedStudyNameOrId(value));
    }, 300), // 300ms debounce delay
    [dispatch],
  );

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const newValue: string = event.target.value;
    setInputValue(newValue); // Update local state immediately
    debouncedHandleInputChange(newValue); // Debounce the Redux store update
  };

  return (
    <div className="p-4 rounded-lg">
      <input
        type="text"
        placeholder="Study name or id..."
        value={inputValue} // Use local state for value
        onChange={handleInputChange}
        className="w-full bg-[#41414B] border-0 text-white placeholder-gray-400 px-3 py-2 rounded-md mb-2"
      />
    </div>
  );
};
