import React from 'react';
import ReactPlayer from 'react-player';

const fontSize = '151px';
const color = '#3B3944';

const NeurouteAnimated: React.FC = () => {
  return (
    <div className="flex items-center justify-center">
      <span style={{ fontSize, color }}>neur</span>
      <div
        className="flex items-center justify-center relative"
        style={{
          position: 'relative',
          width: '80px', // Smaller mask radius
          height: '80px', // Smaller mask radius
          overflow: 'hidden',
          borderRadius: '50%',
          top: '22px',
        }}
      >
        <ReactPlayer
          url="/assets/animated-ball.mp4"
          playing
          loop
          muted
          width="112px"
          height="112px"
          className="inline-block"
          style={{
            position: 'absolute',
            width: '112px',
            height: '112px',
            left: '-16px',
            top: '-16px',
          }}
        />
      </div>
      <span style={{ fontSize, color }}>ute</span>
    </div>
  );
};

export default NeurouteAnimated;
