import React from 'react';
import KadenceBioLogo from './KadenceBioLogo';

interface KadenceLogoSwitcherProps {
  isExpanded: boolean;
  iconSrc: string;
}

const KadenceLogoSwitcher: React.FC<KadenceLogoSwitcherProps> = ({
  isExpanded,
  iconSrc,
}) => {
  return (
    <div className="h-12 flex items-center">
      {isExpanded ? (
        <KadenceBioLogo />
      ) : (
        <img src={iconSrc} alt="KadenceBio" className="w-6 h-6" />
      )}
    </div>
  );
};

export default KadenceLogoSwitcher;
