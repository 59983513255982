import React, { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import { auth, db } from '@/core/setup_firebase';
import { doc, setDoc, addDoc, collection } from 'firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';

import ArrowRightSvg from '@/images/arrow_right.svg';

// Left Section Components
import SideLabel from './sideSections/SideLabel';

// Main Section Components
import Step1Indication from './sections/Step1Indication';

// Attempt number 987654321
import InterventionTypeSection from './stepSections/InterventionTypeSection';
import RelevanceSection from './stepSections/RelevanceSection';
import PhaseSection from './stepSections/PhaseSection';
import PatientCountSection from './stepSections/PatientCountSection';
import InclusionCriteriaSection from './stepSections/InclusionCriteriaSection';
import ExclusionCriteriaSection from './stepSections/ExclusionCriteriaSection';
import TimingSection from './stepSections/TimingSection';

// Import types
import HeaderTitle from './sections/HeaderTitle';
import SearchAndDisplay from './sections/SearchAndDisplay';
import { fetchSimilarConditions } from '@/api/brainstormApi';
import BrainstormDoneSection from './sections/BrainstormDoneSection';
import InclusionCriteriaListSection from './sections/InclusionCriteriaListSection';
import ExclusionCriteriaListSection from './sections/ExclusionCriteriaListSection';
import {
  useLoadBrainstorm,
  initBrainstormData,
} from '@/hooks/useLoadBrainstorm';

const showDebug = false;

if (showDebug) {
  initBrainstormData.indicationFreeText = 'Diabetes';
  initBrainstormData.interventionFreeText = 'giving them drugs';
  initBrainstormData.interventionType = 'Drug';
  initBrainstormData.patientCount = 149;
  initBrainstormData.inclusionCriteria = [];
  initBrainstormData.exclusionCriteria = [];
  initBrainstormData.timing = '12/25';
}

const BrainstormCreateEditPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const {
    brainstorm,
    setBrainstorm,
    loading: brainstormLoading,
    fetchBrainstorm,
  } = useLoadBrainstorm(initBrainstormData);

  // Add ref for the main content area
  const mainContentRef = useRef<HTMLDivElement>(null);

  // Function to scroll to bottom smoothly
  const scrollToBottom = () => {
    if (mainContentRef.current) {
      const scrollContainer = mainContentRef.current;
      scrollContainer.scrollTo({
        top: scrollContainer.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  useEffect(() => {
    let isSubscribed = true;

    const checkAuth = async () => {
      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (!isSubscribed) return;

        if (user) {
          if (id && id !== 'create') {
            const exists = await fetchBrainstorm(id);
            if (!exists && isSubscribed) {
              navigate('/kadencebio/brainstorms');
            }
          }
        } else {
          navigate('/login');
        }
      });

      return unsubscribe;
    };

    const unsubscribe = checkAuth();
    return () => {
      isSubscribed = false;
      unsubscribe.then((fn) => fn());
    };
  }, [id, navigate, fetchBrainstorm]);

  // Helper function to check if a string matches a wildcard pattern
  const matchesWildcard = (str: string, pattern: string) => {
    if (pattern.endsWith('*')) {
      const prefix = pattern.slice(0, -1);
      return str.startsWith(prefix);
    }
    return str === pattern;
  };

  // Main function to check current step
  const isCurrentStep = (field: string | string[]) => {
    if (Array.isArray(field)) {
      return field.some((f) => matchesWildcard(brainstorm.currentStep, f));
    }
    return matchesWildcard(brainstorm.currentStep, field);
  };

  const isSection = (field: string) => {
    // Check if current step matches the pattern
    const isCurrentStepMatch = isCurrentStep(`${field}*`);

    // Check if any completed step starts with the field
    const hasCompletedStep = brainstorm.completedSteps.some((step) =>
      step.startsWith(field),
    );

    return isCurrentStepMatch || hasCompletedStep;
  };

  const setCurrentStep = (field: string) => {
    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev, currentStep: field };
      return updatedBrainstorm;
    });
  };
  const nextStep = (nextStep: string, alsoComplete?: string) => {
    setBrainstorm((prev) => {
      // set the next step as the current step
      const { currentStep: prevStep } = prev;
      const updatedBrainstorm = { ...prev, currentStep: nextStep };
      // add current step to completed steps
      if (!updatedBrainstorm.completedSteps.includes(prevStep)) {
        updatedBrainstorm.completedSteps = [
          ...updatedBrainstorm.completedSteps,
          prevStep,
        ];
      }
      // add additional step to completed steps if provided
      if (alsoComplete) {
        if (!updatedBrainstorm.completedSteps.includes(alsoComplete)) {
          updatedBrainstorm.completedSteps = [
            ...updatedBrainstorm.completedSteps,
            alsoComplete,
          ];
        }
      }
      return updatedBrainstorm;
    });

    // Add a small delay to ensure the new content is rendered before scrolling
    setTimeout(scrollToBottom, 100);
  };

  const completeStep = (field: string | string[]) => {
    if (Array.isArray(field)) {
      field.forEach((f) => completeStep(f));
      return;
    }

    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev };
      // Add the field to completedSteps if it's not already there
      if (!updatedBrainstorm.completedSteps.includes(field)) {
        updatedBrainstorm.completedSteps = [
          ...updatedBrainstorm.completedSteps,
          field,
        ];
      }
      return updatedBrainstorm;
    });

    // Add a small delay to ensure the new content is rendered before scrolling
    setTimeout(scrollToBottom, 100);
  };

  const removeStep = (field: string | string[]) => {
    if (Array.isArray(field)) {
      field.forEach((f) => removeStep(f));
      return;
    }

    setBrainstorm((prev) => {
      const updatedBrainstorm = { ...prev };

      // Check if wildcard is used (ends with *)
      if (field.endsWith('*')) {
        const prefix = field.slice(0, -1); // Remove the * character
        // Filter out all steps that start with the prefix
        updatedBrainstorm.completedSteps =
          updatedBrainstorm.completedSteps.filter(
            (step) => !step.startsWith(prefix),
          );
      } else {
        // Regular exact match removal
        updatedBrainstorm.completedSteps =
          updatedBrainstorm.completedSteps.filter((step) => step !== field);
      }

      return updatedBrainstorm;
    });
  };

  const saveBrainstorm = async () => {
    // Create updated brainstorm with completed step, ensuring no duplicates
    const updatedBrainstorm = {
      ...brainstorm,
      completedSteps: Array.from(
        new Set([...brainstorm.completedSteps, 'brainstorm.done']),
      ),
    };

    try {
      if (id) {
        await setDoc(doc(db, 'brainstorms', id), updatedBrainstorm);
      } else {
        const newBrainstormRef = await addDoc(collection(db, 'brainstorms'), {
          ...updatedBrainstorm,
          userId: auth.currentUser?.uid,
          createdAt: new Date(),
        });
        navigate(`/kadencebio/brainstorm/edit/${newBrainstormRef.id}`);
      }

      // Update state after successful save
      completeStep('brainstorm.done');
      console.log('Brainstorm saved successfully');
    } catch (error) {
      console.error('Error saving brainstorm: ', error);
    }
  };

  const notCompleted = (field: string) =>
    !brainstorm.completedSteps.includes(field);
  const hasStep = (field: string) => brainstorm.completedSteps.includes(field);
  const anyStep = (fields: string[]) => fields.some((field) => hasStep(field));
  const andStep = (fields: string[]) => fields.every((field) => hasStep(field));

  console.log('current', brainstorm.currentStep);
  console.log('completed', brainstorm.completedSteps);

  const handleBuildLibrary = async () => {
    try {
      if (id) {
        await setDoc(doc(db, 'brainstorms', id), brainstorm);
        navigate(`/kadencebio/library/create/?brainstormId=${brainstorm.id}`);
      } else {
        const newBrainstormRef = await addDoc(collection(db, 'brainstorms'), {
          ...brainstorm,
          userId: auth.currentUser?.uid,
          createdAt: new Date(),
        });
        navigate(
          `/kadencebio/library/create/?brainstormId=${newBrainstormRef.id}`,
        );
      }
      console.log('Brainstorm saved successfully');
    } catch (error) {
      console.error('Error saving brainstorm: ', error);
    }
  };

  const renderLeftColumn = () => {
    if (isCurrentStep('indication.edit')) return null;

    const sideProps = {
      brainstorm,
      setBrainstorm,
      isSection,
      isCurrentStep,
      hasStep,
      notCompleted,
      nextStep,
      completeStep,
      removeStep,
      setCurrentStep,
    };

    return (
      <div className="w-1/4 flex flex-col min-w-[200px] ml-10 align-top">
        <SideLabel label="Indication" field="indication" {...sideProps} />
        <SideLabel
          label="Intervention"
          field="interventionType"
          {...sideProps}
        />
        <SideLabel label="Relevance" field="relevance" {...sideProps} />
        <SideLabel label="Phase" field="phase" {...sideProps} />
        <SideLabel label="Patient No." field="patientCount" {...sideProps} />
        <SideLabel label="I/C" field="inclusionCriteria" {...sideProps} />
        <SideLabel label="E/C" field="exclusionCriteria" {...sideProps} />
        <SideLabel label="Timing" field="timing" {...sideProps} />
      </div>
    );
  };

  const renderMainSection = () => {
    if (isCurrentStep('indication.edit')) {
      return (
        <Step1Indication
          brainstorm={brainstorm}
          setBrainstorm={setBrainstorm}
          onNextStep={() => {
            setBrainstorm((prev) => ({
              ...prev,
              similarConditions: [],
              similarConditionsCount: 0,
            }));
            nextStep('indication.search');
          }}
        />
      );
    }

    const sectionProps = {
      brainstorm,
      setBrainstorm,
      isSection,
      isCurrentStep,
      hasStep,
      notCompleted,
      nextStep,
      completeStep,
      removeStep,
      setCurrentStep,
    };

    return (
      <div className="flex flex-col w-3/5">
        <div
          ref={mainContentRef}
          className="overflow-y-auto max-h-[80vh] mt-4 py-2 pr-6"
        >
          {/* SECTION INDICATION */}
          {hasStep('indication.edit') && (
            <HeaderTitle
              prefix="I want to help patients with"
              value={brainstorm.indication}
            />
          )}

          {(isCurrentStep('indication.search') ||
            hasStep('indication.search')) && (
            <SearchAndDisplay
              brainstorm={brainstorm}
              setBrainstorm={setBrainstorm}
              query={async () => {
                if (brainstorm.similarConditions.length > 0) {
                  return;
                }
                const data = await fetchSimilarConditions(
                  brainstorm.indicationFreeText,
                );
                setBrainstorm({
                  ...brainstorm,
                  similarConditions: data,
                  similarConditionsCount: data.length,
                });
              }}
              searchText={`searching for all the ways people have listed studies in ${brainstorm.indication}`}
              resultText={`It turns out there are ${brainstorm.similarConditionsCount} other ways to say ${brainstorm.indication} so we've grouped those together for you.`}
              onNextStep={() =>
                nextStep('interventionType.freeTextEdit', 'indication')
              }
            />
          )}

          {/* SECTION INTERVENTION TYPE */}
          <InterventionTypeSection {...sectionProps} />

          {/* SECTION RELEVANCE */}
          <RelevanceSection {...sectionProps} />

          {/* SECTION PHASE */}
          <PhaseSection {...sectionProps} />

          {/* SECTION PATIENT COUNT */}
          <PatientCountSection {...sectionProps} />

          {/* SECTION INCLUSION CRITERIA */}
          <InclusionCriteriaSection {...sectionProps} />

          {/* SECTION EXCLUSION CRITERIA */}
          <ExclusionCriteriaSection {...sectionProps} />

          {/* SECTION TIMELINE */}
          <TimingSection {...sectionProps} saveBrainstorm={saveBrainstorm} />

          {/* SECTION EDIT OR NEW */}
          {(isCurrentStep('brainstorm.done') ||
            hasStep('brainstorm.done') ||
            hasStep('timing.correct')) && (
            <div className="flex justify-between mt-8">
              <span
                className="text-[#9491a7] flex items-center cursor-pointer"
                onClick={async () => {
                  await saveBrainstorm();
                }}
              >
                <span className="mr-2">Edit my Brainstorm</span>
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#00c7a81a]">
                  <img className="w-4 h-4" src={ArrowRightSvg} alt="Next" />
                </div>
              </span>
              <span
                className="text-[#9491a7] flex items-center cursor-pointer"
                onClick={handleBuildLibrary}
              >
                <span className="mr-2">Build a Library</span>
                <div className="w-8 h-8 flex items-center justify-center rounded-full bg-[#00c7a81a]">
                  <img className="w-4 h-4" src={ArrowRightSvg} alt="Next" />
                </div>
              </span>
            </div>
          )}

          {/* SECTION END */}
        </div>
      </div>
    );
  };

  const renderRightSection = () => {
    if (notCompleted('indication.edit')) return null;

    const sectionProps = {
      brainstorm,
      setBrainstorm,
      isSection,
      isCurrentStep,
      hasStep,
      notCompleted,
      nextStep,
      completeStep,
      removeStep,
      setCurrentStep,
    };

    return (
      <div className="flex flex-col w-2/5">
        <div className="overflow-y-auto max-h-[calc(100vh-60px)] mt-4 py-2 pl-6">
          {(isCurrentStep('inclusionCriteria.edit') ||
            isCurrentStep('inclusionCriteria.correct')) && (
            <InclusionCriteriaListSection {...sectionProps} />
          )}

          {(isCurrentStep('exclusionCriteria.edit') ||
            isCurrentStep('exclusionCriteria.correct')) && (
            <ExclusionCriteriaListSection {...sectionProps} />
          )}

          {hasStep('brainstorm.done') && (
            <BrainstormDoneSection
              {...sectionProps}
              handleBuildLibrary={handleBuildLibrary}
            />
          )}

          {showDebug && (
            <>
              <button
                onClick={saveBrainstorm}
                className="flex items-center justify-center w-[261.6px] h-[56px] px-[22.4px] py-[11.2px] rounded-[30px] bg-[#00C7A8] text-[#101117] hover:opacity-90 transition-opacity"
              >
                <span className="text-[19.6px] font-medium leading-[28px] tracking-[-0.02em] mr-[11.2px]">
                  Test Save
                </span>
              </button>
              <br />
              <b>STEP: {brainstorm.currentStep}</b>
              <br />
              <br />
              <b>STEPS:</b>
              <ul>
                {brainstorm.completedSteps.map((step) => (
                  <li key={step}>
                    <span
                      className="cursor-pointer"
                      onClick={() => setCurrentStep(step)}
                    >
                      {step}
                    </span>{' '}
                    <span
                      className="cursor-pointer text-red-500"
                      onClick={() => removeStep(step)}
                    >
                      X
                    </span>
                  </li>
                ))}
                <li>...</li>
              </ul>
            </>
          )}
        </div>
      </div>
    );
  };

  if (brainstormLoading) {
    return <div>Loading...</div>;
  }

  return (
    <ProjectLayout title="KadenceBio">
      <main className="flex-grow flex flex-col p-0 min-h-screen relative">
        <div className="flex p-0 flex-row w-full">
          {renderLeftColumn()}
          {renderMainSection()}
          {renderRightSection()}
        </div>
      </main>
    </ProjectLayout>
  );
};

export default BrainstormCreateEditPage;
