import { useEffect, useState, useMemo } from 'react';
import sortIcon from '../../../../images/sorting_icon.svg';
import clsx from 'clsx';
import noIcon from '../../../../images/no_icon.svg';
import yesIcon from '../../../../images/yes_icon.svg';
import { useAppSelector } from '../../../../app/hooks';
import { useGetSiteByIdQuery } from '@/api/slices/dashboard/siteApiSlice';
import { SiteData, Data, StudyData } from '@/types/SiteData';
import { useGetStudyByIdQuery } from '@/api/slices/site/StudySlice';
import { API_BASE_URL } from '@/api/config';

// type Data = {
//   country: string;
//   city: string;
//   hasStudies: boolean;
//   ran: number;
//   ptsPerSite: number;
//   trials: number;
//   avgSites: number;
//   population: number;
//   cases: number;
//   potentialPatients: number;
// };

// const data: Data[] = [
//   {
//     country: 'Belgium',
//     city: 'Brussels',
//     hasStudies: true,
//     ran: 0.11,
//     ptsPerSite: 0.48,
//     trials: 5,
//     avgSites: 7.6,
//     population: 6144600,
//     cases: 195,
//     potentialPatients: 11982,
//   },
//   {
//     country: 'France',
//     city: 'Paris',
//     hasStudies: true,
//     ran: 0.21,
//     ptsPerSite: 0.52,
//     trials: 7,
//     avgSites: 8.1,
//     population: 11142000,
//     cases: 243,
//     potentialPatients: 27075,
//   },
//   {
//     country: 'Germany',
//     city: 'Berlin',
//     hasStudies: false,
//     ran: 0.24,
//     ptsPerSite: 0.5,
//     trials: 8,
//     avgSites: 5.5,
//     population: 6144600,
//     cases: 158,
//     potentialPatients: 9708,
//   },
//   {
//     country: 'Greece',
//     city: 'Athens',
//     hasStudies: true,
//     ran: 0.18,
//     ptsPerSite: 0.36,
//     trials: 3,
//     avgSites: 4.3,
//     population: 3100000,
//     cases: 140,
//     potentialPatients: 4340,
//   },
//   {
//     country: 'Spain',
//     city: 'Barcelona',
//     hasStudies: false,
//     ran: 0.15,
//     ptsPerSite: 0.25,
//     trials: 6,
//     avgSites: 6.7,
//     population: 5658000,
//     cases: 330,
//     potentialPatients: 18671,
//   },
//   {
//     country: 'United Kingdom',
//     city: 'London',
//     hasStudies: true,
//     ran: 0.16,
//     ptsPerSite: 0.57,
//     trials: 7,
//     avgSites: 6.6,
//     population: 14000000,
//     cases: 360,
//     potentialPatients: 50400,
//   },
// ];

type SortIconProps = {
  column: keyof Data;
  currentColumn: keyof Data;
  direction: 'asc' | 'desc';
};

function SortIcon({ column, currentColumn, direction }: SortIconProps) {
  if (column !== currentColumn) {
    return <div className="inline-flex ml-2 w-3 h-3"></div>;
  }

  return (
    <img
      src={sortIcon}
      className={clsx(
        'inline-flex ml-2 w-3 h-3',
        direction === 'desc' && 'rotate-180',
      )}
      alt=""
    />
  );
}

function MoreThan2StudiesView({ hasStudies }: { hasStudies: boolean }) {
  const iconProps = {
    className: 'w-4 h-4 mr-2 inline-flex',
    src: hasStudies ? yesIcon : noIcon,
    alt: hasStudies ? 'Yes' : 'No',
  };

  const text = hasStudies ? 'Yes' : 'No';

  return (
    <>
      <img {...iconProps} alt="" />
      {text}
    </>
  );
}

type StudyTableProps = {
  studies: StudyData[];
};

function useFetchStudyData(studies: StudyData[]) {
  const [realData, setRealData] = useState<Data[]>([]);

  const studyIds = useMemo(() => studies.map((study) => study.id), [studies]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const sitesData = await Promise.all(
          studyIds.map(async (id) => {
            const response = await fetch(
              `${API_BASE_URL}/studies-by-name-or-id?name_or_id=${id}`,
            );
            const data = await response.json();
            return data;
          }),
        );

        const transformSiteData = (site: any): Data => ({
          hasStudies: !!site.studies,
          name: site.name,
          coordinates: site.coordinates,
          ran: Number(site?.avg_rand) || 0,
          ptsPerSite: Number(site?.ptsPerSite) || 0,
          trials: Number(site?.trials) || 0,
          avgSites: Number(site?.avgSites) || 0,
          population: Number(site?.population) || 0,
          cases: Number(site?.cases) || 0,
          potentialPatients: Number(site?.potentialPatients) || 0,
        });

        const combinedSites: Data[] = sitesData
          .filter(Boolean)
          .map(transformSiteData);

        setRealData(combinedSites);
      } catch (error) {
        console.error('Error fetching study data:', error);
        setRealData([]);
      }
    };

    fetchData();
  }, [studyIds]);

  console.log('LINE 188 realData: ', realData);
  return realData;
}

export default function StudyTable({ studies }: StudyTableProps) {
  const realData = useFetchStudyData(studies);

  const [sortColumn, setSortColumn] = useState<keyof Data>();
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('desc');

  const handleSort = (column: keyof Data) => {
    if (column === sortColumn) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortColumn(column);
      setSortDirection('asc');
    }
  };

  console.log('LINE 206 realData: ', realData);
  const displayingData =
    useAppSelector((s) => s.enrollmentScenario.sitesTab) === 'all_sites'
      ? realData
      : realData.slice(0, 3);

  const sortedData = useMemo(
    () =>
      [...displayingData].sort((a, b) => {
        // @ts-ignore
        if (a[sortColumn] < b[sortColumn])
          return sortDirection === 'asc' ? -1 : 1;
        // @ts-ignore
        if (a[sortColumn] > b[sortColumn])
          return sortDirection === 'asc' ? 1 : -1;
        return 0;
      }),
    [displayingData, sortColumn, sortDirection],
  );

  return (
    <div className="overflow-auto">
      <table className="min-w-full bg-[#1F1F23] text-gray-300">
        <thead>
          <tr className="text-left text-xs font-normal bg-zinc-800 text-[#B8B9C1] border-b border-gray-700">
            <th
              className="p-2 font-normal cursor-pointer"
              onClick={() => handleSort('hasStudies')}
            >
              2 studies
              {sortColumn && (
                <SortIcon
                  column="hasStudies"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
            <th
              className="p-2 font-normal cursor-pointer"
              onClick={() => handleSort('name')}
            >
              Name
              {sortColumn && (
                <SortIcon
                  column="name"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
            <th
              className="p-2 font-normal cursor-pointer"
              onClick={() => handleSort('coordinates')}
            >
              Coordinates
              {sortColumn && (
                <SortIcon
                  column="ran"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
            <th
              className="p-2 font-normal cursor-pointer"
              onClick={() => handleSort('ran')}
            >
              RAN
              {sortColumn && (
                <SortIcon
                  column="ran"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
            <th
              className="p-2 cursor-pointer font-normal"
              onClick={() => handleSort('trials')}
            >
              # of analyzed trials
              {sortColumn && (
                <SortIcon
                  column="trials"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
            <th
              className="p-2 font-normal cursor-pointer"
              onClick={() => handleSort('avgSites')}
            >
              Avg # of sites
              {sortColumn && (
                <SortIcon
                  column="avgSites"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
            <th
              className="p-2 cursor-pointer font-normal"
              onClick={() => handleSort('population')}
            >
              Total population
              {sortColumn && (
                <SortIcon
                  column="population"
                  currentColumn={sortColumn ?? ''}
                  direction={sortDirection}
                />
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((row, index) => (
            <tr
              key={index}
              className="border-b border-gray-700 text-start text-sm"
            >
              <td className="p-2">
                <MoreThan2StudiesView hasStudies={row.hasStudies} />
              </td>
              <td className="p-2">{row.name}</td>
              <td className="p-2">{row.coordinates}</td>
              <td className="p-2">{row.ran.toFixed(2)}</td>
              <td className="p-2">{row.ptsPerSite.toFixed(2)}</td>
              <td className="p-2">{row.avgSites.toFixed(1)}</td>
              <td className="p-2">{row.population.toLocaleString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
