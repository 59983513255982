export type ScenarioInfoViewProps = {
  title: string;
  subtitle?: string;
  description?: string;
  color: string;
  study: string;
};

export default function ScenarioInfoView(props: ScenarioInfoViewProps) {
  const { title, subtitle, color, description, study } = props;

  return (
    <div className="px-8 py-6 rounded" style={{ backgroundColor: '#2c2b32bb' }}>
      <div className="p-2 flex flex-row items-start relative">
        <div
          className="absolute top-0 bottom-0 left-0 w-1.5"
          style={{ backgroundColor: color }}
        ></div>
        <div className="relative mr-5"></div>

        <div className="flex flex-col flex-grow items-start mr-5">
          <div className="flex flew-row items-center">
            <h1 className="text-white text-xl font-medium mr-5 text-left">
              {title}{' '}
              {subtitle && <span className="font-normal">{subtitle} </span>}
            </h1>
            {description && (
              <p className="text-base text-zinc-200">{description}</p>
            )}
          </div>
          <p className="text-xs text-gray-100">{study}</p>
        </div>
      </div>
    </div>
  );
}
