import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectLayout } from './components/ProjectsLayout';
import plusIcon from '../../images/plus-lg.svg';
import createStudyImage from '../../images/create_study.svg';
import { useDispatch } from 'react-redux';
import { clearStudyCreation } from '../../features/create_study/create_study_slice';

const CreateStudyPage: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleCreateProject = () => {
    dispatch(clearStudyCreation());
    navigate('/wireframes/create-project-form');
  };

  return (
    <ProjectLayout title="Projects">
      <div className="min-h-screen flex flex-col">
        <main className="flex-grow flex flex-col items-center justify-center p-4">
          <img
            src={createStudyImage}
            alt="Create Study Image"
            className="w-[160px] h-[160px] mb-6"
          />
          <h1 className="text-2xl font-medium leading-loose text-center mb-2 text-[#b8b9c1]">
            It seems like you have no studies yet!
          </h1>

          <div className="text-center text-[#b8b9c1] text-base font-medium leading-snug mb-6">
            Start exploring now! Click the button below to create your first
            study
            <br /> and uncover the details that interest you the most.
          </div>

          <button
            className="btn btn-primary text-neutral-800 font-normal"
            onClick={handleCreateProject}
          >
            Create Study <img src={plusIcon} className="inline-flex w-4 h-4" />
          </button>
        </main>
      </div>
    </ProjectLayout>
  );
};

export default CreateStudyPage;
