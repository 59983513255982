import Gantt from 'frappe-gantt';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import randImage from '@/images/rand.svg';
import './gantt.css';
import clsx from 'clsx';
import RandView from '@/pages/dashboard/components/RandView';
import { TimelineInfo } from '@/types/SiteMap';
import styled from 'styled-components';

// Add styled-components styles
const GanttStyles = styled.div`
  .bar-label {
    dominant-baseline: middle;
    text-anchor: start;
    fill: white;
    pointer-events: none;
  }

  .bar-text-overflow {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .bar-wrapper foreignObject {
    overflow: hidden;
  }
`;

const onlyDate = (date: Date) => {
  return new Date(date.setHours(0, 0, 0, 0));
};

interface TimelineChartProps {
  timelineSites: TimelineInfo[];
  onChange?: (sites: TimelineInfo[]) => void;
}

const SiteHeader = ({ sites }: { sites: TimelineInfo[] }) => {
  const avgRandomizationTime = useMemo(() => {
    if (sites.length === 0) {
      return 0;
    }

    return (
      sites.reduce((acc: number, site: TimelineInfo) => acc + site.rand, 0) /
      sites.length
    );
  }, [sites]);

  return (
    <div className="flex flex-row text-white text-left pl-4">
      <div className="flex flex-col mr-10">
        <div className="text-[0.43rem] font-semibold">estimated</div>
        <div className="font-medium leading-4">Time to 1 Patient</div>
      </div>

      <div className="flex flex-col">
        <div
          className="text-[0.43rem] font-semibold"
          style={{ color: '#BFBDC9' }}
        >
          avg. randomization{' '}
        </div>
        <RandView value={Number(avgRandomizationTime.toFixed(2))} medium />
      </div>
    </div>
  );
};

const SiteItem = ({
  site,
  leadSite,
}: {
  site: TimelineInfo;
  leadSite: boolean;
}) => {
  const rand = site.rand.toFixed(2).replace(/\.?0+$/, '');

  return (
    <div className="flex flex-row pl-4 pr-2 py-1.5 text-left h-[50px] items-center">
      <div
        className="flex flex-row px-1.5 py-0.5 mr-4 h-min items-center rounded"
        style={{ minWidth: 70, backgroundColor: '#2C2B32' }}
      >
        <img src={randImage} alt="rand icon" className="h-3 mr-1.5" />
        <span className="text-white text-sm">{rand}</span>
      </div>
      <div
        className={clsx('text-white text-sm', leadSite && 'font-medium')}
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          display: 'block',
          textOverflow: 'ellipsis',
        }}
      >
        {site.name}
      </div>
    </div>
  );
};

type GanttViewModeOption = 'Day' | 'Week' | 'Month' | 'Year';

const GanttViewMode = (props: {
  isSelected: boolean;
  changeViewMode: (viewMode: GanttViewModeOption) => void;
  viewMode: GanttViewModeOption;
}) => {
  const { changeViewMode, viewMode, isSelected } = props;

  return (
    <div
      className={clsx(
        'px-4 pt-[5px] pb-1 cursor-pointer rounded-3xl mr-2 text-gray-100 text-sm',
        isSelected
          ? 'bg-primary border-primary'
          : 'bg-gray-800 border border-gray-700',
      )}
      onClick={() => changeViewMode(viewMode)}
    >
      {viewMode}
    </div>
  );
};

type GanttTask = {
  id: string;
  start: string;
  end: string;
  // ... other task properties
};

export default function TimelineChart({
  timelineSites,
  onChange,
}: TimelineChartProps) {
  const ganttRef = useRef<Gantt | null>(null);
  const currentObservers = useRef<MutationObserver[]>([]);
  const [gantt, setGantt] = useState<Gantt | null>(null);
  const [selectedGanttViewMode, setSelectedGanttViewMode] =
    useState<GanttViewModeOption>('Month');

  const headerHeight = 60;
  const siteItemHeight = 50;
  const scrollbarHeight = 24;

  const scrollPositionRef = useRef<number>(0);
  const ganttContainerRef = useRef<HTMLDivElement>(null);

  const isDragging = useRef(false);

  const drawDashedLines = useCallback(() => {
    const lines = document.querySelectorAll('.grid g line.row-line');
    lines.forEach((line) => {
      const y1 = line.getAttribute('y1');
      if (!y1) {
        return;
      }

      const yOffSet = 25;
      const newY1 = (parseFloat(y1) - yOffSet).toString();
      line.setAttribute('y1', newY1);
      line.setAttribute('y2', newY1);
    });
  }, []);

  const updateGanttHeight = useCallback(() => {
    const ganttElement = document.querySelector('svg.gantt');
    const totalHeight =
      headerHeight + siteItemHeight * timelineSites.length + scrollbarHeight;
    ganttElement?.setAttribute('height', totalHeight.toString());
  }, []);

  const addFirstPatientIcons = useCallback(() => {
    const bars = document.querySelectorAll('.bar-wrapper .bar');
    bars.forEach((bar) => {
      const squareClass = 'first-p-icon-bg';
      const text1Class = 'first-p-icon-text-1';
      const text2Class = 'first-p-icon-text-2';

      const createFirstPIconIfNeeded = () => {
        const minBarWidthForIcon = 200;
        const onlyIconBarWidth = 142;

        const firstPIconSize = 24;
        const width = parseFloat(bar.getAttribute('width')!);

        const shouldDisplayOnlyIcon =
          width > firstPIconSize * 1.5 && width <= onlyIconBarWidth;

        if (width < minBarWidthForIcon && !shouldDisplayOnlyIcon) {
          const squareElement = bar.parentElement?.querySelector(
            `.${squareClass}`,
          );
          const textElement1 = bar.parentElement?.querySelector(
            `.${text1Class}`,
          );
          const textElement2 = bar.parentElement?.querySelector(
            `.${text2Class}`,
          );

          squareElement?.remove();
          textElement1?.remove();
          textElement2?.remove();
          return;
        }

        if (bar.parentElement?.querySelector(`.${squareClass}`)) {
          return;
        }

        const svgNS = 'http://www.w3.org/2000/svg';
        const squareElement = document.createElementNS(svgNS, 'rect');
        squareElement.classList.add(squareClass);
        squareElement.setAttribute('width', firstPIconSize.toString());
        squareElement.setAttribute('height', firstPIconSize.toString());
        squareElement.setAttribute('fill', '#00C7A8');
        squareElement.setAttribute('rx', '4');
        squareElement.setAttribute('ry', '4');
        bar.parentElement?.appendChild(squareElement);

        const textElement1 = document.createElementNS(svgNS, 'text');
        textElement1.classList.add(text1Class);
        textElement1.setAttribute('fill', 'white');
        textElement1.setAttribute('font-size', '12');
        textElement1.textContent = '1';

        const textElement2 = document.createElementNS(svgNS, 'text');
        textElement2.classList.add(text2Class);
        textElement2.setAttribute('fill', 'white');
        textElement2.setAttribute('font-size', '12');
        textElement2.textContent = 'p';

        bar.parentElement?.appendChild(textElement1);
        bar.parentElement?.appendChild(textElement2);
      };

      const updateCoordinates = () => {
        const squareElement = bar.parentElement?.querySelector(
          `.${squareClass}`,
        );
        const textElement1 = bar.parentElement?.querySelector(`.${text1Class}`);
        const textElement2 = bar.parentElement?.querySelector(`.${text2Class}`);

        if (!squareElement || !textElement1 || !textElement2) {
          return;
        }

        const newX = parseFloat(bar.getAttribute('x')!);
        squareElement.setAttribute('x', (newX + 4).toString());
        textElement1.setAttribute('x', (newX + 8).toString());
        textElement2.setAttribute('x', (newX + 16).toString());

        const newY = parseFloat(bar.getAttribute('y')!);
        squareElement.setAttribute('y', (newY + 4).toString());
        textElement1.setAttribute('y', (newY + 18).toString());
        textElement2.setAttribute('y', (newY + 22).toString());
      };

      createFirstPIconIfNeeded();
      updateCoordinates();

      const barText = bar.parentElement?.querySelector('.bar-label');
      if (barText) {
        barText.setAttribute(
          'x',
          (parseFloat(bar.getAttribute('x')!) + 32).toString(),
        );
        barText.setAttribute(
          'width',
          (parseFloat(bar.getAttribute('width')!) - 36).toString(),
        );
        barText.classList.add('bar-text-overflow');
      }

      const observer = new MutationObserver((mutations) => {
        mutations.forEach((mutation) => {
          const attribute = mutation.attributeName;
          if (
            mutation.type === 'attributes' &&
            (attribute === 'x' || attribute === 'width')
          ) {
            createFirstPIconIfNeeded();
            updateCoordinates();

            const barText = bar.parentElement?.querySelector('.bar-label');
            if (barText) {
              barText.setAttribute(
                'x',
                (parseFloat(bar.getAttribute('x')!) + 32).toString(),
              );
              barText.setAttribute(
                'width',
                (parseFloat(bar.getAttribute('width')!) - 36).toString(),
              );
            }
          }
        });
      });

      const config = { attributes: true, childList: false, subtree: false };
      observer.observe(bar, config);
      currentObservers.current.push(observer);
    });
  }, []);

  const handleChange = useCallback(
    (task: GanttTask & { _start: Date; _end: Date }, start?: boolean) => {
      if (timelineSites.length === 0) {
        return;
      }

      if (start) {
        isDragging.current = true;
        return;
      }

      if (ganttContainerRef.current) {
        scrollPositionRef.current = ganttContainerRef.current.scrollLeft;
      }

      const updatedSites = timelineSites.map((site) => {
        if (site.name === task.id) {
          return {
            ...site,
            start: task._start,
            end: task._end,
            firstPatientStartDate: onlyDate(task._start),
            firstPatientEndDate: onlyDate(task._end),
          };
        }
        return site;
      });

      onChange?.(updatedSites);

      requestAnimationFrame(() => {
        if (ganttContainerRef.current) {
          ganttContainerRef.current.scrollLeft = scrollPositionRef.current;
        }
      });

      isDragging.current = false;
    },
    [],
  );

  const setupGantt = useCallback(
    (viewMode: GanttViewModeOption = 'Month') => {
      currentObservers.current.forEach((observer) => observer.disconnect());
      currentObservers.current = [];

      if (ganttRef.current) {
        const element = document.querySelector('#gantt');
        if (element) element.innerHTML = '';
      }

      const currentScroll = ganttContainerRef.current?.scrollLeft || 0;

      const monthsBetween = (date1: Date, date2: Date) => {
        const diff = date2.getTime() - date1.getTime();
        const months = diff / (1000 * 60 * 60 * 24 * 30);
        return Math.ceil(months);
      };

      const tasks: Gantt.Task[] = timelineSites.map((site) => {
        const months = monthsBetween(
          site.firstPatientStartDate,
          site.firstPatientEndDate,
        );

        return {
          id: site.name,
          name: `First patient in ${months} months`,
          start: site.firstPatientStartDate.toISOString().split('T')[0],
          end: site.firstPatientEndDate.toISOString().split('T')[0],
          progress: 0,
          custom_class: 'bar-milestone',
          dependencies: '',
        };
      });

      const gantt = new Gantt('#gantt', tasks, {
        bar_height: 32,
        view_mode: viewMode,
        on_date_change: (task, start, end) => {
          const mouseUp = !isDragging.current;
          handleChange(
            task as GanttTask & { _start: Date; _end: Date },
            !mouseUp,
          );
        },
      });
      ganttRef.current = gantt;
      setGantt(gantt);

      updateGanttHeight();
      drawDashedLines();
      addFirstPatientIcons();

      requestAnimationFrame(() => {
        if (ganttContainerRef.current) {
          ganttContainerRef.current.scrollLeft = currentScroll;
        }
      });
    },
    [timelineSites.length],
  );

  useEffect(() => {
    setupGantt(selectedGanttViewMode);
  }, [selectedGanttViewMode, timelineSites.length]);

  const changeViewMode = useCallback((viewMode: GanttViewModeOption) => {
    currentObservers.current.forEach((observer) => observer.disconnect());
    currentObservers.current = [];

    ganttRef.current?.change_view_mode(viewMode);
    setSelectedGanttViewMode(viewMode);

    drawDashedLines();
    updateGanttHeight();
    addFirstPatientIcons();
  }, []);

  return (
    <GanttStyles>
      <div className="w-full">
        <div className="timeline mt-4 border border-gray-700 flex flex-col items-start rounded-xl shadow overflow-hidden">
          <div className="flex flex-row w-full">
            <div
              className="flex flex-col"
              style={{ minWidth: 300, width: 300 }}
            >
              <div className="flex flex-col justify-center h-[60px] ">
                <SiteHeader sites={timelineSites} />
              </div>
              <div className="w-full flex flex-col flex-shrink-0">
                {timelineSites.map((site, index) => (
                  <SiteItem
                    key={site.name}
                    site={site}
                    leadSite={index === 0}
                  />
                ))}
              </div>
            </div>

            <div className="flex flex-grow overflow-hidden">
              <div
                ref={ganttContainerRef}
                id="gantt"
                className="gantt gantt-target dark flex flex-grow overflow-auto"
              ></div>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-end pr-4 mt-4">
          <GanttViewMode
            isSelected={selectedGanttViewMode === 'Day'}
            changeViewMode={changeViewMode}
            viewMode="Day"
          />
          <GanttViewMode
            isSelected={selectedGanttViewMode === 'Week'}
            changeViewMode={changeViewMode}
            viewMode="Week"
          />
          <GanttViewMode
            isSelected={selectedGanttViewMode === 'Month'}
            changeViewMode={changeViewMode}
            viewMode="Month"
          />
          {/* <GanttViewMode gantt={gantt} viewMode="Year" /> */}
        </div>
      </div>
    </GanttStyles>
  );
}
