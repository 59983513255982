import { FetchArgs } from '@reduxjs/toolkit/query/react';
import { baseApi } from '../../api';
import { ClickHouseQueryParams, SiteDetails, StudyDetails } from '../types';

/**
 * Interface representing the response for a list of studies.
 * @interface
 */

export interface StudyResponse {
  /**
   * The average randomization number of the studies.
   * @type {number}
   */
  avg_rand: number;

  /**
   * The average number of patients enrolled in the studies.
   * @type {number}
   */
  avg_num_patients: number;

  /**
   * The maximum randomization number across the studies.
   * @type {number}
   */
  max_rand: number;

  /**
   * The maximum number of patients enrolled in the studies.
   * @type {number}
   */
  max_num_patients: number;

  /**
   * The list of study details.
   * @type {StudyDetails[]}
   */
  studies: StudyDetails[];
}

/**
 * Interface representing the response for a list of studies based on a search query.
 * @interface
 */
interface StudySearchResponse {
  /**
   * The average randomization number of the studies.
   * @type {number}
   */
  avg_rand: number;

  /**
   * The average number of patients enrolled in the studies.
   * @type {number}
   */
  avg_num_patients: number;

  /**
   * The maximum randomization number across the studies.
   * @type {number}
   */
  max_rand: number;

  /**
   * The maximum number of patients enrolled in the studies.
   * @type {number}
   */
  max_num_patients: number;

  /**
   * The list of study details.
   * @type {StudyDetails[]}
   */
  studies: StudyDetails[];
}

/**
 * Interface representing detailed information about a specific study by its NCT ID.
 * @interface
 */
interface StudyModemResponse {
  /**
   * The name of the study.
   * @type {string}
   */
  name: string;

  /**
   * The randomization number of the study.
   * @type {number}
   */
  rand: number;

  /**
   * The duration of the study in days.
   * @type {number}
   */
  duration: number;

  /**
   * The current status of the study.
   * @type {string}
   */
  status: string;

  /**
   * The name of the sponsor of the study.
   * @type {string}
   */
  sponsor: string;

  /**
   * The number of patients enrolled in the study.
   * @type {number | null}
   */
  num_patients: number | null;

  /**
   * The number of sites involved in the study.
   * @type {number}
   */
  num_sites: number;

  /**
   * A brief description of the study.
   * @type {string}
   */
  description: string;

  /**
   * A detailed description of the study.
   * @type {string}
   */
  detailed_description: string;

  /**
   * The list of site details associated with the study.
   * @type {SiteDetails[]}
   */
  sites: SiteDetails[];
}

/**
 * Type for the query parameters used in getting studies.
 * Inherits properties from ClickHouseQueryParams.
 * @type {ClickHouseQueryParams}
 */
type GetStudyQueryParams = ClickHouseQueryParams;

/**
 * Type for the query parameters used in getting a specific study by its NCT ID.
 * Inherits properties from ClickHouseQueryParams and includes nct_id.
 * @type {ClickHouseQueryParams & { nct_id: string }}
 */
type GetStudyByModemQueryParams = ClickHouseQueryParams & {
  nct_id: string;
};

/**
 * Type for the query parameters used in getting a specific study by its name or NCT ID.
 * @type {{ name_or_nct_id: string }}
 */
type GetStudyByNameOrNctIdQueryParams = {
  name_or_nct_id: string;
};

/**
 * Defines the API endpoints for study data using baseApi.
 * @constant
 * @type {object}
 */
export const studiesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    /**
     * Endpoint to get a list of studies based on query parameters.
     * @param {GetStudyQueryParams} params - The query parameters.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getStudies: builder.query<StudyResponse, GetStudyQueryParams>({
      query: (params: GetStudyQueryParams): string | FetchArgs => {
        const {
          country,
          phase,
          intervention_type,
          condition,
          include_start_date,
          include_end_date,
          include_num_patients,
        } = params;

        const queryParams: URLSearchParams = new URLSearchParams();

        if (country) {
          queryParams.append('country', country);
        }

        // Append multiple intervention_type parameters if they exist
        if (intervention_type) {
          intervention_type.forEach((type: string): void => {
            queryParams.append('intervention_type', type);
          });
        }

        // Append multiple condition parameters if they exist
        if (condition) {
          condition.forEach((condition: string): void => {
            queryParams.append('condition', condition);
          });
        }

        // Optionally append phase if it's provided
        if (phase) {
          queryParams.append('phase', phase);
        }

        if (include_start_date) {
          queryParams.append('include_start_date', 'true');
        }

        if (include_end_date) {
          queryParams.append('include_end_date', 'true');
        }
        if (include_num_patients) {
          queryParams.append('include_num_patients', 'true');
        }

        // Construct the full URL with query parameters
        return `studies-v2?${queryParams}`;
      },
    }),
    /**
     * Endpoint to get detailed information about a specific study by its NCT ID.
     * @param {GetStudyByModemQueryParams} params - The query parameters.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getStudyByNctId: builder.query<
      StudyModemResponse,
      GetStudyByModemQueryParams
    >({
      query: (params: GetStudyByModemQueryParams): string | FetchArgs => {
        const { country, phase, intervention_type, condition, nct_id } = params;

        const queryParams: URLSearchParams = new URLSearchParams();
        if (country) {
          queryParams.append('country', country);
        }

        if (nct_id) {
          queryParams.append('nct_id', nct_id);
        }

        // Optionally append phase if it's provided
        if (phase) {
          queryParams.append('phase', phase);
        }

        // Append multiple intervention_type parameters if they exist
        if (intervention_type) {
          intervention_type.forEach((type: string): void => {
            queryParams.append('intervention_type', type);
          });
        }

        // Append multiple condition parameters if they exist
        if (condition) {
          condition.forEach((cond: string): void => {
            queryParams.append('condition', cond);
          });
        }

        // Construct the full URL with query parameters
        return `study-modem?${queryParams}`;
      },
    }),
    /**
     * Endpoint to get a list of studies based on name or NCT ID.
     * @param {GetStudyByNameOrNctIdQueryParams} params - The query parameters.
     * @param {string} params.name_or_nct_id - The name or NCT ID of the study to search for.
     * @returns {string | FetchArgs} The URL with query parameters.
     */
    getStudyByNameOrNctId: builder.query<
      StudySearchResponse,
      GetStudyByNameOrNctIdQueryParams
    >({
      query: (params: GetStudyByNameOrNctIdQueryParams): string | FetchArgs => {
        const { name_or_nct_id } = params;

        // Initialize URLSearchParams
        const queryParams: URLSearchParams = new URLSearchParams();

        // Append name_or_nct_id parameter if provided
        if (name_or_nct_id) {
          queryParams.append('name_or_id', name_or_nct_id);
        }

        // Construct the full URL with query parameters
        return `/studies-by-name-or-id?${queryParams}`;
      },
      // The `keepUnusedDataFor` option controls how long the cached data should be kept
      keepUnusedDataFor: 600, // Keep the cached data for 10 minutes
    }),
  }),
});

// Export the hooks for querying studies
export const {
  useGetStudiesQuery,
  useGetStudyByNctIdQuery,
  useGetStudyByNameOrNctIdQuery,
} = studiesApi;
