import NeurouteAnimatedSearching from '../components/NeurouteAnimatedSearching';

const SearchingSection: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => (
  <div className="flex flex-row gap-3 align-middle items-center mt-6">
    <NeurouteAnimatedSearching />
    <p className="text-[#9491a7] text-[15px] font-normal font-['Satoshi Variable']">
      {children}
    </p>
  </div>
);

export default SearchingSection;
