import React from 'react';

interface CustomLabelProps {
  viewBox: any;
  value: string;
  title: string;
}

const CustomLabel: React.FC<CustomLabelProps> = ({ viewBox, value, title }) => {
  const { x, y } = viewBox;
  const labelY = y + 4;
  return (
    <g>
      <text
        x={x}
        y={labelY - 10}
        fill="#BFBDC9"
        textAnchor="left"
        fontSize={10}
      >
        {title} {value}
      </text>
    </g>
  );
};

export default CustomLabel;
