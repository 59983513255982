import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Product, Subscription } from '@invertase/firestore-stripe-payments';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useEffect } from 'react';
import { PaymentManager } from './payment_manager';
import { useAuth } from '../../pages/auth/AuthContext';

type PaymentsInitialisationState =
  | 'not_initialized'
  | 'initializing'
  | 'ready'
  | 'error';

interface PaymentsState {
  state: PaymentsInitialisationState;
  products: Product[];
  subscriptions: Subscription[];
}

const initialState: PaymentsState = {
  state: 'not_initialized',
  products: [],
  subscriptions: [],
};

export const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setProducts: (state, action: PayloadAction<Product[]>) => {
      state.products = action.payload;
    },
    setSubscriptions: (state, action: PayloadAction<Subscription[]>) => {
      state.subscriptions = action.payload;
    },
    setState: (state, action: PayloadAction<PaymentsInitialisationState>) => {
      state.state = action.payload;
    },
    clearPayments: (state) => {
      state.state = initialState.state;
      state.products = initialState.products;
      state.subscriptions = initialState.subscriptions;
    },
  },
});

export const { setState, setProducts, setSubscriptions, clearPayments } =
  paymentsSlice.actions;

export const usePayments = () => {
  const { authUser } = useAuth();
  const state = useAppSelector((s) => s.payments.state);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (state !== 'not_initialized' || !authUser) {
      return;
    }

    dispatch(setState('initializing'));

    PaymentManager.getInstance()
      .getProducts()
      .then((products) => {
        console.log('Products:', products);
        dispatch(setProducts(products));
        dispatch(setState('ready'));
      })
      .catch((error) => {
        dispatch(setState('error'));
        console.error('Failed to load products', error);
      });

    PaymentManager.getInstance()
      .getMySubscriptions()
      .then((subscriptions) => {
        dispatch(setSubscriptions(subscriptions));
        console.log('subscriptions:', subscriptions);
      })
      .catch((error) => {
        console.error('Failed to load subscriptions', error);
      });
  }, [state, authUser]);
};

export default paymentsSlice.reducer;
