import React from 'react';
import { Brainstorm } from '@/types/Brainstorm';
import { StepSectionProps } from '../stepSections/StepSectionProps';

const InclusionCriteriaListSection: React.FC<StepSectionProps> = ({
  brainstorm,
  setBrainstorm,
  isSection,
  isCurrentStep,
  hasStep,
  notCompleted,
  nextStep,
  completeStep,
  removeStep,
  setCurrentStep,
}) => {
  const handleCriteriaClick = (criteria: string) => {
    if (brainstorm.inclusionCriteria?.includes(criteria)) {
      // Remove the criteria if it's already selected
      setBrainstorm({
        ...brainstorm,
        inclusionCriteria: brainstorm.inclusionCriteria.filter(
          (item) => item !== criteria,
        ),
      });
    } else {
      // Add the criteria if it's not selected
      setBrainstorm({
        ...brainstorm,
        inclusionCriteria: [...(brainstorm.inclusionCriteria || []), criteria],
      });
    }
  };

  return (
    <div className="mb-8">
      <h2 className="text-white text-2xl font-medium mb-4">Population</h2>
      <p className="text-[#9491a7] mb-4">
        All I/C across relevant studies related to {brainstorm.interventionType}
      </p>
      <ul className="list-disc pl-6">
        {brainstorm.availableInclusionCriteria.map((criteria, index) => (
          <li
            key={index}
            className={`text-[#9491a7] mb-2 cursor-pointer ${
              brainstorm.inclusionCriteria.includes(criteria)
                ? 'bg-[#D9D9D9] bg-opacity-10'
                : ''
            }`}
            onClick={() => handleCriteriaClick(criteria)}
          >
            {criteria}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default InclusionCriteriaListSection;
